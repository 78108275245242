import React, { useState } from 'react';
import GuestLayout from '../components/GuestLayout';
import { T, t } from '../components/T';
import { useSelector } from 'react-redux';
import { ReactComponent as LockIcon } from '../assets/icons/lock.svg';
import TeamIcon from '../assets/icons/team-icon.svg';
import { resetPasswordApi } from '../api/endpoints';
import Textfield from '../components/Textfield/Textfield';
import Button from '../components/Button/Button';
import { useForm, Controller } from 'react-hook-form';
import { ROUTE_HOME } from '../routes/routes';
import { passwordRegex } from '../helpers/validation';

const ResetPassword = () => {
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const [isLoading, setIsLoading] = useState(false);
  const [sussess, setSussess] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });

  const resetPasswordHandler = (data) => {
    setIsLoading(true);
    localStorage.clear();
    const payload = {
      item: {
        password: data?.password,
        confirmPassword: data?.passwordConfirm,
        token: window.location.href.split('reset-password/')[1],
      },
    };
    resetPasswordApi(payload)
      .then(() => setSussess(true))
      .finally(() => setIsLoading(false));
  };

  return (
    <GuestLayout>
      {!sussess && (
        <div className='guest-flow-form-wrapper'>
          <form onSubmit={handleSubmit(resetPasswordHandler)}>
            <h2 className='fw-bolder'>
              <T>app.reset.password</T>
            </h2>
            <div className={`mt-4 mb-4`}>
              <Controller
                name='password'
                control={control}
                rules={{
                  required: t(lng, 'app.password-is-required'),
                  validate: (value) => passwordRegex.test(value) || t(lng, 'app.password-is-invalid'),
                }}
                render={({ field }) => {
                  return (
                    <>
                      <label className='guest-textfield-label'>
                        <T>app.password-placeholder</T>
                      </label>
                      <Textfield
                        id='pass'
                        trim
                        type='password'
                        size='lg'
                        disabled={isLoading}
                        icon={<LockIcon />}
                        fullWidth
                        placeholder={t(lng, 'app.populate.pass')}
                        showValidationEndIcons
                        error={errors?.password?.message}
                        {...field}
                      />
                    </>
                  );
                }}
              />
            </div>
            <div className={`mb-4`}>
              <Controller
                name='passwordConfirm'
                control={control}
                rules={{
                  required: t(lng, 'app.password-is-required'),
                  validate: (value, formValues) =>
                    formValues?.password === value || t(lng, 'app.strings-is-not-match'),
                }}
                render={({ field }) => {
                  return (
                    <>
                      <label className='guest-textfield-label'>
                        <T>app.password-confirm-placeholder</T>
                      </label>
                      <Textfield
                        id='repeatpass'
                        trim
                        type='password'
                        size='lg'
                        disabled={isLoading}
                        icon={<LockIcon />}
                        fullWidth
                        placeholder={t(lng, 'app.confirm.pass')}
                        showValidationEndIcons
                        error={errors?.passwordConfirm?.message}
                        {...field}
                      />
                    </>
                  );
                }}
              />
            </div>
            {isLoading ? (
              <div className='text-center mt-5'>
                <div className='spinner-border text-success' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </div>
              </div>
            ) : (
              <div className='d-grid mt-4'>
                <Button fullWidth disabled={!isValid}>
                  <T>app.reset.pass</T>
                </Button>
              </div>
            )}
          </form>
        </div>
      )}
      {sussess && (
        <div className='guest-flow-form-wrapper'>
          <div className='guest-flow-form-main'>
            <div className='susess-text-align'>
              <div className='icon-title'>
                <img src={TeamIcon} />
              </div>
              <div className='fw-bolder'>The password reset is sent for approval</div>
              <div className='guest-flow-subtitle'>The request should be approved by the Owner.</div>
            </div>
          </div>
          <Button fullWidth onClick={() => window.location.replace(ROUTE_HOME)}>
            <T>app.back.to.login</T>
          </Button>
        </div>
      )}
    </GuestLayout>
  );
};

export default ResetPassword;
