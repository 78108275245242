import { useRef, useState } from 'react';
import { useClickOutside } from '../../helpers/hooks/useClickOutside';
import './DropDownMenu.css';
import { Link } from 'react-router-dom';
import { T } from '../../components/T';
import carouselIcon from '../../assets/icons/carousel-icon.svg';
import { ROUTE_BILLING, ROUTE_HOME, ROUTE_LOGIN_HISTORY, ROUTE_PROFILE } from '../../routes/routes';
import { ReactComponent as PlanIcon } from '../../assets/icons/plan-icon.svg';

const DropDownMenu = ({ data }) => {
  const [open, setOpen] = useState(false);
  const wrapperDown = useRef('dropdown');
  useClickOutside(wrapperDown, () => {
    setOpen(false);
  });
  return (
    <div
      className='v-dropdown-menu profile'
      ref={wrapperDown}
      onClick={() => {
        setOpen(!open);
      }}
    >
      <div className='v-dropdown-main'>
        <i className='icon-contact main-icon' />
        <span className='user-dropdown-name'>{data.fullName}</span>
        <img className={`dropdown-carousel-icon ${open ? 'open' : ''}`} src={carouselIcon} />
      </div>
      {open && (
        <div className='v-dropdown-data profile'>
          <Link to={ROUTE_PROFILE}>
            <div
              className='data-dropdown profile'
              onClick={() => {
                setOpen(false);
              }}
            >
              <i className='icon-contact main-icon' />
              <span>
                <T>app.menu.profile</T>
              </span>
            </div>
          </Link>
          <Link to={ROUTE_BILLING}>
            <div
              className='data-dropdown profile'
              onClick={() => {
                setOpen(false);
              }}
            >
              <PlanIcon style={{ marginLeft: 5, marginRight: 3 }} />
              <span>
                <T>app.plan.and.billing</T>
              </span>
            </div>
          </Link>
          <Link to={ROUTE_LOGIN_HISTORY}>
            <div
              className='data-dropdown profile'
              onClick={() => {
                setOpen(false);
              }}
            >
              <i className='icon-pending main-icon' />
              <span>
                <T>app.menu.history</T>
              </span>
            </div>
          </Link>
          <div
            className='data-dropdown profile'
            onClick={() => {
              localStorage.clear();
              window.location.replace(ROUTE_HOME);
            }}
          >
            <i className='icon-logout' />
            <span>
              <T>app.menu.signout</T>
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
export default DropDownMenu;
