import { T, t } from '../../T';
import VModal from '../../Modal/VModal';
import ActionModal from '../ActionModal';
import { useSelector } from 'react-redux';
import Stepper from '../../Stepper/Stepper';
import { useState } from 'react';
import { ReactComponent as NameIcon } from '../../../assets/icons/email-icon.svg';
import { ReactComponent as RolesIcon } from '../../../assets/icons/pointer-icon2.svg';
import { ReactComponent as SummaryIcon } from '../../../assets/icons/summary-icon2.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import './InviteTeamMemberModal.css';
import 'swiper/css';
import Button from '../../Button/Button';
import { editTeamApi, sendInvitationApi } from '../../../api/endpoints';
import Textfield from '../../Textfield/Textfield';
import { useForm, Controller } from 'react-hook-form';
import CheckboxNew from '../../CheckboxNew/CheckboxNew';
import { useSwiperSlider } from '../../../helpers/hooks/useSwiperSlider';

const InviteTeamMemberModal = ({ toggleFunction, successCallback, roles, editData }) => {
  const isEdit = editData?.email;
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const [successAdded, setsSuccessAdded] = useState(false);
  const [sliding, setSliding] = useState(false);
  const [roleDescription, setRoleDescription] = useState();
  const [currentStep, setCurrentStep] = useState({ id: 1, name: !isEdit ? 'email' : 'roles' });
  const [selectedRoles, setSelectedRoles] = useState(editData?.roles || []);
  const {
    handleSubmit,
    control,
    formState: { errors, dirtyFields, isValid },
    getValues,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: editData?.email || '',
    },
  });
  const [steps, setSteps] = useState([
    ...(!isEdit
      ? [
          {
            id: 1,
            icon: <NameIcon />,
            text: 'app.email.invite',
            name: 'email',
            completed: false,
          },
        ]
      : []),
    {
      id: 2 - (!isEdit ? 0 : 1),
      icon: <RolesIcon />,
      text: 'app.roles.invite',
      name: 'roles',
      completed: false,
    },
    {
      id: 3 - (!isEdit ? 0 : 1),
      icon: <SummaryIcon />,
      text: 'app.summary',
      name: 'summary',
      completed: false,
    },
  ]);
  const { handleCompleteStep, handleSwiperNextSlide, swiperContainerHeight, swiperRef } =
    useSwiperSlider(setSteps);

  const { icon, subText } = stepDescriptionMapping()[currentStep?.name];

  const inviteTeamMember = (data) => {
    const payload = {
      item: {
        email: data?.email,
        roles: selectedRoles?.map((role) => role?.id),
      },
    };
    if (editData?.email) {
      editTeamApi(editData?.id, payload).then(() => {
        setsSuccessAdded(true);
        successCallback();
      });
    } else {
      sendInvitationApi(payload).then(() => {
        setsSuccessAdded(true);
        successCallback();
      });
    }
  };

  const handleStepNextButton = (id, name) => {
    setCurrentStep({ id: id, name: name });
    handleCompleteStep(id - 1);
    handleSwiperNextSlide();
  };

  return (
    <>
      {!successAdded && (
        <VModal
          classHandler={'create-transaction-request-modal'}
          toggleFunction={toggleFunction}
          scrollTrigger={currentStep}
        >
          <div className='create-transaction-request-modal-wrapper'>
            <div className='create-transaction-request-modal-side'>
              <span className='create-transaction-request-modal-side-heading'>
                {`${t(lng, 'app.step')} ${currentStep?.id}`}
              </span>
              <Stepper
                onSelectStepCallback={(stepIndex, stepName) => {
                  setCurrentStep({ id: stepIndex, name: stepName });
                  if (swiperRef.current && swiperRef.current.swiper) {
                    swiperRef.current.swiper.slideTo(stepIndex - 1);
                  }
                }}
                currentStepId={currentStep?.id}
                steps={steps}
              />
            </div>
            <div className='create-transaction-request-modal-content'>
              <span className='create-transaction-request-modal-heading'>
                <div className='create-transaction-request-modal-heading-wrapper'>
                  <span>{!isEdit ? <T>app.invite.a.team.membera</T> : <T>app.edit.invitation</T>}</span>
                  <div className='mobile-stepper'>
                    <Stepper
                      onSelectStepCallback={(stepIndex, stepName) => {
                        setCurrentStep({ id: stepIndex, name: stepName });
                        if (swiperRef.current && swiperRef.current.swiper) {
                          swiperRef.current.swiper.slideTo(stepIndex - 1);
                        }
                      }}
                      currentStepId={currentStep?.id}
                      steps={steps}
                    />
                  </div>
                  <span className='create-transaction-request-modal-side-sub-heading'>
                    {icon}
                    {t(lng, steps[currentStep?.id - 1]?.text)}
                  </span>
                  <span className='create-transaction-request-modal-step-desc'>{t(lng, subText)}</span>
                </div>
              </span>
              <div
                className={`create-transaction-request-swiper-wrapper ${sliding ? 'sliding' : ''}`}
                style={{ height: swiperContainerHeight }}
              >
                <Swiper
                  ref={swiperRef}
                  spaceBetween={50}
                  speed={800}
                  allowTouchMove={false}
                  slidesPerView={1}
                  onSlideChangeTransitionStart={() => setSliding(true)}
                  onSlideChangeTransitionEnd={() => setSliding(false)}
                >
                  <form onSubmit={handleSubmit(inviteTeamMember)} className='signin-form-wrapper'>
                    {!isEdit && (
                      <SwiperSlide>
                        <Controller
                          name='email'
                          control={control}
                          rules={{
                            required: `${t(lng, 'app.email.address')} ${t(lng, 'app.is.required')}`,
                            validate: (value) =>
                              /^[A-Z0-9._]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value) ||
                              t(lng, 'app.email-is-required'),
                          }}
                          render={({ field }) => {
                            return (
                              <>
                                <span className='create-trx-request-modal-select-component-label'>
                                  <T>app.email.address</T>
                                </span>
                                <Textfield
                                  trim
                                  type='text'
                                  fullWidth
                                  size='lg'
                                  placeholder={t(lng, 'app.e.g.team')}
                                  error={errors?.email?.message}
                                  {...field}
                                />
                              </>
                            );
                          }}
                        />
                        <div className='create-trx-request-modal-button'>
                          <Button
                            fullWidth
                            onClick={() => handleStepNextButton(2, 'roles')}
                            disabled={errors?.email || (!editData?.email && !dirtyFields?.email)}
                          >
                            <T>app.next.step</T>
                          </Button>
                        </div>
                      </SwiperSlide>
                    )}
                    <SwiperSlide>
                      <div>
                        <label>
                          <T>app.team.roles</T>
                        </label>
                        <div className='team-invite-roles-wrapper'>
                          {roles
                            .filter((role) => {
                              return role.name !== 'owner';
                            })
                            ?.map((role, id) => (
                              <div key={role?.id} className='team-role'>
                                <CheckboxNew
                                  onMouseEnter={() => setRoleDescription(role?.name)}
                                  id={role?.id}
                                  disabledHoverEffect
                                  multiSelect
                                  label={
                                    <label className='success-api-key-checkbox-label'>{role?.name}</label>
                                  }
                                  value={selectedRoles?.some((selectedRole) => selectedRole?.id === role?.id)}
                                  onChange={(id) => {
                                    if (!selectedRoles?.some((selectedRole) => selectedRole?.id === id)) {
                                      setSelectedRoles((prev) => (prev?.length ? [...prev, role] : [role]));
                                    } else {
                                      setSelectedRoles((prev) =>
                                        prev?.filter((selectedRole) => selectedRole?.id !== role?.id),
                                      );
                                    }
                                  }}
                                />
                                {id % 2 === 0 && <div className='team-invite-divider' />}
                              </div>
                            ))}
                        </div>
                        <div className='team-invite-description-wrapper'>
                          {roleDescription !== '' && (
                            <div>
                              <div className='title'>
                                <T>app.role.desc.for</T>
                              </div>
                              <div className='desc'>
                                {roleDescription === 'admin' && <T>app.role.admin</T>}
                                {roleDescription === 'developer' && <T>app.role.developer</T>}
                                {roleDescription === 'operator' && <T>app.role.operator</T>}
                                {roleDescription === 'viewer' && <T>app.role.viewer</T>}
                                {roleDescription === 'test mode user' && <T>app.role.test.mode.user</T>}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className='create-trx-request-modal-button'>
                          <Button
                            fullWidth
                            onClick={() => handleStepNextButton(!isEdit ? 3 : 2, 'summary')}
                            disabled={!selectedRoles?.length}
                          >
                            <T>app.next.step</T>
                          </Button>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='create-api-key-summary-wrapper'>
                        {editData?.name && (
                          <div className='create-api-key-summary-row'>
                            <T>app.name</T>
                            <span>{editData?.name}</span>
                          </div>
                        )}
                        <div className='create-api-key-summary-row'>
                          <T>app.email.address</T>
                          <span>{getValues()?.email}</span>
                        </div>
                        {selectedRoles?.length ? (
                          <div className='create-api-key-summary-row'>
                            <T>app.team.roles</T>
                            {selectedRoles?.length > 2 ? (
                              <div className='create-api-key-summary-many'>
                                {selectedRoles
                                  ?.slice(0, 2)
                                  ?.map(
                                    (role) =>
                                      `${role?.name?.slice(0, 1)?.toUpperCase()}${role?.name?.slice(
                                        1,
                                        role?.name?.length,
                                      )}`,
                                  )
                                  ?.join(', ')}
                                <div className='wallet-icon plus'>{`+ ${selectedRoles?.length - 2}`}</div>
                              </div>
                            ) : (
                              <div>
                                {selectedRoles
                                  ?.map(
                                    (role) =>
                                      `${role?.name?.slice(0, 1)?.toUpperCase()}${role?.name?.slice(
                                        1,
                                        role?.name?.length,
                                      )}`,
                                  )
                                  ?.join(', ')}
                              </div>
                            )}
                          </div>
                        ) : null}
                        <Button
                          fullWidth
                          type='submit'
                          onClick={handleSubmit(inviteTeamMember)}
                          disabled={!isValid}
                        >
                          <i className='icon-submit-for-approval' />
                          <T>app.submit.for.approval</T>
                        </Button>
                      </div>
                    </SwiperSlide>
                  </form>
                </Swiper>
              </div>
            </div>
          </div>
        </VModal>
      )}
      {successAdded && (
        <ActionModal toggleFunction={toggleFunction}>
          <T>app.invite.member.descr</T>
        </ActionModal>
      )}
    </>
  );
};

export default InviteTeamMemberModal;

const stepDescriptionMapping = () => ({
  email: {
    subText: 'app.email.invite.step.subtext',
    icon: <NameIcon style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
  roles: {
    subText: 'app.roles.invite.step.subtext',
    icon: <RolesIcon style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
  summary: {
    icon: <SummaryIcon fill='#fff' style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
});
