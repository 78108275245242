import codeIcon from '../assets/icons/code-icon.svg';
import hammerIcon from '../assets/icons/hammer-icon.svg';
import infoIcon from '../assets/icons/info-icon.svg';
import { store } from '../redux';
import {
  LOGIN_ATTEMPT,
  STATE_DASHBOARD,
  STATE_PENDING_VERIFICATION,
  STATE_REGISTER_MOBILE,
  STATE_REGISTER_PSP,
  STATE_VERIFY_EMAIL,
} from '../redux/slices/authSlice';
import {
  ROUTE_HOME,
  ROUTE_DOWNLOAD_APP,
  ROUTE_PAYMENT,
  ROUTE_VERIFY_EMAIL,
  ROUTE_LOGIN_ATTEMPT,
  ROUTE_DEVELOPERS,
  ROUTE_TRX_REQUESTS,
  ROUTE_WALLET,
  ROUTE_WEBHOOK,
  ROUTE_TEAM,
  ROUTE_TRX_POLICY,
} from '../routes/routes';
export const blockchainNetworks = {
  MAINNET: 'mainnet',
  TESTNET: 'testnet',
};

export const helpMenuData = [
  {
    menuName: 'app.menu.developers', // "Developers",
    menuIcon: codeIcon,
    menuItems: [
      {
        title: 'app.menu.references', // "APIs Reference",
        link: 'https://developers.vaultody.com/v-1.2024-05-09-108/RESTapis/general-information/getting-started',
      },
      {
        title: 'app.menu.faq', // "FAQ",
        link: 'https://developers.vaultody.com/v-1.2024-05-09-108/FAQ/181-what-is-mpc-wallet',
      },
      {
        title: 'app.menu.glossary', // "Glossary",
        link: 'https://developers.vaultody.com/v-1.2024-05-09-108/Glossary/189-123',
      },
    ],
  },
  {
    menuName: 'app.menu.about', // "About",
    menuIcon: infoIcon,
    menuItems: [
      {
        title: 'app.menu.guides',
        link: 'https://developers.vaultody.com/v-1.2024-05-09-108/Guides/178-backup-and-restore-a-vault',
      },
      {
        title: 'app.menu.support', // "What we support",
        link: 'https://vaultody.com/integrations/protocols',
      },
      {
        title: 'app.menu.blog', // "Blog",
        link: 'https://vaultody.com/blog',
      },
    ],
  },
  {
    menuName: 'app.menu.legal', // "Legal",
    menuIcon: hammerIcon,
    menuItems: [
      {
        title: 'app.menu.privacy', // "Privacy Policy",
        link: 'https://vaultody.com/privacy-policy',
      },
      {
        title: 'app.menu.terms', // "Terms & Conditions",
        link: 'https://vaultody.com/terms-conditions',
      },
      {
        title: 'app.menu.security', // "Data Security Policy",
        link: 'https://vaultody.com/data-security-policy',
      },
    ],
  },
];

export const walletColors = [
  '#8777D9',
  '#2684FF',
  '#57D9A3',
  '#00C7E6',
  '#FFC400',
  '#FF7452',
  '#5243AA',
  '#0052CC',
  '#00875A',
  '#00A3BF',
  '#FF991F',
  '#DE350B',
];

export const blockchainDecimalLimits = {
  bitcoin: 8,
  tron: 6,
  ethereum: 18,
  'binance-smart-chain': 18,
};

export const authRoutesGuardsMapping = {
  [LOGIN_ATTEMPT]: ROUTE_LOGIN_ATTEMPT,
  [STATE_VERIFY_EMAIL]: ROUTE_VERIFY_EMAIL,
  [STATE_REGISTER_MOBILE]: ROUTE_DOWNLOAD_APP,
  [STATE_REGISTER_PSP]: ROUTE_PAYMENT,
  [STATE_DASHBOARD]: ROUTE_HOME,
  [STATE_PENDING_VERIFICATION]: ROUTE_HOME,
};

export const vaultTypes = {
  GENERAL: 'general',
  SMART: 'smart',
  AUTOMATION: 'automation',
};

export const transactionRequestFailReasonsMapping = {
  FEE_LIMIT_TOO_LOW: 'app.trx.fee.limit.too.low',
  INSUFFICIENT_FUNDS: 'app.trx.insufficient.funds',
  NOT_ENOUGH_INPUTS_FOUND: 'app.trx.insufficient.funds',
  OUTPUT_AMOUNT_TOO_LOW: 'app.trx.output.amount.too.low',
  TO_ADDRESS_DOES_NOT_EXIST_ERROR: 'app.trx.to.address.not.exist',
  SINGLE_WALLET_TRANSACTION_NOT_ENABLED: 'app.trx.single.wallet.not.enabled',
  AMOUNT_DECIMALS_EXCEED_SUPPORTED_DECIMALS: 'app.trx.amount.decimals.exceed',
  CONTRACT_GAS_LIMIT_CALCULATION_ERROR: 'app.trx.contract.gas.limit.calculation',
  SINGLE_WALLET_TOKEN_TRANSACTION_NOT_ENABLED: 'app.trx.single.wallet.token.not.enabled',
  INSUFFICIENT_AMOUNT_FOR_ADDRESS_CREATION: 'app.trx.insufficient.funds.address.creation',
  TO_ADDRESS_MUST_BE_DIFFERENT_FROM_SOURCE_ADDRESS: 'app.trx.address.different.from.source',
  BLOCKCHAIN_PREPARE_TRANSACTIONS_CONTRACT_VALIDATE_ERROR: 'app.trx.blockchain.prepare.validation',
};

export const vaultTypeFirstTabMapping = {
  [vaultTypes.GENERAL]: 'assets',
  [vaultTypes.SMART]: 'gas-tanker',
  [vaultTypes.AUTOMATION]: 'automation-rules',
};
// USED TO IGNORE THE TABS LOGIC IN useViewPermissions HOOK
export const customTabsPages = [ROUTE_TEAM, ROUTE_TRX_POLICY];

export const pageTabsMapping = () => {
  const vaultType = store.getState()?.globalReducer?.selectedVaultType;

  return {
    [ROUTE_WEBHOOK]: [
      { label: 'app.all', name: 'all', initiallyActive: true, permissions: ['CALLBACKS_LOGS_VIEW'] },
      { label: 'app.succeeded', name: 'succeeded', permissions: ['CALLBACKS_LOGS_VIEW'] },
      { label: 'app.failed', name: 'failed', permissions: ['CALLBACKS_LOGS_VIEW'] },
    ],
    [ROUTE_DEVELOPERS]: [
      { label: 'app.api.keys', name: 'api-keys', initiallyActive: true, permissions: ['API_KEYS_VIEW'] },
      { label: 'app.webhooks', name: 'webhooks', permissions: ['WEBHOOKS_VIEW'] },
    ],
    [ROUTE_TRX_REQUESTS]: [
      { label: 'app.transaction.req', name: 'requests', initiallyActive: true },
      { label: 'app.transaction.shelf', name: 'drafts', permissions: ['TX_DRAFT_VIEW'] },
    ],
    [ROUTE_WALLET]: vaultType
      ? [
          ...(vaultType?.toLowerCase() === vaultTypes.AUTOMATION
            ? [
                {
                  label: 'app.automation.rules',
                  name: 'automation-rules',
                  initiallyActive: vaultType?.toLowerCase() === vaultTypes.AUTOMATION,
                  permissions: ['AUTOMATION_RULES_VIEW', 'WALLETS_DETAILS_VIEW'],
                },
              ]
            : []),
          ...(vaultType?.toLowerCase() === vaultTypes.SMART ||
          vaultType?.toLowerCase() === vaultTypes.AUTOMATION
            ? [
                {
                  label: 'app.gas.tanker',
                  name: 'gas-tanker',
                  initiallyActive:
                    vaultType?.toLowerCase() === vaultTypes.SMART ||
                    vaultType?.toLowerCase() === vaultTypes.AUTOMATION,
                  permissions: ['WALLETS_DETAILS_VIEW'],
                },
              ]
            : []),
          {
            label: 'app.assets',
            name: 'assets',
            initiallyActive: vaultType?.toLowerCase() === vaultTypes.GENERAL,
            permissions: ['WALLETS_DETAILS_VIEW'],
          },
          { label: 'app.addresses', name: 'addresses', permissions: ['WALLETS_DETAILS_VIEW'] },
          {
            label: 'app.trans.history',
            name: 'transactions-history',
            permissions: ['TRANSACTIONS_HISTORY_VIEW', 'WALLETS_DETAILS_VIEW'],
          },
          { label: 'app.settings', name: 'settings', permissions: ['WALLETS_DETAILS_VIEW'] },
          ...(vaultType?.toLowerCase() !== vaultTypes.AUTOMATION
            ? [
                {
                  label: 'app.applied.policies',
                  name: 'rules',
                  permissions: ['WALLETS_DETAILS_VIEW', 'GOVERNANCE_RULES_VIEW'],
                },
              ]
            : []),
        ]
      : [],
  };
};
