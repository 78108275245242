import Button from '../../../Button/Button';
import { T } from '../../../T';
import Textfield from '../../../Textfield/Textfield';
import '../CreateTransactionRequestNew.css';

const CreateTransactionRequestNoteStep = ({ note, handleChangeNote, submitCallback }) => {
  return (
    <div className='create-trx-request-step-four-wrapper'>
      <span className='create-trx-request-modal-select-component-label'>
        <T>app.note</T>
      </span>
      <div className='create-trx-modal-amount-input-note'>
        <Textfield
          type='text'
          name='description'
          fullWidth
          variant='textarea'
          size='lg'
          maxLength={400}
          placeholder='Enter description'
          value={note}
          onChange={handleChangeNote}
        />
      </div>
      <div className='create-trx-request-modal-button'>
        <Button fullWidth onClick={submitCallback} disabled={!note}>
          <T>app.next.step</T>
        </Button>
      </div>
    </div>
  );
};

export default CreateTransactionRequestNoteStep;
