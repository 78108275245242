import { useEffect, useState } from 'react';
import { diffDate, vaultodyDate } from '../../helpers/date';
import './MyProfile.css';
import ChangeName from '../../components/Modals/ChangeName';
import ChangeEmail from '../../components/Modals/ChangeEmail';
import ChangePassword from '../../components/Modals/ChangePassword';
import { useDispatch, useSelector } from 'react-redux';
import { setPage } from '../../redux/slices/globalSlice';
import Button from '../../components/Button/Button';
import { T } from '../../components/T';
import { getDeviceApi, resetDeviceApi } from '../../api/endpoints';
import ActionModal from '../../components/Modals/ActionModal';
import RemoveDeviceModal from '../../components/Pages/MyProfile/RemoveDeviceModal/RemoveDeviceModal';

const MyProfile = () => {
  const dispatch = useDispatch();
  const myProfile = useSelector((state) => state.userReducer.myProfile);
  const userId = useSelector((state) => state.userReducer.myPackage?.userId);
  const trialBannerVisible = useSelector((state) => state.globalReducer.trialBannerVisible);
  const [openChangeName, setOpenChangeName] = useState(false);
  const [openChangeEmail, setOpenChangeEmail] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [checkOwner, setCheckOwner] = useState(true);
  const [removeDeviceSuccess, setRemoveDeviceSuccess] = useState(false);
  const [openRemoveDevice, setOpenRemoveDevice] = useState({
    open: false,
    deviceType: '',
    deviceCreatedAt: '',
  });

  const getUserDevice = async () => {
    return getDeviceApi().then((res) => {
      setOpenRemoveDevice({
        open: true,
        deviceId: res.data.item.deviceId,
        deviceType: myProfile.registeredDevice.type,
        deviceCreatedAt: vaultodyDate(myProfile.registeredDevice.activatedTime, 'full'),
        dataItem: {
          ...res.data.item,
          name: myProfile?.fullName,
        },
      });
    });
  };

  const removeDeviceHandler = (deviceId) => {
    resetDeviceApi({ item: { deviceId, removedDeviceUserId: userId } }).then(() => {
      setOpenRemoveDevice(false);
      setRemoveDeviceSuccess(true);
    });
  };

  useEffect(() => {
    dispatch(setPage('My profile'));
  }, []);

  useEffect(() => {
    if (Object.values(myProfile).length > 0) {
      const check = myProfile.roles.filter((i) => {
        return i.name === 'owner';
      });
      setCheckOwner(check.length > 0);
    }
  }, [myProfile]);

  return (
    myProfile.length !== 0 && (
      <div className='wallet-settings'>
        {openChangeName && <ChangeName toggleFunction={setOpenChangeName} />}
        {openChangeEmail && <ChangeEmail toggleFunction={setOpenChangeEmail} />}
        {openChangePassword && <ChangePassword toggleFunction={setOpenChangePassword} />}
        {removeDeviceSuccess && (
          <ActionModal toggleFunction={() => setRemoveDeviceSuccess(false)}>
            <T>app.remove.device.descr</T>
          </ActionModal>
        )}
        {openRemoveDevice?.open && (
          <RemoveDeviceModal
            callback={() => {
              setOpenRemoveDevice({ open: false });
            }}
            removeDeviceHandler={() => removeDeviceHandler(openRemoveDevice?.deviceId)}
            dataItem={{
              ...openRemoveDevice?.dataItem,
              createdAt: myProfile.registeredDevice?.activatedTime,
            }}
          />
        )}

        <div className={`profile-page-wrapper ${trialBannerVisible ? 'trial-banner-visible' : ''}`}>
          <div className='table-content'>
            <div className='table-row data profile'>
              <div className='my-profile-table-data'>
                <div className='titles-box'>
                  <div className='titles-box-title'>Full Name</div>
                  <div className='titles-box-sub'>{myProfile.fullName}</div>
                </div>
                {!checkOwner && (
                  <div className='button-holder'>
                    <Button size='sm' variant='neutral' onClick={() => setOpenChangeName(true)}>
                      Change name
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <div className='table-row data profile'>
              <div className='my-profile-table-data'>
                <div className='titles-box'>
                  <div className='titles-box-title'>Email</div>
                  <div className='titles-box-sub'>{myProfile.email}</div>
                </div>
                {!checkOwner && (
                  <div className='button-holder'>
                    <Button size='sm' variant='neutral' onClick={() => setOpenChangeEmail(true)}>
                      Change email
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <div className='table-row data profile'>
              <div className='my-profile-table-data'>
                <div className='titles-box'>
                  <div className='titles-box-title'>Password</div>
                  <div className='titles-box-sub'>
                    {myProfile.lastChangedPasswordTime !== null ? (
                      <>
                        Last changed{' '}
                        {diffDate(myProfile.lastChangedPasswordTime, 'days') === 0
                          ? diffDate(myProfile.lastChangedPasswordTime, 'hours')
                          : diffDate(myProfile.lastChangedPasswordTime, 'days')}{' '}
                        {diffDate(myProfile.lastChangedPasswordTime, 'days') === 0 ? 'hours' : 'days'} ago
                      </>
                    ) : (
                      <> Nothing has ever changed. </>
                    )}
                  </div>
                </div>
                <div className='button-holder'>
                  <Button size='sm' variant='neutral' onClick={() => setOpenChangePassword(true)}>
                    Change password
                  </Button>
                </div>
              </div>
            </div>
            <div className='table-row data profile'>
              <div className='my-profile-table-data'>
                <div className='titles-box'>
                  <div className='titles-box-title'>Register device</div>
                  <div className='titles-box-sub device'>
                    <div className='type'>{myProfile.registeredDevice?.type}</div>
                    <div className='date'>
                      {vaultodyDate(myProfile.registeredDevice?.activatedTime, 'full')}
                    </div>
                    {' • '}
                    <div className='status'>{myProfile?.registeredDevice?.status}</div>
                  </div>
                </div>
                <Button size='sm' variant='neutral' onClick={getUserDevice}>
                  <T>app.remove.device</T>
                </Button>
              </div>
            </div>
            <div className='table-row data profile'>
              <div className='my-profile-table-data'>
                <div className='titles-box'>
                  <div className='titles-box-title'>Assigned roles</div>
                  <div className='titles-box-sub  capitalize'>
                    {myProfile?.roles?.map((i) => {
                      if (myProfile.roles.length === 1) {
                        return myProfile.roles[0].name;
                      } else {
                        if (i === myProfile.roles[myProfile.roles.length - 1]) {
                          return i.name;
                        }
                        return i.name + ', ';
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};
export default MyProfile;
