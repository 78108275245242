import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useClickOutside } from '../../helpers/hooks/useClickOutside';
import { t } from '../T';
import CheckboxNew from '../../components/CheckboxNew/CheckboxNew';
import { findFittingElementsIntoContainer } from '../../helpers/strings';
import FilterTextField from './components/FilterTextField';
import './Filter.css';
import Textfield from '../Textfield/Textfield';
import { ReactComponent as SearchIcon } from '../../assets/icons/search-icon.svg';
import useDebounce from '../../helpers/hooks/useDebounce';
import Radio from '../Radio/Radio';
import { usePopper } from 'react-popper';

const filterIndicatorWidth = 30;

const Filter = ({
  items = [],
  placeholder,
  selectAllField,
  displayIcon,
  roundedIcon,
  fullWidth,
  onChange,
  selectedItems,
  dataKey,
  dataNameKey,
  customIconKey,
  multiSelect,
  hasSearchOption,
  searchFieldPlaceholder,
  searchOptionApiCallback,
  customLabelTemplate,
  customTextfieldLabel,
  disableFocusShadowEffect,
  variant = 'checkbox', // "radio" || "checkbox",
  disabled,
}) => {
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const [open, setOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const wrapperRef = useRef('menu');
  const selectedFieldsSpanRef = useRef(null);
  const [lastEvent, setLastEvent] = useState(); // "ADD" or "REMOVE" element
  const [searchText, setSearchText] = useState('');
  const [indicatorData, setIndicatorData] = useState({
    show: false,
    visibleSelectedItems: [],
  });
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
  });

  const selectedItemsFiltered = useMemo(() => {
    return selectedItems?.length
      ? items?.filter((item) =>
          selectedItems?.some((selItem) => item?.[dataKey || 'id'] === selItem?.[dataKey || 'id']),
        )
      : [];
  }, [selectedItems, items]);

  const selectedItemsToShow = indicatorData?.show
    ? indicatorData?.visibleSelectedItems
    : selectedItemsFiltered;

  useEffect(() => {
    if (hasSearchOption && searchOptionApiCallback) {
      searchOptionApiCallback();
    }
  }, []);

  useDebounce(() => searchOptionApiCallback(searchText), 500, [searchText]);

  const handleOnChange = (filteredArray) => {
    if (onChange) {
      onChange(filteredArray);
    }
  };

  const generateRowLabel = (item) => {
    if (customLabelTemplate) {
      return customLabelTemplate(item);
    }
    return item?.[dataNameKey || 'name'];
  };

  // Measures the text width and returns the items to display in the filter text field
  const getSelectedItemsToDisplay = (containerWidth, textElement) => {
    if (lastEvent === 'REMOVE') {
      const elementsArray = findFittingElementsIntoContainer(
        selectedItemsFiltered?.map((item) => item?.[dataNameKey || 'name']),
        containerWidth,
        textElement,
      );
      return selectedItemsFiltered?.slice(0, elementsArray?.length);
    }
    if (lastEvent === 'ADD' && indicatorData?.show) {
      return selectedItemsFiltered?.slice(0, indicatorData?.visibleSelectedItems?.length);
    }
    return selectedItemsFiltered?.slice(0, selectedItemsFiltered?.length - 1);
  };

  // Check if the text in the text field is overflowing and show the indicator. Example - "+1"
  const handleTextIndicator = () => {
    if (indicatorData?.show && selectedItemsFiltered?.length <= indicatorData?.visibleSelectedItems?.length) {
      setIndicatorData({ show: false, visibleSelectedItems: [] });
      return;
    }
    if (selectedItems?.length && selectedItems?.length !== items?.length) {
      const computedStyle = getComputedStyle(referenceElement);
      // width with padding
      let elementWidth = referenceElement?.clientWidth;
      // width without padding
      elementWidth -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);
      const isTextOverflowing =
        selectedFieldsSpanRef?.current?.offsetWidth &&
        selectedFieldsSpanRef?.current?.offsetWidth >= elementWidth - filterIndicatorWidth;
      if (!isTextOverflowing && !indicatorData?.show) return;
      const visibleSelectedItems = getSelectedItemsToDisplay(
        elementWidth - filterIndicatorWidth,
        selectedFieldsSpanRef?.current,
      );
      if (visibleSelectedItems?.length === 0) return;
      setIndicatorData({
        visibleSelectedItems: visibleSelectedItems,
        show: true,
      });
    }
  };

  const handleCheckAll = () => {
    if (selectedItemsFiltered && items && selectedItemsFiltered?.length === items?.length) {
      handleOnChange([]);
    } else {
      setLastEvent('REMOVE');
      handleOnChange(items);
    }
  };

  const handleCheckboxClick = (data) => {
    if (!multiSelect) {
      setLastEvent('ADD');
      handleOnChange([data]);
      setOpen(false);
      return;
    }
    // eslint-disable-next-line
    if (selectedItemsFiltered?.some((item) => item?.[dataKey || 'id'] == data?.[dataKey || 'id'])) {
      const newItems = selectedItemsFiltered?.filter(
        (item) => item?.[dataKey || 'id'] !== data?.[dataKey || 'id'],
      );
      setLastEvent('REMOVE');
      handleOnChange(newItems);
    } else {
      const newItems = selectedItemsFiltered?.length > 0 ? [...selectedItemsFiltered, data] : [data];
      setLastEvent('ADD');
      handleOnChange(newItems);
    }
  };

  useClickOutside(wrapperRef, () => {
    setOpen(false);
  });

  useEffect(() => {
    if (referenceElement) {
      handleTextIndicator();
    }
  }, [selectedItems, referenceElement]);

  return (
    <div ref={wrapperRef} className={`filter-main-wrapper-new ${fullWidth ? 'fullWidth' : ''}`}>
      <FilterTextField
        open={open}
        setOpen={setOpen}
        items={items}
        selectedItems={selectedItemsFiltered}
        selectedItemsToShow={selectedItemsToShow}
        selectedFieldsSpanRef={selectedFieldsSpanRef}
        textFieldRef={setReferenceElement}
        placeholder={placeholder}
        indicatorData={indicatorData}
        dataNameKey={dataNameKey}
        isMultiSelect={multiSelect}
        disabled={disabled}
        customTextfieldLabel={customTextfieldLabel}
      />
      {open && (
        <div
          className={`checkboxes-new${fullWidth ? ' fullWidth' : ''}`}
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          {hasSearchOption && (
            <Textfield
              type='text'
              fullWidth
              icon={<SearchIcon />}
              placeholder={searchFieldPlaceholder}
              disableFocusShadowEffect={disableFocusShadowEffect}
              size='lg'
              value={searchText}
              onChange={(e) => setSearchText(e.currentTarget.value)}
            />
          )}
          {selectAllField && (
            <div className='filter-checkbox-wrapper'>
              {variant === 'checkbox' ? (
                <CheckboxNew
                  id='all'
                  data={{ id: 'all', name: 'All' }}
                  label={t(lng, 'app.filters.all')}
                  onChange={handleCheckAll}
                  value={
                    selectedItemsFiltered?.length === items?.length ||
                    (selectedItemsFiltered?.length === 0 && !multiSelect)
                  }
                  multiSelect={multiSelect}
                />
              ) : (
                <Radio
                  id='all'
                  data={{ id: 'all', name: 'All' }}
                  label={t(lng, 'app.filters.all')}
                  onChange={handleCheckAll}
                  value={
                    selectedItemsFiltered?.length === items?.length ||
                    (selectedItemsFiltered?.length === 0 && !multiSelect)
                  }
                  multiSelect={multiSelect}
                />
              )}
            </div>
          )}
          {items &&
            items.length > 0 &&
            items.map((item, key) => {
              return (
                <div className='filter-checkbox-wrapper' key={key}>
                  {variant === 'checkbox' ? (
                    <CheckboxNew
                      id={item?.[dataKey || 'id']}
                      item={item}
                      color={item?.color}
                      label={generateRowLabel(item)}
                      displayIcon={displayIcon}
                      roundedIcon={roundedIcon}
                      customIcon={item?.[customIconKey]}
                      multiSelect={multiSelect}
                      onChange={() => handleCheckboxClick(item)}
                      value={
                        multiSelect
                          ? selectedItemsFiltered?.some(
                              (selectedItem) => item?.[dataKey || 'id'] === selectedItem?.[dataKey || 'id'],
                            )
                          : false
                      }
                    />
                  ) : (
                    <Radio
                      id={item?.[dataKey || 'id']}
                      color={item?.color}
                      item={item}
                      label={generateRowLabel(item)}
                      displayIcon={displayIcon}
                      roundedIcon={roundedIcon}
                      customIcon={item?.[customIconKey]}
                      onChange={() => handleCheckboxClick(item)}
                      value={selectedItemsFiltered?.some(
                        (selectedItem) => item?.[dataKey || 'id'] === selectedItem?.[dataKey || 'id'],
                      )}
                    />
                  )}
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};
export default Filter;
