import React from 'react';
import Blockchain from '../../../Blockchain/Blockchain';
import Textfield from '../../../Textfield/Textfield';
import { T, t } from '../../../T';
import { useSelector } from 'react-redux';
import { ReactComponent as InfinityIcon } from '../../../../assets/icons/infinity.svg';
import Button from '../../../Button/Button';
import CurrencyInput from 'react-currency-input-field';
import { blockchainDecimalLimits } from '../../../../helpers/constants';

const CreateVaultAutomationRuleTriggerStep = ({
  selectedAsset,
  submitCallback,
  selectedTreshholdAmount,
  handleChooseTreshholdAmount,
}) => {
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const protocols = useSelector((state) => state.globalReducer.protocols);
  const maxDecimals = blockchainDecimalLimits?.[selectedAsset?.[0]?.blockchain] || 8;
  const selectedAssetProtocol = protocols?.find(
    (protocol) => protocol?.blockchain?.toLowerCase() === selectedAsset?.[0]?.blockchain?.toLowerCase(),
  );

  return (
    <div className='create-vault-automation-rule-content-wrapper'>
      <div className='create-vault-automation-rule-modal-select-component-wrapper'>
        <span className='create-vault-automation-rule-modal-trigger-label-wrapper'>
          <T>app.chosen.asset</T>
          {': '}
          <Blockchain
            showText={false}
            assetType={selectedAsset?.[0]?.type}
            blockchain={selectedAssetProtocol?.currency?.toLowerCase()}
            symbol={selectedAsset?.[0]?.symbol}
          />
          <span>{`${selectedAsset?.[0]?.symbol} (${selectedAssetProtocol?.currency?.toUpperCase()})`}</span>
        </span>
        <div className='create-vault-automation-rule-modal-trigger-from-to-wrapper'>
          <div className='create-vault-automation-rule-modal-trigger-field'>
            <label>
              <T>app.from</T>
            </label>
            <div className='create-vault-automation-rule-modal-trigger-input'>
              <CurrencyInput
                className='form-control'
                placeholder={t(lng, 'app.please.enter.amount')}
                decimalsLimit={maxDecimals}
                allowDecimals
                allowNegativeValue={false}
                value={selectedTreshholdAmount}
                intlConfig={{ locale: 'en-US' }}
                onValueChange={(value) =>
                  handleChooseTreshholdAmount(typeof value === 'undefined' ? 0 : value)
                }
              />
            </div>
          </div>
          <div className='create-vault-automation-rule-modal-trigger-field'>
            <label>
              <T>app.to</T>
            </label>
            <Textfield
              type='number'
              disabled={true}
              icon={<InfinityIcon />}
              fullWidth
              size='lg'
              value={''}
              onChange={(event) => handleChooseTreshholdAmount(event.currentTarget.value)}
            />
          </div>
        </div>
      </div>
      <div className='create-vault-automation-rule-modal-button'>
        <Button fullWidth onClick={submitCallback}>
          <T>app.next.step</T>
        </Button>
      </div>
    </div>
  );
};

export default CreateVaultAutomationRuleTriggerStep;
