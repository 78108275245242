import { useRef, useState } from 'react';
import { useClickOutside } from '../../helpers/hooks/useClickOutside';
import { walletColors } from '../../helpers/constants';

const ColorChanger = ({ changeHandler, state, shrink }) => {
  const [colorMenu, setColorMenu] = useState(false);

  const wrapperRef = useRef('menu');
  useClickOutside(wrapperRef, () => {
    setColorMenu(false);
  });

  const openColorMenu = () => {
    colorMenu ? setColorMenu(false) : setColorMenu(true);
  };
  useClickOutside(wrapperRef, () => {
    setColorMenu(false);
  });
  return (
    <div className='color-panel' ref={wrapperRef}>
      <div className='color-changer' onClick={openColorMenu}>
        <div className='color-select' style={{ backgroundColor: state }} />
        <i className='down-select icon-down-open-big' />
      </div>
      {colorMenu && (
        <div className={`color-menu ${shrink ? 'shrinked' : ''}`}>
          {walletColors.map((colorItem, key) => {
            return (
              <div
                key={key}
                onClick={() => {
                  changeHandler(colorItem);
                }}
                className={`item ${colorItem === state ? 'selected' : ''}`}
                style={{ backgroundColor: colorItem }}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
export default ColorChanger;
