import { ReactComponent as VaultIcon } from '../../assets/icons/vault-icon2.svg';
import { ReactComponent as AssetIcon } from '../../assets/icons/asset-icon.svg';
import { ReactComponent as LocationIcon } from '../../assets/icons/destination-icon.svg';
import { ReactComponent as ContactsIcon } from '../../assets/icons/dashboardMenu/contacts-icon.svg';
import { ReactComponent as AmountIcon } from '../../assets/icons/amount-icon2.svg';
import { ReactComponent as MoneyIcon } from '../../assets/icons/money-icon.svg';
import { ReactComponent as NoteIcon } from '../../assets/icons/note-icon.svg';
import { ReactComponent as SummaryIcon } from '../../assets/icons/summary-icon2.svg';
import { ReactComponent as PencilIcon } from '../../assets/icons/pencil2-icon.svg';
import { ReactComponent as ActionIcon } from '../../assets/icons/action-icon.svg';
import { ReactComponent as PointerIcon } from '../../assets/icons/pointer-icon.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings-icon.svg';
export const staticFeeSteps = [
  {
    id: 1,
    icon: <VaultIcon />,
    text: 'app.select.vault',
    name: 'vault',
    completed: true,
  },
  {
    id: 2,
    icon: <AssetIcon />,
    text: 'app.select.asset',
    name: 'asset',
    completed: false,
  },
  {
    id: 3,
    icon: <LocationIcon />,
    text: 'app.destination',
    name: 'destination',
    completed: false,
  },
  {
    id: 4,
    icon: <AmountIcon />,
    text: 'app.amount',
    name: 'amount',
    completed: false,
  },
  { id: 5, icon: <NoteIcon />, text: 'app.note', name: 'note', completed: false },
  { id: 6, icon: <SummaryIcon />, text: 'app.summary', name: 'summary', completed: false },
];

export const flexibleFeeSteps = [
  {
    id: 1,
    icon: <VaultIcon />,
    text: 'app.select.vault',
    name: 'vault',
    completed: true,
  },
  {
    id: 2,
    icon: <AssetIcon />,
    text: 'app.select.asset',
    name: 'asset',
    completed: false,
  },
  {
    id: 3,
    icon: <LocationIcon />,
    text: 'app.destination',
    name: 'destination',
    completed: false,
  },
  {
    id: 4,
    icon: <AmountIcon />,
    text: 'app.amount.and.fee.priority',
    name: 'amount',
    completed: false,
  },
  { id: 5, icon: <NoteIcon />, text: 'app.note', name: 'note', completed: false },
  { id: 6, icon: <SummaryIcon />, text: 'app.summary', name: 'summary', completed: false },
];

export const btcFeeSteps = [
  {
    id: 1,
    icon: <VaultIcon />,
    text: 'app.select.vault',
    name: 'vault',
    completed: true,
  },
  {
    id: 2,
    icon: <AssetIcon />,
    text: 'app.select.asset',
    name: 'asset',
    completed: false,
  },
  {
    id: 3,
    icon: <LocationIcon />,
    text: 'app.destination',
    name: 'destination',
    completed: false,
  },
  {
    id: 4,
    icon: <AmountIcon />,
    text: 'app.amount',
    name: 'amount',
    completed: false,
  },
  { id: 5, icon: <MoneyIcon />, text: 'app.strategy.and.priority', name: 'strategy', completed: false },
  { id: 6, icon: <NoteIcon />, text: 'app.note', name: 'note', completed: false },
  { id: 7, icon: <SummaryIcon />, text: 'app.summary', name: 'summary', completed: false },
];

export const automationRuleStaticFeeSteps = [
  {
    id: 1,
    icon: <PencilIcon />,
    text: 'app.set.name.automation',
    name: 'name',
    completed: true,
  },
  {
    id: 2,
    icon: <PointerIcon />,
    text: 'app.set.asset.automation',
    name: 'asset',
    completed: false,
  },
  {
    id: 3,
    icon: <ContactsIcon />,
    text: 'app.set.address.automation',
    name: 'destination',
    completed: false,
    fillInsteadOfStroke: true,
  },
  {
    id: 4,
    icon: <SettingsIcon />,
    text: 'app.set.trigger.automation',
    name: 'trigger',
    completed: false,
    fillInsteadOfStroke: true,
  },
  {
    id: 5,
    icon: <ActionIcon />,
    text: 'app.set.action.automation',
    name: 'action',
    completed: false,
    fillInsteadOfStroke: true,
  },
  { id: 6, icon: <SummaryIcon />, text: 'app.summary.automation', name: 'summary', completed: false },
];

export const automationRuleFlexibleFeeSteps = [
  {
    id: 1,
    icon: <PencilIcon />,
    text: 'app.set.name.automation',
    name: 'name',
    completed: true,
  },
  {
    id: 2,
    icon: <PointerIcon />,
    text: 'app.set.asset.automation',
    name: 'asset',
    completed: false,
  },
  {
    id: 3,
    icon: <ContactsIcon />,
    text: 'app.set.address.automation',
    name: 'destination',
    completed: false,
    fillInsteadOfStroke: true,
  },
  {
    id: 4,
    icon: <SettingsIcon />,
    text: 'app.set.trigger.automation',
    name: 'trigger',
    completed: false,
    fillInsteadOfStroke: true,
  },
  {
    id: 5,
    icon: <ActionIcon />,
    text: 'app.set.action.automation',
    name: 'action',
    completed: false,
    fillInsteadOfStroke: true,
  },
  { id: 6, icon: <AssetIcon />, text: 'app.set.fee.automation', name: 'fee', completed: false },
  { id: 7, icon: <SummaryIcon />, text: 'app.summary.automation', name: 'summary', completed: false },
];
