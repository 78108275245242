import { useEffect, useState } from 'react';
import CopyButton from '../../components/CopyButton/CopyButton';
import VModal from '../Modal/VModal';
import { T, t } from '../../components/T';
import { useDispatch, useSelector } from 'react-redux';
import { checkLimits } from '../../helpers/limits';
import { createDepositApi, createGasTankerApi, getBlockchainProtocolsApi } from '../../api/endpoints';
import { setMyUsage } from '../../redux/slices/userSlice';
import Textfield from '../Textfield/Textfield';
import Button from '../Button/Button';
import conffettiSuccessIcon from '../../assets/images/confetti-success.svg';
import Alert from '../Alert/Alert';
import CheckboxNew from '../CheckboxNew/CheckboxNew';
import CryptoIcon from '../CryptoIcon/CryptoIcon';
import Filter from '../Filter/Filter';
import Blockchain from '../Blockchain/Blockchain';

// type can be 'deposit', 'station'
const GenerateAddressModal = ({ toggleFunction, id, sussessFunction, type, walletType }) => {
  const dispatch = useDispatch();
  const typeUser = useSelector((state) => state.userReducer.typeUser);
  const protocols = useSelector((state) => state.globalReducer.protocols);
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const [loading, setLoading] = useState(false);
  const [blockChainData, setBlockChainData] = useState([]);
  const [created, setCreted] = useState(false);
  const [name, setName] = useState('');
  const [selectBlockChain, setSelectBlockChain] = useState({});
  const [address, setAddress] = useState('');
  const [consent, setConsent] = useState(false);

  useEffect(() => {
    if (typeUser) {
      getBlockchainProtocolsApi({
        params: { vaultsTypes: [walletType?.toUpperCase()], networkType: typeUser?.toUpperCase() },
      }).then((res) => setBlockChainData(res?.data?.items));
    }
  }, [typeUser]);

  const handleSelectBlockchain = (selectedBlockchains) => {
    setSelectBlockChain(selectedBlockchains);
  };

  const handleCreateAddressSuccess = (res) => {
    setCreted(true);
    setAddress(res.data.data.item.address);
    if (sussessFunction) {
      sussessFunction();
      checkLimits((usage) => dispatch(setMyUsage(usage)));
    }
  };

  const generateAddress = (event) => {
    event.currentTarget.classList.add('inactive');
    const data = {
      item: {
        name: name,
        blockchain: selectBlockChain?.[0]?.blockchain,
      },
    };
    setLoading(true);
    if (type === 'deposit' || type === 'smart-deposit') {
      createDepositApi(id, data)
        .then(handleCreateAddressSuccess)
        .finally(() => setLoading(false));
    }
    if (type === 'station') {
      createGasTankerApi(id, data)
        .then(handleCreateAddressSuccess)
        .finally(() => setLoading(false));
    }
  };
  return (
    <VModal classHandler='new-desposit-address-modal' toggleFunction={toggleFunction}>
      {!created ? (
        <>
          <div className='v-modal-title'>
            {type === 'station' ? <T>app.new.station.address</T> : <T>app.new.deposit.address</T>}
          </div>
          <div className='create-api-textfield-wrapper'>
            <label htmlFor=''>
              <T>app.address.name</T>
            </label>
            <Textfield
              type='text'
              fullWidth
              size='lg'
              error={
                name?.length > 100 || (name?.length && name?.length < 3)
                  ? t(lng, 'app.address.name.length.error')
                  : undefined
              }
              showValidationEndIcons
              placeholder={t(lng, 'app.address.name.placeholder')}
              value={name}
              onChange={(e) => setName(e.currentTarget.value)}
            />
          </div>
          <div className='create-api-textfield-wrapper'>
            <label htmlFor=''>
              <T>app.select.blockchain.protocol</T>
            </label>
            <Filter
              items={blockChainData}
              fullWidth
              dataNameKey='publicName'
              dataKey='blockchain'
              placeholder={t(lng, 'app.select.assets')}
              customLabelTemplate={(blockchain) => {
                const protocol = protocols?.find(
                  (protocol) => protocol?.blockchain?.toLowerCase() === blockchain?.blockchain?.toLowerCase(),
                );
                return (
                  <div className='generate-address-filter-template'>
                    <Blockchain
                      showText={false}
                      blockchain={protocol?.currency?.toLowerCase()}
                      symbol={protocol?.currency?.toLowerCase()}
                    />
                    <span className='generate-address-blockchain-name'>{protocol?.publicName}</span>
                    <span className='generate-address-blockchain-currency'>
                      {protocol?.currency?.toUpperCase()}
                    </span>
                  </div>
                );
              }}
              customTextfieldLabel={(blockchain) => {
                const protocol = protocols?.find(
                  (protocol) => protocol?.blockchain?.toLowerCase() === blockchain?.blockchain?.toLowerCase(),
                );
                return (
                  <div className='generate-address-filter-template'>
                    <Blockchain
                      showText={false}
                      blockchain={protocol?.currency?.toLowerCase()}
                      symbol={protocol?.currency?.toLowerCase()}
                    />
                    <span className='generate-address-blockchain-name'>{protocol?.publicName}</span>
                    <span className='generate-address-blockchain-currency'>
                      {protocol?.currency?.toUpperCase()}
                    </span>
                  </div>
                );
              }}
              selectedItems={selectBlockChain || []}
              onChange={handleSelectBlockchain}
            />
          </div>
          {typeUser === 'test' && (
            <div className='wallets-alert-wrapper'>
              <Alert text={t(lng, 'app.alert.test.addresses')} variant='warning' />
              <div style={{ marginTop: 10 }}>
                <CheckboxNew
                  id='termsCheckbox'
                  disabledHoverEffect
                  multiSelect
                  reverse
                  label={
                    <label className='form-check-label pt-1 fs-6' htmlFor='termsCheckbox'>
                      <T>app.alert.i-agree-with</T>
                    </label>
                  }
                  value={consent}
                  onChange={() => setConsent((prev) => !prev)}
                />
              </div>
            </div>
          )}
          <div className='button-holder'>
            <Button
              variant='secondary'
              size='md'
              fullWidth
              disabled={
                loading ||
                Object.keys(selectBlockChain).length === 0 ||
                name.length === 0 ||
                !name ||
                (!consent && typeUser === 'test') ||
                name?.length > 100 ||
                (name?.length && name?.length < 3)
              }
              onClick={generateAddress}
            >
              {type === 'station' ? <T>app.generate.new.station.address</T> : <T>app.generate.new.address</T>}
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className='sussess-deposit-address'>
            <img src={conffettiSuccessIcon} className='sussess-deposit-address-image' />
            <div className='congrats'>
              {type === 'station' ? (
                <T>app.susess.station.address.generate</T>
              ) : (
                <T>app.susess.address.generate</T>
              )}
            </div>
            <div className='info-block'>
              <div className='info'>
                <div className='title-holder'>
                  <T>app.blockchain.address.protocol</T>
                  <div className='select-value'>
                    <CryptoIcon
                      className='generate-address-blockchain-icon'
                      symbol={selectBlockChain?.currency}
                    />
                    <span className='name'>{selectBlockChain.publicName}</span>
                    <span className='currency'>{selectBlockChain.currency}</span>
                  </div>
                </div>
                <div className='title-holder'>
                  <T>app.address.deposit</T>
                  <div className='address-holder'>
                    <div className='address-name'>{name}</div>
                  </div>
                </div>
                <div className='title-holder'>
                  <T>app.blockchain.deposit.modal.address</T>
                  <div className='address-holder'>
                    <div className='generated-address-string'>{address}</div>
                    <CopyButton element={address} />
                  </div>
                </div>
              </div>
            </div>
            <div
              className='button-holder'
              onClick={() => {
                toggleFunction(false);
              }}
            >
              <Button variant='close' size='md' fullWidth>
                <T>app.close</T>
              </Button>
            </div>
          </div>
        </>
      )}
    </VModal>
  );
};
export default GenerateAddressModal;
