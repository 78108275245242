import './Drawer.css';

export const Drawer = (props) => {
  const { open, anchor, onClose, children } = props;

  return (
    <>
      <div
        className={`drawer-overlay ${!open ? 'drawer-overlay-hidden' : ''} ${
          open ? 'drawer-overlay-open' : ''
        }`}
        onClick={onClose}
        aria-hidden='true'
      />
      <div
        tabIndex='-1'
        className={`drawer ${open ? 'drawer-animate' : ''} ${!open ? 'drawer-hidden' : ''} ${anchor}`}
      >
        {children}
      </div>
    </>
  );
};
