import React, { useState } from 'react';
import trialImage from '../../assets/images/trial.svg';
import trialBackgroundImage from '../../assets/images/trial-background.svg';
import './TrialBanner.css';
import SelfOnboardingModal from '../Modals/SelfOnboardingModal/SelfOnboardingModal';
import { T, t } from '../T';
import { useDispatch, useSelector } from 'react-redux';
import { diffDate, vaultodyDate } from '../../helpers/date';
import { setTrialBannerVisible } from '../../redux/slices/globalSlice';
import * as moment from 'moment-timezone';
import { STATE_PENDING_VERIFICATION } from '../../redux/slices/authSlice';
import { clientStatuses } from '../../redux/slices/userSlice';

const TrialBanner = () => {
  const dispatch = useDispatch();
  const [onboardModalOpen, setOnboardModalOpen] = useState(false);
  const userVerificationInfo = useSelector((state) => state.userReducer.verificationInfo);
  const myProfile = useSelector((state) => state.userReducer.myProfile);
  const lng = useSelector((state) => state.localizationReducer.selectedData);

  const handleCloseBanner = () => dispatch(setTrialBannerVisible(false));

  return (
    <div className='trial-banner'>
      <img className='image-background' src={trialBackgroundImage} />
      {onboardModalOpen && <SelfOnboardingModal closeFunction={() => setOnboardModalOpen(false)} />}
      <div className='info-wrapper'>
        <img className='image' src={trialImage} />
        <div className='info'>
          <div className='text'>
            {(userVerificationInfo?.state?.toUpperCase() === clientStatuses.PROVISIONAL ||
              userVerificationInfo?.state?.toUpperCase() === clientStatuses.TRIAL) &&
            myProfile?.state?.toLowerCase() === STATE_PENDING_VERIFICATION &&
            moment()
              .tz(new Intl.DateTimeFormat().resolvedOptions().timeZone)
              .isAfter(
                moment(userVerificationInfo?.verificationData?.stripe?.trialExpirationDate * 1000).tz(
                  new Intl.DateTimeFormat().resolvedOptions().timeZone,
                ),
              ) ? (
              <T>app.complete.steps.after.trial</T>
            ) : (
              <>
                <T>app.trial.freetrial</T>
                <span className='highlight-text'>
                  {` ${Math.abs(
                    diffDate(userVerificationInfo?.verificationData?.stripe?.trialExpirationDate, 'days'),
                  )} ${t(lng, 'app.days')} (${vaultodyDate(
                    userVerificationInfo?.verificationData?.stripe?.trialExpirationDate,
                    'full',
                  )})`}
                </span>
              </>
            )}
          </div>
          <span className='small-text'>
            <T>app.trial.complete</T>
          </span>
        </div>
      </div>
      <button className='trial-button' onClick={() => setOnboardModalOpen(true)}>
        <T>app.trial.required</T>
      </button>
      <div className='trial-banner-icon icon-close' onClick={handleCloseBanner} />
    </div>
  );
};

export default TrialBanner;
