import { useDispatch, useSelector } from 'react-redux';
import './DashboardLayout.css';
import '../../pages/DashboardHome/DashboardHome.css';
import TitleBox from '../Pages/TitleBox/TitleBox';
import SelfOnboardingSuspendedModal from '../Modals/SelfOnboardingSuspendedModal/SelfOnboardingSuspendedModal';
import TrialBanner from '../TrialBanner/TrialBanner';
import ExpirationModal from '../ExpirationModal/ExpirationModal';
import Header from '../Pages/Header/Header';
import DashboardMenu from '../DashboardMenu/DashboardMenu';
import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { clientStatuses } from '../../redux/slices/userSlice';
import { ROUTE_HOME, ROUTE_PRICING_TABLE } from '../../routes/routes';
import { setPage } from '../../redux/slices/globalSlice';
import { STATE_PENDING_VERIFICATION } from '../../redux/slices/authSlice';
import { useLocation } from 'react-router-dom';
import MobileHeader from '../Pages/Header/MobileHeader';

const DashboardLayout = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const myProfile = useSelector((state) => state.userReducer.myProfile);
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const userVerificationInfo = useSelector((state) => state.userReducer.verificationInfo);
  const trialBannerVisible = useSelector((state) => state.globalReducer.trialBannerVisible);
  const suspendedModalVisible = useSelector((state) => state.globalReducer.suspendedModalVisible);
  const [modalExp, setModalExp] = useState(false);

  const onIdle = () => {
    if (!window.location.href.includes('localhost')) {
      setModalExp(true);
      localStorage.clear();
    }
  };

  useIdleTimer({
    onIdle: onIdle,
    timeout: 600_000,
    throttle: 500,
  });

  const logOutFuntion = () => {
    window.location.replace(ROUTE_HOME);
  };

  const renderSubscriptionElement = () => {
    if (suspendedModalVisible) {
      return <SelfOnboardingSuspendedModal />;
    }
    if (
      (userVerificationInfo?.state?.toUpperCase() === clientStatuses.PROVISIONAL ||
        userVerificationInfo?.state?.toUpperCase() === clientStatuses.TRIAL) &&
      myProfile?.state?.toLowerCase() === STATE_PENDING_VERIFICATION &&
      trialBannerVisible
    ) {
      return (
        <div className='trial-banner-wrapper'>
          <TrialBanner />
        </div>
      );
    }
    return null;
  };

  if (window.location.pathname === ROUTE_PRICING_TABLE) {
    return children;
  }

  return (
    Object.values(lng).length !== 0 && (
      <div className={`dashboard ${localStorage.getItem('inactive') ? 'inactive-request' : ''}`}>
        <div className='main'>
          {modalExp && <ExpirationModal closeFunction={logOutFuntion} />}
          <Header />
          <MobileHeader />
          <div className='main-dashboard'>
            <DashboardMenu
              hadleSetPage={(pageName) => dispatch(setPage(pageName))}
              link={location.pathname}
            />
            <div className='content-page'>
              {renderSubscriptionElement()}
              <TitleBox name={location.pathname} />
              <div className='content'>{children}</div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default DashboardLayout;
