import { T, t } from '../../T';
import VModal from '../../Modal/VModal';
import ActionModal from '../ActionModal';
import { useSelector } from 'react-redux';
import Stepper from '../../Stepper/Stepper';
import { useState, useEffect } from 'react';
import { ReactComponent as NameIcon } from '../../../assets/icons/pencil2-icon.svg';
import { ReactComponent as PassIcon } from '../../../assets/icons/decoded-icon.svg';
import { ReactComponent as WalletIcon } from '../../../assets/icons/vault-icon2.svg';
import { ReactComponent as IPAddressIcon } from '../../../assets/icons/note-icon.svg';
import { ReactComponent as MenuIcon } from '../../../assets/icons/menu-icon.svg';
import { ReactComponent as SummaryIcon } from '../../../assets/icons/summary-icon2.svg';
import { ReactComponent as EyeIcon } from '../../../assets/icons/eye-icon.svg';
import { ReactComponent as DisabledEyeIcon } from '../../../assets/icons/disabled-eye-icon.svg';
import pendingIcon from '../../../assets/icons/pending-icon.svg';
import iconHelp from '../../../assets/images/help-icon.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import './CreateApiKeyModal.css';
import 'swiper/css';
import CopyButton from '../../CopyButton/CopyButton';
import CheckboxNew from '../../CheckboxNew/CheckboxNew';
import Button from '../../Button/Button';
import {
  createApiKeyApi,
  editApiKeyApi,
  getApiVersionsApi,
  getPermissibleRoutesApi,
  getWalletsApi,
} from '../../../api/endpoints';
import Textfield from '../../Textfield/Textfield';
import { useForm, Controller } from 'react-hook-form';
import Filter from '../../Filter/Filter';
import VaultIcon from '../../VaultIcon/VaultIcon';
import Alert from '../../Alert/Alert';
import Select from '../../Select/Select';
import { unique } from '../../../helpers/array';
import { useSwiperSlider } from '../../../helpers/hooks/useSwiperSlider';
import { passwordRegex } from '../../../helpers/validation';

const CreateApiKeyModal = ({ toggleFunction, successCallback, editData }) => {
  const typeUser = useSelector((state) => state.userReducer.typeUser);
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const [wallets, setWallets] = useState();
  const [success, setSuccess] = useState(false);
  const [successData, setSuccessData] = useState('');
  const [successAdded, setsSuccessAdded] = useState(false);
  const [sliding, setSliding] = useState(false);
  const [currentStep, setCurrentStep] = useState({ id: 1, name: 'name' });
  const [allPermissions, setAllPermissions] = useState([]);
  const [saveSecret, setSaveSecret] = useState(false);
  const [isPassphraseHidden, setIsPassphraseHidden] = useState(true);
  const [apiVersions, setApiVersions] = useState([]);
  const [selectedApiVersion, setSelectedApiVersion] = useState();

  const {
    handleSubmit,
    control,
    formState: { errors, dirtyFields, isValid },
    getValues,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: editData?.name || '',
      passphrase: '',
      vault: editData?.wallets || [],
      ipaddress: editData?.whitelistedIps?.join(',') || '',
      permissions: editData?.routes?.length ? editData?.routes?.map((perm) => ({ id: perm })) : [],
    },
  });
  const [steps, setSteps] = useState([
    {
      id: 1,
      icon: <NameIcon />,
      text: 'app.api.key.name',
      name: 'name',
      completed: false,
    },
    ...(!editData?.id
      ? [
          {
            id: 2,
            icon: <PassIcon />,
            text: 'app.pass.phrase',
            name: 'passphrase',
            completed: false,
          },
        ]
      : []),
    {
      id: 3 - (!editData?.id ? 0 : 1),
      icon: <WalletIcon />,
      text: 'app.select.vault',
      name: 'vault',
      completed: false,
    },
    {
      id: 4 - (!editData?.id ? 0 : 1),
      icon: <IPAddressIcon />,
      text: 'app.ip.address.whitelist',
      name: 'ipaddress',
      completed: false,
    },
    {
      id: 5 - (!editData?.id ? 0 : 1),
      icon: <MenuIcon />,
      text: 'app.general.permissions',
      name: 'permissions',
      completed: false,
    },
    {
      id: 6 - (!editData?.id ? 0 : 1),
      icon: <SummaryIcon />,
      text: 'app.summary',
      name: 'summary',
      completed: false,
    },
  ]);
  const { handleCompleteStep, handleSwiperNextSlide, swiperContainerHeight, swiperRef } =
    useSwiperSlider(setSteps);

  const { icon, subText } = stepDescriptionMapping()[currentStep?.name];

  const handleChooseApiVerison = (apiVersions) => {
    setSelectedApiVersion(apiVersions);
  };

  const getPermissibleRoutes = () => {
    getPermissibleRoutesApi({ params: { versionId: selectedApiVersion?.[0]?.id } }).then((res) =>
      setAllPermissions(res.data.item),
    );
  };

  const createAPIKey = (data) => {
    const payload = {
      item: {
        name: data?.name,
        ...(!editData?.id ? { passphrase: data?.passphrase } : {}),
        routes: data?.permissions?.length ? data?.permissions?.map((perm) => perm?.id) : [],
        whitelistedIps: data?.ipaddress?.length ? data?.ipaddress?.split(',')?.map((ip) => ip?.trim()) : [],
        walletsIds: data?.vault?.map((vault) => vault?.id),
        type: typeUser?.toUpperCase(),
      },
    };
    if (editData?.id) {
      editApiKeyApi(editData.id, payload).then(() => {
        setsSuccessAdded(true);
        successCallback();
      });
    } else {
      createApiKeyApi(payload).then((res) => {
        setSuccess(true);
        setSuccessData(res.data?.data?.item);
        successCallback();
      });
    }
  };

  const handleStepNextButton = (id, name) => {
    setCurrentStep({ id: id, name: name });
    handleCompleteStep(id - 1);
    handleSwiperNextSlide();
  };

  useEffect(() => {
    if (selectedApiVersion?.length > 0) {
      getPermissibleRoutes();
    }
  }, [selectedApiVersion]);

  useEffect(() => {
    if (typeUser) {
      getApiVersionsApi().then((res) => {
        setApiVersions(res?.data?.items);
        const defaultVersion = res?.data?.items?.find((apiVersion) => apiVersion?.isClientDefault === true);
        if (defaultVersion) {
          setSelectedApiVersion([defaultVersion]);
        }
      });
      getWalletsApi({
        params: {
          type: typeUser?.toUpperCase(),
          limit: 0,
        },
      }).then((res) => setWallets(res.data.items));
    }
  }, [typeUser]);

  return (
    <>
      {!successAdded ? (
        <VModal
          classHandler={success ? 'create-api-modal mini-api-key-modal' : 'create-transaction-request-modal'}
          toggleFunction={toggleFunction}
        >
          {!success && (
            <div className='create-transaction-request-modal-wrapper'>
              <div className='create-transaction-request-modal-side'>
                <span className='create-transaction-request-modal-side-heading'>
                  {`${t(lng, 'app.step')} ${currentStep?.id}`}
                </span>
                <Stepper
                  onSelectStepCallback={(stepIndex, stepName) => {
                    setCurrentStep({ id: stepIndex, name: stepName });
                    if (swiperRef.current && swiperRef.current.swiper) {
                      swiperRef.current.swiper.slideTo(stepIndex - 1);
                    }
                  }}
                  currentStepId={currentStep?.id}
                  steps={steps}
                />
              </div>
              <div className='create-transaction-request-modal-content'>
                <span className='create-transaction-request-modal-heading'>
                  <div className='create-transaction-request-modal-heading-wrapper'>
                    <span>{!editData?.id ? <T>app.create.api.key</T> : <T>app.edit.api.key</T>}</span>
                    <div className='mobile-stepper'>
                      <Stepper
                        onSelectStepCallback={(stepIndex, stepName) => {
                          setCurrentStep({ id: stepIndex, name: stepName });
                          if (swiperRef.current && swiperRef.current.swiper) {
                            swiperRef.current.swiper.slideTo(stepIndex - 1);
                          }
                        }}
                        currentStepId={currentStep?.id}
                        steps={steps}
                      />
                    </div>
                    <span className='create-transaction-request-modal-side-sub-heading'>
                      {icon}
                      {t(lng, steps[currentStep?.id - 1]?.text)}
                    </span>
                    <span className='create-transaction-request-modal-step-desc'>{t(lng, subText)}</span>
                  </div>
                </span>
                <div
                  className={`create-transaction-request-swiper-wrapper ${sliding ? 'sliding' : ''}`}
                  style={{ height: swiperContainerHeight }}
                >
                  <Swiper
                    ref={swiperRef}
                    spaceBetween={50}
                    speed={800}
                    allowTouchMove={false}
                    slidesPerView={1}
                    onSlideChangeTransitionStart={() => setSliding(true)}
                    onSlideChangeTransitionEnd={() => setSliding(false)}
                  >
                    <form onSubmit={handleSubmit(createAPIKey)} className='signin-form-wrapper'>
                      <SwiperSlide>
                        <Controller
                          name='name'
                          control={control}
                          rules={{
                            required: `${t(lng, 'app.api.key.name')} ${t(lng, 'app.is.required')}`,
                          }}
                          render={({ field }) => {
                            return (
                              <>
                                <span className='create-trx-request-modal-select-component-label'>
                                  <T>app.api.key.name</T>
                                </span>
                                <Textfield
                                  id='name'
                                  type='text'
                                  name='name'
                                  fullWidth
                                  size='lg'
                                  maxLength={25}
                                  error={errors?.name?.message}
                                  placeholder={t(lng, 'app.ex.produc.api.key')}
                                  {...field}
                                />
                              </>
                            );
                          }}
                        />
                        <div className='create-trx-request-modal-button'>
                          <Button
                            fullWidth
                            onClick={() =>
                              handleStepNextButton(
                                !editData?.id ? 2 : 2,
                                !editData?.id ? 'passphrase' : 'vault',
                              )
                            }
                            disabled={errors?.name || (!editData?.id && !dirtyFields?.name)}
                          >
                            <T>app.next.step</T>
                          </Button>
                        </div>
                      </SwiperSlide>
                      {!editData?.id ? (
                        <SwiperSlide>
                          <Controller
                            name='passphrase'
                            control={control}
                            rules={{
                              required: `${t(lng, 'app.pass.phrase')} ${t(lng, 'app.is.required')}`,
                              validate: (value) =>
                                passwordRegex.test(value) || t(lng, 'app.passphrase.validate'),
                            }}
                            render={({ field }) => {
                              return (
                                <>
                                  <label>
                                    <T>app.pass.phrase</T>
                                  </label>
                                  <Textfield
                                    id='passphrase'
                                    type='password'
                                    trim
                                    name='passphrase'
                                    autoComplete='new-password'
                                    fullWidth
                                    size='lg'
                                    placeholder={t(lng, 'app.enter.phrase')}
                                    error={errors?.passphrase?.message}
                                    {...field}
                                  />
                                </>
                              );
                            }}
                          />
                          <Controller
                            name='repeatPassphrase'
                            control={control}
                            rules={{
                              required: `${t(lng, 'app.pass.phrase')} ${t(lng, 'app.is.required')}`,
                              validate: (value, formValues) =>
                                formValues?.passphrase === value || t(lng, 'app.passphrase-is-not-match'),
                            }}
                            render={({ field }) => {
                              return (
                                <>
                                  <label className='mt-3'>
                                    <T>app.passphrase-confirm-placeholder</T>
                                  </label>
                                  <Textfield
                                    id='repeatpass'
                                    trim
                                    type='password'
                                    size='lg'
                                    fullWidth
                                    autoComplete='new-password'
                                    placeholder={t(lng, 'app.passphrase-confirm-placeholder')}
                                    error={errors?.repeatPassphrase?.message}
                                    {...field}
                                  />
                                </>
                              );
                            }}
                          />
                          <div className='create-trx-request-modal-button'>
                            <Button
                              fullWidth
                              onClick={() => handleStepNextButton(3, 'vault')}
                              disabled={
                                errors?.passphrase ||
                                !dirtyFields?.passphrase ||
                                errors?.repeatPassphrase ||
                                !dirtyFields?.repeatPassphrase
                              }
                            >
                              <T>app.next.step</T>
                            </Button>
                          </div>
                        </SwiperSlide>
                      ) : null}
                      <SwiperSlide>
                        <Controller
                          name='vault'
                          control={control}
                          render={({ field }) => {
                            return (
                              <>
                                <label>
                                  <T>app.select.wallets.optional</T>
                                </label>
                                <Filter
                                  items={wallets}
                                  selectAllField
                                  fullWidth
                                  displayIcon
                                  multiSelect
                                  placeholder='app.select.vaults.api.key'
                                  {...field}
                                  selectedItems={field?.value || []}
                                  onChange={field?.onChange}
                                />
                              </>
                            );
                          }}
                        />
                        <div className='create-trx-request-modal-button'>
                          <Button
                            fullWidth
                            onClick={() => handleStepNextButton(!editData?.id ? 4 : 3, 'ipaddress')}
                          >
                            <T>app.next.step</T>
                          </Button>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Controller
                          name='ipaddress'
                          control={control}
                          rules={{
                            validate: (value) =>
                              !value ||
                              value
                                .split(',')
                                .every((i) =>
                                  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
                                    i?.trim(),
                                  ),
                                ) ||
                              t(lng, 'app.api.key.ipaddress.validate'),
                          }}
                          render={({ field }) => {
                            return (
                              <>
                                <label>
                                  <T>app.ip.address.whitelist.optional</T>
                                </label>
                                <Textfield
                                  id='ipaddress'
                                  autocomplete='off'
                                  type='text'
                                  name='ipaddress'
                                  fullWidth
                                  size='lg'
                                  placeholder='Ex: 216.58.216.164,216.58.216.164'
                                  error={errors?.ipaddress?.message}
                                  {...field}
                                />
                              </>
                            );
                          }}
                        />
                        <div className='create-trx-request-modal-button'>
                          <Button
                            fullWidth
                            onClick={() => handleStepNextButton(!editData?.id ? 5 : 4, 'permissions')}
                            disabled={errors?.ipaddress}
                          >
                            <T>app.next.step</T>
                          </Button>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div>
                          <label>
                            <T>app.api.version</T>
                          </label>
                          <Filter
                            variant='radio'
                            items={apiVersions}
                            selectedItems={selectedApiVersion}
                            onChange={handleChooseApiVerison}
                            fullWidth
                            placeholder='app.choose.api.version'
                            customLabelTemplate={(apiVersion) => (
                              <div className='create-api-api-version-row-wrapper'>
                                <div className='create-api-api-version-wrapper'>
                                  <div>
                                    <div className='create-api-api-version-name'>{apiVersion?.name}</div>
                                    <div className='create-api-api-version-label'>
                                      <T>app.api.version</T>
                                    </div>
                                  </div>
                                  <img src={iconHelp} alt='' />
                                </div>
                                {apiVersion?.isClientDefault && (
                                  <div className='create-api-api-version-default'>
                                    <T>app.default</T>
                                  </div>
                                )}
                                {!apiVersion?.isClientDefault && apiVersion?.id === apiVersions?.[0]?.id && (
                                  <div className='create-api-api-version-latest'>
                                    <T>app.latest</T>
                                  </div>
                                )}
                              </div>
                            )}
                          />
                        </div>
                        <div className='create-api-key-permissions-wrapper'>
                          <Controller
                            name='permissions'
                            control={control}
                            render={({ field }) => {
                              return (
                                <>
                                  <label>
                                    <T>app.general.permissions</T>
                                  </label>
                                  <Select
                                    items={allPermissions?.commonRoutesList}
                                    selectAllField
                                    fullWidth
                                    multiSelect
                                    placeholder={t(lng, 'app.general.permissions.select')}
                                    {...field}
                                    selectedItems={field?.value || []}
                                    onChange={(item, event) => {
                                      if (event === 'ADD ALL') {
                                        const allItems = getValues().permissions;
                                        return field?.onChange(
                                          unique([...allItems, ...allPermissions?.commonRoutesList], 'id'),
                                        );
                                      }
                                      if (event === 'REMOVE ALL') {
                                        const allItems = getValues().permissions;
                                        return field?.onChange(
                                          allItems?.filter(
                                            (selectedItem) =>
                                              !allPermissions?.commonRoutesList?.some(
                                                (perm) => perm?.id === selectedItem?.id,
                                              ),
                                          ),
                                        );
                                      }
                                      if (event === 'REMOVE') {
                                        const allItems = getValues().permissions;
                                        return field?.onChange(
                                          allItems?.filter((selectedItem) => selectedItem?.id !== item?.id),
                                        );
                                      }
                                      if (event === 'ADD') {
                                        const allItems = getValues().permissions;
                                        return field?.onChange(unique([...allItems, item], 'id'));
                                      }
                                    }}
                                  />
                                </>
                              );
                            }}
                          />
                        </div>
                        <Controller
                          name='permissions'
                          control={control}
                          render={({ field }) => {
                            return (
                              <>
                                <label>
                                  <T>app.vault.specific</T>
                                </label>
                                <Select
                                  items={allPermissions?.walletSpecificRoutesList}
                                  selectAllField
                                  fullWidth
                                  multiSelect
                                  placeholder={t(lng, 'app.vault.specific.select')}
                                  disabled={!getValues()?.vault?.length}
                                  {...field}
                                  selectedItems={field?.value || []}
                                  onChange={(item, event) => {
                                    if (event === 'ADD ALL') {
                                      const allItems = getValues().permissions;
                                      return field?.onChange(
                                        unique(
                                          [...allItems, ...allPermissions?.walletSpecificRoutesList],
                                          'id',
                                        ),
                                      );
                                    }
                                    if (event === 'REMOVE ALL') {
                                      const allItems = getValues().permissions;
                                      return field?.onChange(
                                        allItems?.filter(
                                          (selectedItem) =>
                                            !allPermissions?.walletSpecificRoutesList?.some(
                                              (perm) => perm?.id === selectedItem?.id,
                                            ),
                                        ),
                                      );
                                    }
                                    if (event === 'REMOVE') {
                                      const allItems = getValues().permissions;
                                      return field?.onChange(
                                        allItems?.filter((selectedItem) => selectedItem?.id !== item?.id),
                                      );
                                    }
                                    if (event === 'ADD') {
                                      const allItems = getValues().permissions;
                                      return field?.onChange(unique([...allItems, item], 'id'));
                                    }
                                  }}
                                />
                              </>
                            );
                          }}
                        />
                        <div className='create-api-alert-wrapper'>
                          <Alert variant='warning' text={t(lng, 'app.create.api.key.alert')} />
                        </div>
                        <div className='create-trx-request-modal-button'>
                          <Button
                            fullWidth
                            onClick={() => handleStepNextButton(!editData?.id ? 6 : 5, 'summary')}
                          >
                            <T>app.next.step</T>
                          </Button>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className='create-api-key-summary-wrapper'>
                          <div className='create-api-key-summary-row'>
                            <T>app.api.key.name</T>
                            <span>{getValues()?.name}</span>
                          </div>
                          {!editData?.id && (
                            <div className='create-api-key-summary-row'>
                              <T>app.pass.phrase</T>
                              <span>
                                {isPassphraseHidden
                                  ? getValues()?.passphrase?.replace(/./g, '✱')
                                  : getValues()?.passphrase}
                                {isPassphraseHidden ? (
                                  <DisabledEyeIcon
                                    style={{ marginLeft: 10, cursor: 'pointer' }}
                                    onClick={() => setIsPassphraseHidden((prev) => !prev)}
                                  />
                                ) : (
                                  <EyeIcon
                                    style={{ marginLeft: 10, cursor: 'pointer' }}
                                    onClick={() => setIsPassphraseHidden((prev) => !prev)}
                                  />
                                )}
                              </span>
                            </div>
                          )}
                          {getValues()?.vault?.length ? (
                            <div className='create-api-key-summary-row'>
                              <T>app.wallets</T>
                              <div className='wallet-icons'>
                                {getValues()?.vault?.map((wallet, key) => {
                                  if (key < 3) {
                                    return (
                                      <VaultIcon
                                        key={`vault-index-${key}`}
                                        id={wallet?.id}
                                        name={wallet?.name}
                                        color={wallet?.color}
                                      />
                                    );
                                  }
                                })}
                                {getValues()?.vault?.length > 3 && (
                                  <div className='wallet-icon plus'>{`+ ${
                                    getValues()?.vault?.length - 3
                                  }`}</div>
                                )}
                              </div>
                            </div>
                          ) : null}
                          {getValues()?.ipaddress ? (
                            <div className='create-api-key-summary-row'>
                              <T>app.ip.address.whitelist</T>
                              <span>{getValues()?.ipaddress}</span>
                            </div>
                          ) : null}
                          {getValues()?.permissions?.length ? (
                            <div className='create-api-key-summary-row'>
                              <div>
                                <T>app.endpoints</T>
                              </div>
                              <div className='create-api-key-summary-many'>
                                {getValues()?.permissions?.length} <T>app.selected</T>
                              </div>
                            </div>
                          ) : null}
                          <Button
                            fullWidth
                            type='submit'
                            onClick={handleSubmit(createAPIKey)}
                            disabled={!isValid}
                          >
                            <i className='icon-submit-for-approval' />
                            <T>app.submit.for.approval</T>
                          </Button>
                        </div>
                      </SwiperSlide>
                    </form>
                  </Swiper>
                </div>
              </div>
            </div>
          )}
          {success && (
            <div className='sussess-create-key-api-key'>
              <div className='team-icon-api-key-wrapper'>
                <img className='team-icon-api-key' src={pendingIcon} alt='' />
              </div>
              <div className='sussess-msg-api-key'>
                <div className='sussess-title-api-key'>
                  <T>app.api.key.info</T>
                </div>
                <div className='success-api-key-info-wrapper'>
                  <div className='success-api-key-info-row'>
                    <span className='success-api-key-info-text'>
                      <T>app.api.key.name</T>
                    </span>
                    <span className='success-api-key-info-sub-text'>{successData?.name}</span>
                  </div>
                  <div className='success-api-key-info-copy-row'>
                    <span className='success-api-key-info-text'>
                      <T>app.api.key</T>
                    </span>
                    <div className='success-api-key-info-row-copy'>
                      <span className='success-api-key-info-sub-text'>{successData.key}</span>
                      <CopyButton element={successData.key} />
                    </div>
                  </div>
                  <div className='success-api-key-info-copy-row'>
                    <span className='success-api-key-info-text'>
                      <T>app.api.secret</T>
                    </span>
                    <div className='success-api-key-info-row-copy'>
                      <span className='success-api-key-info-sub-text'>{successData.secret}</span>
                      <CopyButton element={successData.secret} />
                    </div>
                  </div>
                  <div className='sussess-secret-msg-api-key'>
                    <T>app.store.it.a.safe.place</T>
                  </div>
                </div>
              </div>
              <div className='success-api-key-checkbox-wrapper'>
                <CheckboxNew
                  id='termsCheckbox'
                  disabledHoverEffect
                  multiSelect
                  reverse
                  label={
                    <label className='success-api-key-checkbox-label' htmlFor='termsCheckbox'>
                      <T>app.i.safely.my.api.secret</T>
                    </label>
                  }
                  value={saveSecret}
                  onChange={() => setSaveSecret((prev) => !prev)}
                />
              </div>
              <div className='button-holder'>
                <Button size='md' fullWidth disabled={!saveSecret} onClick={() => setsSuccessAdded(true)}>
                  <T>app.continue</T>
                </Button>
              </div>
            </div>
          )}
        </VModal>
      ) : (
        <ActionModal toggleFunction={toggleFunction}>
          <T>app.api.key.descr</T>
        </ActionModal>
      )}
    </>
  );
};

export default CreateApiKeyModal;

const stepDescriptionMapping = () => ({
  name: {
    subText: 'app.api.key.name.step.subtext',
    icon: <NameIcon style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
  passphrase: {
    subText: 'app.api.key.passphrase.step.subtext',
    icon: <PassIcon style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
  vault: {
    subText: 'app.vault.step.sub.text2',
    icon: <WalletIcon style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
  ipaddress: {
    subText: 'app.api.key.ipaddress.step.subtext',
    icon: <IPAddressIcon style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
  permissions: {
    subText: 'app.api.key.permissions.step.subtext',
    icon: <MenuIcon style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
  summary: {
    icon: <SummaryIcon fill='#fff' style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
});
