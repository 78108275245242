import { ReactComponent as SpeedFastIcon } from '../../../../assets/icons/speedometer-icon.svg';
import { ReactComponent as SpeedSlowIcon } from '../../../../assets/icons/slow-speedo.svg';
import { ReactComponent as SpeedMediumIcon } from '../../../../assets/icons/medium-speedo.svg';
import Radio from '../../../Radio/Radio';
import Button from '../../../Button/Button';
import { T, t } from '../../../T';
import { useSelector } from 'react-redux';

const CreateVaultAutomationRuleFeeStep = ({
  strategyFees,
  selectedFeePriority,
  handleChooseFee,
  submitCallback,
}) => {
  const lng = useSelector((state) => state.localizationReducer.selectedData);

  return (
    <div className='create-vault-automation-rule-content-wrapper'>
      <div
        className='create-vault-automation-rule-modal-fee-wrapper'
        onClick={() =>
          handleChooseFee({
            name: t(lng, 'app.slow'),
            value: 'slow',
            fee: `~ ${strategyFees?.slow?.feeValue} ${strategyFees?.unit?.toLowerCase()}`,
          })
        }
      >
        <div className='create-vault-automation-rule-modal-fee-first-row'>
          <Radio
            disabledHoverEffect={true}
            reverse={true}
            value={selectedFeePriority?.value === 'slow'}
            label={
              <div className='create-vault-automation-rule-modal-fee-icon-wrapper'>
                <SpeedSlowIcon />
                <T>app.slow</T>
              </div>
            }
          />
        </div>
        <div className='create-vault-automation-rule-modal-fee-second-row'>
          <T>app.fee.priority.slow.desc</T>
          <span>
            ~ {strategyFees?.slow?.feeValue} {strategyFees?.unit?.toLowerCase()}
          </span>
        </div>
      </div>
      <div
        className='create-vault-automation-rule-modal-fee-wrapper'
        onClick={() =>
          handleChooseFee({
            name: t(lng, 'app.standard'),
            value: 'standard',
            fee: `~ ${strategyFees?.standard?.feeValue} ${strategyFees?.unit?.toLowerCase()}`,
          })
        }
      >
        <div className='create-vault-automation-rule-modal-fee-first-row'>
          <Radio
            disabledHoverEffect={true}
            reverse={true}
            value={selectedFeePriority?.value === 'standard'}
            label={
              <div className='create-vault-automation-rule-modal-fee-icon-wrapper'>
                <SpeedMediumIcon />
                <T>app.medium</T>
              </div>
            }
          />
        </div>
        <div className='create-vault-automation-rule-modal-fee-second-row'>
          <T>app.fee.priority.standard.desc</T>
          <span>
            ~ {strategyFees?.standard?.feeValue} {strategyFees?.unit?.toLowerCase()}
          </span>
        </div>
      </div>
      <div
        className='create-vault-automation-rule-modal-fee-wrapper'
        onClick={() =>
          handleChooseFee({
            name: t(lng, 'app.fast'),
            value: 'fast',
            fee: `~ ${strategyFees?.fast?.feeValue} ${strategyFees?.unit?.toLowerCase()}`,
          })
        }
      >
        <div className='create-vault-automation-rule-modal-fee-first-row'>
          <Radio
            disabledHoverEffect={true}
            reverse={true}
            value={selectedFeePriority?.value === 'fast'}
            label={
              <div className='create-vault-automation-rule-modal-fee-icon-wrapper'>
                <SpeedFastIcon />
                <T>app.fast</T>
              </div>
            }
          />
        </div>
        <div className='create-vault-automation-rule-modal-fee-second-row'>
          <T>app.fee.priority.fast.desc</T>
          <span>
            ~ {strategyFees?.fast?.feeValue} {strategyFees?.unit?.toLowerCase()}
          </span>
        </div>
      </div>
      <div className='create-vault-automation-rule-modal-button'>
        <Button fullWidth onClick={submitCallback} disabled={!selectedFeePriority}>
          <T>app.next.step</T>
        </Button>
      </div>
    </div>
  );
};

export default CreateVaultAutomationRuleFeeStep;
