import { T, t } from '../../T';
import VModal from '../../Modal/VModal';
import ActionModal from '../ActionModal';
import { useDispatch, useSelector } from 'react-redux';
import Stepper from '../../Stepper/Stepper';
import { useState, useEffect } from 'react';
import { ReactComponent as NameIcon } from '../../../assets/icons/pencil2-icon.svg';
import { ReactComponent as WalletIcon } from '../../../assets/icons/vault-icon2.svg';
import { ReactComponent as MenuIcon } from '../../../assets/icons/menu-icon.svg';
import { ReactComponent as SummaryIcon } from '../../../assets/icons/summary-icon2.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import './CreateTransactionPolicyModal.css';
import 'swiper/css';
import Button from '../../Button/Button';
import { createTransactionRuleApi, editTrxRule, getWalletsApi } from '../../../api/endpoints';
import Textfield from '../../Textfield/Textfield';
import { useForm, Controller } from 'react-hook-form';
import Filter from '../../Filter/Filter';
import VaultIcon from '../../VaultIcon/VaultIcon';
import { checkLimits } from '../../../helpers/limits';
import { currency } from '../../../helpers/currency';
import CheckboxNew from '../../CheckboxNew/CheckboxNew';
import { setMyUsage } from '../../../redux/slices/userSlice';
import { useSwiperSlider } from '../../../helpers/hooks/useSwiperSlider';

const CreateTransactionPolicyModal = ({ toggleFunction, successCallback, editData, approvers }) => {
  const isEdit = editData?.id;
  const dispatch = useDispatch();
  const typeUser = useSelector((state) => state.userReducer.typeUser);
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const [wallets, setWallets] = useState();
  const [values] = useState([0, 10000, 50000, 250000, 1000000, 5000000, 10000000]);
  const [maxValues, setMaxValues] = useState(
    isEdit
      ? values.filter((value) => {
          return Number(value) > Number(editData?.range?.start);
        })
      : undefined,
  );
  const [success, setSuccess] = useState(false);
  const [sliding, setSliding] = useState(false);
  const [currentStep, setCurrentStep] = useState({ id: 1, name: 'name' });
  const [selectApproversL1, setSelectedApproversL1] = useState(editData?.l1 || []);
  const [selectApproversL2, setSelectedApproversL2] = useState(editData?.l2 || []);
  const {
    handleSubmit,
    control,
    formState: { errors, dirtyFields, isValid },
    getValues,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: editData?.name || '',
      vault: editData?.wallets || [],
      volumeFrom: [{ id: `volumeFrom-${editData?.range?.start}`, name: editData?.range?.start }] || [],
      volumeTo: isEdit
        ? editData.range.end === 0
          ? [{ id: 'volumeTo-infinity', name: 'infinity' }]
          : [{ id: `volumeTo-${editData?.range?.end}`, name: editData?.range?.end }]
        : [],
    },
  });
  const [steps, setSteps] = useState([
    {
      id: 1,
      icon: <NameIcon />,
      text: 'app.rule.name',
      name: 'name',
      completed: false,
    },
    {
      id: 2,
      icon: <WalletIcon />,
      text: 'app.select.wallets',
      name: 'vault',
      completed: false,
    },
    { id: 3, icon: <MenuIcon />, text: 'app.24.hours.amount.limit', name: 'volume', completed: false },
    {
      id: 4,
      icon: <SummaryIcon />,
      text: 'app.gov.leayer',
      name: 'policy',
      completed: false,
    },
    { id: 5, icon: <SummaryIcon />, text: 'app.summary', name: 'summary', completed: false },
  ]);
  const { handleCompleteStep, handleSwiperNextSlide, swiperContainerHeight, swiperRef } =
    useSwiperSlider(setSteps);

  const { icon, subText } = stepDescriptionMapping()[currentStep?.name];

  const createRule = (data) => {
    const payload = {
      item: {
        name: data?.name,
        range: {
          start: Number(data?.volumeFrom?.[0]?.name),
          end: Number(data?.volumeTo?.[0]?.name),
        },
        wallets: data?.vault?.map((wallet) => {
          return wallet.id;
        }),
        l1: selectApproversL1?.map((approver) => approver?.id),
        l2: selectApproversL2?.map((approver) => approver?.id),
      },
    };
    if (data?.volumeTo?.[0]?.name === 'infinity') {
      payload.item.range.end = 0;
    }
    if (isEdit) {
      editTrxRule(editData.id, payload).then(() => {
        setSuccess(true);
        successCallback();
        checkLimits((usage) => dispatch(setMyUsage(usage)));
      });
    } else {
      createTransactionRuleApi(payload).then(() => {
        setSuccess(true);
        successCallback();
        checkLimits((usage) => dispatch(setMyUsage(usage)));
      });
    }
  };

  const handleStepNextButton = (id, name) => {
    setCurrentStep({ id: id, name: name });
    handleCompleteStep(id - 1);
    handleSwiperNextSlide();
  };

  useEffect(() => {
    if (typeUser) {
      getWalletsApi({
        params: {
          type: typeUser?.toUpperCase(),
          limit: 0,
          vaultTypes: ['GENERAL', 'SMART'],
        },
      }).then((res) => setWallets(res.data.items));
    }
  }, [typeUser]);

  return (
    <>
      {!success && (
        <VModal classHandler={'create-policy-modal'} toggleFunction={toggleFunction}>
          <div className='create-policy-modal-wrapper'>
            <div className='create-policy-modal-side'>
              <span className='create-policy-modal-side-heading'>
                {`${t(lng, 'app.step')} ${currentStep?.id}`}
              </span>
              <Stepper
                onSelectStepCallback={(stepIndex, stepName) => {
                  setCurrentStep({ id: stepIndex, name: stepName });
                  if (swiperRef.current && swiperRef.current.swiper) {
                    swiperRef.current.swiper.slideTo(stepIndex - 1);
                  }
                }}
                currentStepId={currentStep?.id}
                steps={steps}
              />
            </div>
            <div className='create-policy-modal-content'>
              <span className='create-policy-modal-heading'>
                <div className='create-policy-modal-heading-wrapper'>
                  <span>{!isEdit ? <T>app.create.a.new.rule</T> : <T>app.update.general.rule</T>}</span>
                  <div className='mobile-stepper'>
                    <Stepper
                      onSelectStepCallback={(stepIndex, stepName) => {
                        setCurrentStep({ id: stepIndex, name: stepName });
                        if (swiperRef.current && swiperRef.current.swiper) {
                          swiperRef.current.swiper.slideTo(stepIndex - 1);
                        }
                      }}
                      currentStepId={currentStep?.id}
                      steps={steps}
                    />
                  </div>
                  <span className='create-policy-modal-side-sub-heading'>
                    {icon}
                    {t(lng, steps[currentStep?.id - 1]?.text)}
                  </span>
                  <span className='create-policy-modal-step-desc'>{t(lng, subText)}</span>
                </div>
              </span>
              <div
                className={`create-policy-swiper-wrapper ${sliding ? 'sliding' : ''}`}
                style={{ height: swiperContainerHeight }}
              >
                <Swiper
                  ref={swiperRef}
                  spaceBetween={50}
                  speed={800}
                  allowTouchMove={false}
                  slidesPerView={1}
                  onSlideChangeTransitionStart={() => setSliding(true)}
                  onSlideChangeTransitionEnd={() => setSliding(false)}
                >
                  <form onSubmit={handleSubmit(createRule)} className='signin-form-wrapper'>
                    <SwiperSlide>
                      <Controller
                        name='name'
                        control={control}
                        rules={{
                          required: `${t(lng, 'app.rule.name')} ${t(lng, 'app.is.required')}`,
                        }}
                        render={({ field }) => {
                          return (
                            <>
                              <span className='create-trx-request-modal-select-component-label'>
                                <T>app.rule.name</T>
                              </span>
                              <Textfield
                                id='name'
                                type='text'
                                name='name'
                                fullWidth
                                size='lg'
                                maxLength={30}
                                placeholder={t(lng, 'app.eg.general.amount.limit')}
                                error={errors?.name?.message}
                                {...field}
                              />
                            </>
                          );
                        }}
                      />
                      <div className='create-trx-request-modal-button'>
                        <Button
                          fullWidth
                          onClick={() => handleStepNextButton(2, 'vault')}
                          disabled={errors?.name || (!isEdit && !dirtyFields?.name)}
                        >
                          <T>app.next.step</T>
                        </Button>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <Controller
                        name='vault'
                        rules={{
                          required: `${t(lng, 'app.wallet')} ${t(lng, 'app.is.required')}`,
                        }}
                        control={control}
                        render={({ field }) => {
                          return (
                            <>
                              <label>
                                <T>app.select.wallets</T>
                              </label>
                              <Filter
                                items={wallets}
                                selectAllField
                                fullWidth
                                displayIcon
                                multiSelect
                                placeholder='app.select.vaults.api.key'
                                {...field}
                                selectedItems={field?.value || []}
                                onChange={field?.onChange}
                              />
                            </>
                          );
                        }}
                      />
                      <div className='create-trx-request-modal-button'>
                        <Button
                          fullWidth
                          onClick={() => handleStepNextButton(3, 'volume')}
                          disabled={errors?.vault || (!isEdit && !dirtyFields?.vault)}
                        >
                          <T>app.next.step</T>
                        </Button>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='create-api-key-permissions-wrapper'>
                        <label>
                          <T>app.24.hours.amount.limit</T>
                        </label>
                        <div className='create-policy-from-to-wrapper'>
                          <Controller
                            name='volumeFrom'
                            control={control}
                            render={({ field }) => (
                              <Filter
                                items={values?.map((val) => ({ id: `volumeFrom-${val}`, name: val }))}
                                fullWidth
                                placeholder='app.from'
                                customLabelTemplate={(val) => currency(Number(val?.name))}
                                customTextfieldLabel={(val) => currency(Number(val?.name))}
                                {...field}
                                selectedItems={field?.value || []}
                                onChange={(e) => {
                                  const filteredMaxValues = values.filter((value) => {
                                    return Number(value) > Number(e?.[0]?.name);
                                  });
                                  setMaxValues(filteredMaxValues);
                                  if (
                                    getValues()?.volumeTo?.[0]?.name &&
                                    Number(e?.[0]?.name) >= Number(getValues()?.volumeTo?.[0]?.name)
                                  ) {
                                    setValue('volumeTo', [
                                      { id: `volumeTo-${filteredMaxValues[0]}`, name: filteredMaxValues[0] },
                                    ]);
                                  }
                                  field?.onChange(e);
                                }}
                              />
                            )}
                          />
                          <Controller
                            name='volumeTo'
                            control={control}
                            rules={{
                              required: `${t(lng, 'app.to')} ${t(lng, 'app.is.required')}`,
                            }}
                            render={({ field }) => {
                              return (
                                <Filter
                                  items={[
                                    ...(maxValues?.length
                                      ? maxValues?.map((val) => ({ id: `volumeTo-${val}`, name: val }))
                                      : []),
                                    { id: 'volumeTo-infinity', name: 'infinity' },
                                  ]}
                                  fullWidth
                                  placeholder='app.to'
                                  disabled={!getValues()?.volumeFrom?.length}
                                  customLabelTemplate={(val) => {
                                    if (val?.name === 'infinity') return <>&infin;</>;
                                    return currency(Number(val?.name));
                                  }}
                                  customTextfieldLabel={(val) => {
                                    if (val?.name === 'infinity') return <>&infin;</>;
                                    return currency(Number(val?.name));
                                  }}
                                  {...field}
                                  selectedItems={field?.value || []}
                                  onChange={(e) => field?.onChange(e)}
                                />
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className='create-trx-request-modal-button'>
                        <Button
                          fullWidth
                          disabled={
                            errors?.volumeFrom ||
                            (!isEdit && !dirtyFields?.volumeFrom) ||
                            errors?.volumeTo ||
                            (!isEdit && !dirtyFields?.volumeTo)
                          }
                          onClick={() => handleStepNextButton(4, 'policy')}
                        >
                          <T>app.next.step</T>
                        </Button>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='level-module'>
                        <label>
                          <T>app.multi.approval.process</T>
                        </label>
                        <div
                          className={
                            getValues()?.volumeTo?.[0]?.name > 0 ||
                            getValues()?.volumeTo?.[0]?.name === 'infinity'
                              ? 'levels'
                              : 'levels no-active'
                          }
                        >
                          <div className='level'>
                            <div className='level-header'>
                              <div className='level-title'>
                                <T>app.level1</T>
                              </div>
                              <div className='level-approves-number'>
                                <T>app.total.approvers</T> {selectApproversL1.length}
                              </div>
                            </div>
                            <div className='create-policy-approvers-wrapper'>
                              {approvers?.map((approver) => (
                                <CheckboxNew
                                  key={approver?.id}
                                  id={approver?.id}
                                  disabledHoverEffect
                                  multiSelect
                                  reverse
                                  label={
                                    <label className='policy-approver-checkbox-label'>{approver?.name}</label>
                                  }
                                  disabled={selectApproversL2?.some((z) => {
                                    return z.id === approver.id;
                                  })}
                                  value={selectApproversL1?.some(
                                    (selectedApprover) => selectedApprover?.id === approver?.id,
                                  )}
                                  onChange={(id) => {
                                    if (
                                      !selectApproversL1?.some(
                                        (selectedApprover) => selectedApprover?.id === id,
                                      )
                                    ) {
                                      setSelectedApproversL1((prev) =>
                                        prev?.length ? [...prev, approver] : [approver],
                                      );
                                    } else {
                                      setSelectedApproversL1((prev) =>
                                        prev?.filter(
                                          (selectedApprover) => selectedApprover?.id !== approver?.id,
                                        ),
                                      );
                                    }
                                  }}
                                />
                              ))}
                            </div>
                          </div>
                          <div className={selectApproversL1.length > 0 ? 'level' : 'level no-active'}>
                            <div className='level-header'>
                              <div className='level-title'>
                                <T>app.level2</T>
                              </div>
                              <div className='level-approves-number'>
                                <T>app.total.approvers</T> {selectApproversL2.length}
                              </div>
                            </div>
                            <div className='create-policy-approvers-wrapper'>
                              {approvers?.map((approver) => (
                                <CheckboxNew
                                  key={approver?.id}
                                  id={approver?.id}
                                  disabledHoverEffect
                                  multiSelect
                                  reverse
                                  label={
                                    <label className='policy-approver-checkbox-label'>{approver?.name}</label>
                                  }
                                  disabled={selectApproversL1?.some((z) => {
                                    return z.id === approver.id;
                                  })}
                                  value={selectApproversL2?.some(
                                    (selectedApprover) => selectedApprover?.id === approver?.id,
                                  )}
                                  onChange={(id) => {
                                    if (
                                      !selectApproversL2?.some(
                                        (selectedApprover) => selectedApprover?.id === id,
                                      )
                                    ) {
                                      setSelectedApproversL2((prev) =>
                                        prev?.length ? [...prev, approver] : [approver],
                                      );
                                    } else {
                                      setSelectedApproversL2((prev) =>
                                        prev?.filter(
                                          (selectedApprover) => selectedApprover?.id !== approver?.id,
                                        ),
                                      );
                                    }
                                  }}
                                />
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='create-trx-request-modal-button'>
                        <Button
                          fullWidth
                          onClick={() => handleStepNextButton(5, 'summary')}
                          disabled={errors?.description}
                        >
                          <T>app.next.step</T>
                        </Button>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='create-api-key-summary-wrapper'>
                        <div className='create-api-key-summary-row'>
                          <T>app.rule.name</T>
                          <span>{getValues()?.name}</span>
                        </div>
                        {getValues()?.vault?.length ? (
                          <div className='create-api-key-summary-row'>
                            <T>app.wallets</T>
                            <div className='wallet-icons'>
                              {getValues()?.vault?.map((wallet, key) => {
                                if (key < 3) {
                                  return (
                                    <VaultIcon
                                      key={`vault-index-${key}`}
                                      id={wallet?.id}
                                      name={wallet?.name}
                                      color={wallet?.color}
                                    />
                                  );
                                }
                              })}
                              {getValues()?.vault?.length > 3 && (
                                <div className='wallet-icon plus'>{`+ ${
                                  getValues()?.vault?.length - 3
                                }`}</div>
                              )}
                            </div>
                          </div>
                        ) : null}
                        <div className='create-api-key-summary-row'>
                          <T>app.24.hours.amount.limit</T>
                          <span>
                            {currency(getValues()?.volumeFrom?.[0]?.name)} -{' '}
                            {getValues()?.volumeTo?.[0]?.name !== 'infinity' ? (
                              currency(getValues()?.volumeTo?.[0]?.name)
                            ) : (
                              <>&infin;</>
                            )}
                          </span>
                        </div>
                        <div className='create-api-key-summary-row'>
                          <T>app.gov.leayer</T>
                          <div>
                            {(selectApproversL1.length || 0) + (selectApproversL2?.length || 0) > 2 ? (
                              <div className='create-api-key-summary-many'>
                                {[...(selectApproversL1 || []), ...(selectApproversL2 || [])]
                                  ?.slice(0, 2)
                                  ?.map((permission) => permission?.name)
                                  ?.join(', ')}
                                <div className='wallet-icon plus'>{`+ ${
                                  (selectApproversL1 || 0) + (selectApproversL2 || 0) - 2
                                }`}</div>
                              </div>
                            ) : (
                              [...(selectApproversL1 || []), ...(selectApproversL2 || [])]
                                ?.map((permission) => permission?.name)
                                ?.join(', ')
                            )}
                          </div>
                        </div>
                        <Button
                          fullWidth
                          type='submit'
                          onClick={handleSubmit(createRule)}
                          disabled={!isValid}
                        >
                          <i className='icon-submit-for-approval' />
                          <T>app.submit.for.approval</T>
                        </Button>
                      </div>
                    </SwiperSlide>
                  </form>
                </Swiper>
              </div>
            </div>
          </div>
        </VModal>
      )}
      {success && (
        <ActionModal toggleFunction={toggleFunction}>
          <T>app.api.key.added.for.create</T>
        </ActionModal>
      )}
    </>
  );
};

export default CreateTransactionPolicyModal;

const stepDescriptionMapping = () => ({
  name: {
    subText: 'app.policy.name.step.subtext',
    icon: <NameIcon style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
  vault: {
    subText: 'app.vault.step.sub.text2',
    icon: <WalletIcon style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
  volume: {
    subText: 'app.policy.volume.step.subtext',
    icon: <MenuIcon style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
  policy: {
    subText: 'app.policy.step.subtext',
    icon: <SummaryIcon style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
  summary: {
    icon: <SummaryIcon fill='#fff' style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
});
