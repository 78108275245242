export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./;:<=>?@[\]^_`{|}~\\])[A-Za-z\d!"#$%&'()*+,-./;:<=>?@[\]^_`{|}~\\]{8,}$/;

export const compareValidator = (value1, value2, errorMessage = 'app.strings-is-not-match') => {
  if (value1 !== value2) {
    return {
      status: false,
      message: errorMessage,
    };
  }

  return {
    status: true,
  };
};

export const emailValidator = (
  value,
  errorMessageRequired = 'app.email-is-required',
  errorMessageInvalid = 'app.email-is-invalid',
) => {
  if (!value) {
    return {
      status: false,
      message: errorMessageRequired,
    };
  } else if (!/^[A-Z0-9._]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
    return {
      status: false,
      message: errorMessageInvalid,
    };
  }

  return {
    status: true,
  };
};

export const lengthValidator = (value, len, max = false, errorMessage = 'app.required-symbols-is') => {
  if (value.length < len) {
    return {
      status: false,
      message: errorMessage,
    };
  }

  if (max && value.length > max) {
    return {
      status: false,
      message: errorMessage,
    };
  }

  return {
    status: true,
  };
};

export const passwordValidator = (
  value,
  errorMessageRequired = 'app.password-is-required',
  errorMessageInvalid = 'app.password-is-invalid',
) => {
  if (!value) {
    return {
      status: false,
      message: errorMessageRequired,
    };
  } else if (!/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()_=+-]).{8,64}$/gm.test(value)) {
    return {
      status: false,
      message: errorMessageInvalid,
    };
  }

  return {
    status: true,
  };
};

export const requireValidator = (value, errorMessage = 'app.field-is-required') => {
  if (!value) {
    return {
      status: false,
      message: errorMessage,
    };
  }

  return {
    status: true,
  };
};
