export const copy = (address) => {
  navigator.clipboard.writeText(address);
};

export const smallAddress = (address, number) => {
  if (!address) return '';
  return `${address.slice(0, number)}...${address.slice(address.length - number, address.length)}`;
};

export const findFittingElementsIntoContainer = (strings, containerWidth, element) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = getCanvasFont(element);

  let accumulatedString = '';
  const longestFittingElements = [];

  for (let i = 0; i < strings.length; i++) {
    const currentString = strings[i];
    if (accumulatedString !== '') {
      accumulatedString += ', ';
    }
    const currentStringWidth = context.measureText(accumulatedString + currentString).width;

    if (currentStringWidth > containerWidth) {
      break;
    }
    accumulatedString += currentString;
    longestFittingElements.push(currentString);
  }

  return longestFittingElements;
};

const getCssStyle = (element, prop) => {
  return window.getComputedStyle(element, null).getPropertyValue(prop);
};

const getCanvasFont = (el = document.body) => {
  const fontWeight = getCssStyle(el, 'font-weight') || 'normal';
  const fontSize = getCssStyle(el, 'font-size') || '16px';
  const fontFamily = getCssStyle(el, 'font-family') || 'Times New Roman';

  return `${fontWeight} ${fontSize} ${fontFamily}`;
};
