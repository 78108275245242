import { useSelector } from 'react-redux';
import BasicModal from '../../../Modals/BasicModal/BasicModal';
import keyIcon from '../../../../assets/icons/key-icon.svg';
import './ApiVersionChooseModal.css';
import { T, t } from '../../../T';
import { useState } from 'react';
import Radio from '../../../Radio/Radio';

const ApiVersionChooseModal = ({ updateApiVersion, toggleFunction, apiVersions }) => {
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const [selectedApiVersion, setSelectedApiVersion] = useState(
    apiVersions?.find((apiVersion) => apiVersion?.isClientDefault === true),
  );
  const generateRowLabel = (apiVersion) => (
    <div className='api-version-select-row'>
      <span className='api-version-row-text'>{apiVersion?.name}</span>
      {apiVersion?.isClientDefault && (
        <div className='create-api-api-version-default'>
          <T>app.default</T>
        </div>
      )}
      {!apiVersion?.isClientDefault && apiVersion?.id === apiVersions?.[0]?.id && (
        <div className='create-api-api-version-latest'>
          <T>app.latest</T>
        </div>
      )}
    </div>
  );
  return (
    <BasicModal
      closeCallback={toggleFunction}
      submitCallback={() => updateApiVersion(selectedApiVersion)}
      icon={keyIcon}
      title={t(lng, 'app.change.api.version')}
      subTitle={t(lng, 'app.select.preffered.api.version')}
      submitButtonText={t(lng, 'app.yes.change.my.api.version')}
    >
      <div className='api-version-info-wrapper'>
        <div className='api-version-info-rows-wrapper'>
          {apiVersions &&
            apiVersions.length > 0 &&
            apiVersions?.map((apiVersion, key) => {
              return (
                <div className='filter-checkbox-wrapper' key={key}>
                  <Radio
                    id={apiVersion?.id}
                    label={generateRowLabel(apiVersion)}
                    onChange={() => setSelectedApiVersion(apiVersion)}
                    value={selectedApiVersion?.id === apiVersion?.id}
                  />
                </div>
              );
            })}
        </div>
        <span className='api-version-info-warning-text'>
          <T>app.this.change.effect.immediatelly</T>
        </span>
      </div>
    </BasicModal>
  );
};

export default ApiVersionChooseModal;
