import {
  ROUTE_CONTACTS,
  ROUTE_DASHBOARD,
  ROUTE_HOME,
  ROUTE_TRX_HISTORY,
  ROUTE_TRX_POLICY,
  ROUTE_TRX_REQUESTS,
  ROUTE_TEAM,
  ROUTE_WALLET,
  ROUTE_DEVELOPERS,
  ROUTE_WEBHOOK,
} from '../routes/routes';

export const rolesShow = (rolesAll = [], roles = []) => {
  if (roles.length === 1) {
    const role1 = rolesAll.filter((i) => {
      return i.id === roles[0];
    })[0]?.name;
    return role1;
  } else if (roles.length > 2) {
    const role1 = rolesAll.filter((i) => {
      return i.id === roles[0];
    })[0]?.name;
    const role2 = rolesAll.filter((i) => {
      return i.id === roles[1];
    })[0]?.name;
    return (
      <div className='many-roles'>
        {`${role1} ,  ${role2}`} + <span> {roles.length - 2} more</span>
      </div>
    );
  } else {
    const role1 = rolesAll.filter((i) => {
      return i.id === roles[0];
    })[0]?.name;
    const role2 = rolesAll.filter((i) => {
      return i.id === roles[1];
    })[0]?.name;
    return `${role1}, ${role2}`;
  }
};

export const routesViewPermissionMapping = {
  [ROUTE_HOME]: 'ASSETS_VIEW',
  [ROUTE_DASHBOARD]: 'ASSETS_VIEW',
  [ROUTE_TRX_REQUESTS]: 'TRANSACTION_REQUESTS_VIEW',
  [ROUTE_TRX_HISTORY]: 'TRANSACTIONS_HISTORY_VIEW',
  [ROUTE_CONTACTS]: 'CONTACTS_VIEW',
  [ROUTE_TRX_POLICY]: 'GOVERNANCE_RULES_VIEW',
  [ROUTE_TEAM]: 'TEAM_MEMBERS_VIEW',
  [ROUTE_DEVELOPERS]: 'DEVELOPERS_SPACE_VIEW',
  [ROUTE_WALLET]: 'WALLETS_VIEW',
  [ROUTE_WEBHOOK]: 'WEBHOOKS_VIEW',
};
