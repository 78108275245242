import React from 'react';
import { useSelector } from 'react-redux';
import Blockchain from '../../../Blockchain/Blockchain';
import { T, t } from '../../../T';
import { ReactComponent as InfinityIcon } from '../../../../assets/icons/infinity.svg';
import Button from '../../../Button/Button';

const CreateVaultAutomationRuleSummaryStep = ({
  selectedName,
  staticTrxFee,
  selectedAsset,
  submitCallback,
  selectedAddress,
  selectedRecipient,
  selectedFeePriority,
  selectedTreshholdAmount,
}) => {
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const protocols = useSelector((state) => state.globalReducer.protocols);

  return (
    <div className='create-vault-automation-rule-modal-summary-wrapper'>
      <div className='create-vault-automation-rule-modal-summary-row-wrapper'>
        <span className='create-vault-automation-rule-modal-summary-text'>
          <T>app.name</T>
        </span>
        <div>{selectedName}</div>
      </div>
      <div className='create-vault-automation-rule-modal-summary-row-wrapper'>
        <span className='create-vault-automation-rule-modal-summary-text'>
          <T>app.asset</T>
        </span>
        <div className='create-vault-automation-rule-modal-summary-blockchain'>
          <Blockchain
            assetType={selectedAsset?.[0]?.type?.toUpperCase()}
            blockchain={protocols
              ?.find(
                (protocol) =>
                  protocol?.blockchain?.toLowerCase() === selectedAsset?.[0]?.blockchain?.toLowerCase(),
              )
              ?.currency?.toLowerCase()}
            symbol={selectedAsset?.[0]?.symbol}
          />
          <span>{` ${selectedAsset?.[0]?.symbol}`}</span>
        </div>
      </div>
      <div className='create-vault-automation-rule-modal-summary-row-wrapper-col'>
        <span className='create-vault-automation-rule-modal-summary-text'>
          <T>app.address</T>
        </span>
        <div className='asset-accordion-details-trx-request-modal-summary-wrapper'>
          {selectedAddress?.length > 0 &&
            selectedAddress?.map((address) => (
              <div
                key={address?.address}
                className='create-vault-automation-rule-modal-utxo-amount-row-summary'
              >
                <div className='create-vault-automation-rule-modal-utxo-amount-row-icon-summary'>
                  <div className='create-vault-automation-rule-modal-destination-receivers-utxo-blockchain-text-wrapper'>
                    <span className='create-vault-automation-rule-modal-destination-receivers-utxo-blockchain-text'>
                      {address?.name || <T>app.unknown.address</T>}
                    </span>
                    {address?.address !== 'all' && (
                      <span className='create-vault-automation-rule-modal-destination-receivers-utxo-blockchain-sub-text'>
                        {address?.address}
                      </span>
                    )}
                  </div>
                </div>
                <Blockchain
                  showText={false}
                  blockchain={protocols
                    ?.find(
                      (protocol) =>
                        protocol?.blockchain?.toLowerCase() === selectedAsset?.[0]?.blockchain?.toLowerCase(),
                    )
                    ?.currency?.toLowerCase()}
                  symbol={selectedAsset?.[0]?.symbol}
                />
              </div>
            ))}
        </div>
      </div>
      <div className='create-vault-automation-rule-modal-summary-row-wrapper'>
        <span className='create-vault-automation-rule-modal-summary-text'>
          <T>app.trigger</T>
        </span>
        <div className='create-vault-automation-rule-step-five-amount-wrapper'>
          <div className='create-vault-automation-rule-step-five-amount-icon-wrapper'>
            <span className='create-vault-automation-rule-modal-summary-text'>
              {`${t(lng, 'app.from')} ${selectedTreshholdAmount} ${t(lng, 'app.to')?.toLowerCase()}`}
              <InfinityIcon style={{ marginLeft: '5px' }} />
            </span>
          </div>
        </div>
      </div>
      <div className='create-vault-automation-rule-modal-summary-row-wrapper'>
        <span className='create-vault-automation-rule-modal-summary-text'>
          <T>app.action</T>
        </span>
        <div className='create-vault-automation-rule-step-five-amount-wrapper'>
          <div className='create-vault-automation-rule-step-five-amount-icon-wrapper'>
            <div className='create-vault-automation-rule-modal-utxo-amount-row-icon-summary'>
              <Blockchain
                showText={false}
                assetType={selectedAsset?.[0]?.type?.toUpperCase()}
                blockchain={protocols
                  ?.find(
                    (protocol) =>
                      protocol?.blockchain?.toLowerCase() === selectedAsset?.[0]?.blockchain?.toLowerCase(),
                  )
                  ?.currency?.toLowerCase()}
                symbol={selectedAsset?.[0]?.symbol}
              />
              <div className='create-vault-automation-rule-modal-recipient-wrapper'>
                <span className='create-vault-automation-rule-modal-recipient-name'>
                  {selectedRecipient?.[0]?.name || <T>app.unknown.address</T>}
                </span>
                <span className='create-vault-automation-rule-modal-recipient-address'>
                  {selectedRecipient?.[0]?.address}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!staticTrxFee && (
        <div className='create-vault-automation-rule-modal-summary-row-wrapper'>
          <span className='create-vault-automation-rule-modal-summary-text'>
            <T>app.fee.priority</T>
          </span>
          <span className='create-vault-automation-rule-step-five-medium-text'>{`${
            selectedFeePriority?.name
          } ${selectedFeePriority?.fee ? `/ ${selectedFeePriority?.fee}` : ''}`}</span>
        </div>
      )}
      <div className='create-vault-automation-rule-modal-button'>
        <Button fullWidth onClick={submitCallback}>
          <i className='icon-submit-for-approval' style={{ fontSize: '24px' }} />
          <T>app.submit.for.approval</T>
        </Button>
      </div>
    </div>
  );
};

export default CreateVaultAutomationRuleSummaryStep;
