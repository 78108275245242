import { useSelector } from 'react-redux';
import Button from '../../../Button/Button';
import VModal from '../../../Modal/VModal';
import { T } from '../../../T';
import './CancelSubscriptionModal.css';
import { vaultodyDate } from '../../../../helpers/date';
import { getStripeBillingPortalLink } from '../../../../api/endpoints';

const CancelSubscriptionModal = ({ setCloseModal }) => {
  const userVerificationInfo = useSelector((state) => state.userReducer.verificationInfo);

  const handleCancelSubscription = async () => {
    return getStripeBillingPortalLink().then((res) => {
      if (res?.data?.item?.url) {
        window.location.href = res?.data?.item?.url;
      }
    });
  };
  return (
    <VModal classHandler='cancel-sub-modal-content' toggleFunction={setCloseModal}>
      <span className='cancel-sub-modal-heading'>
        <T>app.are.you.sure.cancel.sub</T>
      </span>
      <span className='cancel-sub-modal-sub-heading'>
        <T>app.sub.info.expire</T>
      </span>
      <span className='cancel-sub-modal-sub-heading'>
        {vaultodyDate(userVerificationInfo?.verificationData?.stripe?.trialExpirationDate, 'full')}
      </span>
      <div className='cancel-sub-modal-info-wrapper'>
        <ul>
          <li>
            <T>app.account.inactive</T>
          </li>
          <li>
            <T>app.account.reactivate</T>
          </li>
          <li>
            <T>app.all.content.inactive</T>
          </li>
          <li>
            <T>app.backups.transfer.assets</T>
          </li>
        </ul>
      </div>
      <div className='cancel-sub-modal-button-wrapper'>
        <Button variant='delete' size='lg' onClick={handleCancelSubscription}>
          <T>app.yes.cancel.sub</T>
        </Button>
        <Button variant='neutral' size='lg' onClick={() => setCloseModal(false)}>
          <T>app.no.dont.cancel.sub</T>
        </Button>
      </div>
    </VModal>
  );
};

export default CancelSubscriptionModal;
