import { T } from '../../../T';
import ReactLoading from 'react-loading';

import './AutomationRulesTable.css';
import { automationRuleStatuses } from '../../../../helpers/transactionrequests/statuses';
import AutomationRuleRow from './AutomationRuleRow';
import { useSelector } from 'react-redux';

// Type can be "address", "vaults", "assets"
const AutomationRulesTable = ({ data, loading, handleEditRule, handleDeleteRule }) => {
  const trialBannerVisible = useSelector((state) => state.globalReducer.trialBannerVisible);

  return loading ? (
    <div className='team-page wallets wallets-address'>
      <div className='loading'>
        <ReactLoading type='spin' color='##020D1C' />
      </div>
    </div>
  ) : (
    <div>
      <div className={`automation-table ${trialBannerVisible ? 'trial-banner-visible' : ''}`}>
        <div className='automation-table-wrapper'>
          <div className='name'>
            <T>app.name</T>
          </div>
          <div className='asset'>
            <T>app.assets</T>
          </div>
          <div className='asset'>
            <T>app.trigger</T>
          </div>
          <div className='fee-priority'>
            <T>app.destination</T>
          </div>
          <div className='status'>
            <T>app.status</T>
          </div>
          <div className='settings' />
        </div>
        <div className='accordions-parent'>
          {data?.map((rule) => {
            const statusObj = automationRuleStatuses?.find(
              (status) => status?.value === rule?.status.toUpperCase(),
            );
            return (
              <AutomationRuleRow
                rule={rule}
                key={rule?.id}
                status={statusObj}
                handleEdit={handleEditRule}
                handleDelete={handleDeleteRule}
              />
            );
          })}
          {data?.length === 0 && (
            <div className='assets-table-no-assets'>
              <div>
                <T>app.no.automation.rules.found</T>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AutomationRulesTable;
