export const unique = (array, key) => {
  if (!array?.length) return [];
  return [...new Map(array.map((item) => [item[key], item])).values()];
};

export const filterArray = (array, filterValue, arrayValue) => {
  return array.filter((i) => {
    return filterValue === i[arrayValue];
  });
};
export const filteredArray = (array, filterValue, arrayValue) => {
  return array.filter((itemX) => {
    return filterValue.includes(itemX[arrayValue]);
  });
};
export const sortArray = (array, sortValue) => {
  return array.sort((a, b) => {
    return b[sortValue] - a[sortValue];
  });
};
export const selectArray = (get, set, value) => {
  if (!get.includes(value)) {
    set([...get, value]);
  } else {
    const filterArray = [];
    get.forEach((item) => {
      if (item !== value) {
        return filterArray.push(item);
      }
    });
    set(filterArray);
  }
};

export const selectArrayReact = (get, set, value) => {
  if (Array.isArray(value)) {
    set(value);
  } else {
    if (get.includes(value)) {
      const newArray = get.filter((i) => {
        return i !== value;
      });
      set(newArray);
    } else {
      const array = [value, ...get];
      set(array);
    }
  }
};
