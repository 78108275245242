import './Alert.css';
import { ReactComponent as WarningIcon } from '../../assets/icons/warning-icon.svg';

const Alert = ({ text, variant, ...rest }) => {
  return (
    <div className={`alert-component ${variant}`} {...rest}>
      <div className='alert-component-icon-wrapper'>
        <WarningIcon />
      </div>
      <div className='alert-text'>{text}</div>
    </div>
  );
};

export default Alert;
