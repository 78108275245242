import { currency } from '../../helpers/currency';
import VTooltip from '../VTooltip/VTooltip';
import './InfoContainer.css';

const InfoContainer = ({ title, tooltipText, info, isCurrency }) => {
  return (
    <div className='info-container'>
      <div className='title'>
        <div>{title}</div>
        {tooltipText && (
          <div className='question'>
            <div className='icon' data-tooltip-id={title} data-tooltip-content={tooltipText}>
              <VTooltip id={title} />
              <span className='info-container-question-mark'>?</span>
            </div>
          </div>
        )}
      </div>
      <div className='count-bold'>{isCurrency ? currency(info) : info}</div>
    </div>
  );
};

export default InfoContainer;
