import { useEffect, useState, useMemo, useCallback } from 'react';
import DetailsTransactionRequest from '../../components/Modals/DetailsTransactionRequest';
import TransactionRequestsТable from '../../components/Tables/TransactionRequestsТable';
import '../TransactionRequests/TransactionRequests.css';
import ReactLoading from 'react-loading';
import Filter from '../../components/Filter/Filter';
import { T, t } from '../../components/T';
import { unique } from '../../helpers/array';
import {
  getBlockchainProtocolsApi,
  getTrxDetailsApi,
  getTrxHistoryApi,
  getWalletsApi,
} from '../../api/endpoints';
import { useSelector } from 'react-redux';
import Button from '../../components/Button/Button';
import { getCryptoIconFromBucket } from '../../helpers/protocolIcons/protocolIcons';
import VTooltip from '../../components/VTooltip/VTooltip';
import { Drawer } from '../../components/Drawer/Drawer';
// import { transactionHistoryStatuses } from "../../helpers/transactionrequests/statuses";

const TransactionsHistory = ({ backup, setOpenModalTR, buttonVisble, wallet }) => {
  const typeUser = useSelector((state) => state.userReducer.typeUser);
  const myProfile = useSelector((state) => state.userReducer.myProfile);
  const protocols = useSelector((state) => state.globalReducer.protocols);
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const trialBannerVisible = useSelector((state) => state.globalReducer.trialBannerVisible);
  const [openDetailsTrasactionModal, setOpenDetailsTrasactionModal] = useState(false);
  const [detailsData, setDetailsData] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [wallets, setWallets] = useState([]);
  const [selectedProtocols, setSelectedProtocols] = useState();
  const [selectedVaults, setSelectedVaults] = useState();
  const [loadMoreVisible, setLoadMoreVisible] = useState(false);
  const [protocolsWithIcons, setProtocolsWithIcons] = useState([]);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const handleOpen = useCallback(() => setIsFilterDrawerOpen(true), []);
  const handleClose = useCallback(() => setIsFilterDrawerOpen(false), []);
  // const [selectedStatuses, setSelectedStatuses] = useState(
  //   transactionHistoryStatuses
  // );
  const trxCreatePermission = myProfile.premmisions?.includes('TRANSACTION_REQUESTS_CREATE');

  const handleSupportedProtocols = async () => {
    getBlockchainProtocolsApi({
      params: {
        ...(wallet?.vaultType ? { vaultsTypes: [wallet?.vaultType] } : {}),
        networkType: typeUser?.toUpperCase(),
      },
    }).then((res) =>
      setProtocolsWithIcons(
        res?.data?.items?.map((item) => ({
          ...item,
          icon: getCryptoIconFromBucket(item?.currency?.toLowerCase()),
        })),
      ),
    );
  };

  useEffect(() => {
    if (typeUser) {
      handleSupportedProtocols();
    }
  }, [wallet, typeUser]);

  const handleFiltersApiParams = useMemo(() => {
    return {
      ...(!selectedProtocols || selectedProtocols?.length === unique(protocols, 'blockchain')?.length
        ? {}
        : {
            blockchains: selectedProtocols.map((protocol) => {
              return protocol.blockchain;
            }),
          }),
      ...(wallet?.id
        ? { wallets: [wallet?.id] }
        : !selectedVaults || selectedVaults?.length === wallets?.length
          ? {}
          : {
              wallets: selectedVaults.map((wallet) => {
                return wallet.id;
              }),
            }),
    };
    // &${
    //   !selectedStatuses ||
    //   selectedStatuses?.length === transactionHistoryStatuses?.length
    //     ? "statuses[]="
    //     : selectedStatuses
    //         .map((status, index) => {
    //           return `statuses[]=${status.value}${
    //             index === selectedStatuses?.length - 1 ? "" : "&"
    //           }`;
    //         })
    //         .join("")
    // }
  }, [
    selectedVaults,
    selectedProtocols,
    // selectedStatuses,
    protocols,
  ]);

  // const handleStatusFilterChange = (statuses) => {
  //   setSelectedStatuses(statuses);
  // };

  const handleVaultFilterChange = (vaults) => {
    setSelectedVaults(vaults);
  };

  const handleProtocolsFilterChange = (selectedProtocols) => {
    setSelectedProtocols(unique(selectedProtocols, 'blockchain'));
  };

  const getDetailsData = (transaction) => {
    const even = { even: false };
    if (
      transaction.transaction.direction === 'vault-internal' ||
      transaction.transaction.direction === 'client-internal'
    ) {
      even.even = true;
    }
    getTrxDetailsApi(transaction.transaction.id).then((res) => {
      setDetailsData({
        ...res.data.item,
        transaction: {
          ...res.data.item?.transaction,
          direction: transaction.transaction.direction,
        },
        ...even,
      });
      setOpenDetailsTrasactionModal(true);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    });
  };

  const getDataTRHistory = (abortSignal) => {
    setLoading(true);
    setLoadMoreVisible(false);
    getTrxHistoryApi({
      params: {
        limit: 15,
        networkType: typeUser?.toUpperCase(),
        ...handleFiltersApiParams,
      },
      signal: abortSignal,
    })
      .then((res) => {
        setData(res?.data?.items);
        if (res?.data?.hasMore !== undefined && JSON.parse(res?.data?.hasMore) === true) {
          setLoadMoreVisible(true);
        }
      })
      .finally(() => setLoading(false));
  };

  const loadMoreHandler = async () => {
    const lastRecord = data[data.length - 1];
    getTrxHistoryApi({
      params: {
        limit: 15,
        networkType: typeUser?.toUpperCase(),
        startingAfter: lastRecord.id,
        ...handleFiltersApiParams,
      },
    }).then((res) => {
      setData((prevState) => [...prevState, ...res?.data?.items]);
      if (res.data.hasMore === 'false') {
        setLoadMoreVisible(false);
      }
    });
  };

  useEffect(() => {
    if (!selectedProtocols && protocolsWithIcons?.length > 0) {
      setSelectedProtocols(protocolsWithIcons);
    }
  }, [protocolsWithIcons]);

  useEffect(() => {
    const controller = new AbortController();
    if (
      (typeUser && selectedProtocols?.length === 0) ||
      (selectedVaults?.length === 0 && !wallet?.id)
      // selectedStatuses?.length === 0
    ) {
      setData([]);
      setLoadMoreVisible(false);
      return;
    }
    if (
      protocols &&
      typeUser &&
      (selectedVaults || wallet?.id) &&
      selectedProtocols
      // selectedStatuses
    ) {
      getDataTRHistory(controller.signal);
    }
    return () => {
      if (
        controller &&
        typeUser &&
        protocols &&
        typeUser &&
        (selectedVaults || wallet?.id) &&
        selectedProtocols
      )
        controller.abort();
    };
  }, [
    typeUser,
    selectedVaults,
    selectedProtocols,
    // selectedStatuses,
    wallet?.id,
    protocols,
  ]);

  useEffect(() => {
    if (!typeUser || wallet?.id) return;
    const controller = new AbortController();
    getWalletsApi({ params: { type: typeUser?.toUpperCase(), limit: 0 }, signal: controller.signal }).then(
      (res) => {
        setSelectedVaults(res.data?.items);
        setWallets(res.data?.items);
      },
    );
    return () => {
      if (typeUser && !wallet?.id) {
        controller.abort();
      }
    };
  }, [typeUser, wallet?.id]);

  return (
    <div className='transaction-history-page'>
      <div className='button-filters-wrapper'>
        <Drawer anchor='bottom' open={isFilterDrawerOpen} onClose={handleClose}>
          <div className='trx-req-filters-drawer'>
            <span className='title'>
              <T>app.filters</T>
            </span>
            {!wallet?.id && (
              <Filter
                items={wallets}
                placeholder='app.filters.vaults'
                selectAllField
                displayIcon
                multiSelect
                onChange={handleVaultFilterChange}
                selectedItems={selectedVaults || []}
              />
            )}
            <Filter
              items={protocolsWithIcons}
              placeholder='app.filters.blockchains'
              selectAllField
              displayIcon
              roundedIcon
              customIconKey='icon'
              multiSelect
              onChange={handleProtocolsFilterChange}
              selectedItems={selectedProtocols || []}
              dataKey='blockchain'
              dataNameKey='publicName'
            />
            <Button variant='dark' fullWidth onClick={handleClose}>
              <T>app.close</T>
            </Button>
          </div>
        </Drawer>
        <div className='trx-history-filters'>
          {!wallet?.id && (
            <Filter
              items={wallets}
              placeholder='app.filters.vaults'
              selectAllField
              displayIcon
              multiSelect
              onChange={handleVaultFilterChange}
              selectedItems={selectedVaults || []}
            />
          )}
          <Filter
            items={protocolsWithIcons}
            placeholder='app.filters.blockchains'
            selectAllField
            displayIcon
            roundedIcon
            customIconKey='icon'
            multiSelect
            onChange={handleProtocolsFilterChange}
            selectedItems={selectedProtocols || []}
            dataKey='blockchain'
            dataNameKey='publicName'
          />
          {/* <Filter
                items={transactionHistoryStatuses}
                placeholder="app.filters.status"
                selectAllField
                onChange={handleStatusFilterChange}
                selectedItems={selectedStatuses || []}
                dataKey="value"
                dataNameKey="name"
              /> */}
        </div>
        <div className='filters-button-mobile'>
          <Button variant='dark' fullWidth onClick={handleOpen}>
            <T>app.filters</T>
          </Button>
        </div>
        {myProfile?.premmisions && (
          <div className={`button-holder ${buttonVisble === 'true' ? '' : 'hidden'}`}>
            <Button
              size='md'
              onClick={() => {
                setOpenModalTR(true);
              }}
              disabled={!trxCreatePermission || !backup}
              data-tooltip-id='create-trx-req-button-2'
              data-tooltip-content={
                !trxCreatePermission ? t(lng, 'app.role.limitation') : t(lng, 'app.backup.secure')
              }
            >
              {(!trxCreatePermission || !backup) && <VTooltip id='create-trx-req-button-2' />}
              <i className='icon-transaction' />
              <T>app.submit.a.transaction.request</T>
            </Button>
          </div>
        )}
      </div>
      {loading ? (
        <div className='spinner-history'>
          <div className='loading'>
            <ReactLoading type='spin' color='##020D1C' />
          </div>
        </div>
      ) : (
        <div className={`trx-history-table-wrapper ${trialBannerVisible ? 'trial-banner-visible' : ''}`}>
          <TransactionRequestsТable
            data={data}
            detailsHandler={getDetailsData}
            transactionKey='app.no.transactions.data'
            loadMoreHandler={loadMoreHandler}
            loadMore={loadMoreVisible}
          />
        </div>
      )}
      {openDetailsTrasactionModal && (
        <DetailsTransactionRequest
          toggleFunction={setOpenDetailsTrasactionModal}
          data={detailsData}
          isHistory
        />
      )}
    </div>
  );
};
export default TransactionsHistory;
