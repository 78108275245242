import React, { useEffect } from 'react';
import Toasts from '../../helpers/toasts';
import { toast } from 'react-toastify';

const Toast = (props) => {
  useEffect(() => {
    Toasts.toast = toast;
  }, []);

  return <>{props.children}</>;
};

export default Toast;
