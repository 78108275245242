import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import userReducer from './slices/userSlice';
import globalReducer from './slices/globalSlice';
import localizationReducer from './slices/localizationSlice';

export const store = configureStore({
  reducer: {
    authReducer,
    localizationReducer,
    userReducer,
    globalReducer,
  },
});
