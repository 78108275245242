import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { getPackageInfoApi, getProfileInfoApi } from '../api/endpoints';
import { setMyPackage, setMyProfile } from '../redux/slices/userSlice';
import { STATE_REGISTER_PSP } from '../redux/slices/authSlice';

const AuthRoute = ({ isAllowed, redirectPath = '/login' }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authReducer);

  useEffect(() => {
    if (auth.state === STATE_REGISTER_PSP) {
      getPackageInfoApi().then((res) => dispatch(setMyPackage(res.data.item)));
      getProfileInfoApi().then((res) => {
        const data = Object.assign(res.data.item, {
          premmisions: Object.values(res.data.item.permissions).map((i) => {
            return i.permissionName;
          }),
        });
        dispatch(setMyProfile(data));
      });
    }
  }, [auth.state]);

  return !isAllowed ? <Navigate to={redirectPath} replace /> : <Outlet />;
};

export default AuthRoute;
