import { T } from '../T';
import './LoadMore.css';
const LoadMore = ({ loadMoreHandler = () => {} }) => {
  return (
    <div className='load-more'>
      <div className='load-more-button' onClick={loadMoreHandler}>
        <T>app.loadmore</T>
      </div>
    </div>
  );
};
export default LoadMore;
