import { currency } from '../../../../helpers/currency';
import Blockchain from '../../../Blockchain/Blockchain';
import Button from '../../../Button/Button';
import { T, t } from '../../../T';
import '../CreateTransactionRequestNew.css';
import { ReactComponent as ContactIcon } from '../../../../assets/icons/dashboardMenu/contacts-icon.svg';
import { ReactComponent as QuestionMark } from '../../../../assets/icons/question-mark-no-bg.svg';
import { ReactComponent as InternalIcon } from '../../../../assets/icons/internal-icon.svg';
import { useSelector } from 'react-redux';
import CopyButton from '../../../CopyButton/CopyButton';
import VTooltip from '../../../VTooltip/VTooltip';
import VaultIcon from '../../../VaultIcon/VaultIcon';

const CreateTransactionRequestSummaryStep = ({
  note,
  isUtxo,
  isDraft,
  staticTrxFee,
  exchangeRate,
  selectedAsset,
  submitCallback,
  selectedWallet,
  selectedAmount,
  selectedAddress,
  selectedStrategy,
  selectedRecipient,
  selectedFeePriority,
}) => {
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const protocols = useSelector((state) => state.globalReducer.protocols);

  return (
    <div className='create-trx-request-step-five-wrapper'>
      <div className='create-trx-request-step-five-row'>
        <span className='create-trx-request-step-five-text'>
          <T>app.wallet</T>
        </span>
        <div className='create-trx-request-step-five-wallet'>
          <VaultIcon
            id={selectedWallet?.[0]?.id || 'summary-from-wallet'}
            name={selectedWallet?.[0]?.name}
            color={selectedWallet?.[0]?.color}
            vaultType={selectedWallet?.[0]?.vaultType}
          />
          <div>
            <div className='create-trx-request-step-five-text'>{selectedWallet?.[0]?.name}</div>
          </div>
        </div>
      </div>
      <div className='create-trx-request-step-five-row'>
        <span className='create-trx-request-step-five-text'>
          <T>app.asset</T>
        </span>
        <div className='create-trx-request-summary-asset-wrapper'>
          <Blockchain
            showText
            assetType={selectedAsset?.[0]?.type?.toUpperCase()}
            blockchain={protocols
              ?.find(
                (protocol) =>
                  protocol?.blockchain?.toLowerCase() === selectedAsset?.[0]?.blockchain?.toLowerCase(),
              )
              ?.currency?.toLowerCase()}
            symbol={selectedAsset?.[0]?.symbol}
          />
          <span>{` (${selectedAsset?.[0]?.symbol})`}</span>
        </div>
      </div>
      {!isUtxo && (
        <div className='create-trx-request-step-five-row'>
          <span className='create-trx-request-step-five-text'>
            <T>app.from</T>
          </span>
          <div className='create-trx-request-summary-address-wrapper'>
            <div className='create-trx-request-summary-address-info-wrapper'>
              <span
                className='create-trx-request-summary-address-name'
                data-tooltip-id='summary-address-name-from'
                data-tooltip-html={`${(
                  selectedAddress?.[0]?.label ||
                  selectedAddress?.[0]?.name ||
                  t(lng, 'app.unknown.address')
                )?.slice(0, 80)} <br /> ${(
                  selectedAddress?.[0]?.label ||
                  selectedAddress?.[0]?.name ||
                  t(lng, 'app.unknown.address')
                )?.slice(80, 100)}`}
              >
                {selectedAddress?.[0]?.label || selectedAddress?.[0]?.name || <T>app.unknown.address</T>}
              </span>
              <VTooltip id='summary-address-name-from' />
              <span className='create-trx-request-summary-address-address'>
                {selectedAddress?.[0]?.address}
              </span>
            </div>
            <CopyButton element={selectedAddress?.[0]?.address} />
          </div>
        </div>
      )}
      {!isUtxo && (
        <div className='create-trx-request-step-five-row'>
          <span className='create-trx-request-step-five-text'>
            <T>app.to</T>
          </span>
          <div className='create-trx-request-summary-address-wrapper'>
            <div className='create-trx-request-summary-address-info-wrapper'>
              <span
                className='create-trx-request-summary-address-name'
                data-tooltip-id='summary-address-name-to'
                data-tooltip-html={`${(selectedRecipient?.[0]?.name || t(lng, 'app.unknown.address'))?.slice(
                  0,
                  80,
                )} <br /> ${(selectedRecipient?.[0]?.name || t(lng, 'app.unknown.address'))?.slice(80, 100)}`}
              >
                {selectedRecipient?.[0]?.name || <T>app.unknown.address</T>}
              </span>
              <VTooltip id='summary-address-name-to' />
              <span className='create-trx-request-summary-address-address'>
                {selectedRecipient?.[0]?.address}
              </span>
            </div>
            <CopyButton element={selectedRecipient?.[0]?.address} />
          </div>
        </div>
      )}
      {isUtxo && (
        <div className='create-trx-request-step-five-row-col'>
          <span className='create-trx-request-step-five-text'>
            <T>app.destination.addresses</T>
          </span>
          <div className='asset-accordion-details-trx-request-modal-summary-wrapper'>
            {selectedRecipient?.length > 0 &&
              selectedRecipient?.map((recipient) => (
                <div key={recipient?.address} className='create-trx-request-modal-utxo-amount-row-summary'>
                  <div className='create-trx-request-modal-utxo-amount-row-icon-summary'>
                    <div>
                      {recipient?.internal && (
                        <div className='create-trx-request-modal-internal-icon'>
                          <InternalIcon />
                        </div>
                      )}
                      {!recipient?.name && !recipient?.internal && (
                        <div className='create-trx-request-modal-internal-icon'>
                          <QuestionMark />
                        </div>
                      )}
                      {recipient?.name && !recipient?.internal && (
                        <div className='create-trx-request-modal-contact-icon'>
                          <ContactIcon />
                        </div>
                      )}
                    </div>
                    <div className='create-trx-request-modal-destination-receivers-utxo-blockchain-text-wrapper'>
                      <span
                        className='create-trx-request-modal-destination-receivers-utxo-blockchain-text'
                        data-tooltip-id={`summary-utxo-address-name-amount-step-${recipient?.address}`}
                        data-tooltip-html={`${(recipient?.name || t(lng, 'app.unknown.address'))?.slice(
                          0,
                          80,
                        )} <br /> ${(recipient?.name || t(lng, 'app.unknown.address'))?.slice(80, 100)}`}
                      >
                        {recipient?.name || <T>app.unknown.address</T>}
                      </span>
                      <VTooltip id={`summary-utxo-address-name-amount-step-${recipient?.address}`} />
                      <span className='create-trx-request-modal-destination-receivers-utxo-blockchain-sub-text'>
                        {recipient?.address}
                      </span>
                    </div>
                  </div>
                  <div className='create-trx-modal-recepient-amount-wrapper'>
                    <Blockchain
                      showText={false}
                      blockchain={protocols
                        ?.find(
                          (protocol) =>
                            protocol?.blockchain?.toLowerCase() ===
                            selectedAsset?.[0]?.blockchain?.toLowerCase(),
                        )
                        ?.currency?.toLowerCase()}
                      symbol={selectedAsset?.[0]?.symbol}
                    />
                    <span>{recipient?.selectedAmount}</span>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
      <div className='create-trx-request-step-five-row'>
        <span className='create-trx-request-step-five-text'>
          <T>app.amount</T>
        </span>
        <div className='create-trx-request-step-five-amount-wrapper'>
          <div className='create-trx-request-step-five-amount-icon-wrapper'>
            <span className='create-trx-request-step-five-text'>{`${selectedAmount} ${
              selectedAsset?.[0]?.symbol
            } (${currency(selectedAmount * exchangeRate)})`}</span>
          </div>
        </div>
      </div>
      {!staticTrxFee && (
        <div className='create-trx-request-step-five-row'>
          <span className='create-trx-request-step-five-text'>
            {isUtxo ? <T>app.strategy.and.fee.priority</T> : <T>app.fee.priority</T>}
          </span>
          <span className='create-trx-request-step-five-medium-text'>{`${
            isUtxo ? `${selectedStrategy?.[0]?.name}/` : ''
          }${selectedFeePriority?.[0]?.name || t(lng, 'app.medium')}`}</span>
        </div>
      )}
      <div className='create-trx-request-note-step-row'>
        <span className='create-trx-request-step-five-text'>
          <T>app.note</T>
        </span>
        <span className='create-trx-request-note-row'>{note}</span>
      </div>
      <div className='create-trx-request-modal-button'>
        <Button fullWidth onClick={submitCallback}>
          <i className='icon-submit-for-approval' style={{ fontSize: '24px' }} />
          {isDraft ? <T>app.create.draft</T> : <T>app.submit.for.approval</T>}
        </Button>
      </div>
    </div>
  );
};

export default CreateTransactionRequestSummaryStep;
