import 'swiper/css';
import './TransactionsCsvUploadModal.css';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload-icon.svg';
import { ReactComponent as LocationIcon } from '../../../assets/icons/destination-icon.svg';
import VModal from '../../Modal/VModal';
import Button from '../../Button/Button';
import Stepper from '../../Stepper/Stepper';
import { ReactComponent as PencilIcon } from '../../../assets/icons/pencil2-icon.svg';
import { ReactComponent as MoneyIcon } from '../../../assets/icons/money-icon.svg';
import { ReactComponent as DocumentIcon } from '../../../assets/icons/summary-icon2.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { T, t } from '../../T';
import CSVUploader from '../../CSVUploader/CSVUploader';
import CryptoIcon from '../../CryptoIcon/CryptoIcon';
import { useSwiperSlider } from '../../../helpers/hooks/useSwiperSlider';

const TransactionsCsvUploadModal = ({ closeCallback, successCallback }) => {
  const [sliding, setSliding] = useState(false);
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const [currentStep, setCurrentStep] = useState({ id: 1, name: 'upload' });
  const [CSVParsedResult, setCSVParsedResult] = useState();
  const [steps, setSteps] = useState([
    {
      id: 1,
      icon: <UploadIcon />,
      text: 'app.upload.csv',
      name: 'upload',
      completed: false,
    },
    {
      id: 2,
      icon: <PencilIcon />,
      fillInsteadOfStroke: true,
      text: 'app.setup',
      name: 'setup',
      completed: false,
    },
    {
      id: 3,
      icon: <LocationIcon />,
      text: 'app.destination',
      name: 'destination',
      completed: false,
    },
    { id: 4, icon: <MoneyIcon />, text: 'app.amount.and.fee.priority', name: 'amount', completed: false },
    { id: 5, icon: <DocumentIcon />, text: 'app.summary', name: 'summary', completed: false },
  ]);
  const { handleCompleteStep, handleSwiperNextSlide, swiperContainerHeight, swiperRef } = useSwiperSlider(
    setSteps,
    '660px',
  );

  const handleSubmitCsv = () => {};

  return (
    <VModal
      toggleFunction={closeCallback}
      classHandler={`upload-csv-modal ${currentStep?.id > 1 ? 'csv-table' : ''}`}
    >
      <div className='upload-csv-modal-wrapper'>
        <div className='upload-csv-modal-side-banner'>
          <span className='upload-csv-modal-side-banner-label'>
            <T>app.step</T> {currentStep?.id}
          </span>
          <Stepper
            currentStepId={currentStep?.id}
            onSelectStepCallback={(stepIndex, stepName) => {
              setCurrentStep({ id: stepIndex, name: stepName });
              if (swiperRef.current && swiperRef.current.swiper) {
                swiperRef.current.swiper.slideTo(stepIndex - 1);
              }
            }}
            steps={steps}
          />
        </div>
        <div className={`upload-csv-modal-content ${currentStep?.id > 1 ? 'csv-table' : ''}`}>
          <span className='upload-csv-modal-heading'>
            <div className='upload-csv-modal-heading-wrapper'>
              <span>
                <T>app.upload.csv</T>
              </span>
              <span className='upload-csv-modal-side-sub-heading'>
                {stepDescriptionMapping()[currentStep?.name]?.icon}
                {t(lng, stepDescriptionMapping()[currentStep?.name]?.text)}
              </span>
              <span className='upload-csv-modal-step-desc'>
                {t(lng, stepDescriptionMapping()[currentStep?.name]?.subText)}
              </span>
            </div>
          </span>
          <div
            className={`upload-csv-modal-swiper-wrapper ${sliding ? 'sliding' : ''}`}
            style={{ height: swiperContainerHeight }}
          >
            <Swiper
              ref={swiperRef}
              spaceBetween={50}
              speed={800}
              allowTouchMove={false}
              slidesPerView={1}
              onSlideChangeTransitionStart={() => setSliding(true)}
              onSlideChangeTransitionEnd={() => setSliding(false)}
            >
              <SwiperSlide>
                <div>
                  <CSVUploader onSuccess={(parsedResult) => setCSVParsedResult(parsedResult)} />
                  <div className='upload-csv-modal-next-step-button'>
                    <Button
                      variant='primary'
                      fullWidth
                      disabled={!CSVParsedResult}
                      onClick={() => {
                        setCurrentStep({ id: 2, name: 'setup' });
                        handleCompleteStep(1);
                        handleSwiperNextSlide();
                      }}
                    >
                      <T>app.next.step</T>
                    </Button>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <div className='csv-table'>
                    <div className='csv-table-wrapper'>
                      <div className='csv-name'>
                        <T>app.wallet</T>
                      </div>
                      <div className='csv-name'>
                        <T>app.from</T>
                      </div>
                      <div className='csv-name'>
                        <T>app.to</T>
                      </div>
                      <div className='csv-amount'>
                        <T>app.amount</T>
                      </div>
                      <div className='csv-name'>
                        <T>app.fee.priority</T>
                      </div>
                      <div className='csv-name'>
                        <T>app.note</T>
                      </div>
                    </div>
                    <div className='csv-row-wrapper-parent'>
                      {CSVParsedResult?.map((row, index) => {
                        return (
                          <div className='csv-row-wrapper' key={`csv-row-${index}`}>
                            <div className='csv-name'>{row?.vault}</div>
                            <div className='csv-name'>{row?.from}</div>
                            <div className='csv-name'>{row?.to}</div>
                            <div className='csv-amount'>
                              <CryptoIcon
                                className='csv-amount-icon'
                                symbol={row?.amount?.replace(/[^a-zA-Z]/g, '')}
                              />{' '}
                              {row?.amount}
                            </div>
                            <div className='csv-name'>{row?.feepriority}</div>
                            <div className='csv-name'>{row?.note}</div>
                          </div>
                        );
                      })}
                    </div>
                    {CSVParsedResult?.length === 0 && (
                      <div className='assets-table-no-assets'>
                        <div>
                          <T>app.no.assets.found</T>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='upload-csv-modal-next-step-button'>
                  <Button
                    variant='primary'
                    fullWidth
                    onClick={() => {
                      setCurrentStep({ id: 2, name: 'asset' });
                      handleCompleteStep(1);
                      handleSwiperNextSlide();
                    }}
                  >
                    <T>app.next.step</T>
                  </Button>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </VModal>
  );
};

export default TransactionsCsvUploadModal;

const stepDescriptionMapping = () => ({
  upload: {
    text: 'app.upload.step.desc',
    subText: 'app.upload.step.sub.text',
    icon: <UploadIcon style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
  setup: {
    text: 'app.setup.step.desc',
    subText: 'app.setup.step.sub.text',
    icon: <PencilIcon className='csv-pencil-icon' style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
  destination: {
    text: 'app.destination.step.desc',
    subText: 'app.destination.step.sub.text',
    icon: <LocationIcon style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
  amount: {
    text: 'app.amount',
    subText: 'app.amount.descr.tron.btc',
    icon: <MoneyIcon style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
  summary: {
    text: 'app.summary.step.desc',
    icon: <DocumentIcon fill='#fff' style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
});
