import BigNumber from 'bignumber.js';
import { T } from '../T';
import LoadMore from '../LoadMore/LoadMore';
import TransactionRequestsRow from './TransactionRequestsRow';

const TransactionRequestsTable = ({
  isShelf,
  data = [],
  detailsHandler = () => {},
  transactionKey = '',
  loadMoreHandler = () => {},
  loadMore,
  isTransactionRequests,
  handleSelectTransaction,
  selectedTransactions,
  successCallback,
  draftDeleteButtonVisible,
}) => {
  return (
    <div className='trx-table'>
      <div className='table-content'>
        <div className='table-row caption'>
          <div className='table-data date timestamp'>
            <T>app.date.time</T>
          </div>
          <div className='table-data wallet'>
            <T>app.from</T>
          </div>
          <div className='table-data wallet'>
            <T>app.to</T>
          </div>
          <div className='table-data level amount'>
            <T>app.amount</T>
          </div>
          <div className='table-data level'>
            <T>app.status</T>
          </div>
          <div className='table-data settings' />
        </div>
        {data.map((transaction, key) => {
          const resepientsAmount = transaction.recipients.map((i) => {
            return BigNumber(i.amount).toNumber();
          });
          let resepientsAmountInUSD;
          if (!isShelf) {
            resepientsAmountInUSD = transaction.recipients.map((i) => {
              return BigNumber(i.convertedAmount).toNumber();
            });
          } else {
            resepientsAmountInUSD = transaction.recipients.map((i) => {
              return BigNumber(i.amount).toNumber() * transaction?.asset?.exchangeRate;
            });
          }
          const sumreResepientsAmount = BigNumber.sum(...resepientsAmount).toFixed();
          const sumResepientsAmountInUSD = BigNumber.sum(...resepientsAmountInUSD).toFixed();
          return (
            <TransactionRequestsRow
              key={key}
              isDraft={isShelf}
              transaction={transaction}
              detailsHandler={detailsHandler}
              successCallback={successCallback}
              walletName={transaction.wallet?.name}
              recepientAmount={sumreResepientsAmount}
              isTransactionRequests={isTransactionRequests}
              direction={transaction?.transaction?.direction}
              recepientAmountInUSD={sumResepientsAmountInUSD}
              handleSelectTransaction={handleSelectTransaction}
              draftDeleteButtonVisible={draftDeleteButtonVisible}
              innerDirection={transaction?.transaction?.innerDirection}
              isSelected={selectedTransactions?.some((trxId) => trxId === transaction?.id)}
            />
          );
        })}
      </div>
      {loadMore && <LoadMore loadMoreHandler={loadMoreHandler} />}
      {!data && (
        <div className='table-row no-active'>
          <div>
            <T>{transactionKey}</T>
          </div>
        </div>
      )}
    </div>
  );
};
export default TransactionRequestsTable;
