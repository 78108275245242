import './Blockchain.css';
import CryptoIcon from '../CryptoIcon/CryptoIcon';

const Blockchain = ({ showText, blockchain, symbol }) => {
  return (
    <div className='blockchain-symbol-wrapper'>
      <div className='blockchain-image-wrapper'>
        <CryptoIcon className='blockchain-image' symbol={symbol} />
        {blockchain?.toLowerCase() != symbol?.toLowerCase() && (
          <CryptoIcon className='blockchain-secondary-symbol-icon' symbol={blockchain} />
        )}
      </div>
      {showText && (
        <span className='blockchain-text'>{blockchain?.charAt(0)?.toUpperCase() + blockchain?.slice(1)}</span>
      )}
    </div>
  );
};

export default Blockchain;
