import { useEffect, useState } from 'react';
import VModal from '../Modal/VModal';
import { saveAs } from 'file-saver';
import ActionModal from './ActionModal';
import { T, t } from '../T';
import { useSelector } from 'react-redux';
import { finalizeWalletBackupApi, getVaultBackupData, initializeWalletBackupApi } from '../../api/endpoints';
import Button from '../Button/Button';
import VaultIcon from '../VaultIcon/VaultIcon';
const WalletBackUpModal = ({
  toggleFunction,
  editData,
  backupData = { status: '' },
  setBeckUp = () => {},
}) => {
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const messageSocket = useSelector((state) => state.globalReducer.messageSocket);

  const [steps, setSteps] = useState(false);
  const [approve, setApprove] = useState(false);
  const [rsaKey, setRsaKey] = useState({
    input: '',
    repeat: '',
    number: '',
    download: false,
  });
  const [step, setStep] = useState(1);
  useEffect(() => {
    if (backupData.status === 'await-approval') {
      setApprove(true);
      setSteps(true);
    }
    if (backupData.status === 'approved') {
      setSteps(true);
    }
  }, []);
  useEffect(() => {
    if (typeof messageSocket === 'object') {
      if (messageSocket?.data?.event === 'wallet_backup_rejected') {
        if (messageSocket?.data?.item.id === editData.id) {
          toggleFunction(false);
        }
      }
      if (messageSocket?.data?.event === 'wallet_backup_approved') {
        if (messageSocket?.data?.item.id === editData.id) {
          setSteps(true);
          setApprove(false);
        }
      }
    }
  }, [messageSocket]);
  const init = () => {
    const splitKey = rsaKey.input.split('-----');
    const beginKey = splitKey[1];
    const endKey = splitKey[3];
    const contentKey = splitKey[2].replaceAll(' ', '\n');
    const sendKey = `-----${beginKey}-----${contentKey}-----${endKey}-----`;
    const data = { item: { rsaPublicKey: sendKey } };
    initializeWalletBackupApi(editData.id, data).then(() => {
      setSteps(true);
      setApprove(true);
    });
  };
  const download = () => {
    getVaultBackupData(editData.id)
      .then((res) => {
        saveAs(res?.data, `Vaultody_Wallet_Backup_${editData.id}.zip`);
        setRsaKey((prev) => {
          return {
            ...prev,
            ...{ download: true },
          };
        });
      })
      .catch((e) => console.log(e));
  };
  const finalize = () => {
    const data = {
      item: {
        code: rsaKey.number,
      },
    };
    finalizeWalletBackupApi(editData.id, data).then(() => {
      toggleFunction(false);
      setBeckUp(true);
    });
  };
  return (
    <div className='wallet-backup-modal-holder'>
      {!approve && !steps && (
        <VModal classHandler='wallet-backup-modal big-modal' toggleFunction={toggleFunction}>
          <div className='v-modal-title'>
            <T>app.back.up.your.wallet</T>
          </div>
          <div className='v-modal-sub'>
            <T>app.backing.up.a.wallet</T>
          </div>
          <div className='v-modal-sub-detail'>
            <T>app.see.guide.for.info</T>
          </div>
          <div className='v-textarea-group'>
            <div
              className={`v-input-group ${
                rsaKey.input !== rsaKey.repeat && rsaKey.input && rsaKey.repeat
                  ? 'invalid-input'
                  : rsaKey.repeat.length > 0 && rsaKey.input === rsaKey.repeat
                    ? 'valid-input'
                    : ''
              }`}
            >
              <label>
                <T>app.enter.rsa.key</T>
              </label>
              <textarea
                type='text'
                className='form-control'
                placeholder={t(lng, 'app.your.rsa.key')}
                onChange={(e) => {
                  const current = e.currentTarget.value;
                  setRsaKey((prev) => {
                    return {
                      ...prev,
                      ...{ input: current },
                    };
                  });
                }}
              />
            </div>
            <div
              className={`v-input-group ${
                rsaKey.input !== rsaKey.repeat && rsaKey.input && rsaKey.repeat
                  ? 'invalid-input'
                  : rsaKey.repeat.length > 0 && rsaKey.input === rsaKey.repeat
                    ? 'valid-input'
                    : ''
              }`}
            >
              <label>
                <T>app.re.enter.rsa.key</T>
              </label>
              <textarea
                type='text'
                className='form-control'
                placeholder={t(lng, 'app.re.enter.rsa.key')}
                onChange={(e) => {
                  const current = e.currentTarget.value;
                  setRsaKey((prev) => {
                    return {
                      ...prev,
                      ...{ repeat: current },
                    };
                  });
                }}
              />
            </div>
          </div>
          <div className='button-holder'>
            <Button
              size='md'
              fullWidth
              disabled={!rsaKey.repeat.length || rsaKey.input !== rsaKey.repeat}
              onClick={init}
            >
              <i className='icon-submit-for-approval' />
              <T>app.submit.for.approval</T>
            </Button>
          </div>
        </VModal>
      )}
      {!approve && steps && (
        <VModal classHandler='wallet-backup-ready' toggleFunction={toggleFunction}>
          <div className='v-modal-title'>
            <T>app.back.up.your.wallet</T>
          </div>
          <div className='step-number'>
            <span>{step} / 2</span>
          </div>
          <div className='steps'>
            <div className='step-title'>
              <T>app.wallet.backup.files</T>
            </div>
            <div className='wallet-info'>
              <VaultIcon
                id={editData?.id || 'backup-wallet-icon'}
                name={editData?.name}
                color={editData?.color}
                vaultType={editData?.vaultType}
              />
              <div className='name'>{editData.name}</div>
            </div>
            {step === 1 ? (
              <div>
                <div className='file'>
                  <div className='name'>Vaultody_Wallet_Guide</div>
                  <div className='button-holder download'>
                    <Button onClick={download} size='sm' variant='neutral'>
                      <i className='icon-download' />
                      <T>app.download</T>
                    </Button>
                  </div>
                </div>
                <div className='button-holder'>
                  <Button
                    fullWidth
                    disabled={!rsaKey.download}
                    onClick={() => setStep(2)}
                    size='md'
                    variant='dark'
                  >
                    <T>app.continue.to.backup.conf</T>
                  </Button>
                </div>
              </div>
            ) : (
              <div className='step step2'>
                <div className='big-title'>
                  <T>app.check.your.pdf.file</T>
                </div>
                <div className='pdf-info'>
                  <T>app.in.our.pdf.file.you.unique</T>
                </div>
                <div className='v-input-group'>
                  <input
                    onInput={(e) => {
                      if (e.currentTarget.value.length > e.currentTarget.maxLength)
                        e.currentTarget.value = e.currentTarget.value.slice(0, e.currentTarget.maxLength);
                    }}
                    onChange={(e) => {
                      const current = e.currentTarget.value;
                      setRsaKey((prev) => {
                        return {
                          ...prev,
                          ...{ number: current },
                        };
                      });
                    }}
                    onWheel={(e) => {
                      return e.stopPropagation();
                    }}
                    type='number'
                    maxLength='6'
                    placeholder={t(lng, 'app.enter.six.dig.code')}
                    className='form-control'
                  />
                </div>
                <div className='button-holder'>
                  <Button
                    fullWidth
                    variant='dark'
                    onClick={finalize}
                    disabled={rsaKey.number.length !== 6}
                    size='lg'
                  >
                    <T>app.finalize.backup</T>
                  </Button>
                </div>
              </div>
            )}
          </div>
        </VModal>
      )}
      {approve && (
        <ActionModal toggleFunction={toggleFunction}>
          <T>app.vault.backup.descr</T>
        </ActionModal>
      )}
    </div>
  );
};
export default WalletBackUpModal;
