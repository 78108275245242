import { useState } from 'react';
import ActionModal from './ActionModal';
import { T, t } from '../T';
import { deleteApiKeyApi } from '../../api/endpoints';
import BasicModal from './BasicModal/BasicModal';
import { useSelector } from 'react-redux';
import VaultIcon from '../VaultIcon/VaultIcon';

const RemoveAPIModal = ({ toggleFunction, data }) => {
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const [sussess, setSussess] = useState(false);

  const deleteAPIKey = () => {
    deleteApiKeyApi(data.id).then(() => setSussess(true));
  };

  return (
    <>
      {!sussess && (
        <BasicModal
          closeCallback={toggleFunction}
          submitCallback={deleteAPIKey}
          title={t(lng, 'app.are.you.sure.remove.api.key')}
        >
          <div className='remove-api-key-info-wrapper'>
            <div className='remove-api-key-info-row'>
              <span className='remove-api-key-info-text'>
                <T>app.api.key.name</T>
              </span>
              <span className='remove-api-key-info-sub-text-break-all'>{data.name}</span>
            </div>
            <div className='remove-api-key-info-row'>
              <span className='remove-api-key-info-text'>
                <T>app.api.key</T>
              </span>
              <span className='remove-api-key-info-sub-text-break-all'>{data.key}</span>
            </div>
            <div className='remove-api-key-info-row'>
              <span className='remove-api-key-info-text'>
                <T>app.wallets</T>
              </span>
              <div className='remove-api-vaults-wrapper'>
                {data?.wallets?.length > 0 &&
                  data?.wallets?.map((wallet, index) => (
                    <VaultIcon
                      key={`wallet-${index}`}
                      id={`remove-api-key-wallet-${index}`}
                      name={wallet?.name}
                      color={wallet?.color}
                    />
                  ))}
              </div>
            </div>
          </div>
        </BasicModal>
      )}
      {sussess && (
        <ActionModal toggleFunction={toggleFunction}>
          <T>app.remove.api.key.descr</T>
        </ActionModal>
      )}
    </>
  );
};
export default RemoveAPIModal;
