import { useMemo } from 'react';
import './Stepper.css';
import { t } from '../T';
import { useSelector } from 'react-redux';

// 'horizontal', 'vertical' for direction
const Stepper = ({ steps, currentStepId, onSelectStepCallback }) => {
  const lng = useSelector((state) => state.localizationReducer.selectedData);

  const biggestStepIdCompleted = useMemo(() => {
    let highestId = -Infinity;
    for (let i = 0; i < steps?.length; i++) {
      const step = steps[i];
      if (step?.completed && step?.id > highestId) {
        highestId = step?.id;
      }
    }
    return highestId;
  }, [steps]);

  return (
    <div className='stepper-component'>
      {steps?.length > 0 &&
        steps?.map((step) => {
          const stepLineCss = `step-line ${
            step?.completed &&
            currentStepId !== step?.id + 1 &&
            currentStepId !== step?.id &&
            steps?.[step?.id]?.completed
              ? 'green'
              : ''
          }${
            step?.id > currentStepId &&
            !step?.completed &&
            !steps?.[step?.id]?.completed &&
            step?.id - 1 !== biggestStepIdCompleted
              ? 'grey'
              : ''
          }${
            step?.completed && currentStepId === step?.id && steps?.[step?.id]?.completed
              ? 'white-green-gradient'
              : ''
          }${
            (step?.completed && steps?.[step?.id]?.id === currentStepId) ||
            biggestStepIdCompleted === step?.id
              ? 'green-white-gradient'
              : ''
          }${
            (step?.id === currentStepId && !steps?.[step?.id]?.completed) ||
            step?.id - 1 === biggestStepIdCompleted
              ? 'white-grey-gradient'
              : ''
          }`;

          return (
            <div
              key={step?.id}
              onClick={() => {
                if (step?.id < biggestStepIdCompleted + 2) {
                  onSelectStepCallback(step?.id, step?.name);
                }
              }}
              className={`step ${
                step?.id === currentStepId || step?.id - 1 === biggestStepIdCompleted ? 'current' : ''
              } ${
                step?.id > currentStepId && step?.id - 1 !== biggestStepIdCompleted && !step?.completed
                  ? 'disabled'
                  : ''
              } ${step?.completed && step?.id !== currentStepId ? 'completed' : ''}`}
            >
              <div className='step-bubble'>
                {step?.id !== steps?.length && <div className={stepLineCss} />}
                {step?.id}
              </div>
              <div className={`stepper-icon-text-wrapper ${step?.fillInsteadOfStroke ? 'filled' : ''}`}>
                {step?.icon && step?.icon}
                <span className='stepper-text'>{t(lng, step?.text)}</span>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Stepper;
