import './BasicModal.css';
import trashIcon from '../../../assets/images/trash.svg';
import VModal from '../../Modal/VModal';
import Button from '../../Button/Button';
import { T } from '../../T';

const BasicModal = ({
  icon,
  title,
  subTitle,
  children,
  closeCallback,
  submitCallback,
  submitButtonText,
  submitButtonColor,
  submitButtonDisabled,
  submitButtonTextColor,
}) => {
  return (
    <VModal toggleFunction={closeCallback} classHandler='remove-modal-component'>
      <img className='remove-modal-icon' src={icon || trashIcon} alt='' />
      <div className='remove-modal-title-wrapper'>
        <span className='remove-modal-title'>{title}</span>
        <span className='remove-modal-sub-title'>{subTitle}</span>
      </div>
      {children}
      <div className='remove-modal-button-wrapper'>
        <Button
          size='md'
          fullWidth
          onClick={submitCallback}
          disabled={!!submitButtonDisabled}
          style={{
            ...(submitButtonColor ? { background: submitButtonColor } : {}),
            ...(submitButtonTextColor ? { color: submitButtonTextColor } : {}),
          }}
        >
          {!submitButtonText && <i className='icon-submit-for-approval' />}
          {submitButtonText ? submitButtonText : <T>app.submit.for.approval</T>}
        </Button>
        <Button variant='close' size='md' fullWidth onClick={closeCallback}>
          <T>app.close</T>
        </Button>
      </div>
    </VModal>
  );
};

export default BasicModal;
