import * as moment from 'moment-timezone';

export const vaultodyDate = (dateString = '', type) => {
  const newDate = moment.unix(dateString);
  if (type === 'full') {
    return newDate.tz(new Intl.DateTimeFormat().resolvedOptions().timeZone).format('DD MMM YYYY HH:mm z');
  } else if (type === 'time') {
    return newDate.tz(new Intl.DateTimeFormat().resolvedOptions().timeZone).format('HH:mm z');
  } else {
    return newDate.tz(new Intl.DateTimeFormat().resolvedOptions().timeZone).format('DD MMM YYYY');
  }
};

export const diffDate = (dateString = '', type) => {
  const newDate = moment.unix(dateString);
  return moment().diff(newDate, type);
};
