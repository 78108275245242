import { useEffect, useRef, useState } from 'react';

export const useSwiperSlider = (setSteps, initialContainerHeight, additionalDependancies) => {
  const swiperRef = useRef();
  const [swiperContainerHeight, setSwiperContainerHeight] = useState(initialContainerHeight || '195px');

  const handleCompleteStep = (stepId) =>
    setSteps((prev) =>
      prev?.map((step) => {
        if (step?.id === stepId) {
          return {
            ...step,
            completed: true,
          };
        }
        return step;
      }),
    );

  const handleSwiperNextSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  useEffect(() => {
    if (swiperRef.current) {
      const swiper = swiperRef.current?.swiper;
      const currentSlide = swiper?.slides[swiper.activeIndex];
      if (currentSlide) {
        const slideHeight = currentSlide?.clientHeight;
        setSwiperContainerHeight(`${slideHeight}px`);
      }
    }
  }, [
    swiperRef?.current?.swiper?.slides[swiperRef?.current?.swiper?.activeIndex]?.clientHeight,
    ...(additionalDependancies?.length ? additionalDependancies : []),
  ]);

  return { swiperContainerHeight, swiperRef, handleSwiperNextSlide, handleCompleteStep };
};
