import bscScanIcon from '../../assets/icons/blockchain-scanners/bscscan-logo.svg';
import etherScanIcon from '../../assets/icons/blockchain-scanners/etherscan-logo.svg';
import blockExplorerIcon from '../../assets/icons/blockchain-scanners/blockExplorer-logo.png';
import polygonScanIcon from '../../assets/icons/blockchain-scanners/polygonscan.svg';
import tronScanIcon from '../../assets/icons/blockchain-scanners/tronscan.svg';
import optimismIcon from '../../assets/icons/blockchain-scanners/optimism-logo.svg';

export const blockchainScannersIcons = (blockchain) => {
  switch (blockchain) {
    case 'tron':
      return tronScanIcon;
    case 'ethereum':
      return etherScanIcon;
    case 'binance-smart-chain':
      return bscScanIcon;
    case 'polygon':
      return polygonScanIcon;
    case 'optimism':
      return optimismIcon;
    default:
      return blockExplorerIcon;
  }
};

export const getCryptoIconFromBucket = (asset) => {
  return `https://storage.googleapis.com/public-cryptoapis/crypto-icons/${asset}.png`;
};
