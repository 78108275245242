import { useSelector } from 'react-redux';
import backgroundReg from '../assets/images/bg-guest.png';
import logo from '../assets/images/logo.svg';
import ReactLoading from 'react-loading';
import './GuestLayout.css';

const GuestLayout = (props) => {
  const lng = useSelector((state) => state.localizationReducer.selectedData);

  return Object.values(lng).length !== 0 ? (
    <div className='layout-guest'>
      <div className='guest-layout'>
        <div className='logo'>
          <img src={logo} />
        </div>
        {props.children}
      </div>
      <div
        className='layout-guest-sidebanner'
        style={{
          backgroundImage: `url(${backgroundReg})`,
        }}
      >
        <div>
          <p className='layout-guest-sidebanner-title'>
            Custody Technology Solution for Managing Digital Assets
          </p>
          <p className='layout-guest-sidebanner-subtitle'>
            Vaultody is a cutting-edge technology solution enabling companies and institutions to securely
            operate with their digital assets.
          </p>
        </div>
      </div>
    </div>
  ) : (
    <div className='layout-guest-loading'>
      <ReactLoading type='spin' color='##020D1C' />
    </div>
  );
};

export default GuestLayout;
