import '../CreateTransactionRequestNew.css';
import Filter from '../../../Filter/Filter';
import Button from '../../../Button/Button';
import { T, t } from '../../../T';
import { getContactsApi, getContactsByNameSearchApi } from '../../../../api/endpoints';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete-icon.svg';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus-icon.svg';
import { ReactComponent as QuestionMark } from '../../../../assets/icons/question-mark-no-bg.svg';
import { smallAddress } from '../../../../helpers/strings';
import Textfield from '../../../Textfield/Textfield';
import { currency } from '../../../../helpers/currency';
import Blockchain from '../../../Blockchain/Blockchain';
import VTooltip from '../../../VTooltip/VTooltip';
import Alert from '../../../Alert/Alert';

const CreateTransactionRequestDestinationStep = ({
  isUtxo,
  wallets,
  addresses,
  gasTanker,
  recipientType,
  selectedAsset,
  submitCallback,
  selectedAddress,
  prefilledAddress,
  selectedRecipient,
  internalAddresses,
  handleChooseAddress,
  addressesSearchFunc,
  handleChooseRecipient,
  handleRemoveRecipient,
  selectedInternalWallets,
  handleToAddressTypeField,
  handleChooseInternalWallet,
  internalAddressesSearchFunc,
}) => {
  const protocols = useSelector((state) => state.globalReducer.protocols);
  const typeUser = useSelector((state) => state.userReducer.typeUser);
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const [contacts, setContacts] = useState();
  const [addressFieldValue, setAddressFieldValue] = useState('');

  const getContactsBySearching = async (searchText) => {
    return getContactsByNameSearchApi(typeUser, {
      params: {
        item: searchText,
        limit: 20,
        statuses: ['approved'],
        blockchain: selectedAsset?.[0]?.blockchain,
      },
    }).then((res) => {
      setContacts(res.data?.items);
    });
  };

  const getContacts = async () => {
    return getContactsApi(typeUser, selectedAsset?.[0]?.blockchain, {
      params: { limit: 30, statuses: ['approved'] },
    }).then((res) => {
      setContacts(res.data?.items);
    });
  };

  return (
    <div className='create-trx-request-step-one-wrapper'>
      {gasTanker?.address && (
        <Alert text={`${t(lng, 'app.fee.payer')} - ${gasTanker?.address}`} variant='warning' />
      )}
      {!isUtxo && (
        <div className='create-trx-request-modal-select-component-wrapper'>
          <span className='create-trx-request-modal-select-component-label'>
            <T>app.from</T>
          </span>
          <Filter
            key='select-from-address-field'
            items={addresses?.addresses}
            selectedItems={selectedAddress?.length > 0 ? selectedAddress : prefilledAddress}
            onChange={handleChooseAddress}
            fullWidth
            variant='radio'
            disableFocusShadowEffect
            placeholder='app.from'
            dataKey='address'
            dataNameKey='label'
            hasSearchOption
            searchOptionApiCallback={(searchText) => addressesSearchFunc(searchText)}
            searchFieldPlaceholder={t(lng, 'app.search.address.sender')}
            customLabelTemplate={(address) => (
              <div className='create-trx-request-modal-step-two-sender-filter-label-wrapper'>
                <span
                  className='create-trx-request-modal-step-two-sender-filter-label-top'
                  data-tooltip-id={`address-name-${address?.address}`}
                  data-tooltip-content={address?.label}
                  data-tooltip-html={`${(address?.label || 'No name')?.slice(0, 80)} <br /> ${(
                    address?.label || 'No name'
                  )?.slice(80, 100)}`}
                >
                  <VTooltip id={`address-name-${address?.address}`} place='top-start' />
                  {address?.label}
                </span>
                <span className='create-trx-request-modal-step-two-sender-filter-label-middle'>
                  {address?.address}
                </span>
                <span className='create-trx-request-modal-step-two-sender-filter-label-bottom'>
                  {`${address?.availableAmount} ${selectedAsset?.[0]?.symbol} (${currency(
                    addresses?.exchangeRate * address?.availableAmount,
                  )})`}
                </span>
              </div>
            )}
          />
        </div>
      )}
      <div className='create-trx-request-modal-select-component-wrapper'>
        <div>
          <div className='create-trx-request-contact-or-one-time-address-wrapper'>
            <label>
              <T>app.to</T>
            </label>
            <div className='create-trx-request-contact-or-one-time-address'>
              <div
                className={`create-trx-request-contact-or-one-time-address-text ${
                  recipientType === 'contacts' ? 'selected' : ''
                }`}
                onClick={() => handleToAddressTypeField('contacts')}
              >
                <T>app.trusted.contacts</T>
              </div>
              <div
                className={`create-trx-request-contact-or-one-time-address-text ${
                  recipientType === 'one-time' ? 'selected' : ''
                }`}
                onClick={() => handleToAddressTypeField('one-time')}
              >
                <T>app.one.time.address</T>
              </div>
              <div
                className={`create-trx-request-contact-or-one-time-address-text ${
                  recipientType === 'internal' ? 'selected' : ''
                }`}
                onClick={() => handleToAddressTypeField('internal')}
              >
                <T>app.internal.addresses</T>
              </div>
            </div>
          </div>
          {recipientType === 'one-time' &&
            (isUtxo ? (
              <div className='create-trx-request-modal-destination-utxo-address-wrapper'>
                <Textfield
                  trim
                  type='text'
                  fullWidth
                  size='lg'
                  value={addressFieldValue}
                  onChange={(e) => setAddressFieldValue(e.currentTarget.value)}
                />
                <Button
                  disabled={
                    !addressFieldValue ||
                    selectedRecipient?.some((recepient) => recepient?.address === addressFieldValue)
                  }
                  icon={<PlusIcon style={{ margin: '0 5px 3px 0' }} />}
                  onClick={() => {
                    handleChooseRecipient([{ address: addressFieldValue }], true);
                    setAddressFieldValue('');
                  }}
                >
                  <T>app.add</T>
                </Button>
              </div>
            ) : (
              <Textfield
                trim
                type='text'
                fullWidth
                size='lg'
                value={selectedRecipient?.[0]?.address || ''}
                onChange={(e) => handleChooseRecipient([{ address: e.currentTarget.value }])}
              />
            ))}
          {recipientType === 'contacts' && (
            <Filter
              key='select-contacts-field'
              fullWidth
              items={contacts}
              variant='radio'
              multiSelect={isUtxo}
              disableFocusShadowEffect
              placeholder='app.contacts'
              hasSearchOption
              customLabelTemplate={(contact) => {
                return (
                  <div key={contact?.address} className='create-trx-request-modal-filter-label-wrapper'>
                    <Blockchain
                      showText={false}
                      blockchain={contact?.blockchain}
                      symbol={protocols
                        ?.find(
                          (protocol) =>
                            protocol?.blockchain?.toLowerCase() === contact?.blockchain?.toLowerCase(),
                        )
                        ?.currency?.toLowerCase()}
                    />
                    <div className='create-trx-request-modal-filter-labels-wrapper'>
                      <span className='create-trx-request-modal-filter-label'>{contact?.name}</span>
                      <span className='create-trx-request-modal-filter-label-sub'>
                        {smallAddress(contact?.address, 8)}
                      </span>
                    </div>
                  </div>
                );
              }}
              searchOptionApiCallback={(searchText) => {
                if (searchText?.length > 2) {
                  getContactsBySearching(searchText);
                } else {
                  getContacts();
                }
              }}
              searchFieldPlaceholder={t(lng, 'app.search.contact')}
              onChange={handleChooseRecipient}
              selectedItems={selectedRecipient?.filter((recipient) => recipient?.name) || []}
            />
          )}
          {recipientType === 'internal' && (
            <div className='create-trx-request-modal-internal-address-wrapper'>
              <Filter
                key='select-internal-vaults-field'
                fullWidth
                items={wallets}
                variant='radio'
                selectedItems={selectedInternalWallets}
                onChange={handleChooseInternalWallet}
                disableFocusShadowEffect
                placeholder='app.select.vault'
                displayIcon
              />
              <Filter
                key='select-internal-addresses-field'
                fullWidth
                items={internalAddresses}
                variant='radio'
                multiSelect={isUtxo}
                dataKey='id'
                disableFocusShadowEffect
                placeholder='app.internal.addresses'
                hasSearchOption
                searchOptionApiCallback={(searchText) => internalAddressesSearchFunc(searchText)}
                searchFieldPlaceholder={t(lng, 'app.search.internal.addresses')}
                customLabelTemplate={(internalAddress) => {
                  return (
                    <div
                      key={internalAddress?.address}
                      className='create-trx-request-modal-filter-label-wrapper'
                    >
                      <Blockchain
                        showText={false}
                        blockchain={protocols
                          ?.find(
                            (protocol) =>
                              protocol?.blockchain?.toLowerCase() ===
                              internalAddress?.blockchain?.toLowerCase(),
                          )
                          ?.currency?.toLowerCase()}
                        symbol={protocols
                          ?.find(
                            (protocol) =>
                              protocol?.blockchain?.toLowerCase() ===
                              internalAddress?.blockchain?.toLowerCase(),
                          )
                          ?.currency?.toLowerCase()}
                      />
                      <div className='create-trx-request-modal-step-two-sender-filter-label-wrapper-internal'>
                        <span
                          className='create-trx-request-modal-filter-label'
                          data-tooltip-id={`internal-address-name-${internalAddress?.address}`}
                          data-tooltip-content={internalAddress?.name}
                          data-tooltip-html={`${(internalAddress?.name || 'No name')?.slice(0, 80)} <br /> ${(
                            internalAddress?.name || 'No name'
                          )?.slice(80, 100)}`}
                        >
                          <VTooltip
                            id={`internal-address-name-${internalAddress?.address}`}
                            place='top-start'
                          />
                          {internalAddress?.name || <T>app.no.name</T>}
                        </span>
                        <span className='create-trx-request-modal-filter-label-sub'>
                          {smallAddress(internalAddress?.address, 8)}
                        </span>
                      </div>
                    </div>
                  );
                }}
                onChange={(recipients) =>
                  handleChooseRecipient(
                    recipients?.map((recipient) => ({ ...recipient, internal: true })),
                    false,
                    true,
                  )
                }
                selectedItems={selectedRecipient?.filter((recipient) => recipient?.internal) || []}
              />
            </div>
          )}
        </div>
      </div>
      {isUtxo && (
        <div className='create-trx-request-modal-destination-receivers-utxo-container'>
          {selectedRecipient?.length > 0 &&
            selectedRecipient?.map((recipient) => (
              <div key={recipient?.address} className='create-trx-request-modal-destination-receivers-utxo'>
                <div className='create-trx-request-modal-destination-receivers-utxo-blockchain-wrapper'>
                  {recipient?.name ? (
                    <Blockchain
                      showText={false}
                      blockchain={recipient?.blockchain}
                      symbol={protocols
                        ?.find(
                          (protocol) =>
                            protocol?.blockchain?.toLowerCase() === recipient?.blockchain?.toLowerCase(),
                        )
                        ?.currency?.toLowerCase()}
                    />
                  ) : (
                    <div className='create-trx-request-modal-internal-icon'>
                      <QuestionMark />
                    </div>
                  )}
                  <div className='create-trx-request-modal-destination-receivers-utxo-blockchain-text-wrapper'>
                    <span
                      className='create-trx-request-modal-destination-receivers-utxo-blockchain-text'
                      data-tooltip-id={`utxo-address-name-${recipient?.address}`}
                      data-tooltip-content={recipient?.name}
                      data-tooltip-html={`${(recipient?.name || t(lng, 'app.unknown.address'))?.slice(
                        0,
                        80,
                      )} <br /> ${(recipient?.name || t(lng, 'app.unknown.address'))?.slice(80, 100)}`}
                    >
                      {recipient?.name || <T>app.unknown.address</T>}
                    </span>
                    <VTooltip id={`utxo-address-name-${recipient?.address}`} place='top-start' />
                    <span className='create-trx-request-modal-destination-receivers-utxo-blockchain-sub-text'>
                      {recipient?.address}
                    </span>
                  </div>
                </div>
                <div className='create-trx-request-modal-destination-receivers-utxo-address-wrapper'>
                  <div
                    className='create-trx-request-modal-icon'
                    onClick={() => handleRemoveRecipient(recipient?.address)}
                  >
                    <DeleteIcon className='create-trx-request-modal-icon-svg' />
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
      <div className='create-trx-request-modal-button'>
        <Button
          fullWidth
          onClick={submitCallback}
          disabled={!selectedRecipient || selectedRecipient?.length === 0 || (!selectedAddress && !isUtxo)}
        >
          <T>app.next.step</T>
        </Button>
      </div>
    </div>
  );
};

export default CreateTransactionRequestDestinationStep;
