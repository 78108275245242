import { vaultodyDate } from '../../helpers/date';
import { smallAddress } from '../../helpers/strings';
import { allTransactionStatuses } from '../../helpers/transactionrequests/statuses';
import VModal from '../Modal/VModal';
import { blockchainScannersIcons } from '../../helpers/protocolIcons/protocolIcons';
import { BigNumber } from 'bignumber.js';
import { currency } from '../../helpers/currency';
import { T, t } from '../T';
import CopyButton from '../CopyButton/CopyButton';
import { useSelector } from 'react-redux';
import { getBlockchainScannerLink } from '../../helpers/blockchain/blockchain';
import VTooltip from '../VTooltip/VTooltip';
import ContactAddNewModal from '../Pages/Contacts/ContactAddNewModal/ContactAddNewModal';
import ContactCreateSuccessModal from '../Pages/Contacts/ContactCreateSuccessModal/ContactCreateSuccessModal';
import { useState } from 'react';
import { ReactComponent as ContactsIcon } from '../../assets/icons/dashboardMenu/contacts-icon.svg';
import CryptoIcon from '../CryptoIcon/CryptoIcon';
import VaultIcon from '../VaultIcon/VaultIcon';
import Alert from '../Alert/Alert';
import { transactionRequestFailReasonsMapping } from '../../helpers/constants';

const DetailsTransactionRequest = ({
  toggleFunction = () => {},
  data = [],
  isTransactionRequest,
  isHistory,
}) => {
  const protocols = useSelector((state) => state.globalReducer.protocols);
  const typeUser = useSelector((state) => state.userReducer.typeUser);
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const [addNewContactModal, setAddNewContactModal] = useState({ open: false });
  const [createdContact, setCreatedContact] = useState({ open: false });

  const handleAddContact = (address) => {
    setAddNewContactModal({
      open: true,
      blockchain: [
        protocols?.find((protocol) => protocol?.blockchain?.toLowerCase() === data.blockchain?.toLowerCase()),
      ],
      address,
    });
  };

  const resepientsAmount = data.recipients.map((i) => {
    return BigNumber(i.amount).toNumber();
  });
  const resepientsAmountInUSD = data.recipients.map((i) => {
    return BigNumber(i.convertedAmount).toNumber();
  });
  const sumreResepientsAmount = BigNumber.sum(...resepientsAmount).toFixed();
  const sumResepientsAmountInUSD = BigNumber.sum(...resepientsAmountInUSD).toFixed();

  return (
    <VModal classHandler='details-transaction-modal' toggleFunction={toggleFunction}>
      {createdContact?.open && (
        <ContactCreateSuccessModal
          displayAddAnotherButton={false}
          address={createdContact?.address}
          addressName={createdContact?.name}
          blockchain={createdContact?.blockchain}
          addCallback={() => setAddNewContactModal(true)}
          closeCallback={() => {
            setCreatedContact({ open: false });
          }}
        />
      )}
      {addNewContactModal?.open && (
        <ContactAddNewModal
          address={addNewContactModal?.address}
          blockchain={addNewContactModal?.blockchain}
          closeCallback={() => {
            setAddNewContactModal({ open: false });
          }}
          callback={(createdContact) => {
            setAddNewContactModal({ open: false });
            setCreatedContact({ open: true, ...createdContact });
          }}
        />
      )}
      <div className='v-modal-title'>
        <T>app.transaction.details</T>
      </div>
      <div className='transaction-info'>
        {Object.keys(data.transaction).length > 0 && (
          <div className='id'>
            <div className='row-transaction-info'>
              <div className='label'>
                <T>app.transaction.id</T>
              </div>
              <div className='input'>
                <span>{smallAddress(data.transaction.id, 7)}</span>{' '}
                <CopyButton element={data.transaction.id} />{' '}
              </div>
            </div>
            <div className='row-transaction-info'>
              <div className='label'>
                <T>app.date.time</T>
              </div>
              <div className='input'>
                <div className='info'>{vaultodyDate(data?.transaction?.date, 'full')}</div>
              </div>
            </div>
          </div>
        )}
        <div className='type'>
          {isTransactionRequest && (
            <div className='row-transaction-info'>
              <div className='label'>
                <T>app.transaction.request.id</T>
              </div>
              <div className='info'>
                <div className='input capitalize'>{data.id}</div>
              </div>
            </div>
          )}
          <div className='row-transaction-info timestamp'>
            <div className='label'>
              <T>app.type</T>
            </div>
            <div className='info'>
              {(data.transaction.direction === 'vault-internal' ||
                data.transaction.direction === 'client-internal') && (
                <>
                  <VTooltip id='trx-details-internal-outgoing' />
                  <span
                    data-tooltip-id='trx-details-internal-outgoing'
                    data-tooltip-content={t(lng, 'app.transaction.internal.outgoing')}
                    className='icon-send icon even'
                  />
                  <span>
                    <T>app.transaction.outgoing</T>
                  </span>
                </>
              )}
              {data.transaction.direction === 'incoming' && (
                <>
                  <VTooltip id='trx-details-incoming' />
                  <span
                    data-tooltip-id='trx-details-incoming'
                    data-tooltip-content={t(lng, 'app.transaction.incoming')}
                    className='icon-receive icon'
                  />
                  <span>
                    <T>app.transaction.incoming</T>
                  </span>
                </>
              )}
              {data.transaction.direction === 'outgoing' && (
                <>
                  <VTooltip id='trx-details-outgoing' />
                  <span
                    data-tooltip-id='trx-details-outgoing'
                    data-tooltip-content={t(lng, 'app.transaction.outgoing')}
                    className='icon-send icon'
                  />
                  <span>
                    <T>app.transaction.outgoing</T>
                  </span>
                </>
              )}
              {Object.keys(data.transaction).length === 0 && (
                <>
                  <VTooltip id='trx-details-default-outgoing' />
                  <span
                    data-tooltip-id='trx-details-default-outgoing'
                    data-tooltip-content={t(lng, 'app.transaction.outgoing')}
                    className='icon-send icon'
                  />
                  <span>
                    <T>app.transaction.outgoing</T>
                  </span>
                </>
              )}
            </div>
          </div>
          <div className='row-transaction-info'>
            <div className='label'>
              <T>app.status</T>
            </div>
            <div className='info'>
              <img
                className='status-icon'
                src={
                  allTransactionStatuses?.find((trxStatus) => data.status.toUpperCase() === trxStatus?.value)
                    ?.icon
                }
              />
              <div className='input capitalize'>
                {
                  allTransactionStatuses?.find((trxStatus) => data.status.toUpperCase() === trxStatus?.value)
                    ?.name
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      {transactionRequestFailReasonsMapping[data?.failReason] && (
        <div className='trx-details-fail-reason-wrapper'>
          <Alert
            variant='error'
            text={`${t(lng, 'app.trx.fail.reason')}: ${t(
              lng,
              transactionRequestFailReasonsMapping[data?.failReason],
            )}`}
          />
        </div>
      )}
      <div className='amount'>
        <div className='value-data'>
          <div className='label'>
            <T>app.sent.amount</T>
          </div>
          <div className='value'>
            <div className='save'>
              <CryptoIcon symbol={data?.asset?.symbol} />
              {data?.recipients.length > 1 ? sumreResepientsAmount : data.recipients[0].amount}
              <span> {data.asset.symbol}</span>
            </div>
            <div className='fiat'>{currency(sumResepientsAmountInUSD)}</div>
          </div>
        </div>
        {Object.keys(data.transaction).length > 0 && (
          <>
            <div className='value-data'>
              <div className='label'>
                <T>app.transaction.fee</T>
              </div>
              <div className='value'>
                <div className='save'>
                  <CryptoIcon symbol={data?.feeData?.unitSymbol} />
                  <span>{data.feeData.amount}</span>
                  <span> {data.feeData.unitSymbol}</span>
                </div>
                <div className='fiat'>{currency(data.feeData.amountInUsd)}</div>
              </div>
            </div>
            <div className='value-data'>
              <div className='label'>
                <T>app.total.amount</T>
              </div>
              <div className='value'>
                {data.asset.standard ? (
                  <div className='save'>
                    <CryptoIcon symbol={data?.asset?.symbol} />
                    <span>{BigNumber(sumreResepientsAmount).toFixed()}</span>
                    <span> {data.asset.symbol}</span>

                    <span>+</span>
                    <CryptoIcon symbol={data?.feeData?.unitSymbol} />
                    <span>{data.feeData.amount}</span>
                    <span> {data.feeData.unitSymbol}</span>
                  </div>
                ) : (
                  <div className='save'>
                    <CryptoIcon symbol={data?.asset?.symbol} />
                    <span>{BigNumber(sumreResepientsAmount).plus(data.feeData.amount).toFixed()}</span>
                    <span> {data.asset.symbol}</span>
                  </div>
                )}

                <div className='fiat'>
                  {currency(BigNumber(sumResepientsAmountInUSD).plus(data.feeData.amountInUsd).toFixed())}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className='wallet-info'>
        <div className='from-to'>
          <div className='from-to-section'>
            <div className='label'>
              <T>app.from</T>
            </div>
            <div className='trx-details-modal-recipients-column'>
              <div className='trx-details-modal-recipients-row'>
                <div className='wallet-cell-trx-address-to trx-details'>
                  <VaultIcon
                    id={data?.wallet?.id}
                    name={data?.wallet?.name}
                    color={data?.wallet?.color}
                    vaultType={data?.wallet?.vaultType}
                  />
                  <div className='wallet-cell-trx-wallet-name-to'>
                    <div
                      className='name-wallet-trx-details-to'
                      data-tooltip-id='trx-details-sender-name'
                      data-tooltip-content={data.wallet.name}
                    >
                      <VTooltip id='trx-details-sender-name' />
                      {data.wallet.name}
                    </div>
                    {data.sender && (
                      <div
                        className='address-sender-trx-details-to'
                        data-tooltip-id='trx-details-sender-address'
                        data-tooltip-content={data.sender}
                      >
                        <VTooltip id='trx-details-sender-address' />
                        {smallAddress(data.sender, 8)}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='from-to-section'>
            <div className='label'>
              <T>app.to</T>
            </div>
            <div className='trx-details-modal-recipients-column'>
              {data?.recipients?.map((recipient) => {
                let receiverVaultInfo;
                if (data?.transaction?.direction === 'vault-internal') {
                  receiverVaultInfo = { ...data.wallet, address: recipient?.address };
                } else {
                  receiverVaultInfo = (data?.clientRelatedVaults?.length &&
                    data?.clientRelatedVaults?.find((vault) => vault?.address === recipient?.address)) || {
                    address: recipient?.address,
                    name: recipient?.contactName || '',
                  };
                }
                return (
                  <div className='trx-details-modal-recipients-row' key={recipient?.address}>
                    <div className='wallet-cell-trx-address-to trx-details'>
                      {receiverVaultInfo?.name && receiverVaultInfo?.color && (
                        <VaultIcon
                          id={receiverVaultInfo?.id}
                          name={receiverVaultInfo?.name}
                          color={receiverVaultInfo?.color}
                        />
                      )}
                      <div className='wallet-cell-trx-wallet-name-to'>
                        <div
                          className='name-wallet-trx-details-to'
                          data-tooltip-id='trx-details-wallet-name'
                          data-tooltip-content={receiverVaultInfo?.name}
                        >
                          <VTooltip id='trx-details-wallet-name' />
                          {receiverVaultInfo?.name}
                        </div>
                        <div
                          className='address-sender-trx-details-to'
                          data-tooltip-id='trx-details-wallet-address'
                          data-tooltip-content={receiverVaultInfo?.address}
                        >
                          <VTooltip id='trx-details-wallet-address' />
                          {smallAddress(receiverVaultInfo?.address, 8)}
                        </div>
                      </div>
                    </div>
                    {!receiverVaultInfo?.name && receiverVaultInfo?.address && (
                      <div
                        className='trx-row-add-contact-wrapper'
                        data-tooltip-id='trx-details-add-contact'
                        data-tooltip-content={t(lng, 'app.add.contact')}
                        onClick={() => handleAddContact(receiverVaultInfo?.address)}
                      >
                        <VTooltip id='trx-details-add-contact' />
                        <ContactsIcon className='trx-row-add-contact' />
                      </div>
                    )}
                    <CopyButton element={receiverVaultInfo?.address} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {!protocols.filter((i) => {
          return i.blockchain === data.blockchain && i.network === typeUser + 'net';
        })[0]?.blockchainStaticTxFee && (
          <div className='from-to'>
            {data.feeData.priority && (
              <div className='from-to-section'>
                <div className='label'>
                  <T>app.fee.priority</T>
                </div>
                <div className='fee capitalize'>{data.feeData.priority}</div>
              </div>
            )}
            {data.prepareStrategy && (
              <div className='from-to-section'>
                <div className='label'>
                  <T>app.prepare.strategy</T>
                </div>
                <div className='strategy capitalize'>{data.prepareStrategy.replaceAll('_', ' ')}</div>
              </div>
            )}
          </div>
        )}
        <div className={`rules  ${Object.keys(data?.governance).length === 0 ? 'no-rules' : ''}`}>
          <div className='title-rule'>
            <T>app.applied.level.process</T>
          </div>
          {Object.keys(data?.governance).length > 0 && data?.type?.toLowerCase() !== 'linking' ? (
            <div className='approves'>
              <div className='levels'>
                <div className='level level1'>
                  <div className='title'>
                    <T>app.level1</T>
                  </div>
                  <div className='values'>
                    {data.governance.l1.map((approver, key) => {
                      return (
                        <div className='status-approver' key={key}>
                          <span className={`status-icon ${approver.status.toLowerCase()}`} />
                          <span className='trx-details-level-approver'>{approver.name}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className='level level2'>
                  <div className='title'>
                    <T>app.level2</T>
                  </div>
                  <div className='values'>
                    {data.governance.l2.map((approver, key) => {
                      return (
                        <div className='status-approver' key={key}>
                          <span className={`status-icon ${approver.status.toLowerCase()}`} />
                          <span className='trx-details-level-approver'>{approver.name}</span>
                        </div>
                      );
                    })}
                    {data.governance.l2.length === 0 && (
                      <div className='no-approve status-approver'>
                        <span className='status-icon warning' />
                        <span>
                          <T>app.level2.no.approver</T>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='approvers-empty-wrapper'>
              <div className='no-rules-title'>
                <T>app.no.rules.applied</T>
              </div>
            </div>
          )}
        </div>
        <div className='from-to'>
          <div className='from-to-section'>
            <div className='label'>
              <T>app.no.requested.by</T>
            </div>
            <div className='fee capitalize'>
              {data?.type?.toLowerCase() === 'linking'
                ? '-'
                : `${data?.requestedBy?.name}, ${vaultodyDate(data?.requestedBy?.date, 'full')}`}
            </div>
          </div>
          <div className='from-to-section'>
            <div className='label'>
              <T>app.note</T>
            </div>
            <div className='strategy capitalize'>
              {data?.type?.toLowerCase() === 'linking' ? '-' : data?.note}
            </div>
          </div>
        </div>
        {data?.transactionId && (data?.status?.toUpperCase() === 'BROADCASTED' || isHistory) && (
          <div
            className='blockexplorer-button'
            onClick={() => {
              window.open(
                getBlockchainScannerLink(
                  data?.blockchain?.toLowerCase(),
                  data?.transactionId,
                  data?.network?.toLowerCase(),
                ),
                '_blank',
              );
            }}
          >
            <img
              className='blochain-scanner-logo'
              src={blockchainScannersIcons(data?.blockchain?.toLowerCase())}
            />
          </div>
        )}
      </div>
    </VModal>
  );
};

export default DetailsTransactionRequest;
