import { useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import ReactLoading from 'react-loading';
const AccLimits = ({ page, pages, name, to, from }) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(false);
  }, [page, to, name, from]);
  return loading ? (
    <div className='account-limit'>
      <ReactLoading width={30} height={30} type='spin' color='##020D1C' />
    </div>
  ) : (
    <div className='account-limit'>
      <div className='circle'>
        <CircularProgressbar
          styles={buildStyles({
            pathColor: '#020D1C',
          })}
          strokeWidth={9}
          value={from}
          maxValue={to}
        />
      </div>
      <div className='account-limit-titles'>
        <div className='page'>{page ? page : pages[name]}</div>
        <div className='limit'>
          {from} / {to}
        </div>
      </div>
    </div>
  );
};
export default AccLimits;
