import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setMessageSocket } from '../../redux/slices/globalSlice';

export default function useWebsocket(url, authenticated, options = {}) {
  const dispatch = useDispatch();
  const { retryCount = 3, retryInterval = 3000 } = options;
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [wsMessage, setWsMessage] = useState();
  const retryAttempts = useRef(0);

  const connectWebsocket = () => {
    const ws = new WebSocket(url);
    ws.onopen = () => {
      setSocket(ws);
      setIsConnected(true);
      retryAttempts.current = 0;
      console.log('Vaultody WS Server connected!');
    };

    ws.onclose = () => {
      console.log('Ws closing!');
      setIsConnected(false);
      if (retryAttempts.current < retryCount) {
        setTimeout(connectWebsocket, retryInterval);
        retryAttempts.current += 1;
      }
    };

    ws.onerror = () => {
      console.log('Ws error!');
      setIsConnected(false);
    };

    ws.onmessage = (message) => {
      const JSONmsg = JSON.parse(message.data);
      if (JSONmsg.data.event === 'websocket_connected') {
        localStorage.setItem('token', JSONmsg?.data?.item?.token);
      }
      setWsMessage(message);
      if (message?.data) {
        dispatch(setMessageSocket(JSON.parse(message?.data)));
      } else {
        dispatch(setMessageSocket(''));
      }
    };
  };

  useEffect(() => {
    if (url && authenticated) {
      connectWebsocket();
    }

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [url, authenticated]);

  return { isConnected, wsMessage, socket };
}
