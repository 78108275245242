import { useEffect, useState } from 'react';
import WalletAdressesTable from '../../Tables/Wallets/WalletAdressesTable';
import WalletsRulesTable from './WalletRulesTable';
import WalletSettingsTable from './WalletSettingsTable';
import WalletTHTable from './WalletTHTable';
import { getWalletApi } from '../../../api/endpoints';
import AssetsTable from './AssetsTable/AssetsTable';
import WalletGasTankerTable from './WalletGasTankerTable/WalletGasTankerTable';
import WalletAutomationRules from './WalletAutomationRules';
import { useOutletContext } from 'react-router-dom';
import { T } from '../../T';

const WalletDetailTable = ({ id, walletData }) => {
  const [activeTab, isTabViewPermissionMissing] = useOutletContext();
  const [backup, setBeckUp] = useState(false);

  useEffect(() => {
    getWalletApi(id).then((res) => setBeckUp(res.data.item.settings.backup.status === 'done'));
  }, []);

  if (isTabViewPermissionMissing) {
    return (
      <div className='table governance-layer-table'>
        <div className='table-row no-active no-prem'>
          <T>app.role.limitation</T>
        </div>
      </div>
    );
  }

  return (
    <div className='wallet-detail-table team-page'>
      {activeTab === 'automation-rules' && <WalletAutomationRules backup={backup} wallet={walletData} />}
      {activeTab === 'gas-tanker' && <WalletGasTankerTable backup={backup} id={id} walletData={walletData} />}
      {activeTab === 'assets' && (
        <div className='assets-table-tab'>
          <AssetsTable walletId={id} type='address' walletData={walletData} />
        </div>
      )}
      {activeTab === 'addresses' && <WalletAdressesTable backup={backup} id={id} walletData={walletData} />}
      {activeTab === 'transactions-history' && <WalletTHTable backup={backup} id={id} />}
      {activeTab === 'settings' && (
        <WalletSettingsTable setBeckUp={setBeckUp} editData={walletData} id={id} />
      )}
      {activeTab === 'rules' && <WalletsRulesTable id={id} />}
    </div>
  );
};

export default WalletDetailTable;
