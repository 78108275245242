import React from 'react';
import Button from '../../../Button/Button';
import Filter from '../../../Filter/Filter';
import { T, t } from '../../../T';
import { useSelector } from 'react-redux';

const CreateVaultAutomationRuleAddressesStep = ({
  addresses,
  submitCallback,
  selectedAddress,
  handleChooseAddress,
  addressesSearchFunc,
}) => {
  const lng = useSelector((state) => state.localizationReducer.selectedData);

  return (
    <div className='create-trx-request-step-one-wrapper'>
      <div className='create-trx-request-modal-select-component-wrapper'>
        <span className='create-trx-request-modal-select-component-label'>
          <T>app.select.address</T>
        </span>
        <Filter
          key='select-from-address-field'
          items={[
            { subscribeAll: true, name: t(lng, 'app.subscribe.all.addresses'), address: 'all' },
            ...(addresses?.addresses?.length > 0 ? addresses?.addresses : []),
          ]}
          selectedItems={selectedAddress?.length > 0 ? selectedAddress : []}
          onChange={handleChooseAddress}
          fullWidth
          disableFocusShadowEffect
          placeholder='app.addresses'
          dataKey='address'
          multiSelect={true}
          dataNameKey='name'
          hasSearchOption
          searchOptionApiCallback={(searchText) => addressesSearchFunc(searchText)}
          searchFieldPlaceholder={t(lng, 'app.search.address.sender')}
          customLabelTemplate={(address) =>
            address?.subscribeAll ? (
              <div className='create-trx-request-modal-step-two-sender-filter-label-top'>{address?.name}</div>
            ) : (
              <div className='create-trx-request-modal-step-two-sender-filter-label-wrapper'>
                <span className='create-trx-request-modal-step-two-sender-filter-label-top'>
                  {address?.name}
                </span>
                <span className='create-trx-request-modal-step-two-sender-filter-label-middle'>
                  {address?.address}
                </span>
              </div>
            )
          }
        />
      </div>
      <div className='create-trx-request-modal-button'>
        <Button fullWidth onClick={submitCallback} disabled={!selectedAddress}>
          <T>app.next.step</T>
        </Button>
      </div>
    </div>
  );
};

export default CreateVaultAutomationRuleAddressesStep;
