import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Papa from 'papaparse';
import Toasts from '../../helpers/toasts';
import { T, t } from '../T';
import { useSelector } from 'react-redux';
import Lottie from 'react-lottie';
import * as animationData from '../../helpers/lotties/csv-loading.json';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete-icon.svg';

const CSVUploader = ({ onSuccess }) => {
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [fileName, setFileName] = useState();
  const [parsedResult, setParsedResult] = useState();

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file.type === 'text/csv') {
      const reader = new FileReader();
      reader.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentLoaded = Math.round((event.loaded / event.total) * 100);
          setProgress(percentLoaded);
        }
      };
      reader.onload = (event) => {
        setLoading(true);
        setFileName(file?.name);
        Papa.parse(event.target.result, {
          header: true,
          transformHeader: (h) => h?.toLowerCase()?.replace(' ', ''),
          complete: (results) => {
            setParsedResult(results.data);
            setLoading(false);
            onSuccess(results.data);
          },
          error: (e) => {
            Toasts.error(e.message);
            setLoading(false);
          },
        });
      };
      reader.readAsText(file);
    } else {
      Toasts.error(t(lng, 'Not a csv file'));
    }
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <div
      {...getRootProps({ className: 'dropzone' })}
      style={{
        width: '100%',
        border: '1.5px dashed #B1B4B9',
        padding: '50px',
        display: 'flex',
        gap: '20px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {!progress && <input {...getInputProps()} />}
      {loading ? (
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          height={50}
          width={300}
        />
      ) : !fileName ? (
        <T>app.choose.csv.file</T>
      ) : (
        <div
          style={{
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
            flexWrap: 'nowrap',
          }}
        >
          {fileName}
          <DeleteIcon
            onClick={(e) => {
              e?.stopPropagation();
              setFileName(undefined);
              setParsedResult(undefined);
              setProgress(0);
            }}
            style={{ marginBottom: '3px', width: '17px', height: '17px', cursor: 'pointer' }}
          />
        </div>
      )}
      {progress > 0 && !parsedResult && (
        <div style={{ width: `100%`, height: '10px', border: '1px solid #8B9097', borderRadius: '4px' }}>
          <div
            style={{ width: `${progress}%`, height: '8px', backgroundColor: '#353D49', borderRadius: '4px' }}
          />
        </div>
      )}
    </div>
  );
};

export default CSVUploader;
