import Filter from '../../../Filter/Filter';
import Button from '../../../Button/Button';
import { T, t } from '../../../T';
import { getContactsApi, getContactsByNameSearchApi } from '../../../../api/endpoints';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { smallAddress } from '../../../../helpers/strings';
import Textfield from '../../../Textfield/Textfield';
import Blockchain from '../../../Blockchain/Blockchain';

const CreateVaultAutomationRuleDestinationStep = ({
  wallets,
  recipientType,
  selectedAsset,
  submitCallback,
  selectedRecipient,
  internalAddresses,
  handleChooseRecipient,
  selectedInternalWallets,
  handleToAddressTypeField,
  handleChooseInternalWallet,
  internalAddressesSearchFunc,
}) => {
  const protocols = useSelector((state) => state.globalReducer.protocols);
  const typeUser = useSelector((state) => state.userReducer.typeUser);
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const [contacts, setContacts] = useState();

  const getContactsBySearching = async (searchText) => {
    return getContactsByNameSearchApi(typeUser, {
      params: {
        item: searchText,
        limit: 20,
        statuses: ['approved'],
        blockchain: selectedAsset?.[0]?.blockchain,
      },
    }).then((res) => {
      setContacts(res.data?.items);
    });
  };

  const getContacts = async () => {
    return getContactsApi(typeUser, selectedAsset?.[0]?.blockchain, {
      params: { limit: 30, statuses: ['approved'] },
    }).then((res) => {
      setContacts(res.data?.items);
    });
  };

  return (
    <div className='create-trx-request-step-one-wrapper'>
      <div className='create-trx-request-modal-select-component-wrapper'>
        <div>
          <div className='create-trx-request-contact-or-one-time-address-wrapper'>
            <label>
              <T>app.to</T>
            </label>
            <div className='create-trx-request-contact-or-one-time-address'>
              <div
                className={`create-trx-request-contact-or-one-time-address-text ${
                  recipientType === 'contacts' ? 'selected' : ''
                }`}
                onClick={() => handleToAddressTypeField('contacts')}
              >
                <T>app.trusted.contacts</T>
              </div>
              <div
                className={`create-trx-request-contact-or-one-time-address-text ${
                  recipientType === 'one-time' ? 'selected' : ''
                }`}
                onClick={() => handleToAddressTypeField('one-time')}
              >
                <T>app.one.time.address</T>
              </div>
              <div
                className={`create-trx-request-contact-or-one-time-address-text ${
                  recipientType === 'internal' ? 'selected' : ''
                }`}
                onClick={() => handleToAddressTypeField('internal')}
              >
                <T>app.internal.addresses</T>
              </div>
            </div>
          </div>
          {recipientType === 'one-time' && (
            <Textfield
              trim
              type='text'
              fullWidth
              size='lg'
              value={selectedRecipient?.[0]?.address || ''}
              onChange={(e) => handleChooseRecipient([{ address: e.currentTarget.value }])}
            />
          )}
          {recipientType === 'contacts' && (
            <Filter
              key='select-contacts-field'
              fullWidth
              items={contacts}
              variant='radio'
              multiSelect={false}
              disableFocusShadowEffect
              placeholder='app.contacts'
              hasSearchOption
              customLabelTemplate={(contact) => {
                return (
                  <div key={contact?.address} className='create-trx-request-modal-filter-label-wrapper'>
                    <Blockchain
                      showText={false}
                      blockchain={contact?.blockchain}
                      symbol={protocols
                        ?.find(
                          (protocol) =>
                            protocol?.blockchain?.toLowerCase() === contact?.blockchain?.toLowerCase(),
                        )
                        ?.currency?.toLowerCase()}
                    />
                    <div>
                      <span className='create-trx-request-modal-filter-label'>{contact?.name}</span>
                      <span className='create-trx-request-modal-filter-label-sub'>
                        {smallAddress(contact?.address, 8)}
                      </span>
                    </div>
                  </div>
                );
              }}
              searchOptionApiCallback={(searchText) => {
                if (searchText?.length > 2) {
                  getContactsBySearching(searchText);
                } else {
                  getContacts();
                }
              }}
              searchFieldPlaceholder={t(lng, 'app.search.contact')}
              onChange={handleChooseRecipient}
              selectedItems={selectedRecipient?.filter((recipient) => recipient?.name) || []}
            />
          )}
          {recipientType === 'internal' && (
            <div className='create-trx-request-modal-internal-address-wrapper'>
              <Filter
                key='select-internal-addresses-automation-field'
                fullWidth
                items={wallets}
                variant='radio'
                selectedItems={selectedInternalWallets}
                onChange={handleChooseInternalWallet}
                disableFocusShadowEffect
                placeholder='app.select.vault'
                displayIcon
              />
              <Filter
                key='select-internal-addresses-field'
                fullWidth
                items={internalAddresses}
                variant='radio'
                dataKey='id'
                disableFocusShadowEffect
                placeholder='app.internal.addresses'
                hasSearchOption
                searchOptionApiCallback={(searchText) => internalAddressesSearchFunc(searchText)}
                searchFieldPlaceholder={t(lng, 'app.search.internal.addresses')}
                customLabelTemplate={(internalAddress) => {
                  return (
                    <div
                      key={internalAddress?.address}
                      className='create-trx-request-modal-filter-label-wrapper'
                    >
                      <Blockchain
                        showText={false}
                        blockchain={protocols
                          ?.find(
                            (protocol) =>
                              protocol?.blockchain?.toLowerCase() ===
                              internalAddress?.blockchain?.toLowerCase(),
                          )
                          ?.currency?.toLowerCase()}
                        symbol={protocols
                          ?.find(
                            (protocol) =>
                              protocol?.blockchain?.toLowerCase() ===
                              internalAddress?.blockchain?.toLowerCase(),
                          )
                          ?.currency?.toLowerCase()}
                      />
                      <div className='create-trx-request-modal-filter-labels-wrapper'>
                        <span className='create-trx-request-modal-filter-label'>
                          {internalAddress?.name || <T>app.no.name</T>}
                        </span>
                        <span className='create-trx-request-modal-filter-label-sub'>
                          {smallAddress(internalAddress?.address, 8)}
                        </span>
                      </div>
                    </div>
                  );
                }}
                onChange={(recipients) =>
                  handleChooseRecipient(
                    recipients?.map((recipient) => ({ ...recipient, internal: true })),
                    false,
                    true,
                  )
                }
                selectedItems={selectedRecipient?.filter((recipient) => recipient?.internal) || []}
              />
            </div>
          )}
        </div>
      </div>
      <div className='create-trx-request-modal-button'>
        <Button
          fullWidth
          onClick={submitCallback}
          disabled={!selectedRecipient || selectedRecipient?.length === 0}
        >
          <T>app.next.step</T>
        </Button>
      </div>
    </div>
  );
};

export default CreateVaultAutomationRuleDestinationStep;
