import { useRef, useState } from 'react';
import { currency } from '../../helpers/currency';
import { vaultodyDate } from '../../helpers/date';
import { useSelector } from 'react-redux';
import infinityIcon from '../../assets/icons/infinity.svg';
import activeIcon from '../../assets/icons/statuses/status-signed.svg';
import pendingIcon from '../../assets/icons/pending-icon.svg';
import infoIcon from '../../assets/icons/info-icon.svg';
import editIcon from '../../assets/icons/pencil-icon.svg';
import WalletsTable from '../GovernanceLayer/WalletsTable';
import ActionMenu from '../ActionMenu/ActionMenu';
import { T } from '../T';

const GLRow = ({ dataItem, viewHandler, editHadler, type }) => {
  const myProfile = useSelector((state) => state.userReducer.myProfile);
  const refW = useRef('gl-settings');
  const updatePolicyPermission = myProfile.premmisions?.includes('GOVERNANCE_RULES_UPDATE');
  const viewPolicyPermission = myProfile.premmisions?.includes('GOVERNANCE_RULES_VIEW');

  return (
    <div className='table-row data' ref={refW}>
      <div className='gov-table-data name'>
        <span className='mobile-cell-title'>
          <T>app.rules.name</T>
        </span>
        <div className='name-data'>
          <div className='icon-wrapper'>
            <img src={type?.toLowerCase() === 'active' ? activeIcon : pendingIcon} className='sub-icon' />
            <i className='icon-global' />
          </div>

          <div className='name-right'>
            <div className='name'>{dataItem.name}</div>
            <div className='creator'>{`Created by ${dataItem.createdBy}, ${vaultodyDate(
              dataItem.createdAt,
            )}`}</div>
          </div>
        </div>
      </div>
      <div className='gov-table-data amount-limit'>
        <span className='mobile-cell-title'>
          <T>app.amount.limit.24.hours</T>
        </span>
        <div>
          <span>{`From ${currency(dataItem.range.start)} to `}</span>
          {dataItem.range.end === 0 ? (
            <img className='infin' src={infinityIcon} />
          ) : (
            <span>{currency(dataItem.range.end)}</span>
          )}
        </div>
      </div>
      <div className='gov-table-data level'>
        <span className='mobile-cell-title'>
          <T>app.level1</T>
        </span>
        {dataItem.l1.length}
      </div>
      <div className='gov-table-data level'>
        <span className='mobile-cell-title'>
          <T>app.level2</T>
        </span>
        {dataItem.l2.length}
      </div>
      <div className='gov-table-data wallet-table'>
        <span className='mobile-cell-title'>
          <T>app.wallets.applied</T>
        </span>
        <div className='wallet-icons'>
          {dataItem.wallets.map((wallet, key) => {
            return <WalletsTable wallet={wallet} keyWallet={key} key={key} />;
          })}
          {dataItem.wallets[3] && (
            <div className='wallet-icon plus'>{`+ ${dataItem.wallets.length - 3}`}</div>
          )}
        </div>
      </div>
      {type === 'active' && (
        <div>
          <span className='mobile-cell-title'>
            <T>app.settings</T>
          </span>
          <ActionMenu
            data={dataItem}
            items={[
              {
                name: 'Rule Details',
                icon: infoIcon,
                permission: viewPolicyPermission,
                callback: (data) => viewHandler(data?.id),
              },
              {
                name: 'Apply/Edit Rule',
                icon: editIcon,
                permission: updatePolicyPermission,
                callback: (data) => editHadler(data?.id),
              },
            ]}
          />
        </div>
      )}
    </div>
  );
};
export default GLRow;
