import 'swiper/css';
import './CreateVaultAutomationRule.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { T, t } from '../../T';
import VModal from '../../Modal/VModal';
import { useSelector } from 'react-redux';
import Stepper from '../../Stepper/Stepper';
import { useState, useEffect } from 'react';
import {
  getBlockchainStrategiesFeesApi,
  createAutomationRuleApi,
  getWalletAddressesApi,
  editAutomationRuleApi,
  getAllAssetsApi,
  getWalletsApi,
  getInternalAddressesSearchApi,
} from '../../../api/endpoints';
import { ReactComponent as PencilIcon } from '../../../assets/icons/pencil2-icon.svg';
import { ReactComponent as ActionIcon } from '../../../assets/icons/action-icon.svg';
import { ReactComponent as PointerIcon } from '../../../assets/icons/pointer-icon.svg';
import { ReactComponent as AssetIcon } from '../../../assets/icons/settings-icon.svg';
import { ReactComponent as LocationIcon } from '../../../assets/icons/dashboardMenu/contacts-icon.svg';
import { ReactComponent as SettingsIcon } from '../../../assets/icons/settings-icon.svg';
import { ReactComponent as SummaryIcon } from '../../../assets/icons/summary-icon2.svg';
import CreateVaultAutomationRuleNameStep from './components/CreateVaultAutomationRuleNameStep';
import CreateVaultAutomationRuleAddressesStep from './components/CreateVaultAutomationRuleAddressesStep';
import CreateVaultAutomationRuleTriggerStep from './components/CreateVaultAutomationRuleTriggerStep';
import CreateVaultAutomationRuleDestinationStep from './components/CreateVaultAutomationRuleDestinationStep';
import CreateVaultAutomationRuleFeeStep from './components/CreateVaultAutomationRuleFeeStep';
import {
  automationRuleFlexibleFeeSteps,
  automationRuleStaticFeeSteps,
} from '../../../helpers/transactionrequests/steps';
import CreateVaultAutomationRuleSummaryStep from './components/CreateVaultAutomationRuleSummaryStep';
import CreateVaultAutomationRuleAssetStep from './components/CreateVaultAutomationRuleAssetStep';
import { useSwiperSlider } from '../../../helpers/hooks/useSwiperSlider';

const CreateVaultAutomationRule = ({ toggleFunction, successCallback, wallet, editData }) => {
  const isAssetChoosingDisabled = editData?.asset?.id;
  const typeUser = useSelector((state) => state.userReducer.typeUser);
  const protocols = useSelector((state) => state.globalReducer.protocols);
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const [assets, setAssets] = useState();
  const [addresses, setAddresses] = useState();
  const [internalWallets, setInternalWallets] = useState();
  const [selectedInternalWallets, setSelectedInternalWallets] = useState(undefined);
  const [searchResultsAddresses, setSearchResultsAddresses] = useState();
  const [sliding, setSliding] = useState(false);
  const [selectedName, setSelectedName] = useState(editData?.name || '');
  const [selectedAsset, setSelectedAsset] = useState(
    editData?.asset
      ? [
          {
            network: editData?.network,
            ...editData?.asset,
          },
        ]
      : undefined,
  );
  const [selectedTreshholdAmount, setSelectedTreshholdAmount] = useState(editData?.amountRange?.from || 0);
  const [selectedAddress, setSelectedAddress] = useState(
    !editData?.addressesList?.length
      ? [{ subscribeAll: true, name: t(lng, 'app.subscribe.all.addresses'), address: 'all' }]
      : editData?.addressesList?.map((address) => ({ ...address, name: address?.label })),
  );
  const [selectedRecipient, setSelectedRecipient] = useState(
    editData?.action?.destination ? [{ address: editData?.action?.destination }] : undefined,
  );
  const [selectedFeePriority, setSelectedFeePriority] = useState({
    name: editData?.maxFee?.feePriority,
    value: editData?.maxFee?.feePriority,
  });
  const [internalAddresses, setInternalAddresses] = useState();
  const [recipientType, setRecipientType] = useState('one-time');
  const [strategyFees, setStrategyFees] = useState();
  const [currentStep, setCurrentStep] = useState({ id: 1, name: 'name' });
  const [steps, setSteps] = useState([
    {
      id: 1,
      icon: <PencilIcon />,
      text: 'app.set.name.automation',
      name: 'name',
      completed: false,
      fillInsteadOfStroke: true,
    },
    {
      id: 2,
      icon: <PointerIcon />,
      text: 'app.set.asset.automation',
      name: 'asset',
      completed: false,
    },
    {
      id: 3,
      icon: <LocationIcon className='vault-automation-rule-contacts-icon' />,
      text: 'app.set.address.automation',
      name: 'destination',
      completed: false,
      fillInsteadOfStroke: true,
    },
    {
      id: 4,
      icon: <SettingsIcon />,
      text: 'app.set.trigger.automation',
      name: 'trigger',
      completed: false,
      fillInsteadOfStroke: true,
    },
    {
      id: 5,
      icon: <ActionIcon />,
      text: 'app.set.action.automation',
      name: 'action',
      completed: false,
      fillInsteadOfStroke: true,
    },
    { id: 6, icon: <SummaryIcon />, text: 'app.summary.automation', name: 'summary', completed: false },
  ]);
  const { handleCompleteStep, handleSwiperNextSlide, swiperContainerHeight, swiperRef } = useSwiperSlider(
    setSteps,
    '195px',
    [selectedRecipient, recipientType],
  );

  const assetBlockchainInfo = protocols.find((protocolVal) => {
    return protocolVal.blockchain === selectedAsset?.[0].blockchain;
  });

  const createVaultAutomationRule = () => {
    if (editData?.id) {
      editAutomationRuleApi(wallet?.id, editData?.id, {
        item: {
          trigger: {},
          name: selectedName,
          subscribeAllAddresses: selectedAddress?.some((address) => address?.subscribeAll),
          addresses: selectedAddress?.some((address) => address?.subscribeAll)
            ? []
            : selectedAddress?.map((address) => address?.id),
          // // TODO: CHANGE NAME OF "maxFee" ATTRIBUTE ON FE AND BE
          maxFee: {
            feePriority: selectedFeePriority?.value ? selectedFeePriority?.value : 'medium',
          },
          range: {
            start: selectedTreshholdAmount?.toString(),
            end: '0',
          },
          action: {
            type: 'transfer',
            destination: selectedRecipient?.[0]?.address,
          },
        },
      }).then(() => {
        successCallback();
      });
    } else {
      createAutomationRuleApi({
        item: {
          trigger: {},
          vaultId: wallet?.id,
          name: selectedName,
          network: selectedAsset?.[0]?.network,
          asset: { ...selectedAsset?.[0], id: selectedAsset?.[0]?.assetId },
          blockchain: selectedAsset?.[0]?.blockchain,
          subscribeAllAddresses: selectedAddress?.some((address) => address?.subscribeAll),
          addresses: selectedAddress?.some((address) => address?.subscribeAll)
            ? []
            : selectedAddress?.map((address) => address?.id),
          // TODO: CHANGE NAME OF "maxFee" ATTRIBUTE ON FE AND BE
          maxFee: {
            feePriority: selectedFeePriority?.value ? selectedFeePriority?.value : 'medium',
          },
          range: {
            start: selectedTreshholdAmount?.toString(),
            end: '0',
          },
          action: {
            type: 'transfer',
            destination: selectedRecipient?.[0]?.address,
          },
        },
      }).then(() => {
        successCallback();
      });
    }
  };

  const handleChooseName = (name) => setSelectedName(name);

  const handleChooseTreshholdAmount = (amount) => setSelectedTreshholdAmount(amount);

  const handleChooseFeePriority = (feePriority) => setSelectedFeePriority(feePriority);

  const handleChooseAsset = (assets) => {
    setSelectedAsset(assets);
    setSelectedTreshholdAmount(0);
    setSelectedAddress(undefined);
    setSelectedRecipient(undefined);
    setSelectedFeePriority(undefined);
    handleToAddressTypeField('one-time');
    setSteps((prev) =>
      prev?.map((step, index) => {
        if (index === 0) {
          return step;
        }
        return { ...step, completed: false };
      }),
    );
  };

  const handleChooseAddress = (addresses) => {
    setSelectedAddress(addresses);
  };

  const handleChooseInternalWallet = (wallets) => {
    setSelectedInternalWallets(wallets);
  };

  const handleChooseRecipient = (recipients) => {
    setSelectedRecipient(recipients);
  };

  // THIS IS FOR THE TABS IN THE DESTINATION STEP "TO" ADDRESS
  const handleToAddressTypeField = (type) => {
    setRecipientType(type);
  };

  const internalAddressesSearchFunc = (searchText) => {
    if (searchText?.length > 0) {
      getInternalAddressesSearchApi(
        selectedInternalWallets?.[0]?.id,
        typeUser,
        selectedAsset?.[0]?.blockchain,
        {
          params: { limit: 50, item: searchText },
        },
      ).then((res) => {
        setInternalAddresses(res.data.items);
      });
    } else {
      if (typeUser && selectedInternalWallets?.[0]?.id) {
        getWalletAddressesApi(selectedInternalWallets?.[0]?.id, {
          params: {
            limit: 50,
            blockchain: selectedAsset?.[0]?.blockchain,
          },
          paramsSerializer: {
            indexes: null, // by default: false
          },
        }).then((res) => setInternalAddresses(res?.data?.items));
      }
    }
  };

  const handleAddressSearch = (searchText) => {
    if (searchText?.length > 0) {
      getInternalAddressesSearchApi(wallet?.id, typeUser, selectedAsset?.[0]?.blockchain, {
        params: {
          limit: 50,
          item: searchText,
          // linkStatus: 'linked',
          addressType: ['smart-deposit', 'deposit'],
        },
      }).then((res) =>
        setSearchResultsAddresses({
          ...addresses,
          addresses: res?.data?.items?.map((address) => ({
            ...address,
            availableAmount: address?.balances?.[0]?.assetData?.availableAmount,
          })),
          shouldUse: true,
        }),
      );
    } else {
      setSearchResultsAddresses({
        ...addresses,
        addresses: [],
        shouldUse: false,
      });
      if (selectedAsset?.[0]?.id && wallet?.id && selectedAsset?.[0]?.blockchain && typeUser) {
        getWalletAddressesApi(wallet?.id, {
          params: {
            limit: 50,
            blockchain: selectedAsset?.[0]?.blockchain,
            addressType: ['smart-deposit', 'deposit'],
          },
        }).then((res) => setAddresses({ addresses: res?.data?.items }));
      }
    }
  };

  const handleAssetsSearch = (searchText) => {
    if (searchText?.length > 0) {
      getAllAssetsApi(typeUser, { params: { limit: 50, searchItem: searchText } }).then((res) => {
        setAssets(
          res?.data?.items?.map((item) => ({
            ...item,
            id: `${item?.id}-${item?.blockchain}-${item?.network}`,
            assetId: item?.id,
          })),
        );
      });
    } else {
      if (typeUser) {
        getAllAssetsApi(typeUser, { params: { limit: 50 } }).then((res) => {
          setAssets(
            res?.data?.items?.map((item) => ({
              ...item,
              id: `${item?.id}-${item?.blockchain}-${item?.network}`,
              assetId: item?.id,
            })),
          );
        });
      }
    }
  };

  useEffect(() => {
    if (!wallet?.id) {
      return;
    }
    getAllAssetsApi(typeUser, { params: { limit: 50 } }).then((res) => {
      setAssets(
        res?.data?.items?.map((item) => ({
          ...item,
          id: `${item?.id}-${item?.blockchain}-${item?.network}`,
          assetId: item?.id,
        })),
      );
    });
  }, [wallet?.id]);

  useEffect(() => {
    if (!selectedAsset?.[0]?.id || !selectedInternalWallets?.[0]?.id) {
      return;
    }
    getWalletAddressesApi(selectedInternalWallets?.[0]?.id, {
      params: {
        limit: 50,
        blockchain: selectedAsset?.[0]?.blockchain,
      },
      paramsSerializer: {
        indexes: null, // by default: false
      },
    }).then((res) => setInternalAddresses(res?.data?.items));
  }, [selectedAsset?.[0]?.id, selectedInternalWallets?.[0]?.id]);

  // GET BLOCKCHAIN AND NETWORK STRAGIES FEES
  useEffect(() => {
    if (selectedAsset?.[0]?.network && !assetBlockchainInfo?.blockchainStaticTxFee) {
      getBlockchainStrategiesFeesApi(selectedAsset?.[0]?.blockchain, selectedAsset?.[0]?.network).then(
        (res) => setStrategyFees(res?.data?.item),
      );
    }
  }, [selectedAsset?.[0]?.network]);

  useEffect(() => {
    if (assetBlockchainInfo?.blockchainStaticTxFee !== undefined) {
      const assetTypeSteps = assetBlockchainInfo?.blockchainStaticTxFee
        ? automationRuleStaticFeeSteps
        : automationRuleFlexibleFeeSteps;
      setSteps(assetTypeSteps);
    }
  }, [assetBlockchainInfo?.blockchain]);

  // REMOVING ALL OTHER SELECTED ADDRESSES IF SUBSCRIBE ALL FIELD IS CLICKED
  useEffect(() => {
    if (selectedAddress?.length && selectedAddress[selectedAddress?.length - 1]?.subscribeAll) {
      setSelectedAddress((prev) => prev?.filter((prevAddress) => prevAddress?.subscribeAll === true));
    } else {
      setSelectedAddress((prev) => prev?.filter((prevAddress) => !prevAddress?.subscribeAll));
    }
  }, [selectedAddress?.length]);

  // HANDLE ADDRESSES FETCH WHEN ASSET ID CHANGES
  useEffect(() => {
    if (selectedAsset?.length > 0) {
      getWalletAddressesApi(wallet?.id, {
        params: {
          limit: 50,
          blockchain: selectedAsset?.[0]?.blockchain,
          addressType: ['smart-deposit', 'deposit'],
        },
      }).then((res) => setAddresses({ addresses: res?.data?.items }));
    }
  }, [selectedAsset?.[0]?.id]);

  useEffect(() => {
    if (typeUser) {
      getWalletsApi({ params: { type: typeUser?.toUpperCase(), limit: 0 } }).then((res) =>
        setInternalWallets(res.data.items),
      );
    }
  }, [typeUser]);

  return (
    <VModal classHandler={`create-transaction-request-modal`} toggleFunction={toggleFunction}>
      <div className='create-transaction-request-modal-wrapper'>
        <div className='create-transaction-request-modal-side'>
          <span className='create-transaction-request-modal-side-heading'>
            {`${t(lng, 'app.step')} ${currentStep?.id}`}
          </span>
          <Stepper
            onSelectStepCallback={(stepIndex, stepName) => {
              setCurrentStep({ id: stepIndex, name: stepName });
              if (swiperRef.current && swiperRef.current.swiper) {
                swiperRef.current.swiper.slideTo(stepIndex - 1);
              }
            }}
            currentStepId={currentStep?.id}
            steps={steps}
          />
        </div>
        <div className='create-transaction-request-modal-content'>
          <span className='create-transaction-request-modal-heading'>
            <div className='create-transaction-request-modal-heading-wrapper'>
              <span>{editData?.id ? <T>app.edit.automation.rule</T> : <T>app.new.automation.rule</T>}</span>
              <span className='create-transaction-request-modal-side-sub-heading'>
                {stepDescriptionMapping()[currentStep?.name]?.icon}
                {t(lng, stepDescriptionMapping()[currentStep?.name]?.text)}
              </span>
              <span className='create-transaction-request-modal-step-desc'>
                {t(
                  lng,
                  stepDescriptionMapping(
                    assetBlockchainInfo?.blockchain === 'bitcoin',
                    assetBlockchainInfo?.blockchainStaticTxFee,
                  )[currentStep?.name]?.subText,
                )}
              </span>
            </div>
          </span>
          <div
            className={`create-transaction-request-swiper-wrapper ${sliding ? 'sliding' : ''}`}
            style={{ height: swiperContainerHeight }}
          >
            <Swiper
              ref={swiperRef}
              spaceBetween={50}
              speed={800}
              allowTouchMove={false}
              slidesPerView={1}
              onSlideChangeTransitionStart={() => setSliding(true)}
              onSlideChangeTransitionEnd={() => setSliding(false)}
            >
              <SwiperSlide>
                <CreateVaultAutomationRuleNameStep
                  isEdit={editData?.id}
                  selectedName={selectedName}
                  handleChooseName={handleChooseName}
                  submitCallback={() => {
                    setCurrentStep({ id: 2, name: 'asset' });
                    handleCompleteStep(1);
                    handleSwiperNextSlide();
                  }}
                />
              </SwiperSlide>
              <SwiperSlide>
                <CreateVaultAutomationRuleAssetStep
                  assets={assets}
                  selectedWallet={wallet}
                  selectedAsset={selectedAsset}
                  handleChooseAsset={handleChooseAsset}
                  assetsSearchFunc={(searchText) => handleAssetsSearch(searchText)}
                  disabled={!wallet || isAssetChoosingDisabled}
                  submitCallback={() => {
                    setCurrentStep({ id: 3, name: 'destination' });
                    handleCompleteStep(2);
                    handleSwiperNextSlide();
                  }}
                />
              </SwiperSlide>
              <SwiperSlide>
                <CreateVaultAutomationRuleAddressesStep
                  selectedAddress={selectedAddress}
                  handleChooseAddress={handleChooseAddress}
                  addressesSearchFunc={(searchText) => handleAddressSearch(searchText)}
                  addresses={searchResultsAddresses?.shouldUse ? searchResultsAddresses : addresses}
                  submitCallback={() => {
                    setCurrentStep({ id: 4, name: 'trigger' });
                    handleCompleteStep(3);
                    handleSwiperNextSlide();
                  }}
                />
              </SwiperSlide>
              <SwiperSlide>
                <CreateVaultAutomationRuleTriggerStep
                  handleChooseTreshholdAmount={handleChooseTreshholdAmount}
                  selectedTreshholdAmount={selectedTreshholdAmount}
                  selectedAsset={selectedAsset}
                  submitCallback={() => {
                    setCurrentStep({ id: 5, name: 'action' });
                    handleCompleteStep(4);
                    handleSwiperNextSlide();
                  }}
                />
              </SwiperSlide>
              <SwiperSlide>
                <CreateVaultAutomationRuleDestinationStep
                  internalAddressesSearchFunc={internalAddressesSearchFunc}
                  handleChooseInternalWallet={handleChooseInternalWallet}
                  handleToAddressTypeField={handleToAddressTypeField}
                  handleChooseRecipient={handleChooseRecipient}
                  selectedInternalWallets={selectedInternalWallets}
                  internalAddresses={internalAddresses}
                  selectedRecipient={selectedRecipient}
                  recipientType={recipientType}
                  selectedAsset={selectedAsset}
                  wallets={internalWallets}
                  submitCallback={() => {
                    setCurrentStep({
                      id: 6,
                      name: !assetBlockchainInfo?.blockchainStaticTxFee ? 'fee' : 'summary',
                    });
                    handleCompleteStep(5);
                    handleSwiperNextSlide();
                  }}
                />
              </SwiperSlide>
              {!assetBlockchainInfo?.blockchainStaticTxFee && (
                <SwiperSlide>
                  <CreateVaultAutomationRuleFeeStep
                    strategyFees={strategyFees}
                    selectedFeePriority={selectedFeePriority}
                    handleChooseFee={handleChooseFeePriority}
                    submitCallback={() => {
                      setCurrentStep({ id: 7, name: 'summary' });
                      handleCompleteStep(6);
                      handleSwiperNextSlide();
                    }}
                  />
                </SwiperSlide>
              )}
              <SwiperSlide>
                <CreateVaultAutomationRuleSummaryStep
                  strategyFees={strategyFees}
                  selectedName={selectedName}
                  selectedAsset={selectedAsset}
                  selectedAddress={selectedAddress}
                  selectedRecipient={selectedRecipient}
                  selectedFeePriority={selectedFeePriority}
                  selectedTreshholdAmount={selectedTreshholdAmount}
                  staticTrxFee={assetBlockchainInfo?.blockchainStaticTxFee}
                  submitCallback={() => createVaultAutomationRule()}
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </VModal>
  );
};
export default CreateVaultAutomationRule;

const stepDescriptionMapping = () => ({
  name: {
    text: 'app.set.name.automation.desc',
    subText: 'app.set.name.automation.sub.text',
    icon: <PencilIcon style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
  asset: {
    text: 'app.set.asset.automation.desc',
    subText: 'app.set.asset.automation.sub.text',
    icon: <PointerIcon style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
  destination: {
    text: 'app.set.address.automation.desc',
    subText: 'app.set.address.automation.sub.text',
    icon: (
      <LocationIcon
        style={{ marginRight: '10px', marginBottom: '4px' }}
        className='automation-rule-contacts-icon'
      />
    ),
  },
  trigger: {
    text: 'app.set.trigger.automation.desc',
    subText: 'app.set.trigger.automation.sub.text',
    icon: <SettingsIcon style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
  action: {
    text: 'app.set.action.automation.desc',
    subText: 'app.set.action.automation.sub.text',
    icon: <ActionIcon style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
  fee: {
    text: 'app.set.fee.automation.desc',
    subText: 'app.set.fee.automation.sub.text',
    icon: <AssetIcon style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
  summary: {
    text: 'app.summary.automation.desc',
    icon: <SummaryIcon fill='#fff' style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
});
