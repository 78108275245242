import { useRef, useState } from 'react';
import { useClickOutside } from '../../helpers/hooks/useClickOutside';
import { usePopper } from 'react-popper';
import './ActionMenu.css';

const ActionMenu = ({ data, items, popperOptions }) => {
  const [open, setOpen] = useState(false);
  const wrapper = useRef('d');
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: 'preventOverflow', enabled: true }],
  });

  useClickOutside(wrapper, () => {
    setOpen(false);
  });

  return (
    <div ref={wrapper}>
      <div
        ref={setReferenceElement}
        className='actions icon-dots'
        onClick={() => {
          open ? setOpen(false) : setOpen(true);
        }}
      />
      {open && (
        <div
          className={`action-menu active`}
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          {items?.length > 0 &&
            items?.map((item) => (
              <div
                key={item?.name}
                className={`action-menu-item ${item?.permission ? ' ' : 'inactive'}`}
                onClick={() => {
                  setOpen(false);
                  if (item?.callback) {
                    item?.callback(data);
                  }
                }}
              >
                {item.icon && <img className='action-dropdown-icon' src={item?.icon} />}
                {item.name}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
export default ActionMenu;
