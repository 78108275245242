import { useState } from 'react';
import VModal from '../Modal/VModal';
import { T } from '../T';
import ActionModal from './ActionModal';
import { changeProfileNameApi } from '../../api/endpoints';
import Textfield from '../Textfield/Textfield';
import Button from '../Button/Button';

const ChangeName = ({ toggleFunction }) => {
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
  });
  const [sussess, setSussess] = useState(false);
  const changeName = () => {
    changeProfileNameApi({
      item: { firstName: data?.firstName?.trim(), lastName: data?.lastName?.trim() },
    }).then(() => setSussess(true));
  };
  return (
    <>
      {!sussess && (
        <VModal classHandler='change-name' toggleFunction={toggleFunction}>
          <div className='v-modal-title'>Change Name</div>
          <div className='v-input-group'>
            <label>First Name</label>
            <Textfield
              type='text'
              fullWidth
              placeholder='Enter your new first name'
              value={data?.firstName}
              size='lg'
              minLength={2}
              maxLength={30}
              onChange={(e) => {
                const value = e.currentTarget.value;
                setData((state) => {
                  return {
                    ...state,
                    ...{ firstName: value },
                  };
                });
              }}
            />
          </div>
          <div className={`v-input-group `}>
            <label>Last Name</label>
            <Textfield
              type='text'
              fullWidth
              placeholder='Enter your new last name'
              size='lg'
              value={data?.lastName}
              maxLength={30}
              onChange={(e) => {
                const value = e.currentTarget.value;
                setData((state) => {
                  return {
                    ...state,
                    ...{ lastName: value },
                  };
                });
              }}
            />
          </div>
          <div className='button-holder'>
            <Button
              size='md'
              fullWidth
              disabled={data.firstName.length < 2 || data.lastName.length < 2}
              onClick={() => changeName()}
            >
              <i className='icon-submit-for-approval' />
              <T>app.submit.for.approval</T>
            </Button>
          </div>
        </VModal>
      )}
      {sussess && (
        <ActionModal toggleFunction={toggleFunction}>
          <T>app.name.change.approval</T>
        </ActionModal>
      )}
    </>
  );
};

export default ChangeName;
