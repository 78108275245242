import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import WalletDetailTable from '../../components/Tables/Wallets/WalletDetailTable';
import ReactLoading from 'react-loading';
import { getWalletApi } from '../../api/endpoints';
import { useDispatch, useSelector } from 'react-redux';
import { setWalletColor } from '../../redux/slices/userSlice';
import { setPage, setSelectedVaultType } from '../../redux/slices/globalSlice';

const Wallet = () => {
  const dispatch = useDispatch();
  const typeUser = useSelector((state) => state.userReducer.typeUser);
  const location = useLocation();
  const [editData, setEditData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    return () => {
      dispatch(setWalletColor('#fff'));
    };
  }, []);

  useEffect(() => {
    if (typeUser) {
      getWalletApi(location.pathname.split('/')[2])
        .then((res) => {
          setEditData(res.data?.item);
          dispatch(setPage(res.data?.item?.name));
          dispatch(setWalletColor(res.data?.item?.color));
          dispatch(setSelectedVaultType(res.data?.item?.vaultType));
        })
        .finally(() => setLoading(false));
    }
    return () => dispatch(setSelectedVaultType(undefined));
  }, [typeUser]);

  return loading ? (
    <div className='team-page wallets'>
      <div className='loading'>
        <ReactLoading type='spin' color='##020D1C' />
      </div>
    </div>
  ) : (
    <WalletDetailTable walletData={editData} id={location.pathname.split('/')[2]} />
  );
};

export default Wallet;
