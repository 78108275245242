import './Tabs.css';
import { T } from '../T';

const Tabs = ({ items, value, onChange }) => {
  const handleChangeTab = (e) => {
    const selectedTab = e.currentTarget.getAttribute('data-type');
    onChange(selectedTab);
    window.location.hash = selectedTab;
  };

  return (
    <div className='tabs-component'>
      {items?.length > 0 &&
        items?.map((item) => (
          <div
            key={item?.name}
            onClick={handleChangeTab}
            data-type={item?.name}
            className={`tab ${value === item?.name ? 'selected' : ''}`}
          >
            <T>{item?.label}</T>
          </div>
        ))}
    </div>
  );
};

export default Tabs;
