import './VaultCreationModalCard.css';
import darkCheckmarkIcon from '../../../../../../assets/icons/dark-checkmark.svg';
import { ReactComponent as Xmark } from '../../../../../../assets/icons/xmark.svg';
import { ReactComponent as Checkmark } from '../../../../../../assets/icons/green-checkmark.svg';

const VaultCreationModalCard = ({
  features,
  title,
  icon,
  description,
  value,
  callback,
  type,
  disabled,
  comingSoon,
}) => {
  return (
    <div className={`vault-creation-modal-card ${disabled ? 'disabled' : ''}`} onClick={callback}>
      {comingSoon && (
        <div className='ribbon'>
          COMING <br /> SOON
        </div>
      )}
      {((value && value !== type) || disabled) && <div className='vault-creation-modal-card-overlay' />}
      <div className='vault-creation-modal-card-header'>
        <div className='vault-creation-modal-card-clickable'>
          {value === type && <img src={darkCheckmarkIcon} alt='' />}
        </div>
        <div className='vault-creation-modal-card-icon-and-title'>
          <img src={icon} alt='' />
        </div>
        <div className='vault-creation-modal-card-empty' />
      </div>
      <div className='vault-creation-modal-card-bold'>
        <span>{title}</span>
      </div>
      <span className='vault-creation-modal-card-desc'>{description}</span>
      <div className='vault-creation-modal-card-features'>
        {features?.length > 0 &&
          features?.map((feature) => (
            <div className='vault-creation-modal-card-feature-wrapper' key={feature?.label}>
              <span className='vault-creation-modal-card-feature-text'>{feature?.label}</span>
              {feature?.customText ? (
                <span className='vault-creation-modal-card-feature-custom-text'>{feature?.customText}</span>
              ) : feature?.available ? (
                <Checkmark className='create-vault-checkmark-icon' />
              ) : (
                <Xmark />
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default VaultCreationModalCard;
