import { useEffect, useState } from 'react';
import TeamActiveTable from '../../components/Tables/TeamActiveTable';
import TeamPendingTable from '../../components/Tables/TeamPendingTable';
import './Team.css';
import ReactLoading from 'react-loading';
import ActionModal from '../../components/Modals/ActionModal';
import { T, t } from '../../components/T';
import { useDispatch, useSelector } from 'react-redux';
import { checkLimits } from '../../helpers/limits';
import LimitsModal from '../../components/Modals/LimitsModal';
import {
  deleteInvitationApi,
  deleteTeamMemberApi,
  getGovernanceRolesApi,
  getTeamApi,
  getTeamInvitationsApi,
  resetDeviceApi,
} from '../../api/endpoints';
import { setMyUsage } from '../../redux/slices/userSlice';
import Button from '../../components/Button/Button';
import RemoveDeviceModal from '../../components/Pages/MyProfile/RemoveDeviceModal/RemoveDeviceModal';
import { ROUTE_TEAM } from '../../routes/routes';
import BasicModal from '../../components/Modals/BasicModal/BasicModal';
import VTooltip from '../../components/VTooltip/VTooltip';
import InviteTeamMemberModal from '../../components/Modals/InviteTeamMemberModal/InviteTeamMemberModal';

const Team = () => {
  const dispatch = useDispatch();
  const messageSocket = useSelector((state) => state.globalReducer.messageSocket);
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const limits = useSelector((state) => state.userReducer.limits);
  const myProfile = useSelector((state) => state.userReducer.myProfile);
  const [active, setActive] = useState(0);
  const [pending, setPending] = useState(0);
  const [tab, setTab] = useState();
  const [activeData, setActiveData] = useState([]);
  const [pendingData, setPendingData] = useState([]);
  const [openModalAdd, setOpenModal] = useState(false);
  const [limitModal, setlimitModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState([]);
  const [removeModal, setRemoveModal] = useState(false);
  const [removeData, setRemoveData] = useState([]);
  const [cancelModal, setCancelModal] = useState(false);
  const [cancelData, setCancelData] = useState([]);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModalSuccessRemove, setOpenModalSuccessRemove] = useState(false);
  const [openRemoveDeviceModal, setOpenRemoveDeviceModal] = useState({ open: false });
  const [removeDeviceSuccess, setRemoveDeviceSuccess] = useState(false);
  const invitePermission = myProfile.premmisions?.includes('TEAM_MEMBERS_INVITE');
  const cancelInvitationPermission = myProfile.premmisions?.includes('TEAM_MEMBERS_DELETE_INVITATION');

  const removeDeviceHandler = async (dataItem) => {
    setOpenRemoveDeviceModal({
      open: true,
      deviceId: dataItem?.deviceId,
      userId: dataItem?.id,
      dataItem: dataItem,
    });
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const removeDeviceApiCall = async (deviceId, userId) => {
    return resetDeviceApi({ item: { deviceId, removedDeviceUserId: userId } }).then(() => {
      setOpenRemoveDeviceModal({ open: false });
      setRemoveDeviceSuccess(true);
    });
  };

  useEffect(() => {
    activeDataRequest();
    pendingDataRequest();
    rolesRequest();
  }, []);

  const rolesRequest = () => {
    getGovernanceRolesApi().then((res) => setRoles(res.data.items));
  };
  const activeDataRequest = () => {
    getTeamApi()
      .then((res) => {
        setActiveData(res.data.items);
        setActive(
          res.data.items.filter((member) => {
            return member.status !== 'deleted';
          }).length,
        );
      })
      .finally(() => setLoading(false));
  };

  const pendingDataRequest = () => {
    getTeamInvitationsApi().then((res) => {
      setPendingData(res.data.items);
      setPending(res.data.items.length);
    });
  };

  useEffect(() => {
    if (typeof messageSocket === 'object') {
      if (
        messageSocket?.data?.event === 'team_member_invitation_approved' ||
        messageSocket === 'team_member_invitation_rejected'
      ) {
        pendingDataRequest();
        checkLimits((usage) => dispatch(setMyUsage(usage)));
      }
      if (
        messageSocket?.data?.event === 'update_team_member_approved' ||
        messageSocket?.data?.event === 'team_member_removal_approved'
      ) {
        activeDataRequest();
        checkLimits((usage) => dispatch(setMyUsage(usage)));
      }
    }
  }, [messageSocket]);

  useEffect(() => {
    if (tab === 'pending') {
      pendingDataRequest();
    }
    if (tab === 'active') {
      activeDataRequest();
    }
  }, [tab]);

  const editModalHandler = (data) => {
    setEditModal(true);
    setEditData(data);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const removeModalHandler = (data) => {
    setRemoveModal(true);
    setRemoveData(data);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const cancelModalHandler = (data) => {
    setCancelModal(true);
    setCancelData(data);
  };

  const sendCancelRequest = () => {
    deleteInvitationApi(cancelData.id).then(() => {
      checkLimits((usage) => dispatch(setMyUsage(usage)));
      setCancelModal(false);
      setCancelData([]);
      pendingDataRequest();
    });
  };

  const sendRemoveRequest = () => {
    deleteTeamMemberApi(removeData.id).then(() => {
      setRemoveModal(false);
      setOpenModalSuccessRemove(true);
    });
  };

  const handleChangeTab = (e) => {
    const selectedTab = e.currentTarget.getAttribute('data-type');
    setTab(selectedTab);
    window.location.hash = selectedTab;
  };

  useEffect(() => {
    if (!window.location.hash) {
      window.location.hash = 'active';
    }
    setTab(window.location.hash?.replace('#', ''));
  }, []);

  return loading ? (
    <div className={'team-page ' + tab}>
      <div className='loading'>
        <ReactLoading type='spin' color='##020D1C' />
      </div>
    </div>
  ) : (
    <div className={'team-page ' + tab}>
      <div className='header-table'>
        <div className='tabs'>
          <div onClick={handleChangeTab} className='tab' data-type='active'>
            <T>app.team.members</T> <span className='info-active'>{active > 9 ? '9+' : active}</span>
          </div>
          <div onClick={handleChangeTab} className='tab' data-type='pending'>
            <T>app.pending.invites</T> <span className='info-pending'>{pending > 9 ? '9+' : pending}</span>
          </div>
        </div>
      </div>
      <div className='team-button-filters-wrapper'>
        <div className='button-holder margin-right-50'>
          <Button
            size='md'
            disabled={!invitePermission}
            data-tooltip-id='invite-team-member'
            data-tooltip-content={t(lng, 'app.role.limitation')}
            onClick={() => {
              if (limits[ROUTE_TEAM].from >= limits[ROUTE_TEAM].to) {
                setlimitModal(true);
              } else {
                setOpenModal(true);
              }
            }}
          >
            {!invitePermission && <VTooltip id='invite-team-member' />}
            <i className='icon-add-a-new-wallet' />
            <T>app.invite.a.team.membera</T>
          </Button>
        </div>
      </div>

      {tab === 'active' ? (
        <TeamActiveTable
          rolesAll={roles}
          removeDeviceHandler={(dataItem) => removeDeviceHandler(dataItem)}
          data={activeData.filter((member) => {
            return member.status !== 'deleted';
          })}
          removeHandler={removeModalHandler}
          editHadler={editModalHandler}
        />
      ) : (
        <TeamPendingTable rolesAll={roles} data={pendingData} cancelHandler={cancelModalHandler} />
      )}
      {openModalAdd && (
        <InviteTeamMemberModal
          roles={roles}
          successCallback={() => {
            checkLimits((usage) => dispatch(setMyUsage(usage)));
            pendingDataRequest();
          }}
          toggleFunction={setOpenModal}
        />
      )}
      {openModalSuccessRemove && (
        <ActionModal
          toggleFunction={() => {
            setOpenModalSuccessRemove(false);
            setRemoveData([]);
          }}
        >
          <T>app.remove.team.member.descr</T>
        </ActionModal>
      )}
      {editModal && (
        <InviteTeamMemberModal
          editData={{
            ...editData,
            roles: editData?.roles?.map((editRoleId) => roles?.find((role) => role?.id === editRoleId)),
          }}
          roles={roles}
          successCallback={() => {
            checkLimits((usage) => dispatch(setMyUsage(usage)));
            pendingDataRequest();
            setEditData([]);
          }}
          toggleFunction={setEditModal}
        />
      )}
      {limitModal && <LimitsModal toggleFunction={setlimitModal} modalName='app.members.limit' />}
      {removeDeviceSuccess && (
        <ActionModal toggleFunction={() => setRemoveDeviceSuccess(false)}>
          <T>app.remove.device.descr</T>
        </ActionModal>
      )}
      {openRemoveDeviceModal?.open && (
        <RemoveDeviceModal
          callback={() => {
            setOpenRemoveDeviceModal({ open: false });
          }}
          removeDeviceHandler={() =>
            removeDeviceApiCall(openRemoveDeviceModal?.deviceId, openRemoveDeviceModal?.userId)
          }
          dataItem={openRemoveDeviceModal?.dataItem}
        />
      )}
      {removeModal && (
        <BasicModal
          closeCallback={() => setRemoveModal(false)}
          submitCallback={sendRemoveRequest}
          title={t(lng, 'app.are.you.remove.team.member')}
        >
          <div className='remove-member-key-info-wrapper'>
            <div className='remove-member-key-info-row'>
              <span className='remove-member-key-info-text'>
                <T>app.team.member.name</T>
              </span>
              <span className='remove-member-key-info-sub-text'>{removeData.name}</span>
            </div>
            <div className='remove-member-key-info-row'>
              <span className='remove-member-key-info-text'>
                <T>app.team.member.email</T>
              </span>
              <span className='remove-member-key-info-sub-text'>{removeData.email}</span>
            </div>
            <div className='remove-member-key-info-row'>
              <span className='remove-member-key-info-text'>
                <T>app.team.member.roles</T>
              </span>
              <span className='remove-member-key-info-sub-text'>
                {removeData?.roles
                  ?.map((roleId) => roles?.find((role) => role?.id === roleId)?.name)
                  ?.join(', ')}
              </span>
            </div>
          </div>
        </BasicModal>
      )}
      {cancelModal && (
        <BasicModal
          closeCallback={() => setCancelModal(false)}
          submitCallback={sendCancelRequest}
          title={t(lng, 'app.are.you.want.cancel')}
          submitButtonColor='#DC3241'
          submitButtonTextColor='#fff'
          submitButtonText={t(lng, 'app.cancel.invite')}
          submitButtonDisabled={!cancelInvitationPermission}
        >
          <div className='remove-member-key-info-wrapper'>
            <div className='remove-member-key-info-row'>
              <span className='remove-member-key-info-text'>
                <T>app.team.member.email</T>
              </span>
              <span className='remove-member-key-info-sub-text'>{cancelData?.email}</span>
            </div>
            <div className='remove-member-key-info-row'>
              <span className='remove-member-key-info-text'>
                <T>app.team.member.roles</T>
              </span>
              <span className='remove-member-key-info-sub-text'>
                {cancelData?.roles
                  ?.map((roleId) => roles?.find((role) => role?.id === roleId)?.name)
                  ?.join(', ')}
              </span>
            </div>
          </div>
        </BasicModal>
      )}
    </div>
  );
};

export default Team;
