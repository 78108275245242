import React, { useState, useEffect } from 'react';
import './PaymentPending.css';
import ReactLoading from 'react-loading';
import Button from '../../components/Button/Button';
import { T } from '../../components/T';
import { logoutUser } from '../../redux/slices/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getStripePricingTable } from '../../helpers/stripe';
import { getVerificationDataApi } from '../../api/endpoints';
import { setVerificationInfo } from '../../redux/slices/userSlice';

const PaymentPending = () => {
  const dispatch = useDispatch();
  const myProfile = useSelector((state) => state.userReducer.myProfile);
  const myPackage = useSelector((state) => state.userReducer.myPackage);
  const userVerificationInfo = useSelector((state) => state.userReducer.verificationInfo);
  const pricingTableData = getStripePricingTable(true);
  const [hasDeterminedPaymentType, setHasDeterminedPaymentType] = useState(false);

  useEffect(() => {
    getVerificationDataApi({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((res) => dispatch(setVerificationInfo(res?.data?.item)))
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    if (userVerificationInfo?.state) {
      if (userVerificationInfo?.verificationData?.stripe?.paymentLink) {
        window.location.href = userVerificationInfo?.verificationData?.stripe?.paymentLink;
      } else {
        setHasDeterminedPaymentType(true);
      }
    }
  }, [userVerificationInfo?.state]);

  return hasDeterminedPaymentType ? (
    <div className='payment-pending-container'>
      {pricingTableData?.tableId && (
        <stripe-pricing-table
          client-reference-id={myPackage?.clientId}
          customer-email={myProfile?.email}
          pricing-table-id={pricingTableData?.tableId}
          publishable-key={pricingTableData?.tablePublishableKey}
        ></stripe-pricing-table>
      )}
      <div className='payment-pending-button-login'>
        <Button
          size='md'
          onClick={() => {
            localStorage.clear();
            dispatch(logoutUser());
            window.location.replace('/');
          }}
        >
          <T>app.back.to.login</T>
        </Button>
      </div>
    </div>
  ) : (
    <div className='pricing-page-loading-container'>
      <ReactLoading type='spin' color='##020D1C' />
    </div>
  );
};

export default PaymentPending;
