import VModal from '../Modal/VModal';
import PendingApprovalIcon from '../../assets/icons/pending-approval.svg';
import Button from '../Button/Button';
import { T } from '../T';

const ActionModal = ({ toggleFunction, children }) => {
  return (
    <VModal toggleFunction={toggleFunction} classHandler='action-modal'>
      <div className='suceess-modal'>
        <div className='action-modal-icon-title'>
          <img src={PendingApprovalIcon} />
        </div>
        <span className='action-modal-heading'>
          <T>app.submitted.for.approval</T>
        </span>
        <div className='action-modal-content'>{children}</div>
        <div className='action-modal-button'>
          <Button variant='close' size='md' fullWidth onClick={() => toggleFunction(false)}>
            <T>app.close</T>
          </Button>
        </div>
      </div>
    </VModal>
  );
};

export default ActionModal;
