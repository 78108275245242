export const ROUTE_HOME = '/';
export const ROUTE_VERIFY_EMAIL = '/verify-email';
export const ROUTE_PENDING_APPROVAL = '/pending-approval';
export const ROUTE_LOGIN_ATTEMPT = '/login-attempt';
export const ROUTE_LOGIN = '/login';
export const ROUTE_PAYMENT = '/payment';
export const ROUTE_WALLETS = '/vaults';
export const ROUTE_REGISTER = '/register';
export const ROUTE_VERIFY_MOBILE = '/verify-mobile';
export const ROUTE_DOWNLOAD_APP = '/download-app';
export const ROUTE_DASHBOARD = '/dashboard';
export const ROUTE_FORGOT = '/forgot-password';
export const ROUTE_RESET = '/reset-password/*';
export const ROUTE_TRX_REQUESTS = '/transactions-requests';
export const ROUTE_TRX_HISTORY = '/transactions-history';
export const ROUTE_CONTACTS = '/contacts';
export const ROUTE_TRX_POLICY = '/governance-layer';
export const ROUTE_TEAM = '/team';
export const ROUTE_DEVELOPERS = '/developers';
export const ROUTE_WEBHOOK = '/webhook';
export const ROUTE_WALLET = '/vault';
export const ROUTE_PROFILE = '/profile';
export const ROUTE_LOGIN_HISTORY = '/login-history';
export const ROUTE_BILLING = '/billing';
export const ROUTE_PRICING_TABLE = '/pricing-table';
export const ROUTE_ERROR = '/error';
export const ROUTE_TRX_REQUESTS_SHELF = '/transaction-shelf';
