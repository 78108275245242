import { useRef, useState } from 'react';
import { useClickOutside } from '../../helpers/hooks/useClickOutside';
import './DropDownMenu.css';
import { T } from '../T';
import iconHelp from '../../assets/images/help-icon.svg';
import { helpMenuData } from '../../helpers/constants';
import carouselIcon from '../../assets/icons/carousel-icon.svg';
import { Link } from 'react-router-dom';

const DropDownHelpMenu = () => {
  const [open, setOpen] = useState(false);

  const wrapperDown = useRef('dropdown');
  useClickOutside(wrapperDown, () => {
    setOpen(false);
  });
  return (
    <div
      className='v-dropdown-menu'
      ref={wrapperDown}
      onClick={() => {
        setOpen(!open);
      }}
    >
      <div className='v-dropdown-main'>
        <span className='main-icon'>?</span>
        <span>Help</span>
        <img className={`dropdown-carousel-icon ${open ? 'open' : ''}`} src={carouselIcon} />
      </div>
      {open && (
        <div className='v-dropdown-data'>
          {helpMenuData.map((menu, key) => (
            <div key={key} className='drop-down-block'>
              <div className='title'>
                <img src={menu.menuIcon} height={12} className='menu-icon' />
                <T>{menu.menuName}</T>
              </div>
              {menu?.menuItems?.length > 0 &&
                menu.menuItems.map((menuItem, index) => {
                  return (
                    <Link key={menuItem?.link} to={menuItem?.link} target='_blank'>
                      <div className={`data-dropdown help ${index === 0 ? 'first' : ''}`}>
                        <span className='help-menu-link'>
                          <T>{menuItem.title}</T>
                        </span>
                        <img src={iconHelp} />
                      </div>
                    </Link>
                  );
                })}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default DropDownHelpMenu;
