import { useEffect, useState } from 'react';
import { vaultodyDate } from '../../../helpers/date';
import PendingRemove from '../../../assets/icons/pending-remove.svg';
import ActionMenu from '../../ActionMenu/ActionMenu';
import ReactLoading from 'react-loading';
import { filterArray } from '../../../helpers/array';
import RemoveAPIModal from '../../Modals/RemoveAPIModal';
import { T, t } from '../../T';
import { useSelector } from 'react-redux';
import CopyButton from '../../CopyButton/CopyButton';
import { getApiKeysApi, getApiVersionsApi, updateApiVersionApi } from '../../../api/endpoints';
import pendingIcon from '../../../assets/icons/statuses/status-pending.svg';
import signedIcon from '../../../assets/icons/statuses/status-signed.svg';
import Button from '../../Button/Button';
import WalletsTable from '../../GovernanceLayer/WalletsTable';
import VTooltip from '../../VTooltip/VTooltip';
import deleteIcon from '../../../assets/icons/delete-icon.svg';
import editIcon from '../../../assets/icons/pencil-icon.svg';
import { ReactComponent as EditIcon2 } from '../../../assets/icons/edit-icon.svg';
import ApiVersionChooseModal from '../../Pages/Developers/ApiVersionChooseModal/ApiVersionChooseModal';
import CreateApiKeyModal from '../../Modals/CreateApiKeyModal/CreateApiKeyModal';

const APITable = () => {
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const typeUser = useSelector((state) => state.userReducer.typeUser);
  const myProfile = useSelector((state) => state.userReducer.myProfile);
  const messageSocket = useSelector((state) => state.globalReducer.messageSocket);
  const trialBannerVisible = useSelector((state) => state.globalReducer.trialBannerVisible);
  const [apiList, setApiList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openCraeteModal, setOpenCreateModal] = useState(false);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openApiVersionModal, setOpenApiVersionModal] = useState(false);
  const [removeData, setRemoveData] = useState({});
  const [editData, setEditData] = useState({});
  const [apiVersions, setApiVersions] = useState();
  const createApiKeyPermission = myProfile.premmisions?.includes('API_KEYS_CREATE');
  const updateApiKeyPermission = myProfile.premmisions?.includes('API_KEYS_UPDATE');
  const deleteApiKeyPermission = myProfile.premmisions?.includes('API_KEYS_DELETE');
  const updateApiVersionPermission = myProfile.premmisions?.includes('API_VERSION_UPDATE');

  const openRemoveModalHandler = (value) => {
    setRemoveData(value);
    setOpenRemoveModal(true);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const openEditModalHandler = (value) => {
    setEditData(value);
    setOpenEditModal(true);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const getAPIKeys = () => {
    getApiKeysApi({ params: { limit: 100 } })
      .then((res) => {
        setApiList(res.data.items);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const getApiVersions = async () => {
    return getApiVersionsApi().then((res) => {
      setApiVersions(res?.data?.items);
    });
  };

  const updateDefaultApiVersion = async (apiVersion) => {
    updateApiVersionApi({ item: { apiVersionId: apiVersion?.id } }).then(() => {
      getApiVersions();
      setOpenApiVersionModal(false);
    });
  };

  useEffect(() => {
    getApiVersions();
  }, []);

  useEffect(() => {
    getAPIKeys();
  }, []);

  useEffect(() => {
    if (typeUser) {
      getAPIKeys();
    }
  }, [typeUser]);

  useEffect(() => {
    if (typeof messageSocket === 'object' && typeUser) {
      if (
        messageSocket?.data?.event === 'api_key_creation_approved' ||
        messageSocket?.data?.event === 'api_key_creation_rejected' ||
        messageSocket?.data?.event === 'api_key_update_approved'
      ) {
        getAPIKeys();
      }
    }
  }, [messageSocket, typeUser]);

  // TODO: Need to see how backend status is comming
  const stautsShow = (stautsUser) => {
    if (stautsUser === 'pending-delete') {
      return (
        <div className='status-bar'>
          <img className='status-icon' src={PendingRemove} alt='Mobile sync' />
          <div className='status-name'>
            <T>app.pending.delete</T>
          </div>
        </div>
      );
    } else if (stautsUser === 'pending_creation') {
      return (
        <div className='status-bar'>
          <img className='status-icon' src={pendingIcon} alt='Mobile sync' />
          <div className='status-name'>
            <T>app.pending</T>
          </div>
        </div>
      );
    } else if (stautsUser === 'pending') {
      return (
        <div className='status-bar'>
          <img className='status-icon' src={pendingIcon} alt='Mobile sync' />
          <div className='status-name'>
            <T>app.pending.approval</T>
          </div>
        </div>
      );
    } else {
      return (
        <div className='status-bar'>
          <img className='status-icon' src={signedIcon} alt='Mobile sync' />
          <div className='status-name'>
            <T>app.active</T>
          </div>
        </div>
      );
    }
  };

  return loading ? (
    <div className='team-page '>
      <div className='loading'>
        <ReactLoading type='spin' color='##020D1C' />
      </div>
    </div>
  ) : (
    <div className='api-table team-page'>
      <div className='api-table-title-wrapper'>
        <div className='titles-info'>
          <div className='title'>
            <T>app.standartd.keys</T>
          </div>
          <div className='subtitle'>
            <T>app.this.key.allow.api.req</T>
          </div>
        </div>
        <div className='api-table-api-version-button-wrapper'>
          <div className='api-table-api-version-wrapper'>
            <span className='api-table-api-version-trans-text'>
              <T>app.your.default.api.version</T>
            </span>
            <span className='api-table-api-version-text'>
              {apiVersions?.find((apiVersion) => apiVersion?.isClientDefault === true)?.name}
            </span>
            <div
              className='api-table-api-version-icon-wrapper'
              data-tooltip-id='api-table-api-version'
              data-tooltip-content={
                updateApiVersionPermission
                  ? t(lng, 'app.only.one.api.version')
                  : t(lng, 'app.role.limitation')
              }
              onClick={() => {
                if (apiVersions?.length > 1 && updateApiVersionPermission) {
                  setOpenApiVersionModal(true);
                }
              }}
            >
              <EditIcon2
                className={`api-table-api-version-icon ${
                  apiVersions?.length > 1 && updateApiVersionPermission ? '' : 'disabled'
                }`}
              />
              {(apiVersions?.length < 1 || apiVersions?.length === 0 || !updateApiVersionPermission) && (
                <VTooltip id='api-table-api-version' />
              )}
            </div>
          </div>
          {myProfile.premmisions && (
            <div className='button-holder'>
              <Button
                data-tooltip-id='create-api-key-button'
                data-tooltip-content={t(lng, 'app.role.limitation')}
                onClick={() => setOpenCreateModal(true)}
                disabled={!createApiKeyPermission}
                size='md'
              >
                {!createApiKeyPermission && <VTooltip id='create-api-key-button' />}
                <i className='icon-key' />
                <T>app.create.api.key</T>
              </Button>
            </div>
          )}
        </div>
      </div>

      <div
        className={`table governance-layer-table api-table ${
          trialBannerVisible ? 'trial-banner-visible' : ''
        }`}
      >
        <div className='table-row caption'>
          <div className='api-keys-table-data name'>
            <T>app.name</T>
          </div>
          <div className='api-keys-table-data key'>
            <T>app.api.keys</T>
          </div>
          <div className='api-keys-table-data wallets'>
            <T>app.wallets</T>
          </div>

          <div className='api-keys-table-data created-at'>
            <T>app.last.used</T>
          </div>
          <div className='api-keys-table-data created-at'>
            <T>app.created</T>
          </div>
          <div className='api-keys-table-data status'>
            <T>app.status</T>
          </div>
          <div className='api-keys-table-data settings' />
        </div>
        {apiList.length > 0 &&
          filterArray(apiList, typeUser?.toUpperCase(), 'type').map((api, key) => {
            return (
              <div key={key} className='table-row data'>
                <div className='api-keys-table-data name'>
                  <span className='mobile-cell-title'>
                    <T>app.name</T>
                  </span>
                  <span data-tooltip-id='api-key-name' data-tooltip-content={api?.name}>
                    <VTooltip id='api-key-name' />
                    {api.name}
                  </span>
                </div>
                <div className='api-keys-table-data key'>
                  <span className='mobile-cell-title'>
                    <T>app.api.keys</T>
                  </span>
                  <div className='api-keys-table-data-flex'>
                    <div data-tooltip-id='api-key' data-tooltip-content={api?.key} className='api-key-text'>
                      <VTooltip id='api-key' />
                      {api.key}
                    </div>
                    <CopyButton element={api.key} />
                  </div>
                </div>
                <div className='api-keys-table-data wallets'>
                  <span className='mobile-cell-title'>
                    <T>app.wallets</T>
                  </span>
                  <div className='api-keys-table-data-flex'>
                    {api?.wallets.length !== 0 ? (
                      api?.wallets.map((wallet, key) => {
                        return <WalletsTable wallet={wallet} keyWallet={key} key={key} />;
                      })
                    ) : (
                      <>N/A</>
                    )}
                    {api?.wallets.length !== 0 && api?.wallets[3] && (
                      <div className='wallet-icon plus'>{`+ ${api.wallets.length - 3}`}</div>
                    )}
                  </div>
                </div>
                <div className='api-keys-table-data created-at'>
                  <span className='mobile-cell-title'>
                    <T>app.last.used</T>
                  </span>
                  {vaultodyDate(api.lastUsedAt, 'full')}
                </div>
                <div className='api-keys-table-data created-at'>
                  <span className='mobile-cell-title'>
                    <T>app.created</T>
                  </span>
                  {vaultodyDate(api.createdAt, 'full')}
                </div>
                <div className='api-keys-table-data status'>
                  <span className='mobile-cell-title'>
                    <T>app.status</T>
                  </span>
                  {stautsShow(api.status)}
                </div>
                <div className='api-keys-table-data settings'>
                  {api.status === 'active' && (
                    <>
                      <span className='mobile-cell-title'>
                        <T>app.settings</T>
                      </span>
                      <ActionMenu
                        data={api}
                        items={[
                          {
                            name: t(lng, 'app.edit.api.key'),
                            icon: editIcon,
                            permission: updateApiKeyPermission,
                            callback: openEditModalHandler,
                          },
                          {
                            name: t(lng, 'app.remove.api.key'),
                            icon: deleteIcon,
                            permission: deleteApiKeyPermission,
                            callback: openRemoveModalHandler,
                          },
                        ]}
                      />
                    </>
                  )}
                </div>
              </div>
            );
          })}
        {filterArray(apiList, typeUser?.toUpperCase(), 'type').length === 0 && (
          <div className='table-row no-active'>
            <div>
              <T>app.no.api</T>{' '}
            </div>
          </div>
        )}
      </div>
      {openCraeteModal && (
        <CreateApiKeyModal successCallback={getAPIKeys} toggleFunction={setOpenCreateModal} />
      )}
      {openApiVersionModal && (
        <ApiVersionChooseModal
          toggleFunction={() => setOpenApiVersionModal(false)}
          updateApiVersion={(apiVersion) => updateDefaultApiVersion(apiVersion)}
          apiVersions={apiVersions}
        />
      )}
      {openRemoveModal && (
        <RemoveAPIModal toggleFunction={() => setOpenRemoveModal(false)} data={removeData} />
      )}
      {openEditModal && (
        <CreateApiKeyModal
          successCallback={getAPIKeys}
          toggleFunction={setOpenEditModal}
          editData={editData}
        />
      )}
    </div>
  );
};

export default APITable;
