import './Accordion.css';
import carouselIcon from '../../assets/icons/carousel-icon.svg';

const Accordion = ({ titleComponent, detailsComponent, onOpenCallback, open }) => {
  return (
    <div className='accordion-component'>
      <div
        className={`accordion-title-wrapper ${open ? 'open' : ''}`}
        onClick={() => {
          if (onOpenCallback) {
            onOpenCallback(open);
          }
        }}
      >
        <img src={carouselIcon} className={`accordion-carousel ${open ? 'open' : ''}`} />
        {titleComponent}
      </div>
      {detailsComponent && (
        <div className={`accordion-details-wrapper ${open ? 'open' : 'false'}`}>{detailsComponent}</div>
      )}
    </div>
  );
};

export default Accordion;
