import { logoutUser } from '../../redux/slices/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/Button/Button';
import { T } from '../../components/T';
import './PricingPage.css';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { getSessionClientSecretApi } from '../../api/endpoints';
import { getStripePricingTable } from '../../helpers/stripe';
import { ROUTE_DASHBOARD } from '../../routes/routes';

const PricingPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userVerificationInfo = useSelector((state) => state.userReducer.verificationInfo);
  const [loading, setLoading] = useState(false);
  const [customerSessionClientSecret, setCustomerSessionClientSecret] = useState();
  const pricingTableData = getStripePricingTable(false);

  useEffect(() => {
    setLoading(true);
    getSessionClientSecretApi()
      .then((res) => setCustomerSessionClientSecret(res?.data?.item?.secret))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (userVerificationInfo?.verificationData?.stripe?.subscriptionStatus?.toLowerCase() !== 'canceled') {
      navigate(ROUTE_DASHBOARD);
    }
  }, [userVerificationInfo]);

  return !loading ? (
    <div className='pricing-page-container'>
      <div className='pricing-page-button-login'>
        <Button
          size='md'
          onClick={() => {
            localStorage.clear();
            dispatch(logoutUser());
            window.location.replace('/');
          }}
        >
          <T>app.back.to.login</T>
        </Button>
      </div>
      {customerSessionClientSecret && pricingTableData?.tableId && (
        <stripe-pricing-table
          customer-session-client-secret={customerSessionClientSecret}
          pricing-table-id={pricingTableData?.tableId}
          publishable-key={pricingTableData?.tablePublishableKey}
        ></stripe-pricing-table>
      )}
      {!customerSessionClientSecret && (
        <span className='pricing-page-something-went-wrong'>app.something.went.wrong</span>
      )}
    </div>
  ) : (
    <div className='pricing-page-loading-container'>
      <ReactLoading type='spin' color='##020D1C' />
    </div>
  );
};

export default PricingPage;
