export const getSenderAndReceiversWalletNames = (transaction, isTransactionRequests, isDraft) => {
  if (transaction?.transaction?.direction === 'vault-internal') {
    return {
      receiver: {
        name: transaction.wallet?.name,
        color: transaction?.wallet?.color,
        address: transaction?.recipients?.[0]?.address,
        vaultType: transaction?.wallet?.vaultType,
      },
      sender: {
        name: transaction.wallet?.name,
        color: transaction?.wallet?.color,
        address: Array.isArray(transaction.sender) ? transaction.sender[0] : transaction.sender,
        vaultType: transaction?.wallet?.vaultType,
      },
    };
  }
  if (
    transaction?.transaction?.innerDirection === 'outgoing' &&
    transaction?.transaction?.direction === 'client-internal'
  ) {
    const relatedVaultInfo = transaction?.clientRelatedVaults?.[0];
    return {
      receiver: {
        name: relatedVaultInfo?.name,
        color: relatedVaultInfo?.color,
        address: relatedVaultInfo?.address,
        vaultType: relatedVaultInfo?.vaultType,
      },
      sender: {
        name: transaction.wallet?.name,
        color: transaction?.wallet?.color,
        address: Array.isArray(transaction.sender) ? transaction.sender[0] : transaction.sender,
        vaultType: transaction?.wallet?.vaultType,
      },
    };
  }
  if (
    transaction?.transaction?.innerDirection === 'incoming' &&
    transaction?.transaction?.direction === 'client-internal'
  ) {
    const relatedVaultInfo =
      transaction?.clientRelatedVaults?.find((vault) => vault?.address === transaction?.sender) ||
      transaction?.clientRelatedVaults?.[0];
    return {
      sender: {
        name: relatedVaultInfo?.name,
        color: relatedVaultInfo?.color,
        address: Array.isArray(transaction.sender) ? transaction.sender[0] : transaction.sender,
        vaultType: relatedVaultInfo?.vaultType,
      },
      receiver: {
        name: transaction.wallet?.name,
        color: transaction?.wallet?.color,
        address: transaction?.recipients?.[0]?.address,
        vaultType: transaction?.wallet?.vaultType,
      },
    };
  }
  if (transaction?.transaction?.direction === 'incoming') {
    return {
      sender: {
        address: Array.isArray(transaction.sender) ? transaction.sender[0] : transaction.sender,
        ...(transaction?.senderContactName ? { name: transaction?.senderContactName } : {}),
      },
      receiver: {
        name: transaction.wallet?.name,
        color: transaction?.wallet?.color,
        address: transaction?.recipients?.[0]?.address,
        vaultType: transaction?.wallet?.vaultType,
      },
    };
  }
  if (transaction?.transaction?.direction === 'outgoing') {
    return {
      receiver: {
        address: transaction?.recipients?.[0]?.address,
        ...(transaction?.recipients?.[0]?.contactName
          ? { name: transaction?.recipients?.[0]?.contactName }
          : {}),
      },
      sender: {
        name: transaction.wallet?.name,
        color: transaction?.wallet?.color,
        address: Array.isArray(transaction.sender) ? transaction.sender[0] : transaction.sender,
        vaultType: transaction?.wallet?.vaultType,
      },
    };
  }
  if (isDraft) {
    return {
      receiver: {
        name: transaction.recipients?.[0]?.name || transaction.recipients?.[0]?.contactName,
        color: transaction.recipients?.[0]?.color,
        address: transaction.recipients?.[0]?.address,
        vaultType: transaction.recipients?.[0]?.vaultType,
      },
      sender: {
        name: transaction?.senders?.[0]?.name,
        color: transaction?.senders?.[0]?.color,
        address: transaction.senders?.[0]?.address,
        vaultType: transaction?.senders?.[0]?.vaultType,
      },
    };
  }
  if (!Object.keys(transaction?.transaction || {})?.length) {
    const receiverVault = isTransactionRequests
      ? transaction?.recipients?.[0]?.contactName
        ? { name: transaction?.recipients?.[0]?.contactName }
        : transaction?.clientRelatedVaults?.[0]
      : undefined;
    return {
      receiver: {
        name: receiverVault?.name,
        color: receiverVault?.color,
        address: transaction?.recipients?.[0]?.address,
      },
      sender: {
        name: transaction.wallet?.name,
        color: transaction?.wallet?.color,
        address: Array.isArray(transaction.sender) ? transaction.sender[0] : transaction.sender,
      },
    };
  }
  return {
    receiver: undefined,
    sender: undefined,
  };
};
