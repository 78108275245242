import './Error.css';
import { useSelector } from 'react-redux';
import { T } from '../../components/T';
import Lottie from 'react-lottie';
import * as animationData from '../../helpers/lotties/error-404.json';

const Error = () => {
  const trialBannerVisible = useSelector((state) => state.globalReducer.trialBannerVisible);

  return (
    <div className={`error-page-table-wrapper ${trialBannerVisible ? 'trial-banner-visible' : ''}`}>
      <h2 className='error-page-label'>
        <T>app.not.found</T>
      </h2>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: animationData,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        height={200}
        width={600}
      />
    </div>
  );
};
export default Error;
