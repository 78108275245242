import VModal from '../Modal/VModal';
import EXPImg from '../../assets/images/session-expired.svg';
import { T } from '../T';
import Button from '../Button/Button';
const ExpirationModal = ({ closeFunction }) => {
  return (
    <VModal classHandler='exp-modal' toggleFunction={closeFunction}>
      <div>
        <img src={EXPImg} alt='exp' />
      </div>
      <div className='v-modal-title'>
        <T>app.your.session.has.exp</T>
      </div>
      <div className='v-modal-subtitle'>
        <T>app.have.been.inactive.10.minutes</T>
      </div>
      <Button size='md' fullWidth onClick={closeFunction}>
        <T>app.back.to.login</T>
      </Button>
    </VModal>
  );
};

export default ExpirationModal;
