import VModal from '../../Modal/VModal';
import './SelfOnboardingModal.css';
import SelfOnboardingModalCard from './SelfOnboardingModalCard';
import requiredStepsIcon from '../../../assets/icons/required-steps.svg';
import emailSentIcon from '../../../assets/icons/email-sent-icon.svg';
import { T, t } from '../../T';
import { useDispatch, useSelector } from 'react-redux';
import { clientStatuses } from '../../../redux/slices/userSlice';
import { vaultodyDate } from '../../../helpers/date';
import SumsubWebSdk from '@sumsub/websdk-react';
import { getStripeCheckoutLink, getSumSubTokenApi, sendZohoContractEmail } from '../../../api/endpoints';
import { useState } from 'react';
import * as moment from 'moment-timezone';
import { ROUTE_HOME } from '../../../routes/routes';
import { logoutUser } from '../../../redux/slices/authSlice';

const SelfOnboardingModal = ({ closeFunction }) => {
  const dispatch = useDispatch();
  const myProfile = useSelector((state) => state.userReducer.myProfile);
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const userVerificationInfo = useSelector((state) => state.userReducer.verificationInfo);
  const [sumSubAccessToken, setSumSubAccessToken] = useState();
  const [zohoEmailSent, setZohoEmailSent] = useState(false);
  const [zohoLoading, setZohoLoading] = useState(false);

  if (!myProfile) return null;
  const isOwner = myProfile.roles?.some((i) => {
    return i.name === 'owner';
  });

  const zohoHandler = async () => {
    setZohoLoading(true);
    return sendZohoContractEmail()
      .then(() => setZohoEmailSent(true))
      .finally(() => setZohoLoading(false));
  };

  const stripeHandler = async () => {
    return getStripeCheckoutLink({ params: { trial: false } }).then((res) => {
      if (res?.data?.item?.url) {
        window.location.href = res?.data?.item?.url;
      }
    });
  };

  const sumSubAccessTokenExpirationHandler = async () => {
    return getSumSubTokenApi().then((res) => {
      setSumSubAccessToken(res?.data?.item?.accessToken);
      return res?.data?.item?.accessToken;
    });
  };

  const renderTrialDateInfo = () => {
    if (
      moment()
        .tz(new Intl.DateTimeFormat().resolvedOptions().timeZone)
        .isAfter(
          moment(userVerificationInfo?.verificationData?.stripe?.trialExpirationDate * 1000).tz(
            new Intl.DateTimeFormat().resolvedOptions().timeZone,
          ),
        )
    ) {
      return null;
    }
    if (
      userVerificationInfo?.state?.toUpperCase() === clientStatuses.TRIAL ||
      userVerificationInfo?.state?.toUpperCase() === clientStatuses.PROVISIONAL
    ) {
      return (
        <div className='onboarding-modal-trial-wrapper'>
          <T>app.trial.expire</T>
          <div>
            {vaultodyDate(userVerificationInfo?.verificationData?.stripe?.trialExpirationDate, 'full')}
          </div>
        </div>
      );
    }
    return (
      <div className='onboarding-modal-trial-wrapper'>
        <div>
          <T>app.trial.expired</T>
        </div>
      </div>
    );
  };

  return (
    <VModal toggleFunction={closeFunction} classHandler='self-onboarding-modal'>
      {sumSubAccessToken ? (
        <SumsubWebSdk
          accessToken={sumSubAccessToken}
          expirationHandler={sumSubAccessTokenExpirationHandler}
          onApplicantSubmitted={() => setSumSubAccessToken(null)}
          onApplicantResubmitted={() => setSumSubAccessToken(null)}
          config={{
            lang: 'en',
            email: myProfile?.email,
            theme: 'light',
          }}
          onMessage={(e) => console.log(e)}
          onError={(e) => console.log(e)}
        />
      ) : (
        <>
          <div className='onboard-icon-wrapper'>
            <img className='onboard-modal-image' src={requiredStepsIcon} />
          </div>
          <span className='onboarding-modal-text'>
            <T>app.trial.steps</T>
          </span>
          <span className='onboarding-modal-sub-text'>
            <T>app.trial.completed.by.owner</T>
          </span>
          {renderTrialDateInfo()}
          <div className='cards-wrapper'>
            <SelfOnboardingModalCard
              type='sumsub'
              status={userVerificationInfo?.verificationData?.sumSub?.state}
              isOwner={isOwner}
              callback={sumSubAccessTokenExpirationHandler}
              tooltipText={t(lng, 'app.should.complete.by.owner')}
              disabled={userVerificationInfo?.verificationData?.sumSub?.state?.toUpperCase() === 'COMPLETED'}
            />
            <SelfOnboardingModalCard
              type='contract'
              status={userVerificationInfo?.verificationData?.zoho?.state}
              isOwner={isOwner}
              callback={zohoHandler}
              loading={zohoLoading}
              tooltipText={t(lng, 'app.should.complete.by.owner')}
              disabled={userVerificationInfo?.verificationData?.zoho?.state?.toUpperCase() === 'SIGNED'}
            />
            <SelfOnboardingModalCard
              type='payment'
              status={!userVerificationInfo?.verificationData?.stripe?.paid ? 'NOT_RECEIVED' : 'RECEIVED'}
              isOwner={isOwner}
              callback={stripeHandler}
              tooltipText={t(lng, 'app.should.complete.by.owner')}
              disabled={userVerificationInfo?.verificationData?.stripe?.paid === true}
            />
          </div>
          {zohoEmailSent && (
            <div className='onboard-modal-email-sent'>
              <img src={emailSentIcon} />
              <T>app.email.sent</T>
            </div>
          )}
          {userVerificationInfo?.state?.toUpperCase() === clientStatuses.TRIAL ||
            (userVerificationInfo?.state?.toUpperCase() === clientStatuses.PROVISIONAL && (
              <button className='onboard-modal-button' onClick={closeFunction}>
                <T>app.close</T>
              </button>
            ))}
          {userVerificationInfo?.state?.toUpperCase() === clientStatuses.SUSPENDED && (
            <button
              className='onboard-modal-button-sign-in'
              onClick={() => {
                localStorage.clear();
                dispatch(logoutUser());
                window.location.replace(ROUTE_HOME);
              }}
            >
              <T>app.back.to.login</T>
              {/* Back To Sign In */}
            </button>
          )}
        </>
      )}
    </VModal>
  );
};

export default SelfOnboardingModal;
