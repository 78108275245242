import { vaultodyDate } from '../../helpers/date';
import Pending from '../../assets/icons/pending-icon.svg';
import { rolesShow } from '../../helpers/roles';
import { T, t } from '../T';
import { useSelector } from 'react-redux';
import Button from '../Button/Button';
import VTooltip from '../VTooltip/VTooltip';

const TeamPendingTable = ({ data = [], cancelHandler, rolesAll = [] }) => {
  const myProfile = useSelector((state) => state.userReducer.myProfile);
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const trialBannerVisible = useSelector((state) => state.globalReducer.trialBannerVisible);
  const teamDeletePermission = myProfile.premmisions?.includes('TEAM_MEMBERS_DELETE_INVITATION');

  return (
    <div
      className={`table governance-layer-table team-table ${
        trialBannerVisible ? 'trial-banner-visible' : ''
      }`}
    >
      <div className='table-content'>
        <div className='table-row caption'>
          <div className='pending-table-data name'>
            <T>app.email</T>
          </div>
          <div className='pending-table-data role'>
            <T>app.roles</T>
          </div>
          <div className='pending-table-data date'>
            <T>app.invite.sent</T>
          </div>
          <div className='pending-table-data status'>
            <T>app.status</T>
          </div>
          <div className='pending-table-data settings' />
        </div>
        {data.map((dataItem, key) => {
          return (
            <div key={key} className='table-row data'>
              <div className='pending-table-data name'>
                <span className='mobile-cell-title'>
                  <T>app.name.email</T>
                </span>
                <div>
                  <div className='name-data'>
                    <div className='icon-wrapper'>
                      <i className='icon-contact' />
                    </div>
                    <div className='name-right'>
                      <div className='name'>{dataItem.email}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='pending-table-data role'>
                {' '}
                <span className='mobile-cell-title'>
                  <T>app.roles</T>
                </span>
                {rolesShow(rolesAll, dataItem.roles)}
              </div>
              <div className='pending-table-data date'>
                <span className='mobile-cell-title'>
                  <T>app.date.created</T>
                </span>
                {vaultodyDate(dataItem.invitationSentAt, 'full')}
              </div>
              <div className='pending-table-data status'>
                <span className='mobile-cell-title'>
                  <T>app.status</T>
                </span>
                <div className='status-bar'>
                  <img className='status-bar-icon' src={Pending} />
                  <div className='status-name'>
                    {dataItem.status.charAt(0).toUpperCase() + dataItem.status.slice(1).replace('-', ' ')}
                  </div>
                </div>
              </div>
              <div className='pending-table-data settings'>
                <Button
                  variant='neutral'
                  onClick={() => cancelHandler(dataItem)}
                  disabled={!teamDeletePermission}
                  size='sm'
                  data-tooltip-id='team-delete'
                  data-tooltip-content={t(lng, 'app.role.limitation')}
                >
                  {!teamDeletePermission && <VTooltip id='team-delete' />}
                  <T>app.cancel.invite</T>
                </Button>
              </div>
            </div>
          );
        })}

        {data.length === 0 && (
          <div className='table-row no-active'>
            <div>
              <T>app.no.pending.team.users</T>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TeamPendingTable;
