import './Radio.css';
import checkmarkIcon from '../../assets/icons/checkmark.svg';
import VaultIcon from '../VaultIcon/VaultIcon';

const Radio = ({
  value = false,
  onChange,
  label,
  item,
  id,
  color,
  displayIcon = false,
  roundedIcon = false,
  customIcon,
  disabledHoverEffect,
  reverse, // Reverses icon and label
}) => {
  const handleClick = () => {
    if (onChange && id) {
      onChange(id);
    }
  };

  return (
    <div
      className={`${!value ? 'radio-group-new' : 'radio-group-new checked'} ${
        disabledHoverEffect ? 'disabledHover' : ''
      }`}
      onClick={handleClick}
    >
      <div htmlFor={id} className={`radio-value-new ${reverse ? 'reversed' : ''}`} value={id}>
        {label && (
          <>
            {displayIcon && !customIcon && (
              <VaultIcon
                id={id}
                name={label}
                color={color}
                vaultType={item?.vaultType}
                className={`wallet-icon-new ${roundedIcon && 'round'}`}
              />
            )}
            {displayIcon && customIcon && (
              <img src={customIcon} className={`wallet-icon-new ${roundedIcon && 'round'}`} />
            )}
            <div className='radio-label-text'>{label}</div>
          </>
        )}
      </div>

      <div className='radio-button-wrapper'>
        <input id={id} type='radio-checkmark-new' />
        <div className='radio-checkmark-new'>
          {value && <img className='radio-checkmark-icon' src={checkmarkIcon} />}
        </div>
      </div>
    </div>
  );
};

export default Radio;
