import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { t } from '../T';
import SocialIcons from '../SocialIcons/SocialIcons';
import { ReactComponent as AssetsIcon } from '../../assets/icons/dashboardMenu/assets-icon.svg';
import { ReactComponent as VaultsIcon } from '../../assets/icons/dashboardMenu/vaults-icon.svg';
import { ReactComponent as RequestsIcon } from '../../assets/icons/dashboardMenu/requests-icon.svg';
import { ReactComponent as TrxHistoryIcon } from '../../assets/icons/dashboardMenu/trx-history-icon.svg';
import { ReactComponent as PolicyIcon } from '../../assets/icons/dashboardMenu/policy-icon.svg';
import { ReactComponent as TeamIcon } from '../../assets/icons/dashboardMenu/team-icon.svg';
import { ReactComponent as ContactsIcon } from '../../assets/icons/dashboardMenu/contacts-icon.svg';
import {
  ROUTE_BILLING,
  ROUTE_DEVELOPERS,
  ROUTE_LOGIN_HISTORY,
  ROUTE_PROFILE,
  ROUTE_HOME,
  ROUTE_TEAM,
  ROUTE_TRX_HISTORY,
  ROUTE_TRX_POLICY,
  ROUTE_TRX_REQUESTS,
  ROUTE_WALLETS,
  ROUTE_TRX_REQUESTS_SHELF,
} from '../../routes/routes';
import VTooltip from '../VTooltip/VTooltip';

const DashboardMenu = ({ hadleSetPage, link }) => {
  const lng = useSelector((state) => state.localizationReducer.selectedData);

  const [active, setActive] = useState(link);
  // Menu configuration
  const dashboardMenu = [
    {
      icon: <AssetsIcon className='icon' />,
      title: t(lng, 'app.assets'),
      link: ROUTE_HOME,
    },
    {
      icon: <VaultsIcon className='icon' />,
      title: t(lng, 'app.wallets'),
      link: ROUTE_WALLETS,
    },
    {
      icon: <RequestsIcon className='icon' />,
      title: t(lng, 'app.transaction.req'),
      link: ROUTE_TRX_REQUESTS,
      alternativeRoutes: ROUTE_TRX_REQUESTS_SHELF,
    },
    {
      icon: <TrxHistoryIcon className='icon' />,
      title: t(lng, 'app.trans.history'),
      link: ROUTE_TRX_HISTORY,
    },
    {
      icon: <ContactsIcon className='icon' />,
      title: t(lng, 'app.contacts'),
      link: '/contacts',
    },
    {
      icon: <PolicyIcon className='icon' />,
      title: t(lng, 'app.gov.leayer'),
      link: ROUTE_TRX_POLICY,
    },
    {
      icon: <TeamIcon className='icon' />,
      title: t(lng, 'app.team'),
      link: ROUTE_TEAM,
    },
  ];
  const location = useLocation();
  useEffect(() => {
    if (active.includes('wallet')) {
      setActive(ROUTE_WALLETS);
    }
  }, [active]);
  useEffect(() => {
    if (
      location.pathname.includes(ROUTE_DEVELOPERS) ||
      location.pathname.includes(ROUTE_PROFILE) ||
      location.pathname.includes(ROUTE_LOGIN_HISTORY) ||
      location.pathname.includes(ROUTE_BILLING)
    ) {
      setActive('');
    }
    if (location.pathname === ROUTE_HOME) {
      setActive(ROUTE_HOME);
      hadleSetPage(t(lng, 'app.assets'));
    }
    if (location.pathname.includes(ROUTE_TRX_POLICY)) {
      setActive(ROUTE_TRX_POLICY);
    }
  }, [location]);
  // Handle for Click Menu Item
  const hadleClickMenuItem = (menuLink, menuTitle) => {
    setActive(menuLink);
    hadleSetPage(t(lng, menuTitle));
  };
  return (
    <div className='menu'>
      <ul>
        {dashboardMenu.map((menuItem, key) => {
          return (
            <div key={key}>
              <Link to={menuItem.link}>
                <li
                  onClick={() => {
                    hadleClickMenuItem(menuItem.link, menuItem.title);
                  }}
                  className={
                    active === menuItem.link ||
                    (menuItem?.alternativeRoutes && active?.includes(menuItem?.alternativeRoutes))
                      ? 'active'
                      : ''
                  }
                  data-tooltip-content={menuItem.title}
                  data-tooltip-id={`${menuItem.title}-menu`}
                >
                  <VTooltip
                    className='dashboard-menu-item-tooltip'
                    id={`${menuItem.title}-menu`}
                    place='right'
                  />
                  {menuItem?.icon}
                  <div className='text'>{menuItem.title}</div>
                </li>
              </Link>
            </div>
          );
        })}
      </ul>
      <SocialIcons />
    </div>
  );
};
export default DashboardMenu;
