import React from 'react';
import './AssetAccordionTitle.css';
import { useSelector } from 'react-redux';
import { T } from '../../../T';
import { currency } from '../../../../helpers/currency';
import CryptoIcon from '../../../CryptoIcon/CryptoIcon';

const AssetAccordionTitle = ({ asset }) => {
  const protocols = useSelector((state) => state.globalReducer.protocols);

  return (
    <div className='asset-title-accordion-details-wrapper'>
      <div className='asset-title-accordion-details'>
        <div className='first-column'>
          <span className='mobile-cell-title'>
            <T>app.asset</T>
          </span>
          <div className='first-column-asset'>
            <div className='asset-title-accordion-symbol-wrapper'>
              <CryptoIcon className='main-symbol-icon' symbol={asset?.symbol} />
              {asset?.symbol?.toLowerCase() !==
                protocols
                  ?.find(
                    (protocol) => protocol?.blockchain?.toLowerCase() === asset?.blockchain?.toLowerCase(),
                  )
                  ?.currency?.toLowerCase() && (
                <CryptoIcon
                  className='secondary-symbol-icon'
                  symbol={
                    protocols?.find(
                      (protocol) => protocol?.blockchain?.toLowerCase() === asset?.blockchain?.toLowerCase(),
                    )?.currency
                  }
                />
              )}
            </div>
            <div className='name' title={asset?.assetName || asset?.symbol || <T>app.no.name</T>}>
              {asset?.assetName || asset?.symbol || <T>app.no.name</T>}
            </div>
          </div>
        </div>
        <div className='balance'>
          <span className='mobile-cell-title'>
            <T>app.available.balance</T>
          </span>
          <div className='balance-asset-wrapper'>
            <div className='text'>{currency(asset?.availableAmount * asset?.exchangeRate)}</div>
            <div className='sub-text'>
              {Number(asset?.availableAmount)
                ?.toFixed(8)
                ?.replace(/\.?0+$/, '')}{' '}
              {asset?.symbol}
            </div>
          </div>
        </div>
        <div className='balance'>
          <span className='mobile-cell-title'>
            <T>app.total.balance</T>
          </span>
          <div className='balance-asset-wrapper'>
            <div className='text'>{currency(asset?.totalAmount * asset?.exchangeRate)}</div>
            <div className='sub-text'>
              {Number(asset?.totalAmount)
                ?.toFixed(8)
                ?.replace(/\.?0+$/, '')}{' '}
              {asset?.symbol}
            </div>
          </div>
        </div>
        <div className='balance'>
          <span className='mobile-cell-title'>
            <T>app.aml.blocked</T>
          </span>
          <div className='balance-asset-wrapper'>
            <div className='text'>{currency(asset?.blockedAmount * asset?.exchangeRate)}</div>
            <div className='sub-text'>
              {Number(asset?.blockedAmount)
                ?.toFixed(8)
                ?.replace(/\.?0+$/, '')}{' '}
              {asset?.symbol}
            </div>
          </div>
        </div>
        <div className='balance'>
          <span className='mobile-cell-title'>
            <T>app.allocated</T>
          </span>
          <div className='balance-asset-wrapper'>
            <div className='text'>{currency(asset?.allocatedAmount * asset?.exchangeRate)}</div>
            <div className='sub-text'>
              {Number(asset?.allocatedAmount)
                ?.toFixed(8)
                ?.replace(/\.?0+$/, '')}{' '}
              {asset?.symbol}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetAccordionTitle;
