import React, { useEffect, useRef, useState } from 'react';
import { T, t } from '../../components/T';
import { ReactComponent as AtIcon } from '../../assets/icons/at.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as LockIcon } from '../../assets/icons/lock.svg';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTE_LOGIN, ROUTE_VERIFY_EMAIL } from '../../routes/routes';
import ReCAPTCHA from 'react-google-recaptcha';
import { STATE_VERIFY_EMAIL, dashboardState, loginUser } from '../../redux/slices/authSlice';
import { registerApi } from '../../api/endpoints';
import Textfield from '../../components/Textfield/Textfield';
import CheckboxNew from '../../components/CheckboxNew/CheckboxNew';
import Button from '../../components/Button/Button';
import { useForm, Controller } from 'react-hook-form';
import GuestLayout from '../../components/GuestLayout';
import { useCookies } from 'react-cookie';
import { passwordRegex } from '../../helpers/validation';

const SignUp = () => {
  const [cookies, setCookie] = useCookies(['plid', 'ztid']);
  const [paymentLinkId, setPaymentLinkId] = useState();
  const [zohoId, setZohoId] = useState();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const [isLoading, setIsLoading] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });

  const changeTermsHandler = () => {
    setTermsChecked((prev) => !prev);
  };

  const recaptchaRef = useRef();

  const signUpClickHandler = async (data) => {
    setIsLoading(true);
    const reCaptchaTokenRes = await recaptchaRef.current.executeAsync();
    registerApi({
      item: {
        email: data?.email,
        firstName: data?.firstName?.trim(),
        lastName: data?.lastName?.trim(),
        password: data?.password,
        reCaptchaToken: reCaptchaTokenRes,
        plid: paymentLinkId || null,
        ztid: zohoId || null,
        tc: true,
      },
    })
      .then((res) => {
        dispatch(loginUser(res.data.data.item.websocketToken));
        dispatch(dashboardState(STATE_VERIFY_EMAIL));
        return navigate(ROUTE_VERIFY_EMAIL);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (searchParams.get('plid') && searchParams.get('ztid')) {
      const oneDayFromNow = new Date();
      oneDayFromNow.setDate(oneDayFromNow.getDate() + 1);
      setCookie('plid', searchParams.get('plid'), { expires: oneDayFromNow });
      setCookie('ztid', searchParams.get('ztid'), { expires: oneDayFromNow });
      setPaymentLinkId(searchParams.get('plid'));
      setZohoId(searchParams.get('ztid'));
      window.history.pushState({}, null, '/register');
      return;
    }
    if (cookies?.plid && cookies?.ztid) {
      setPaymentLinkId(cookies?.plid);
      setZohoId(cookies?.ztid);
      return;
    }
  }, []);

  return (
    <GuestLayout>
      <h2 className='fw-bolder'>
        <T>app.sign-up-title</T>
      </h2>
      <form onSubmit={handleSubmit(signUpClickHandler)} className='signin-form-wrapper'>
        <div>
          <div className='first-name-last-name-wrapper'>
            <div className='guest-flow-textfield-wrapper'>
              <Controller
                name='firstName'
                control={control}
                rules={{
                  required: t(lng, 'app.first.name.req'),
                  validate: (value) => value?.trim()?.length > 1 || t(lng, 'app.first.name.validate'),
                }}
                render={({ field }) => {
                  return (
                    <>
                      <label className='guest-textfield-label'>
                        <T>app.first-name-placeholder</T>
                      </label>
                      <Textfield
                        id='fname'
                        type='text'
                        disabled={isLoading}
                        fullWidth
                        size='lg'
                        autoComplete='new-password'
                        icon={<UserIcon />}
                        error={errors?.firstName?.message}
                        placeholder={t(lng, 'app.first-name-placeholder')}
                        showValidationEndIcons
                        {...field}
                      />
                    </>
                  );
                }}
              />
            </div>
            <div className='guest-flow-textfield-wrapper'>
              <Controller
                name='lastName'
                control={control}
                rules={{
                  required: t(lng, 'app.last.name.req'),
                  validate: (value) => value?.trim()?.length > 1 || t(lng, 'app.last.name.validate'),
                }}
                render={({ field }) => {
                  return (
                    <>
                      <label className='guest-textfield-label'>
                        <T>app.last-name-placeholder</T>
                      </label>
                      <Textfield
                        id='lname'
                        type='text'
                        disabled={isLoading}
                        fullWidth
                        size='lg'
                        autoComplete='new-password'
                        icon={<UserIcon />}
                        error={errors?.lastName?.message}
                        placeholder={t(lng, 'app.last-name-placeholder')}
                        showValidationEndIcons
                        {...field}
                      />
                    </>
                  );
                }}
              />
            </div>
          </div>

          <div className={`mb-3`}>
            <Controller
              name='email'
              control={control}
              rules={{
                required: t(lng, 'app.email-is-required'),
                validate: (value) =>
                  /^[A-Z0-9._]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value) || t(lng, 'app.email-is-invalid'),
              }}
              render={({ field }) => {
                return (
                  <>
                    <label className='guest-textfield-label'>
                      <T>app.email-placeholder</T>
                    </label>
                    <Textfield
                      id='email'
                      trim
                      type='text'
                      disabled={isLoading}
                      icon={<AtIcon />}
                      size='lg'
                      fullWidth
                      autoComplete='new-password'
                      placeholder={t(lng, 'app.email-placeholder')}
                      showValidationEndIcons
                      error={errors?.email?.message}
                      {...field}
                    />
                  </>
                );
              }}
            />
          </div>

          <div className={`mt-1 mb-3`}>
            <Controller
              name='password'
              control={control}
              rules={{
                required: t(lng, 'app.password-is-required'),
                validate: (value) => passwordRegex.test(value) || t(lng, 'app.password-is-invalid'),
              }}
              render={({ field }) => {
                return (
                  <>
                    <label className='guest-textfield-label'>
                      <T>app.password-placeholder</T>
                    </label>
                    <Textfield
                      id='pass'
                      trim
                      type='password'
                      disabled={isLoading}
                      autoComplete='new-password'
                      size='lg'
                      icon={<LockIcon />}
                      fullWidth
                      placeholder={t(lng, 'app.password-placeholder')}
                      showValidationEndIcons
                      error={errors?.password?.message}
                      {...field}
                    />
                  </>
                );
              }}
            />
          </div>

          <div className={`mb-3`}>
            <Controller
              name='repeatPassword'
              control={control}
              rules={{
                required: t(lng, 'app.password-is-required'),
                validate: (value, formValues) =>
                  formValues?.password === value || t(lng, 'app.strings-is-not-match'),
              }}
              render={({ field }) => {
                return (
                  <>
                    <label className='guest-textfield-label'>
                      <T>app.password-confirm-placeholder</T>
                    </label>
                    <Textfield
                      id='repeatpass'
                      trim
                      type='password'
                      disabled={isLoading}
                      size='lg'
                      icon={<LockIcon />}
                      fullWidth
                      autoComplete='new-password'
                      placeholder={t(lng, 'app.password-confirm-placeholder')}
                      showValidationEndIcons
                      error={errors?.repeatPassword?.message}
                      {...field}
                    />
                  </>
                );
              }}
            />
          </div>
          <span className='recaptcha-text'>
            This site is protected by reCAPTCHA and the Google{' '}
            <a href='https://policies.google.com/privacy'>Privacy Policy</a> and{' '}
            <a href='https://policies.google.com/terms'>Terms of Service</a> apply.
          </span>
          <div className='signup-terms-wrapper'>
            <CheckboxNew
              id='termsCheckbox'
              multiSelect
              disabledHoverEffect
              reverse
              label={
                <label className='form-check-label pt-1 fs-6' htmlFor='termsCheckbox'>
                  <T>app.i-agree-with</T>{' '}
                  <a href='https://vaultody.com/terms-conditions' style={{ marginLeft: '5px' }}>
                    <T>app.terms-of-use</T>
                  </a>
                </label>
              }
              onChange={changeTermsHandler}
              value={termsChecked}
            />
          </div>
        </div>
        <div>
          {isLoading ? (
            <div className='text-center mt-5'>
              <div className='spinner-border text-success' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          ) : (
            <div className='d-grid mt-4'>
              <Button fullWidth disabled={!isValid || !termsChecked}>
                <T>app.sign-up-button</T>
              </Button>
            </div>
          )}
          <div className='guest-layout-footer'>
            Already have an account? <Link to={ROUTE_LOGIN}>Sign in</Link>
          </div>
        </div>
        <ReCAPTCHA size='invisible' ref={recaptchaRef} sitekey='6LdbiH8iAAAAAJk5hD-AQcNi2RsBSpjIKyDv2v93' />
      </form>
    </GuestLayout>
  );
};

export default SignUp;
