import { createSlice } from '@reduxjs/toolkit';
import { ROUTE_DEVELOPERS, ROUTE_HOME, ROUTE_TEAM, ROUTE_TRX_POLICY } from '../../routes/routes';

export const clientStatuses = {
  PROVISIONAL: 'PROVISIONAL',
  TRIAL: 'TRIAL',
  ACTIVE: 'ACTIVE',
  SUSPENDED: 'SUSPENDED',
};

const initialState = {
  typeUser: '',
  myProfile: {},
  myPackage: [],
  myUsage: [],
  verificationInfo: {},
  walletColor: '',
  limits: {
    [ROUTE_HOME]: {
      from: 0,
      to: 0,
    },
    [ROUTE_TRX_POLICY]: {
      from: 0,
      to: 150,
    },
    [ROUTE_TEAM]: {
      from: 0,
      to: 0,
    },
    [ROUTE_DEVELOPERS]: {
      from: 0,
      to: 0,
    },
  },
};

export const userSlice = createSlice({
  name: 'userReducer',
  initialState: initialState,
  reducers: {
    setTypeUser: (state, action) => {
      state.typeUser = action.payload;
    },
    setMyProfile: (state, action) => {
      state.myProfile = action.payload;
    },
    setMyPackage: (state, action) => {
      state.myPackage = action.payload;
    },
    setMyUsage: (state, action) => {
      state.myUsage = action.payload;
    },
    setWalletColor: (state, action) => {
      state.walletColor = action.payload;
    },
    setLimits: (state, action) => {
      state.limits = action.payload;
    },
    setVerificationInfo: (state, action) => {
      state.verificationInfo = action.payload;
      state.isPaid = action.payload?.verificationData?.stripe?.paid;
    },
  },
});

export const {
  setLimits,
  setMyUsage,
  setTypeUser,
  setMyPackage,
  setMyProfile,
  setWalletColor,
  setVerificationInfo,
} = userSlice.actions;

export default userSlice.reducer;

// Actions
// export const fetchUsers = () => async (dispatch) => {
//   try {
//     await api
//       .get("/users")
//       .then((response) => dispatch(usersSuccess(response.data)));
//   } catch (e) {
//     return console.error(e.message);
//   }
// };
