import { T, t } from '../../../T';
import VModal from '../../../Modal/VModal';
import { useSelector } from 'react-redux';
import Stepper from '../../../Stepper/Stepper';
import { useState } from 'react';
import { ReactComponent as NameIcon } from '../../../../assets/icons/pencil2-icon.svg';
import { ReactComponent as SelectIcon } from '../../../../assets/icons/pointer-icon2.svg';
import { ReactComponent as SummaryIcon } from '../../../../assets/icons/summary-icon2.svg';
import { ReactComponent as ContactsIcon } from '../../../../assets/icons/contacts-icon2.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import './ContactAddNewModal.css';
import 'swiper/css';
import Button from '../../../Button/Button';
import Textfield from '../../../Textfield/Textfield';
import { useForm, Controller } from 'react-hook-form';
import { createNewContactApi } from '../../../../api/endpoints';
import Filter from '../../../Filter/Filter';
import { getCryptoIconFromBucket } from '../../../../helpers/protocolIcons/protocolIcons';
import { blockchainNetworks } from '../../../../helpers/constants';
import Blockchain from '../../../Blockchain/Blockchain';
import { useSwiperSlider } from '../../../../helpers/hooks/useSwiperSlider';

const ContactAddNewModal = ({ callback, closeCallback, address, blockchain }) => {
  const typeUser = useSelector((state) => state.userReducer.typeUser);
  const protocols = useSelector((state) => state.globalReducer.protocols);
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const [sliding, setSliding] = useState(false);
  const [currentStep, setCurrentStep] = useState({ id: 1, name: 'name' });
  const {
    handleSubmit,
    control,
    formState: { errors, dirtyFields, isValid },
    getValues,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      address: address || '',
      blockchain: blockchain,
    },
  });
  const [steps, setSteps] = useState([
    {
      id: 1,
      icon: <NameIcon />,
      text: 'app.name',
      name: 'name',
      completed: false,
    },
    {
      id: 2,
      icon: <SelectIcon />,
      text: 'app.blockchain',
      name: 'blockchain',
      completed: false,
    },
    {
      id: 3,
      icon: <ContactsIcon />,
      text: 'app.enter.address',
      name: 'address',
      completed: false,
    },
    { id: 4, icon: <SummaryIcon />, text: 'app.summary', name: 'summary', completed: false },
  ]);
  const { handleCompleteStep, handleSwiperNextSlide, swiperContainerHeight, swiperRef } =
    useSwiperSlider(setSteps);

  const { icon, subText } = stepDescriptionMapping()[currentStep?.name];

  const createContact = (data) => {
    const payload = {
      item: {
        ...data,
        blockchain: data?.blockchain?.[0]?.blockchain,
      },
    };
    if (typeUser) {
      return createNewContactApi(typeUser, payload).then(() =>
        callback({ ...data, blockchain: data?.blockchain[0]?.blockchain }),
      );
    }
  };

  const handleStepNextButton = (id, name) => {
    setCurrentStep({ id: id, name: name });
    handleCompleteStep(id - 1);
    handleSwiperNextSlide();
  };

  return (
    <VModal classHandler={'create-transaction-request-modal'} toggleFunction={closeCallback}>
      <div className='create-transaction-request-modal-wrapper'>
        <div className='create-transaction-request-modal-side'>
          <span className='create-transaction-request-modal-side-heading'>
            {`${t(lng, 'app.step')} ${currentStep?.id}`}
          </span>
          <Stepper
            onSelectStepCallback={(stepIndex, stepName) => {
              setCurrentStep({ id: stepIndex, name: stepName });
              if (swiperRef.current && swiperRef.current.swiper) {
                swiperRef.current.swiper.slideTo(stepIndex - 1);
              }
            }}
            currentStepId={currentStep?.id}
            steps={steps}
          />
        </div>
        <div className='create-transaction-request-modal-content'>
          <span className='create-transaction-request-modal-heading'>
            <div className='create-transaction-request-modal-heading-wrapper'>
              <span>
                <T>app.add.new.contact</T>
              </span>
              <div className='mobile-stepper'>
                <Stepper
                  onSelectStepCallback={(stepIndex, stepName) => {
                    setCurrentStep({ id: stepIndex, name: stepName });
                    if (swiperRef.current && swiperRef.current.swiper) {
                      swiperRef.current.swiper.slideTo(stepIndex - 1);
                    }
                  }}
                  currentStepId={currentStep?.id}
                  steps={steps}
                />
              </div>
              <span className='create-transaction-request-modal-side-sub-heading'>
                {icon}
                {t(lng, steps[currentStep?.id - 1]?.text)}
              </span>
              <span className='create-transaction-request-modal-step-desc'>{t(lng, subText)}</span>
            </div>
          </span>
          <div
            className={`create-transaction-request-swiper-wrapper ${sliding ? 'sliding' : ''}`}
            style={{ height: swiperContainerHeight }}
          >
            <Swiper
              ref={swiperRef}
              spaceBetween={50}
              speed={800}
              allowTouchMove={false}
              slidesPerView={1}
              onSlideChangeTransitionStart={() => setSliding(true)}
              onSlideChangeTransitionEnd={() => setSliding(false)}
            >
              <form onSubmit={handleSubmit(createContact)} className='signin-form-wrapper'>
                <SwiperSlide>
                  <Controller
                    name='name'
                    control={control}
                    rules={{
                      required: `${t(lng, 'app.contact.name')} ${t(lng, 'app.is.required')}`,
                    }}
                    render={({ field }) => {
                      return (
                        <>
                          <span className='create-trx-request-modal-select-component-label'>
                            <T>app.contact.name</T>
                          </span>
                          <Textfield
                            id='name'
                            trim
                            type='text'
                            fullWidth
                            size='lg'
                            placeholder={t(lng, 'app.enter.address.name.example')}
                            error={errors?.name?.message}
                            {...field}
                          />
                        </>
                      );
                    }}
                  />
                  <div className='create-trx-request-modal-button'>
                    <Button
                      fullWidth
                      onClick={() => handleStepNextButton(2, 'blockchain')}
                      disabled={errors?.name || !dirtyFields?.name}
                    >
                      <T>app.next.step</T>
                    </Button>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <Controller
                    name='blockchain'
                    control={control}
                    render={({ field }) => {
                      return (
                        <>
                          <label>
                            <T>app.blockchain</T>
                          </label>
                          {protocols?.length > 0 && (
                            <Filter
                              items={protocols
                                ?.filter((prot) =>
                                  `${typeUser}net` === blockchainNetworks?.MAINNET
                                    ? prot?.network === blockchainNetworks?.MAINNET
                                    : prot?.network !== blockchainNetworks?.MAINNET,
                                )
                                ?.map((filteredProt) => ({
                                  ...filteredProt,
                                  icon: getCryptoIconFromBucket(filteredProt?.currency?.toLowerCase()),
                                }))}
                              dataNameKey='publicName'
                              dataKey='blockchain'
                              customIconKey='icon'
                              fullWidth
                              displayIcon
                              placeholder='app.filters.blockchains'
                              {...field}
                              selectedItems={field?.value || []}
                              onChange={field?.onChange}
                            />
                          )}
                        </>
                      );
                    }}
                  />
                  <div className='create-trx-request-modal-button'>
                    <Button
                      fullWidth
                      onClick={() => handleStepNextButton(3, 'address')}
                      disabled={errors?.blockchain || (!blockchain && !dirtyFields?.blockchain)}
                    >
                      <T>app.next.step</T>
                    </Button>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <Controller
                    name='address'
                    control={control}
                    rules={{
                      required: `${t(lng, 'app.address')} ${t(lng, 'app.is.required')}`,
                    }}
                    render={({ field }) => {
                      return (
                        <>
                          <span className='create-trx-request-modal-select-component-label'>
                            <T>app.enter.address</T>
                          </span>
                          <Textfield
                            id='address'
                            type='text'
                            fullWidth
                            trim
                            placeholder={t(lng, 'app.enter.address.example')}
                            size='lg'
                            error={errors?.address?.message}
                            {...field}
                          />
                        </>
                      );
                    }}
                  />
                  <div className='create-trx-request-modal-button'>
                    <Button
                      fullWidth
                      onClick={() => handleStepNextButton(4, 'summary')}
                      disabled={errors?.address || (!address && !dirtyFields?.address)}
                    >
                      <T>app.next.step</T>
                    </Button>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className='create-api-key-summary-wrapper'>
                    <div className='create-api-key-summary-row'>
                      <T>app.contact.name</T>
                      <span>{getValues()?.name}</span>
                    </div>
                    <div className='create-api-key-summary-row'>
                      <T>app.blockchain</T>
                      <div className='create-contact-summary-blockchain-wrapper'>
                        <Blockchain
                          showText={false}
                          blockchain={protocols
                            ?.find(
                              (protocol) =>
                                protocol?.blockchain?.toLowerCase() ===
                                getValues()?.blockchain?.[0]?.blockchain?.toLowerCase(),
                            )
                            ?.currency?.toLowerCase()}
                          symbol={protocols
                            ?.find(
                              (protocol) =>
                                protocol?.blockchain?.toLowerCase() ===
                                getValues()?.blockchain?.[0]?.blockchain?.toLowerCase(),
                            )
                            ?.currency?.toLowerCase()}
                        />
                        <span>
                          {
                            protocols?.find(
                              (protocol) =>
                                protocol?.blockchain?.toLowerCase() ===
                                getValues()?.blockchain?.[0]?.blockchain?.toLowerCase(),
                            )?.publicName
                          }
                        </span>
                      </div>
                    </div>
                    <div className='create-api-key-summary-row'>
                      <T>app.enter.address</T>
                      <span className='create-contact-summary-address'>{getValues()?.address}</span>
                    </div>
                    <Button fullWidth type='submit' onClick={handleSubmit(createContact)} disabled={!isValid}>
                      <i className='icon-submit-for-approval' />
                      <T>app.submit.for.approval</T>
                    </Button>
                  </div>
                </SwiperSlide>
              </form>
            </Swiper>
          </div>
        </div>
      </div>
    </VModal>
  );
};

export default ContactAddNewModal;

const stepDescriptionMapping = () => ({
  name: {
    subText: 'app.contact.name.step.subtext',
    icon: <NameIcon style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
  blockchain: {
    subText: 'app.contact.blockchain.step.subtext',
    icon: <SelectIcon style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
  address: {
    subText: 'app.contact.address.step.subtext',
    icon: <ContactsIcon style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
  summary: {
    icon: <SummaryIcon fill='#fff' style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
});
