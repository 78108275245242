const toastConfig = { position: 'top-right', autoClose: 5000, theme: 'dark' };
export default class Toasts {
  static toast;

  static error(message) {
    if (Toasts.toast) {
      Toasts.toast.error(message, toastConfig);
    }
  }
  static success(message) {
    if (Toasts.toast) {
      Toasts.toast.success(message, toastConfig);
    }
  }
  static warning(message) {
    if (Toasts.toast) {
      Toasts.toast.warning(message, toastConfig);
    }
  }
  static info(message) {
    if (Toasts.toast) {
      Toasts.toast.info(message, toastConfig);
    }
  }
}
