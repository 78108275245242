import { useState } from 'react';
import ActionModal from './ActionModal';
import { T, t } from '../T';
import { deleteWebhookApi } from '../../api/endpoints';
import BasicModal from './BasicModal/BasicModal';
import { useSelector } from 'react-redux';
import VaultIcon from '../VaultIcon/VaultIcon';

const RemoveWebhooksModal = ({ toggleFunction, data }) => {
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const [sussess, setSussess] = useState(false);

  const deleteWebhook = () => {
    deleteWebhookApi(data.id).then(() => setSussess(true));
  };

  return (
    <>
      {!sussess && (
        <BasicModal
          closeCallback={toggleFunction}
          submitCallback={deleteWebhook}
          title={t(lng, 'app.are.you.sure.remove.webhooks')}
        >
          <div className='remove-webhook-info-wrapper'>
            <div className='remove-webhook-info-row'>
              <span className='remove-webhook-info-text'>
                <T>app.url</T>
              </span>
              <span className='remove-webhook-info-sub-text-break-all'>{data.url}</span>
            </div>
            <div className='remove-webhook-info-row'>
              <span className='remove-webhook-info-text'>
                <T>app.wallets</T>
              </span>
              <div className='remove-api-vaults-wrapper'>
                {data?.wallets?.length > 0 &&
                  data?.wallets?.map((wallet, index) => (
                    <VaultIcon
                      key={`wallet-webhooks-${index}`}
                      id={`remove-webhook-wallet-${index}`}
                      name={wallet?.name}
                      color={wallet?.color}
                    />
                  ))}
              </div>
            </div>
            <div className='remove-webhook-info-row'>
              <span className='remove-webhook-info-text'>
                <T>app.events.subscribed</T>
              </span>
              <span className='remove-webhook-info-sub-text'>
                {data?.events?.length > 0 &&
                  data?.events
                    ?.map((event) =>
                      event
                        ?.toLowerCase()
                        ?.replace(/_/g, ' ')
                        ?.replace(/\b\w/g, (char) => char?.toUpperCase()),
                    )
                    ?.join(', ')}
              </span>
            </div>
          </div>
        </BasicModal>
      )}
      {sussess && (
        <ActionModal toggleFunction={toggleFunction}>
          <T>app.delete.webhook.descr</T>
        </ActionModal>
      )}
    </>
  );
};
export default RemoveWebhooksModal;
