import './CheckboxNew.css';
import checkmarkIcon from '../../assets/icons/checkmark.svg';
import VaultIcon from '../VaultIcon/VaultIcon';

const CheckboxNew = ({
  value = false,
  onChange,
  label,
  item,
  id,
  color,
  displayIcon = false,
  roundedIcon = false,
  customIcon,
  multiSelect,
  disabledHoverEffect,
  reverse, // Reverses icon and label
  disabled,
  ...rest
}) => {
  const handleClick = () => {
    if (disabled) return;
    if (onChange && id) {
      onChange(id);
    }
  };

  return (
    <div
      className={`${!value ? 'checkbox-group-new' : 'checkbox-group-new checked'} ${
        disabledHoverEffect ? 'disabledHover' : ''
      }`}
      onClick={handleClick}
      {...rest}
    >
      <div
        htmlFor={id}
        className={`value-checkbox-new ${reverse ? 'reversed' : ''} ${
          !displayIcon && !customIcon && !multiSelect ? 'only-label' : ''
        }`}
        value={id}
      >
        {label && (
          <>
            {displayIcon && !customIcon && (
              <VaultIcon
                id={id}
                name={label}
                color={color}
                vaultType={item?.vaultType}
                className={`wallet-icon-new ${roundedIcon && 'round'}`}
              />
            )}
            {displayIcon && customIcon && (
              <img src={customIcon} className={`wallet-icon-new ${roundedIcon && 'round'}`} />
            )}
            <div className={`checkbox-label-text ${disabled ? 'disabled' : ''}`}>{label}</div>
          </>
        )}
      </div>
      {multiSelect && (
        <div className={`checkbox-checkmarm-wrapper ${reverse ? 'reversed' : ''}`}>
          <input id={id} type='checkbox-new' />
          <div className={`checkmark-new ${disabled ? 'disabled' : ''}`}>
            {value && <img className='checkbox-checkmark-icon' src={checkmarkIcon} />}
          </div>
        </div>
      )}
    </div>
  );
};

export default CheckboxNew;
