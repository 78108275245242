import React from 'react';
import externalIcon from '../../../assets/icons/external.svg';
import externalLightIcon from '../../../assets/icons/external-light.svg';
import userIcon from '../../../assets/icons/user2.svg';
import walletIcon from '../../../assets/icons/wallet.svg';
import documentIcon from '../../../assets/icons/document.svg';
import { T } from '../../../components/T';
import ReactLoading from 'react-loading';
import failedIcon from '../../../assets/icons/statuses/status-failed.svg';

import {
  paymentStatuses,
  signedContractStatuses,
  sumSubStatuses,
} from '../../../helpers/transactionrequests/statuses';
import VTooltip from '../../VTooltip/VTooltip';

const typeMapping = {
  sumsub: {
    icon: userIcon,
    text: <T>app.onboard.identity</T>,
    statuses: sumSubStatuses,
  },
  contract: {
    icon: documentIcon,
    text: <T>app.onboard.contract</T>,
    statuses: signedContractStatuses,
  },
  payment: {
    icon: walletIcon,
    text: <T>app.onboard.payment</T>,
    statuses: paymentStatuses,
  },
  suspended: {
    icon: documentIcon,
    text: <T>app.unpaid.invoices</T>,
    statuses: [
      {
        value: 'SUSPENDED',
        name: <T>app.unpaid</T>,
        icon: failedIcon,
        color: '#d10000',
      },
    ],
  },
};

// type - "contract", "sumsub", "payment"
const SelfOnboardingModalCard = React.memo(
  ({ status, type, isOwner, callback, loading, tooltipText, disabled }) => {
    const statusObj = typeMapping[type]?.statuses?.find(
      (statusObj) => statusObj?.value === status?.toUpperCase(),
    );
    return (
      <div className='self-onboard-modal-card'>
        <div className='info-wrapper'>
          <div className='icon'>
            <img className='big-icon' src={typeMapping[type]?.icon} />
            <img className='small-icon' src={statusObj?.icon} />
          </div>
          <div className='info'>
            <div className='main-info'>{typeMapping[type]?.text}</div>
            <div className='status' style={{ color: statusObj?.color }}>
              {statusObj?.name}
            </div>
          </div>
        </div>
        <div data-tooltip-id={`${type}-onboard-card`} data-tooltip-content={tooltipText}>
          {!isOwner && <VTooltip id={`${type}-onboard-card`} />}
          {loading ? (
            <ReactLoading width={20} height={20} type='spin' color='#000' />
          ) : (
            <img
              onClick={() => {
                if (callback && isOwner && !disabled) {
                  callback();
                }
              }}
              className={`link ${isOwner && !disabled ? '' : 'disabled'}`}
              src={isOwner && !disabled ? externalIcon : externalLightIcon}
            />
          )}
        </div>
      </div>
    );
  },
);

export default SelfOnboardingModalCard;
