import { useRef, useState } from 'react';
import './VaultCreationModal.css';
import { T, t } from '../../../T';
import VaultCreationModalCard from './components/VaultCreationModalCard/VaultCreationModalCard';
import { useSelector } from 'react-redux';
import generalVaultIcon from '../../../../assets/icons/general-vault-icon.svg';
import smartVaultIcon from '../../../../assets/icons/smart-vault-icon.svg';
import automationVaultIcon from '../../../../assets/icons/automation-vault-icon.svg';
import VModal from '../../../Modal/VModal';
import Button from '../../../Button/Button';
import Stepper from '../../../Stepper/Stepper';
import { ReactComponent as VaultTypeIcon } from '../../../../assets/icons/vault-type-icon.svg';
import { ReactComponent as PencilIcon } from '../../../../assets/icons/pencil2-icon.svg';
import { ReactComponent as DocumentIcon } from '../../../../assets/icons/summary-icon2.svg';
import Textfield from '../../../Textfield/Textfield';
import Alert from '../../../Alert/Alert';
import ColorChanger from '../../../ColorChanger/ColorChanger';
import CheckboxNew from '../../../CheckboxNew/CheckboxNew';
import { createWalletApi } from '../../../../api/endpoints';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { vaultTypes } from '../../../../helpers/constants';
import { useSwiperSlider } from '../../../../helpers/hooks/useSwiperSlider';

const VaultCreationModal = ({ closeCallback, successCallback }) => {
  const [sliding, setSliding] = useState(false);
  const scrollRef = useRef(null);
  const typeUser = useSelector((state) => state.userReducer.typeUser);
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const [vaultType, setVaultType] = useState('');
  const [vaultName, setVaultName] = useState('');
  const [vaultColor, setVaultColor] = useState('#00A3BF');
  const [consent, setConsent] = useState(false);
  const [currentStep, setCurrentStep] = useState({ id: 1, name: 'type' });
  const [steps, setSteps] = useState([
    {
      id: 1,
      icon: <VaultTypeIcon />,
      fillInsteadOfStroke: true,
      text: 'app.vault.type',
      name: 'type',
      completed: false,
    },
    {
      id: 2,
      icon: <PencilIcon />,
      fillInsteadOfStroke: true,
      text: 'app.vault.name',
      name: 'name',
      completed: false,
    },
    { id: 3, icon: <DocumentIcon />, text: 'app.summary', name: 'summary', completed: false },
  ]);
  const { handleCompleteStep, handleSwiperNextSlide, swiperContainerHeight, swiperRef } = useSwiperSlider(
    setSteps,
    '660px',
  );

  const handleVaultCardClick = (type) => setVaultType(type);

  const createNewVaultRequest = () => {
    const data = {
      item: {
        name: vaultName?.trim(),
        color: vaultColor,
        type: typeUser?.toLocaleUpperCase(),
        rules: [],
        vaultType: vaultType?.toUpperCase(),
      },
    };
    createWalletApi(data).then((res) => {
      successCallback(res.data?.data?.item);
    });
  };

  return (
    <VModal toggleFunction={closeCallback} scrollTrigger={currentStep}>
      <div className='vault-modal-creation-wrapper'>
        <div className='vault-modal-side-banner'>
          <span className='vault-modal-side-banner-label'>
            <T>app.step</T> {currentStep?.id}
          </span>
          <Stepper
            currentStepId={currentStep?.id}
            onSelectStepCallback={(stepIndex, stepName) => {
              setCurrentStep({ id: stepIndex, name: stepName });
              if (swiperRef.current && swiperRef.current.swiper) {
                swiperRef.current.swiper.slideTo(stepIndex - 1);
              }
            }}
            steps={steps}
          />
        </div>
        <div className='vault-modal-content'>
          <div
            className={`create-vault-swiper-wrapper ${sliding ? 'sliding' : ''}`}
            style={{ height: swiperContainerHeight }}
          >
            <Swiper
              ref={swiperRef}
              spaceBetween={50}
              speed={800}
              allowTouchMove={false}
              slidesPerView={1}
              onSlideChangeTransitionStart={() => setSliding(true)}
              onSlideChangeTransitionEnd={() => setSliding(false)}
            >
              <SwiperSlide>
                <div>
                  <span className='vault-modal-content-heading'>
                    <T>app.vault.choose.type</T>
                  </span>
                  <div className='mobile-stepper'>
                    <Stepper
                      onSelectStepCallback={(stepIndex, stepName) => {
                        setCurrentStep({ id: stepIndex, name: stepName });
                        if (swiperRef.current && swiperRef.current.swiper) {
                          swiperRef.current.swiper.slideTo(stepIndex - 1);
                        }
                      }}
                      currentStepId={currentStep?.id}
                      steps={steps}
                    />
                  </div>
                  <div className='vault-modal-content-card-wrapper'>
                    <VaultCreationModalCard
                      key='general'
                      type='general'
                      value={vaultType}
                      callback={() => handleVaultCardClick('general')}
                      title={t(lng, 'app.vault.general')}
                      icon={generalVaultIcon}
                      description={t(lng, 'app.vault.general.desc')}
                      features={[
                        { label: t(lng, 'app.vault.all.protocols'), available: true },
                        { label: t(lng, 'app.vault.trx.policies'), available: true },
                        { label: t(lng, 'app.vault.automation.rules'), available: false },
                        { label: t(lng, 'app.vault.remove.dust'), available: false },
                        { label: t(lng, 'app.vault.gas.tanker'), available: false },
                        { label: t(lng, 'app.vault.manual.transactions'), available: true },
                        { label: t(lng, 'app.vault.access.via.endpoints'), available: true },
                        { label: t(lng, 'app.vault.deposit.addresses'), available: true },
                      ]}
                    />
                    <VaultCreationModalCard
                      key={vaultTypes.SMART}
                      type={vaultTypes.SMART}
                      value={vaultType}
                      callback={() => handleVaultCardClick(vaultTypes.SMART)}
                      title={t(lng, 'app.vault.smart')}
                      icon={smartVaultIcon}
                      description={t(lng, 'app.vault.smart.desc')}
                      features={[
                        {
                          label: t(lng, 'app.vault.all.protocols'),
                          customText: t(lng, 'app.vault.only.evm'),
                        },
                        { label: t(lng, 'app.vault.trx.policies'), available: true },
                        { label: t(lng, 'app.vault.automation.rules'), available: false },
                        { label: t(lng, 'app.vault.remove.dust'), available: true },
                        { label: t(lng, 'app.vault.gas.tanker'), available: true },
                        { label: t(lng, 'app.vault.manual.transactions'), available: true },
                        { label: t(lng, 'app.vault.access.via.endpoints'), available: true },
                        { label: t(lng, 'app.vault.deposit.addresses'), available: true },
                      ]}
                    />
                    <VaultCreationModalCard
                      key={vaultTypes.AUTOMATION}
                      type={vaultTypes.AUTOMATION}
                      value={vaultType}
                      callback={() => handleVaultCardClick(vaultTypes.AUTOMATION)}
                      title={t(lng, 'app.vault.automation')}
                      icon={automationVaultIcon}
                      description={t(lng, 'app.vault.automation.desc')}
                      features={[
                        { label: t(lng, 'app.vault.all.protocols'), available: true },
                        { label: t(lng, 'app.vault.trx.policies'), available: false },
                        { label: t(lng, 'app.vault.automation.rules'), available: true },
                        { label: t(lng, 'app.vault.remove.dust'), available: true },
                        { label: t(lng, 'app.vault.gas.tanker'), available: true },
                        { label: t(lng, 'app.vault.manual.transactions'), available: false },
                        { label: t(lng, 'app.vault.access.via.endpoints'), available: true },
                        { label: t(lng, 'app.vault.deposit.addresses'), available: true },
                      ]}
                    />
                  </div>
                  <div className='vault-modal-next-step-button'>
                    <Button
                      variant='primary'
                      fullWidth
                      disabled={!vaultType}
                      onClick={() => {
                        setCurrentStep({ id: 2, name: 'asset' });
                        handleCompleteStep(1);
                        handleSwiperNextSlide();
                      }}
                    >
                      <T>app.next.step</T>
                    </Button>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <>
                  <span className='vault-modal-content-heading'>
                    <T>app.vault.choose.name</T>
                  </span>
                  <div className='mobile-stepper'>
                    <Stepper
                      onSelectStepCallback={(stepIndex, stepName) => {
                        setCurrentStep({ id: stepIndex, name: stepName });
                        if (swiperRef.current && swiperRef.current.swiper) {
                          swiperRef.current.swiper.slideTo(stepIndex - 1);
                        }
                      }}
                      currentStepId={currentStep?.id}
                      steps={steps}
                    />
                  </div>
                  <div className='v-input-group'>
                    <label>
                      <T>app.wallet.name.color</T>
                    </label>
                    <div className='vault-modal-textfield-wrapper'>
                      <Textfield
                        type='text'
                        fullWidth
                        size='lg'
                        placeholder={t(lng, 'app.placeholder.wallet.create')}
                        error={
                          vaultName?.length > 30 || (vaultName?.length && vaultName?.length < 3)
                            ? t(lng, 'app.vault.name.length.error')
                            : undefined
                        }
                        value={vaultName}
                        onChange={(event) => {
                          setVaultName(event.currentTarget.value);
                        }}
                      />
                    </div>
                    <ColorChanger state={vaultColor} changeHandler={setVaultColor} shrink={true} />
                  </div>
                  <div className='vault-modal-alert-consent-button-wrapper'>
                    {typeUser === 'test' && (
                      <>
                        <Alert variant='warning' text={t(lng, 'app.vault.creation.testnet')} />
                        <CheckboxNew
                          id='termsCheckbox'
                          disabledHoverEffect
                          multiSelect
                          reverse
                          label={
                            <label className='form-check-label pt-1 fs-6' htmlFor='termsCheckbox'>
                              <T>app.alert.i-agree-with</T>
                            </label>
                          }
                          value={consent}
                          onChange={() => setConsent((prev) => !prev)}
                        />
                      </>
                    )}
                    <Button
                      fullWidth
                      disabled={
                        !vaultName ||
                        !vaultColor ||
                        (typeUser === 'test' && !consent) ||
                        vaultName?.length > 30 ||
                        (vaultName?.length && vaultName?.length < 3)
                      }
                      onClick={() => {
                        setCurrentStep({ id: 3, name: 'summary' });
                        handleCompleteStep(2);
                        handleSwiperNextSlide();
                      }}
                    >
                      <T>app.next.step</T>
                    </Button>
                  </div>
                </>
              </SwiperSlide>
              <SwiperSlide>
                <>
                  <span className='vault-modal-content-heading'>
                    <T>app.vault.summary</T>
                  </span>
                  <div className='mobile-stepper'>
                    <Stepper
                      onSelectStepCallback={(stepIndex, stepName) => {
                        setCurrentStep({ id: stepIndex, name: stepName });
                        if (swiperRef.current && swiperRef.current.swiper) {
                          swiperRef.current.swiper.slideTo(stepIndex - 1);
                        }
                      }}
                      currentStepId={currentStep?.id}
                      steps={steps}
                    />
                  </div>
                  <div className='vault-modal-summary-wrapper-info'>
                    <div className='vault-modal-summary-row-border'>
                      <span className='vault-modal-summary-text'>
                        <T>app.vault.type</T>
                      </span>
                      <span className='vault-modal-summary-subtext'>{vaultType}</span>
                    </div>
                    <div className='vault-modal-summary-row-border'>
                      <span className='vault-modal-summary-text'>
                        <T>app.vault.name</T>
                      </span>
                      <span className='vault-modal-summary-subtext'>{vaultName}</span>
                    </div>
                    <div className='vault-modal-summary-row'>
                      <span className='vault-modal-summary-text'>
                        <T>app.vault.color</T>
                      </span>
                      <div className='vault-modal-summary-color' style={{ background: vaultColor }} />
                    </div>
                    <div className='vault-modal-alert-wrapper'>
                      {typeUser === 'test' && (
                        <Alert variant='warning' text={t(lng, 'app.vault.creation.testnet')} />
                      )}
                    </div>
                    <Button fullWidth disabled={!vaultName || !vaultColor} onClick={createNewVaultRequest}>
                      <T>app.create.new.wallet</T>
                    </Button>
                  </div>
                </>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </VModal>
  );
};

export default VaultCreationModal;
