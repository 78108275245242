import { useEffect, useState } from 'react';
import './PlanAndBilling.css';
import { setPage } from '../../redux/slices/globalSlice';
import { T, t } from '../../components/T';
import { useDispatch, useSelector } from 'react-redux';
import InfoContainer from '../../components/InfoContainer/InfoContainer';
import PlanDetailsBox from '../../components/Pages/PlanAndBilling/PlansAndBilling/PlanDetailsBox';
import { ReactComponent as AssetsIcon } from '../../assets/icons/dashboardMenu/assets-icon.svg';
import { ReactComponent as VaultsIcon } from '../../assets/icons/dashboardMenu/vaults-icon.svg';
import { ReactComponent as TrxPolicyIcon } from '../../assets/icons/dashboardMenu/policy-icon.svg';
import { ReactComponent as TeamIcon } from '../../assets/icons/dashboardMenu/team-icon.svg';
import { ReactComponent as ContactsIcon } from '../../assets/icons/dashboardMenu/contacts-icon.svg';
import { ReactComponent as AddressIcon } from '../../assets/icons/address-icon.svg';
import { ReactComponent as BackArrowIcon } from '../../assets/icons/back-arrow-icon.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar-icon.svg';
import { ReactComponent as PercentageIcon } from '../../assets/icons/percentage-icon.svg';
import { currency } from '../../helpers/currency';
import Button from '../../components/Button/Button';
import { ReactComponent as ExportIcon } from '../../assets/icons/export-icon.svg';
import UpgradePlanModal from '../../components/Pages/PlanAndBilling/UpgradePlanModal/UpgradePlanModal';
import CancelSubscriptionModal from '../../components/Pages/PlanAndBilling/CancelSubscriptionModal/CancelSubscriptionModal';
import VTooltip from '../../components/VTooltip/VTooltip';
// import Tabs from '../../components/Tabs/Tabs';

const PlanAndBilling = () => {
  const dispatch = useDispatch();
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const myUsage = useSelector((state) => state.userReducer.myUsage);
  const myProfile = useSelector((state) => state.userReducer.myProfile);
  const myPackage = useSelector((state) => state.userReducer.myPackage);
  const userVerificationInfo = useSelector((state) => state.userReducer.verificationInfo);
  const trialBannerVisible = useSelector((state) => state.globalReducer.trialBannerVisible);
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const [cancelPlanModalOpen, setCancelPlanModalOpen] = useState(false);
  const planPrice = myPackage?.prices?.find((price) => price?.currency === 'USD');
  const isOwner = myProfile.roles?.some((i) => {
    return i.name === 'owner';
  });

  useEffect(() => {
    dispatch(setPage(t(lng, 'app.plan.and.billing')));
  }, []);
  return (
    <div className={`plan-details-page ${trialBannerVisible ? 'trial-banner-visible' : ''}`}>
      {upgradeModalOpen && <UpgradePlanModal setCloseModal={setUpgradeModalOpen} />}
      {cancelPlanModalOpen && <CancelSubscriptionModal setCloseModal={setCancelPlanModalOpen} />}
      {/* <Tabs
        defaultActiveTab='plan-details'
        items={[
          {
            name: 'plan-details',
            label: 'app.plan.details',
          },
          {
            name: 'plan-history',
            label: 'app.plan.history',
          },
          {
            name: 'plan-methods',
            label: 'app.plan.methods',
          },
        ]}
      /> */}
      <div className='plan-details-sections'>
        <div className='plan-details-first-section'>
          <InfoContainer title={t(lng, 'app.current.plan')} info={myPackage?.name} />
          {(planPrice?.amount || planPrice?.yearlyMonthAmount) && (
            <InfoContainer
              title={t(lng, 'app.plan.pricing')}
              info={planPrice?.amount || planPrice?.yearlyMonthAmount}
              isCurrency
            />
          )}
        </div>
        <div className='plan-details-second-section'>
          <Button
            disabled={!isOwner}
            data-tooltip-id='plan-and-billing-upgrade'
            data-tooltip-content={t(lng, 'app.role.limitation')}
            size='md'
            variant='primary'
            onClick={() => setUpgradeModalOpen(true)}
          >
            {!isOwner && <VTooltip id='plan-and-billing-upgrade' />}
            <ExportIcon style={{ marginRight: '3.5px' }} />
            <T>app.upgrade.plan</T>
          </Button>
          <Button
            disabled={!isOwner || !userVerificationInfo?.verificationData?.stripe?.id}
            data-tooltip-id='plan-and-billing-cancel'
            data-tooltip-content={
              userVerificationInfo?.verificationData?.stripe?.id
                ? t(lng, 'app.role.limitation')
                : t(lng, 'app.please.contact.us')
            }
            size='md'
            variant='neutral'
            onClick={() => setCancelPlanModalOpen(true)}
          >
            {(!isOwner || !userVerificationInfo?.verificationData?.stripe?.id) && (
              <VTooltip id='plan-and-billing-cancel' />
            )}
            <T>app.cancel.plan</T>
          </Button>
        </div>
      </div>
      <span className='plan-details-page-section-title'>{t(lng, 'app.plan.limits')}</span>
      <div className='plan-details-page-info-boxes'>
        <div className='plan-details-page-info-box-combo'>
          <div className='plan-details-page-info-box'>
            <PlanDetailsBox
              info={`${myUsage?.mainWalletsCount || 0}/${myPackage?.constraints?.mainWalletsCount}`}
              title={t(lng, 'app.vaults.mainnet')}
              icon={<AssetsIcon />}
            />
          </div>
          <div className='plan-details-page-info-box'>
            <PlanDetailsBox
              info={`${myUsage?.testWalletsCount || 0}/${myPackage?.constraints?.testWalletsCount}`}
              title={t(lng, 'app.vaults.testnet')}
              icon={<VaultsIcon />}
            />
          </div>
          <div className='plan-details-page-info-box'>
            <PlanDetailsBox
              info={`${myUsage?.addressesCount || 0}/${myPackage?.constraints?.walletAddressesCount}`}
              title={t(lng, 'app.plan.page.addresses')}
              icon={<AddressIcon />}
            />
          </div>
        </div>
        <div className='plan-details-page-info-box-combo'>
          <div className='plan-details-page-info-box'>
            <PlanDetailsBox
              info={`${myUsage?.currentRulesCount || 0}/${myPackage?.constraints?.rulesCount}`}
              title={t(lng, 'app.plan.page.trx.policies')}
              icon={<TrxPolicyIcon />}
            />
          </div>
          <div className='plan-details-page-info-box'>
            <PlanDetailsBox
              info={`${myUsage?.currentTeamMembersCount || 0}/${myPackage?.constraints?.teamMembersCount}`}
              title={t(lng, 'app.plan.page.team.members')}
              icon={<TeamIcon />}
            />
          </div>
          <div className='plan-details-page-info-box'>
            <PlanDetailsBox
              info={`${myUsage?.contactsCount || 0}/${myPackage?.constraints?.waasContactsCount}`}
              title={t(lng, 'app.plan.page.contacts')}
              icon={<ContactsIcon className='plan-and-billing-contacts-icon' />}
            />
          </div>
        </div>
      </div>
      <span className='plan-details-page-section-title'>{t(lng, 'app.commissions')}</span>
      <div className='plan-details-page-info-boxes'>
        <div className='plan-details-page-info-box-combo'>
          <div className='plan-details-page-info-box'>
            <PlanDetailsBox
              info={`${myPackage?.assetsUnderManagementBPS} ${t(lng, 'app.bps.above')} ${currency(
                myPackage?.assetsUnderManagementVolumeWithoutCharge,
              )}`}
              title={t(lng, 'app.plan.page.aum.monthly.commission')}
              icon={<CalendarIcon />}
            />
          </div>
          <div className='plan-details-page-info-box'>
            <PlanDetailsBox
              info={`${myPackage?.outgoingTransactionBPS} ${t(lng, 'app.bps.above')} ${currency(
                myPackage?.outgoingTransactionVolumeWithoutCharge,
              )}`}
              title={t(lng, 'app.plan.page.outgoing,monthly.commission')}
              icon={<BackArrowIcon />}
            />
          </div>
          <div className='plan-details-page-info-box'>
            <PlanDetailsBox
              info={`${currency(myPackage?.outgoingTransactionFixedCommissionFee)}`}
              title={t(lng, 'app.plan.page.fixed.outgoing.monthly,commission')}
              icon={<PercentageIcon />}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanAndBilling;
