import React, { useState } from 'react';
import { getCryptoIconFromBucket } from '../../helpers/protocolIcons/protocolIcons';
import notFound from '../../assets/icons/question-mark.svg';

const CryptoIcon = ({ className, symbol }) => {
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => setImageError(true);

  return symbol ? (
    <img
      className={className ? className : ''}
      onError={handleImageError}
      src={!imageError ? getCryptoIconFromBucket(symbol?.toLowerCase()) : notFound}
      alt=''
    />
  ) : null;
};

export default CryptoIcon;
