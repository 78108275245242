import React from 'react';
import carouselIcon from '../../../assets/icons/carousel-icon-light.svg';
import { t } from '../../T';
import { useSelector } from 'react-redux';

const FilterTextField = ({
  open,
  setOpen,
  textFieldRef,
  selectedItems,
  selectedItemsToShow,
  placeholder,
  selectedFieldsSpanRef,
  indicatorData,
  items,
  dataNameKey,
  isMultiSelect,
  disabled,
  customTextfieldLabel,
}) => {
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  return (
    <div
      ref={textFieldRef}
      className={
        open ? 'select-custom-new open scroll-element' : `select-custom-new ${disabled ? 'disabled' : ''}`
      }
      onClick={() => {
        if (!disabled) {
          setOpen((prev) => !prev);
        }
      }}
    >
      {open && <div className='select-custom-new-border-layer' />}
      <span className='filter-selected-item' ref={selectedFieldsSpanRef}>
        {selectedItems?.length === items?.length &&
          items?.length > 0 &&
          isMultiSelect &&
          `${t(lng, 'app.filters.all')} ${t(lng, placeholder)}`}
        {selectedItemsToShow?.length > 0 &&
          (selectedItems?.length !== items?.length || !isMultiSelect) &&
          selectedItemsToShow.map((item, index) => {
            return (
              <span key={index}>
                {customTextfieldLabel ? customTextfieldLabel(item) : item?.[dataNameKey || 'name']}
                {index < selectedItems?.length - 1 ? ', ' : ''}
              </span>
            );
          })}
        {(!selectedItemsToShow || selectedItemsToShow?.length === 0) && placeholder && t(lng, placeholder)}
      </span>
      {indicatorData?.show && selectedItems?.length !== items?.length && (
        <span className='indicator'>
          {' '}
          {`+${selectedItems?.length - indicatorData?.visibleSelectedItems?.length}`}
        </span>
      )}
      <img className={`filter-carousel-icon ${open ? 'open' : ''}`} src={carouselIcon} />
    </div>
  );
};

export default FilterTextField;
