import { T } from '../T';

const SocialIcons = () => {
  return (
    <>
      <div className='social-media'>
        <a href='https://twitter.com/Vaultody' target='_blank' rel='noreferrer'>
          <div className='x-media' />
        </a>
        <a href='https://www.linkedin.com/company/vaultody' target='_blank' rel='noreferrer'>
          <div className='linkedin' />
        </a>
        <a href='https://github.com/Vaultody' target='_blank' rel='noreferrer'>
          {' '}
          <div className='github' />
        </a>
      </div>
      <div className='vaultody-copy'>
        <T>app.all.rights.reserved</T>
      </div>
    </>
  );
};

export default SocialIcons;
