import './SwitchButton.css';
const SwitchButton = ({ label, switchFunction, switchState = false }) => {
  return (
    <div className='switch-button'>
      <label className='switch'>
        <input
          type='checkbox'
          checked={switchState}
          onChange={() => {
            switchFunction(!switchState);
          }}
        />
        <span className='slider round' />
      </label>
      <span className={`label ${switchState ? 'checked' : ''}`}>{label}</span>
    </div>
  );
};
export default SwitchButton;
