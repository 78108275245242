import { useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import menuIcon from '../../../assets/icons/hamburger-icon.svg';
import SwitchButton from '../../SwitchButton/SwitchButton';
import { T, t } from '../../T';
import { useDispatch, useSelector } from 'react-redux';
import { clientStatuses, setTypeUser } from '../../../redux/slices/userSlice';
import {
  ROUTE_DEVELOPERS,
  ROUTE_HOME,
  ROUTE_WALLET,
  ROUTE_WALLETS,
  ROUTE_WEBHOOK,
  ROUTE_TRX_HISTORY,
  ROUTE_TRX_REQUESTS,
  ROUTE_TRX_REQUESTS_SHELF,
  ROUTE_TRX_POLICY,
  ROUTE_TEAM,
  ROUTE_PROFILE,
  ROUTE_LOGIN_HISTORY,
  ROUTE_BILLING,
} from '../../../routes/routes';
import './MobileHeader.css';
import { Drawer } from '../../Drawer/Drawer';
import { ReactComponent as TrxHistoryIcon } from '../../../assets/icons/dashboardMenu/trx-history-icon.svg';
import { ReactComponent as RequestsIcon } from '../../../assets/icons/dashboardMenu/requests-icon.svg';
import { ReactComponent as AssetsIcon } from '../../../assets/icons/dashboardMenu/assets-icon.svg';
import { ReactComponent as VaultsIcon } from '../../../assets/icons/dashboardMenu/vaults-icon.svg';
import { ReactComponent as PolicyIcon } from '../../../assets/icons/dashboardMenu/policy-icon.svg';
import { ReactComponent as TeamIcon } from '../../../assets/icons/dashboardMenu/team-icon.svg';
import { ReactComponent as QuestionIcon } from '../../../assets/icons/question-mark-no-bg.svg';
import { ReactComponent as WebhookIcon } from '../../../assets/icons/webhook-icon.svg';
import { ReactComponent as LogoutIcon } from '../../../assets/icons/logout-icon.svg';
import { ReactComponent as ExternalIcon } from '../../../assets/icons/external.svg';
import { ReactComponent as PendingIcon } from '../../../assets/icons/pending.svg';
import { ReactComponent as PlanIcon } from '../../../assets/icons/plan-icon.svg';
import { ReactComponent as CodeIcon } from '../../../assets/icons/code-icon.svg';
import { ReactComponent as KeyIcon } from '../../../assets/icons/key-icon.svg';
import { ReactComponent as UserIcon } from '../../../assets/icons/user2.svg';
import { ReactComponent as ContactsIcon } from '../../../assets/icons/dashboardMenu/contacts-icon.svg';
import { setPage } from '../../../redux/slices/globalSlice';
import Accordion from '../../Accordion/Accordion';
import SocialIcons from '../../SocialIcons/SocialIcons';

const MobileHeader = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const typeUser = useSelector((state) => state.userReducer.typeUser);
  const myProfile = useSelector((state) => state.userReducer.myProfile);
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const userVerificationInfo = useSelector((state) => state.userReducer.verificationInfo);
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(location?.pathname);
  const [accordionOpenIndexes, setAccordionOpenIndexes] = useState([]);

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  const dashboardMenu = [
    {
      icon: <AssetsIcon className='icon' />,
      title: t(lng, 'app.assets'),
      link: ROUTE_HOME,
    },
    {
      icon: <VaultsIcon className='icon' />,
      title: t(lng, 'app.wallets'),
      link: ROUTE_WALLETS,
    },
    {
      icon: <RequestsIcon className='icon' />,
      title: t(lng, 'app.transaction.req'),
      link: ROUTE_TRX_REQUESTS,
      alternativeRoutes: ROUTE_TRX_REQUESTS_SHELF,
    },
    {
      icon: <TrxHistoryIcon className='icon' />,
      title: t(lng, 'app.trans.history'),
      link: ROUTE_TRX_HISTORY,
    },
    {
      icon: <ContactsIcon className='icon' />,
      title: t(lng, 'app.contacts'),
      link: '/contacts',
    },
    {
      icon: <PolicyIcon className='icon' />,
      title: t(lng, 'app.gov.leayer'),
      link: ROUTE_TRX_POLICY,
    },
    {
      icon: <TeamIcon className='icon' />,
      title: t(lng, 'app.team'),
      link: ROUTE_TEAM,
    },
    {
      icon: <CodeIcon className='icon' />,
      title: t(lng, 'app.developers'),
      subMenu: [
        {
          icon: <KeyIcon className='icon' />,
          title: t(lng, 'app.api.keys'),
          link: `${ROUTE_DEVELOPERS}#api-keys`,
        },
        {
          icon: <WebhookIcon className='icon' />,
          title: t(lng, 'app.webhooks'),
          link: `${ROUTE_DEVELOPERS}#webhooks`,
        },
      ],
    },
    {
      icon: <UserIcon className='icon' />,
      title: t(lng, 'app.menu.profile'),
      subMenu: [
        {
          icon: <KeyIcon className='icon' />,
          title: t(lng, 'app.menu.account.details'),
          link: ROUTE_PROFILE,
        },
        {
          icon: <PlanIcon className='icon' />,
          title: t(lng, 'app.plan.and.billing'),
          link: ROUTE_BILLING,
        },
        {
          icon: <PendingIcon className='icon' />,
          title: t(lng, 'app.menu.history'),
          link: ROUTE_LOGIN_HISTORY,
        },
      ],
    },
    {
      icon: <QuestionIcon style={{ padding: '2px' }} className='icon' />,
      title: 'Help',
      subMenu: [
        {
          icon: <ExternalIcon style={{ padding: '2px' }} className='icon' />,
          title: t(lng, 'app.menu.references'),
          link: 'https://developers.vaultody.com/v-1.2024-05-09-108/RESTapis/general-information/getting-started',
        },
        {
          icon: <ExternalIcon style={{ padding: '2px' }} className='icon' />,
          title: t(lng, 'app.menu.faq'),
          link: 'https://developers.vaultody.com/v-1.2024-05-09-108/FAQ/181-what-is-mpc-wallet',
        },
        {
          icon: <ExternalIcon style={{ padding: '2px' }} className='icon' />,
          title: t(lng, 'app.menu.glossary'),
          link: 'https://developers.vaultody.com/v-1.2024-05-09-108/Glossary/189-123',
        },
        {
          icon: <ExternalIcon style={{ padding: '2px' }} className='icon' />,
          title: t(lng, 'app.menu.guides'),
          link: 'https://developers.vaultody.com/v-1.2024-05-09-108/Guides/178-backup-and-restore-a-vault',
        },
        {
          icon: <ExternalIcon style={{ padding: '2px' }} className='icon' />,
          title: t(lng, 'app.menu.support'),
          link: 'https://vaultody.com/integrations/protocols',
        },
        {
          icon: <ExternalIcon style={{ padding: '2px' }} className='icon' />,
          title: t(lng, 'app.menu.blog'),
          link: 'https://vaultody.com/blog',
        },
        {
          icon: <ExternalIcon style={{ padding: '2px' }} className='icon' />,
          title: t(lng, 'app.menu.privacy'),
          link: 'https://vaultody.com/privacy-policy',
        },
        {
          icon: <ExternalIcon style={{ padding: '2px' }} className='icon' />,
          title: t(lng, 'app.menu.terms'),
          link: 'https://vaultody.com/terms-conditions',
        },
        {
          icon: <ExternalIcon style={{ padding: '2px' }} className='icon' />,
          title: t(lng, 'app.menu.security'),
          link: 'https://vaultody.com/data-security-policy',
        },
      ],
    },
    {
      icon: <LogoutIcon style={{ padding: '2px' }} className='icon' />,
      title: t(lng, 'app.menu.signout'),
      link: ROUTE_LOGIN_HISTORY,
      onClick: () => {
        localStorage.clear();
        window.location.replace(ROUTE_HOME);
      },
    },
  ];

  const handleTestModeToggle = (switchState) => {
    if (
      userVerificationInfo?.state?.toUpperCase() === clientStatuses.ACTIVE ||
      userVerificationInfo?.state === null
    ) {
      const mode = switchState ? 'test' : 'main';
      dispatch(setTypeUser(mode));
      localStorage.setItem('mode', mode);
      if (location.pathname?.includes(`${ROUTE_WALLET}/`)) {
        navigate(ROUTE_HOME);
      }
      if (location.pathname?.includes(`${ROUTE_WEBHOOK}/`)) {
        navigate(`${ROUTE_DEVELOPERS}#api-keys`);
      }
    }
  };

  useEffect(() => {
    if (userVerificationInfo?.state !== undefined) {
      const typeUserFromLocalStorage =
        localStorage.getItem('mode') === 'test' ||
        (userVerificationInfo?.state !== null &&
          userVerificationInfo?.state?.toUpperCase() !== clientStatuses.ACTIVE)
          ? 'test'
          : 'main';
      dispatch(setTypeUser(typeUserFromLocalStorage));
    }
  }, [userVerificationInfo?.state]);

  useEffect(() => {
    if (active.includes('wallet')) {
      setActive(ROUTE_WALLETS);
    }
  }, [active]);

  useEffect(() => {
    if (
      location.pathname.includes(ROUTE_PROFILE) ||
      location.pathname.includes(ROUTE_LOGIN_HISTORY) ||
      location.pathname.includes(ROUTE_BILLING)
    ) {
      setActive('');
    }
    if (location.pathname === ROUTE_HOME) {
      setActive(ROUTE_HOME);
      dispatch(setPage(t(lng, 'app.assets')));
    }
    if (location.pathname.includes(ROUTE_TRX_POLICY)) {
      setActive(ROUTE_TRX_POLICY);
    }
  }, [location]);

  const hadleClickMenuItem = (menuLink, menuTitle) => {
    setActive(menuLink);
    dispatch(setPage(t(lng, menuTitle)));
  };

  return (
    <div className={`mobile-header ${typeUser}`}>
      <div className='mobile-header-hamburger'>
        <img src={menuIcon} className='hamburger-menu' onClick={handleOpen} />
      </div>
      <div className='mobile-header-logo'>
        <img src={'/logo192.png'} onClick={() => navigate(ROUTE_HOME)} />
      </div>
      <div className='mobile-header-testmode'>
        <SwitchButton label='TEST' switchFunction={handleTestModeToggle} switchState={typeUser === 'test'} />
      </div>
      {typeUser === 'test' && (
        <div className='testdata'>
          <T>app.test.data</T>
        </div>
      )}
      <Drawer anchor='left' open={open} onClose={handleClose}>
        <div className='mobile-menu-user'>
          <span>{myProfile?.fullName}</span>
          <span className='email'>{myProfile?.email}</span>
        </div>
        <div className='mobile-menu'>
          <ul>
            {dashboardMenu.map((menuItem, menuKey) => {
              return menuItem?.subMenu ? (
                <li key={menuKey} className='no-menu-active-effects'>
                  <Accordion
                    open={accordionOpenIndexes?.includes(menuKey)}
                    onOpenCallback={(isAccordionOpened) => {
                      if (!isAccordionOpened) {
                        setAccordionOpenIndexes((prev) => [...prev, menuKey]);
                      } else {
                        setAccordionOpenIndexes((prev) =>
                          prev?.filter((accordionIndex) => accordionIndex !== menuKey),
                        );
                      }
                    }}
                    titleComponent={
                      <div className='mobile-menu-accordion-title-wrapper'>
                        {menuItem?.icon}
                        <div className='text'>{menuItem.title}</div>
                      </div>
                    }
                    detailsComponent={
                      <div className='mobile-menu-submenu-wrapper'>
                        <ul>
                          {menuItem?.subMenu?.map((subMenuItem, key) => (
                            <Link to={subMenuItem.link} key={`submenu-${key}`}>
                              <li
                                onClick={() => {
                                  hadleClickMenuItem(subMenuItem?.link, subMenuItem?.title);
                                  setAccordionOpenIndexes((prev) =>
                                    prev?.filter((accordionIndex) => accordionIndex !== menuKey),
                                  );
                                  handleClose();
                                }}
                                className={`submenu-active-effects ${
                                  active === subMenuItem.link ||
                                  (subMenuItem?.alternativeRoutes &&
                                    active?.includes(subMenuItem?.alternativeRoutes))
                                    ? 'active'
                                    : ''
                                }`}
                              >
                                {subMenuItem?.icon}
                                <div className='text'>{subMenuItem.title}</div>
                              </li>
                            </Link>
                          ))}
                        </ul>
                      </div>
                    }
                  />
                </li>
              ) : (
                <div key={menuKey}>
                  <Link to={menuItem.link}>
                    <li
                      onClick={() => {
                        if (menuItem?.onClick) {
                          menuItem?.onClick();
                        } else {
                          hadleClickMenuItem(menuItem?.link, menuItem?.title);
                          handleClose();
                        }
                      }}
                      className={
                        active === menuItem.link ||
                        (menuItem?.alternativeRoutes && active?.includes(menuItem?.alternativeRoutes))
                          ? 'active'
                          : ''
                      }
                    >
                      {menuItem?.icon}
                      <div className='text'>{menuItem.title}</div>
                    </li>
                  </Link>
                </div>
              );
            })}
          </ul>
          <div>
            <SocialIcons />
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default MobileHeader;
