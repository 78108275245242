import React, { useState } from 'react';
import Textfield from '../../../Textfield/Textfield';
import CheckboxNew from '../../../CheckboxNew/CheckboxNew';
import Alert from '../../../Alert/Alert';
import Button from '../../../Button/Button';
import { T, t } from '../../../T';
import { useSelector } from 'react-redux';

const CreateVaultAutomationRuleNameStep = ({ isEdit, selectedName, handleChooseName, submitCallback }) => {
  const [consent, setConsent] = useState(isEdit || false);
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const typeUser = useSelector((state) => state.userReducer.typeUser);

  return (
    <>
      <div className='v-input-group'>
        <label>
          <T>app.automation.name</T>
        </label>
        <div className='vault-automation-rule-textfield-wrapper'>
          <Textfield
            type='text'
            fullWidth
            size='lg'
            placeholder={t(lng, 'app.placeholder.wallet.create')}
            value={selectedName}
            onChange={(event) => handleChooseName(event.currentTarget.value)}
          />
        </div>
      </div>
      <div className='vault-automation-rule-alert-consent-button-wrapper'>
        {typeUser === 'test' && (
          <>
            <Alert variant='warning' text={t(lng, 'app.vault.creation.testnet')} />
            <CheckboxNew
              id='termsCheckbox'
              disabledHoverEffect
              multiSelect
              reverse
              label={
                <label className='form-check-label pt-1 fs-6' htmlFor='termsCheckbox'>
                  <T>app.alert.i-agree-with</T>
                </label>
              }
              value={consent}
              onChange={() => setConsent((prev) => !prev)}
            />
          </>
        )}
        <Button
          fullWidth
          disabled={!selectedName || (typeUser === 'test' && !consent)}
          onClick={() => submitCallback()}
        >
          <T>app.next.step</T>
        </Button>
      </div>
    </>
  );
};

export default CreateVaultAutomationRuleNameStep;
