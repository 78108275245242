import { T, t } from '../../T';
import VModal from '../../Modal/VModal';
import { useSelector } from 'react-redux';
import { ReactComponent as SummaryIcon } from '../../../assets/icons/summary-icon2.svg';
import CopyButton from '../../CopyButton/CopyButton';
import { currency } from '../../../helpers/currency';
import VaultIcon from '../../VaultIcon/VaultIcon';
import Blockchain from '../../Blockchain/Blockchain';
import { getSenderAndReceiversWalletNames } from '../../../helpers/transactionrequests/utils';
import BigNumber from 'bignumber.js';
import VTooltip from '../../VTooltip/VTooltip';
import './DetailsTransactionDraft.css';
import Button from '../../Button/Button';
import Alert from '../../Alert/Alert';
import { convertTrxDraftApi } from '../../../api/endpoints';

const DetailsTransactionDraft = ({ toggleFunction, data }) => {
  const protocols = useSelector((state) => state.globalReducer.protocols);
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const myProfile = useSelector((state) => state.userReducer.myProfile);
  const senderAndReceiversWalletNames = getSenderAndReceiversWalletNames(data, true, true);
  const convertDraftPermission = myProfile.premmisions?.includes('TX_DRAFT_CONVERT');

  const resepientsAmount = data.recipients.map((i) => {
    return BigNumber(i.amount).toNumber();
  });
  const resepientsAmountInUSD = data.recipients.map((i) => {
    return BigNumber(i.amount).toNumber() * data?.asset?.exchangeRate;
  });
  const sumreResepientsAmount = BigNumber.sum(...resepientsAmount).toFixed();
  const sumResepientsAmountInUSD = BigNumber.sum(...resepientsAmountInUSD).toFixed();

  const handleSendDraftForApproval = () => {
    convertTrxDraftApi({ item: { draftIds: [data?.id] } }).then(() => toggleFunction());
  };

  return (
    <VModal classHandler='details-transaction-draft-modal' toggleFunction={toggleFunction}>
      <div className='v-modal-title'>
        <T>app.transaction.details.draft</T>
      </div>
      <div className='details-transaction-draft-summary'>
        <SummaryIcon />
        <T>app.summary</T>
      </div>
      <div>
        {data?.failReason && (
          <Alert variant='error' text={`${t(lng, 'app.trx.fail.reason')}: ${data?.failReason}`} />
        )}
        <TransactionDraftDetailsRow
          title={t(lng, 'app.wallet')}
          info={
            <div className='details-transaction-draft-wallet-wrapper'>
              <VaultIcon
                id={data?.senders?.[0]?.vaultId}
                name={data?.senders?.[0]?.name}
                color={data?.senders?.[0]?.color}
                vaultType={data?.senders?.[0]?.vaultType}
              />
              <span className='details-transaction-draft-wallet-name'>{data?.senders?.[0]?.name}</span>
            </div>
          }
        />
        <TransactionDraftDetailsRow
          title={t(lng, 'app.asset')}
          info={
            <div className='details-transaction-draft-blockchain'>
              <Blockchain
                showText
                assetType={data.type?.toUpperCase()}
                blockchain={protocols
                  ?.find(
                    (protocol) => protocol?.blockchain?.toLowerCase() === data?.blockchain?.toLowerCase(),
                  )
                  ?.currency?.toUpperCase()}
                symbol={data?.asset?.symbol}
              />
              <span className='details-transaction-draft-blockchain-label'>{` (${data?.asset?.symbol})`}</span>
            </div>
          }
        />
        <TransactionDraftDetailsRow
          title={t(lng, 'app.from')}
          info={
            <div className='details-transaction-draft-address-info-wrapper'>
              <div className='details-transaction-draft-address-info-info'>
                <span className='details-transaction-draft-address-info-name'>
                  {senderAndReceiversWalletNames?.sender?.name}
                </span>
                <span className='details-transaction-draft-address-info-address'>
                  {senderAndReceiversWalletNames?.sender?.address}
                </span>
              </div>
              <CopyButton element={senderAndReceiversWalletNames?.sender?.address} />
            </div>
          }
        />
        <TransactionDraftDetailsRow
          title={t(lng, 'app.to')}
          info={
            <div className='details-transaction-draft-to-address-scrollable'>
              {data?.recipients?.map((recipient, index) => (
                <div
                  key={`${recipient?.address} - ${index}`}
                  className='details-transaction-draft-address-info-wrapper'
                >
                  <div className='details-transaction-draft-address-info-info'>
                    <span className='details-transaction-draft-address-info-name'>
                      {recipient?.name || recipient?.contactName}
                    </span>
                    <span className='details-transaction-draft-address-info-address'>
                      {recipient?.address}
                    </span>
                  </div>
                  <CopyButton element={recipient?.address} />
                </div>
              ))}
            </div>
          }
        />
        <TransactionDraftDetailsRow
          title={t(lng, 'app.amount')}
          info={
            <div
              className='details-transaction-draft-amount-text'
              data-tooltip-id='trx-no-amounts-draft'
              data-tooltip-content={`${sumreResepientsAmount} ${data.asset.symbol}`}
            >
              <VTooltip id='trx-no-amounts-draft' />
              {sumreResepientsAmount} {data.asset.symbol}
              <div className='details-transaction-draft-amount-text-sub'>
                {`(${currency(sumResepientsAmountInUSD)})`}
              </div>
            </div>
          }
        />
        <TransactionDraftDetailsRow
          title={t(lng, 'app.fee.priority')}
          info={<span className='details-transaction-draft-priority'>{data?.feePriority || '-'}</span>}
        />
        <TransactionDraftDetailsRow
          title={t(lng, 'app.note')}
          info={<span className='details-transaction-draft-note'>{data?.note || '-'}</span>}
        />
      </div>
      {!data?.failReason && data?.status === 'created' && (
        <>
          <Alert variant='warning' text={t(lng, 'app.submit.convert.alert.single')} />
          <div className='details-transaction-draft-button'>
            <Button
              size='md'
              fullWidth
              onClick={handleSendDraftForApproval}
              disabled={data?.failReason || !convertDraftPermission}
              data-tooltip-id='trx-convert-draft-permission'
              data-tooltip-content={
                !convertDraftPermission ? t(lng, 'app.role.limitation') : data?.failReason
              }
            >
              {!convertDraftPermission && <VTooltip id='trx-convert-draft-permission' />}
              <i className='icon-submit-for-approval' />
              <T>app.submit.draft</T>
            </Button>
          </div>
        </>
      )}
    </VModal>
  );
};

const TransactionDraftDetailsRow = ({ title, info }) => {
  return (
    <div className='trx-draft-details-row'>
      <span>{title}</span>
      {info}
    </div>
  );
};

export default DetailsTransactionDraft;
