import './UpgradePlanModal.css';
import { ReactComponent as UpgradeIcon } from '../../../../assets/images/upgrade-icon.svg';
import { ReactComponent as PhoneIcon } from '../../../../assets/icons/phone-icon.svg';
import VModal from '../../../Modal/VModal';
import { T } from '../../../T';
import Button from '../../../Button/Button';

const UpgradePlanModal = ({ setCloseModal }) => {
  const handleUpgradeButton = () => {
    window.open('https://book.vaultody.com/#/introduction', '_blank');
  };

  return (
    <VModal classHandler='upgrade-sub-modal-content' toggleFunction={setCloseModal}>
      <div className='upgrade-sub-modal-img-wrapper'>
        <UpgradeIcon />
      </div>
      <span className='upgrade-sub-modal-heading'>
        <T>app.are.you.ready.upgrade.plan</T>
      </span>
      <span className='upgrade-sub-modal-sub-heading'>
        <T>app.upgrade.plan.desc</T>
      </span>
      <div className='upgrade-sub-modal-button-wrapper'>
        <Button variant='primary' size='lg' fullWidth onClick={handleUpgradeButton}>
          <PhoneIcon style={{ marginRight: 5 }} />
          <T>app.book.call</T>
        </Button>
        <Button variant='neutral' size='lg' fullWidth onClick={() => setCloseModal(false)}>
          <T>app.close</T>
        </Button>
      </div>
    </VModal>
  );
};

export default UpgradePlanModal;
