import React from 'react';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';

export const T = (props) => {
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  return <>{parse(t(lng, props.children))}</>;
};

export const t = (lng, key) => {
  return lng[key] ? lng[key] : key;
};
