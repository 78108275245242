import { useSelector } from 'react-redux';
import '../CreateTransactionRequestNew.css';
import { T, t } from '../../../T';
import Button from '../../../Button/Button';
import Filter from '../../../Filter/Filter';
import minimizeIcon from '../../../../assets/icons/optimize-size-icon.svg';
import optimizeIcon from '../../../../assets/icons/minimize-dust-icon.svg';
import speedFastIcon from '../../../../assets/icons/speedometer-icon.svg';
import speedSlowIcon from '../../../../assets/icons/slow-speedo.svg';
import speedMediumIcon from '../../../../assets/icons/medium-speedo.svg';

const CreateTransactionRequestStrategyStep = ({
  selectedStrategy,
  selectedFeePriority,
  handleChooseStrategy,
  handleChooseFeePriority,
  strategyFees,
  submitCallback,
}) => {
  const lng = useSelector((state) => state.localizationReducer.selectedData);

  return (
    <div className='create-trx-request-step-three-wrapper'>
      <div className='create-trx-request-modal-select-component-wrapper'>
        <span className='create-trx-request-modal-select-component-label'>
          <T>app.prepare.strategy</T>
        </span>
        <Filter
          key='strategy-selector'
          items={[
            {
              name: t(lng, 'app.strategy.minimize'),
              id: 'minimize-dust',
              desc: t(lng, 'app.strategy.minimize.desc'),
              icon: optimizeIcon,
              value: 'minimize_dust',
            },
            {
              name: t(lng, 'app.strategy.optimize'),
              id: 'optimize-size',
              desc: t(lng, 'app.strategy.optimize.desc'),
              icon: minimizeIcon,
              value: 'optimize_size',
            },
          ]}
          selectedItems={selectedStrategy}
          onChange={handleChooseStrategy}
          fullWidth
          placeholder='app.prepare.strategy'
          disableFocusShadowEffect
          customLabelTemplate={(strategy) => (
            <div className='create-trx-request-priority-wrapper'>
              <img className='create-trx-request-priority-icon' src={strategy?.icon} alt='' />
              <div className='create-trx-request-priority-data-wrapper'>
                <span className='create-trx-request-priority-data-label'>{strategy?.name}</span>
                <span className='create-trx-request-priority-data-desc-label'>{strategy?.desc}</span>
              </div>
            </div>
          )}
        />
      </div>
      <div className='create-trx-request-modal-select-component-wrapper'>
        <span className='create-trx-request-modal-select-component-label'>
          <T>app.fee.priority</T>
        </span>
        <Filter
          items={[
            {
              name: 'Slow',
              id: 'slow',
              desc: t(lng, 'app.fee.priority.slow.desc'),
              fee: strategyFees?.slow?.feeValue,
              value: 'slow',
              icon: speedSlowIcon,
            },
            {
              name: 'Standard',
              id: 'standard',
              desc: t(lng, 'app.fee.priority.standard.desc'),
              fee: strategyFees?.standard?.feeValue,
              value: 'standard',
              icon: speedMediumIcon,
            },
            {
              name: 'Fast',
              id: 'fast',
              desc: t(lng, 'app.fee.priority.fast.desc'),
              fee: strategyFees?.fast?.feeValue,
              value: 'fast',
              icon: speedFastIcon,
            },
          ]}
          selectedItems={selectedFeePriority}
          onChange={handleChooseFeePriority}
          fullWidth
          placeholder='app.fee.priority'
          disableFocusShadowEffect
          customLabelTemplate={(priority) => (
            <div className='create-trx-request-priority-wrapper'>
              <img className='create-trx-request-priority-icon' src={priority?.icon} alt='' />
              <div className='create-trx-request-priority-data-wrapper'>
                <span className='create-trx-request-priority-data-label'>{priority?.name}</span>
                <span className='create-trx-request-priority-data-desc-label'>{priority?.desc}</span>
                <span className='create-trx-request-priority-data-fee-label'>
                  ~ {strategyFees?.unit?.toLowerCase()} {priority?.fee}
                </span>
              </div>
            </div>
          )}
        />
      </div>
      <div className='create-trx-request-modal-button'>
        <Button
          fullWidth
          onClick={submitCallback}
          disabled={!selectedFeePriority?.length || !selectedStrategy?.length}
        >
          <T>app.next.step</T>
        </Button>
      </div>
    </div>
  );
};

export default CreateTransactionRequestStrategyStep;
