import { useEffect, useState } from 'react';
import { T, t } from '../../T';
import { Link } from 'react-router-dom';
import { getWalletRulesApi } from '../../../api/endpoints';
import { useDispatch, useSelector } from 'react-redux';
import { setPage } from '../../../redux/slices/globalSlice';

const WalletsRulesTable = ({ id }) => {
  const dispatch = useDispatch();
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const trialBannerVisible = useSelector((state) => state.globalReducer.trialBannerVisible);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getWalletRulesApi(id)
      .then((res) => {
        setData(res.data.items);
      })
      .finally(() => setLoading(true));
  }, []);

  const onlyFirstNameSection = (data) => {
    if (data.length === 0) {
      return (
        <div className='no-approve'>
          <T>app.level2.no.approver</T>
        </div>
      );
    } else {
      const onlyFirstName = data.map((name) => {
        return name.name.split(' ')[0];
      });
      return <div>{onlyFirstName.join(', ')}</div>;
    }
  };

  return (
    loading && (
      <>
        <div className='link'>
          <Link to='/governance-layer' onClick={() => dispatch(setPage(t(lng, 'app.gov.leayer')))}>
            <T>app.want.new.rule</T>
            <i className='icon-arrow-right' />
          </Link>
        </div>
        <div
          className={`table governance-layer-table wallets-rules-table ${
            trialBannerVisible ? 'trial-banner-visible' : ''
          }`}
        >
          <div className='table-content'>
            <div className='table-row caption'>
              <div className='wallets-policy-table-data range'>
                <T>app.range</T>
              </div>
              <div className='wallets-policy-table-data name'>
                <T>app.rule.name</T>
              </div>
              <div className='wallets-policy-table-data level'>
                <T>app.level1</T>
              </div>
              <div className='wallets-policy-table-data level'>
                <T>app.level2</T>
              </div>
            </div>
            {data?.map((item, key) => {
              return (
                <div key={key} className='table-row data'>
                  <div className='wallets-policy-table-data range'>
                    <span className='mobile-cell-title'>
                      <T>app.range</T>
                    </span>
                    <div>
                      <T>app.from</T> {item.range.start} USD TO {item.range.end} USD
                    </div>
                  </div>
                  <div className='wallets-policy-table-data name'>
                    <span className='mobile-cell-title'>
                      <T>app.rule.name</T>
                    </span>
                    <div className='name-rule'>{item.name}</div>
                  </div>
                  <div className='wallets-policy-table-data level'>
                    <span className='mobile-cell-title'>
                      <T>app.level1</T>
                    </span>
                    {onlyFirstNameSection(item.l1)}
                  </div>
                  <div className='wallets-policy-table-data level'>
                    <span className='mobile-cell-title'>
                      <T>app.level2</T>
                    </span>
                    {onlyFirstNameSection(item.l2)}
                  </div>
                </div>
              );
            })}
            {data.length === 0 && (
              <div className='table-row no-active'>
                <div>
                  <T>app.no.gov.data</T>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    )
  );
};

export default WalletsRulesTable;
