import { createSlice } from '@reduxjs/toolkit';

// USER STATES FROM SERVER
export const STATE_VERIFY_EMAIL = 'verify-email';
export const STATE_REGISTER_MOBILE = 'register-mobile';
export const STATE_PENDING_APPROVAL = 'admin-approval';
export const STATE_REGISTER_PSP = 'register_psp';
export const STATE_DASHBOARD = 'dashboard';
export const STATE_PENDING_REGISTRATION = 'pending-registration';
export const STATE_AWAIT_APPROVAL = 'await-approval';
export const STATE_REJECTED = 'rejected';
export const STATE_DELETED = 'deleted';
export const STATE_PENDING_NON_ADMIN_APPROVAL = 'pending-approval';
export const STATE_PENDING_VERIFICATION = 'pending-verification';

// USER STATES FROM FRONTEND
export const LOGIN_ATTEMPT = 'LOGIN_ATTEMPT';

const initialState = {
  isLogged: undefined,
  token: '',
  attempts: 0,
  state: '',
};

export const authSlice = createSlice({
  name: 'authReducer',
  initialState: initialState,
  reducers: {
    loginUser: (state, action) => {
      state.isLogged = action.payload ? true : false;
      state.token = action.payload || '';
      state.attempts = action.payload ? state.attempts + 1 : state.attempts;
    },
    logoutUser: (state) => {
      state.isLogged = false;
      state.token = '';
    },
    dashboardState: (state, action) => {
      state.state = action.payload;
    },
    loginAttempt: (state, action) => {
      state = action.payload;
    },
  },
});

export const { loginUser, logoutUser, dashboardState, loginAttempt } = authSlice.actions;

export default authSlice.reducer;

// Actions
// export const fetchUsers = () => async (dispatch) => {
//   try {
//     await api
//       .get("/users")
//       .then((response) => dispatch(usersSuccess(response.data)));
//   } catch (e) {
//     return console.error(e.message);
//   }
// };
