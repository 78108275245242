import { currency } from '../../helpers/currency';
import { vaultodyDate } from '../../helpers/date';
import VModal from '../Modal/VModal';
import { T } from '../T';
import infinityIcon from '../../assets/icons/infinity.svg';
import VaultIcon from '../VaultIcon/VaultIcon';

const GLViewModal = ({ toggleFunction, viewData }) => {
  return (
    <VModal toggleFunction={toggleFunction}>
      <div className='view-modal'>
        <span className='trx-rule-view-modal-title'>
          <T>app.policy.rule.details</T>
        </span>
        <div className='trx-rule-details-info-view'>
          <div className='trx-rule-details-range-info'>
            <div className='trx-rule-details-info-view-sub-heading'>
              <T>app.amount.limit.24.hours</T>
            </div>
            <div>
              <span className='trx-rule-details-info-view-heading'>{`From ${currency(
                viewData?.range?.start,
              )} to `}</span>
              {viewData?.range?.end === 0 ? (
                <img className='infin' src={infinityIcon} />
              ) : (
                <span className='trx-rule-details-info-view-heading'>{currency(viewData?.range?.end)}</span>
              )}
            </div>
          </div>
        </div>
        <div className='trx-rule-details-info-view'>
          <div className='trx-rule-details-info-row'>
            <div className='trx-rule-details-info-view-sub-text'>
              <T>app.rule.name</T>
            </div>
            <div className='trx-rule-details-info-view-info-text'>{viewData?.name}</div>
          </div>
          <div className='trx-rule-details-info-row'>
            <div className='trx-rule-details-info-view-sub-text'>
              <T>app.created.by</T>
            </div>
            <div className='trx-rule-details-info-view-info-text'>{viewData?.createdBy}</div>
          </div>
          <div className='trx-rule-details-info-row'>
            <div className='trx-rule-details-info-view-sub-text'>
              <T>app.created.date.time</T>
            </div>
            <div className='trx-rule-details-info-view-info-text'>
              {vaultodyDate(viewData?.createdAt, 'full')}
            </div>
          </div>
        </div>
        <div className='trx-rule-details-approves'>
          <span className='trx-rule-details-info-view-section-text'>
            <T>app.selected.members.approvers</T>
          </span>
          <div className='trx-rule-details-levels'>
            <div className='trx-rule-details-level trx-rule-details-level1'>
              <span className='trx-rule-details-title'>
                <T>app.level1</T>
              </span>
              <div className='trx-rule-details-values'>
                {viewData?.l1?.map((approver, key) => {
                  return <div key={key}>{approver.name}</div>;
                })}
                {(viewData?.l1?.length === 0 || !viewData?.l1?.length) && (
                  <div>
                    <T>app.level1.no.approver</T>
                  </div>
                )}
              </div>
            </div>
            <div className='trx-rule-details-level trx-rule-details-level2'>
              <span className='trx-rule-details-title'>
                <T>app.level2</T>
              </span>
              <div className='trx-rule-details-values'>
                {viewData?.l2?.map((approver, key) => {
                  return <div key={key}>{approver.name}</div>;
                })}
                {(viewData?.l2?.length === 0 || !viewData?.l2?.length) && (
                  <div>
                    <T>app.level2.no.approver</T>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='trx-rule-details-title'>
          <T>app.selected.vaults</T>
        </div>
        <div className='trx-rule-details-wallets'>
          {viewData?.wallets?.map((wallet, key) => {
            return (
              <div className='trx-rule-details-wallets-row' key={key}>
                <div className='trx-rule-details-wallet'>
                  <VaultIcon id={wallet?.id} name={wallet?.name} color={wallet?.color} />
                </div>
                <div className='trx-rule-details-info-view-info-text'>{wallet.name}</div>
              </div>
            );
          })}
        </div>
      </div>
    </VModal>
  );
};

export default GLViewModal;
