import { useEffect, useState } from 'react';
import TransactionsHistory from '../../../pages/TransactionsHistory/TransactionsHistory';
import { getWalletApi } from '../../../api/endpoints';
import CreateTransactionRequestNew from '../../Modals/CreateTransactionRequestNew/CreateTransactionRequestNew';
import { vaultTypes } from '../../../helpers/constants';

const WalletTHTable = ({ id, backup }) => {
  const [openModalTR, setOpenModalTR] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    getWalletApi(id).then((res) => setData(res.data.item));
  }, []);

  return (
    <div className='wallet-th'>
      {data?.id && (
        <TransactionsHistory
          wallet={data}
          backup={backup}
          setOpenModalTR={setOpenModalTR}
          buttonVisble={data?.vaultType?.toLowerCase() !== vaultTypes.AUTOMATION ? 'true' : 'false'}
        />
      )}
      {openModalTR && <CreateTransactionRequestNew toggleFunction={setOpenModalTR} prefilledWallet={data} />}
    </div>
  );
};
export default WalletTHTable;
