import React, { useEffect, useState } from 'react';
import { useJwt } from 'react-jwt';
import QRCode from 'react-qr-code';
import GuestLayout from '../components/GuestLayout';
import TeamIcon from '../assets/images/mobile-download.svg';
import { T } from '../components/T';
import { getDeviceApi } from '../api/endpoints';
import { LOGIN_ATTEMPT, STATE_DASHBOARD, dashboardState } from '../redux/slices/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ROUTE_HOME } from '../routes/routes';

const VerifyMobileStep2 = () => {
  const messageSocket = useSelector((state) => state.globalReducer.messageSocket);
  const token = localStorage.getItem('token');
  const { decodedToken } = useJwt(token);
  const [loading, setLoading] = useState(false);
  const [deviceInfo, setDeviceInfo] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      getDeviceApi({
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => {
        setDeviceInfo(res.data.item);
      });
    }
  }, [token]);

  useEffect(() => {
    setLoading(true);
  }, [token]);

  useEffect(() => {
    if (messageSocket) {
      const jsonData = messageSocket;
      if (jsonData?.data?.item?.state === STATE_DASHBOARD) {
        dispatch(dashboardState(LOGIN_ATTEMPT));
      }
      if (jsonData.data.event === 'login_attempt_approved') {
        localStorage.setItem('token', jsonData.data.item.token);
        localStorage.setItem('state', jsonData.data.item.userState);
        dispatch(dashboardState(jsonData.data.item.userState));
      } else if (jsonData.data.event === 'login_attempt_rejected') {
        window.location.replace(ROUTE_HOME);
      }
    }
  }, [messageSocket]);

  return (
    <GuestLayout>
      <div className='guest-flow-form-wrapper'>
        <div className='guest-flow-form-main'>
          <div className='register-mobile'>
            <div className='icon'>
              <img src={TeamIcon} alt='icon' />
            </div>
            <div className='fw-bolder text-center link-account-bold-text'>
              <T>app.scan.qr.code.below</T>
            </div>
            <div className='guest-flow-subtitle text-center'>
              <T>app.open.valultody.qr.text</T>
            </div>
            {loading && Object.values(deviceInfo).length > 0 && (
              <QRCode
                className='qr-code'
                value={JSON.stringify({
                  baseUrl: window.location.origin,
                  clientId: decodedToken.clientId,
                  userId: decodedToken.userId,
                  deviceId: deviceInfo.deviceId,
                  activationCode: deviceInfo.activationCode,
                })}
              />
            )}
            <div className='waiting-scan'>
              <T>app.waiting.for.scan</T>
            </div>
          </div>
        </div>
      </div>
    </GuestLayout>
  );
};

export default VerifyMobileStep2;
