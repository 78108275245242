import VModal from '../../Modal/VModal';
import './SelfOnboardingSuspendedModal.css';
import SelfOnboardingModalCard from '../SelfOnboardingModal/SelfOnboardingModalCard';
import requiredStepsIcon from '../../../assets/icons/required-steps.svg';
import { T, t } from '../../T';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../Button/Button';
import { logoutUser } from '../../../redux/slices/authSlice';
import { getStripeBillingPortalLink } from '../../../api/endpoints';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PRICING_TABLE } from '../../../routes/routes';

const SelfOnboardingSuspendedModal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const myProfile = useSelector((state) => state.userReducer.myProfile);
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const userVerificationInfo = useSelector((state) => state.userReducer.verificationInfo);

  if (!myProfile) return null;
  const isOwner = myProfile.roles?.some((i) => {
    return i.name === 'owner';
  });

  const stripeBillingPortalHandler = async () => {
    if (userVerificationInfo?.verificationData?.stripe?.subscriptionStatus?.toLowerCase() === 'canceled') {
      navigate(ROUTE_PRICING_TABLE);
      return;
    }
    return getStripeBillingPortalLink().then((res) => {
      if (res?.data?.item?.url) {
        window.location.href = res?.data?.item?.url;
      }
    });
  };

  return (
    <VModal toggleFunction={() => {}} classHandler='suspended-self-onboarding-modal' hideCloseIcon>
      <div className='suspended-onboard-icon-wrapper'>
        <img className='suspended-onboard-modal-image' src={requiredStepsIcon} />
      </div>
      <div className='suspended-onboarding-modal-trial-wrapper'>
        <T>app.account.suspended</T>
      </div>
      <span className='suspended-onboarding-modal-sub-text'>
        <T>app.use.stripe.link.below</T>
      </span>
      <div className='suspended-cards-wrapper'>
        <SelfOnboardingModalCard
          type='suspended'
          status='SUSPENDED'
          isOwner={isOwner}
          callback={stripeBillingPortalHandler}
          tooltipText={t(lng, 'app.should.complete.by.owner')}
        />
      </div>
      <Button
        variant='neutral'
        onClick={() => {
          localStorage.clear();
          dispatch(logoutUser());
          window.location.replace('/');
        }}
      >
        <T>app.back.to.login</T>
      </Button>
    </VModal>
  );
};

export default SelfOnboardingSuspendedModal;
