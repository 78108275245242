import './PlanDetailsBox.css';

const PlanDetailsBox = ({ title, info, icon }) => {
  return (
    <div className='plan-details-box-wrapper'>
      <div className='plan-details-box-icon'>{icon}</div>
      <div className='plan-details-box-info-wrapper'>
        <span className='plan-details-box-title'>{title}</span>
        <span className='plan-details-box-info'>{info}</span>
      </div>
    </div>
  );
};

export default PlanDetailsBox;
