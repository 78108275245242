import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_LANGUAGE } from './../../config/Common';

const defaultLanguage = DEFAULT_LANGUAGE;

const initialState = {
  default: defaultLanguage,
  selectedLanguage: defaultLanguage,
  selectedData: {},
  data: {},
};

export const localizationSlice = createSlice({
  name: 'localizationReducer',
  initialState: initialState,
  reducers: {
    setLocalization: (state, action) => {
      let selectedData;
      if (action.payload.items) {
        action.payload.data.items.forEach((selected) => {
          if (selected.code === action.selectedLanguage) {
            selectedData = selected.translations;
            return false;
          }
        });
      } else {
        selectedData = action.payload.item.translations;
      }
      state.selectedLanguage = action.selected;
      state.data = action.payload;
      state.selectedData = selectedData;
    },
  },
});

export const { setLocalization } = localizationSlice.actions;

export default localizationSlice.reducer;
