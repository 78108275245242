import './Button.css';

const Button = ({
  children,
  size = 'lg', // sm, md, lg
  variant = 'primary', // primary, secondary, dark, delete, neutral, close
  fullWidth,
  width,
  icon,
  ...rest
}) => {
  return (
    <button
      style={{ ...(width && { width: `${width}px` }) }}
      className={`button-component-main ${variant} ${size} ${fullWidth && 'fullWidth'}`}
      {...rest}
    >
      {icon && icon}
      {children}
    </button>
  );
};

export default Button;
