import '../CreateTransactionRequestNew.css';
import Filter from '../../../Filter/Filter';
import Button from '../../../Button/Button';
import { T, t } from '../../../T';
import { currency } from '../../../../helpers/currency';
import Alert from '../../../Alert/Alert';
import { useSelector } from 'react-redux';
import Blockchain from '../../../Blockchain/Blockchain';

const CreateTransactionRequestAssetStep = ({
  assets,
  handleChooseAsset,
  selectedWallet,
  selectedAsset,
  submitCallback,
}) => {
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const protocols = useSelector((state) => state.globalReducer.protocols);

  return (
    <div className='create-trx-request-step-one-wrapper'>
      {assets !== undefined && !assets?.length && selectedWallet && (
        <Alert text={t(lng, 'app.vault.no.assets')} variant='error' />
      )}
      <div className='create-trx-request-modal-select-component-wrapper'>
        <span className='create-trx-request-modal-select-component-label'>
          <T>app.asset</T>
        </span>
        <Filter
          items={assets}
          selectedItems={selectedAsset}
          onChange={handleChooseAsset}
          fullWidth
          dataKey='id'
          dataNameKey='assetName'
          placeholder='app.assets'
          disabled={!selectedWallet}
          disableFocusShadowEffect
          customLabelTemplate={(asset) => (
            <div className='create-trx-request-assets-select-wrapper'>
              <div className='create-trx-request-assets-select-row-wrapper'>
                <Blockchain
                  assetType={asset?.type}
                  showText={false}
                  symbol={asset?.symbol}
                  blockchain={protocols
                    ?.find(
                      (protocol) => protocol?.blockchain?.toLowerCase() === asset?.blockchain?.toLowerCase(),
                    )
                    ?.currency?.toLowerCase()}
                />
                <span className='create-trx-request-assets-select-row-label'>{asset?.symbol}</span>
                <span className='create-trx-request-assets-select-row-sub-label'>
                  {(asset?.blockchain?.charAt(0).toUpperCase() + asset?.blockchain.slice(1))?.replaceAll(
                    '-',
                    ' ',
                  )}
                </span>
              </div>
              <div className='create-trx-request-assets-select-asset-data-wrapper'>
                <span className='create-trx-request-assets-select-asset-data-label'>
                  {`${asset?.assetData?.availableAmount} ${asset?.symbol} (${currency(
                    asset?.exchangeRate * asset?.assetData?.availableAmount,
                  )})`}
                </span>
              </div>
            </div>
          )}
        />
      </div>
      <div className='create-trx-request-modal-button'>
        <Button fullWidth onClick={submitCallback} disabled={!selectedAsset}>
          <T>app.next.step</T>
        </Button>
      </div>
    </div>
  );
};

export default CreateTransactionRequestAssetStep;
