import { T } from '../../T';
import { useDispatch, useSelector } from 'react-redux';
import { setPage } from '../../../redux/slices/globalSlice';
import { useEffect } from 'react';
import WalletTableRow from '../../Pages/Wallets/WalletTableRow';

const WalletTable = ({ handleSelectWalletId, data = [] }) => {
  const dispatch = useDispatch();
  const trialBannerVisible = useSelector((state) => state.globalReducer.trialBannerVisible);

  useEffect(() => {
    dispatch(setPage(''));
  }, []);

  return (
    <div
      className={`table wallets-table ${
        trialBannerVisible ? 'trial-banner-visible' : ''
      }`}
    >
      <div className='table-content'>
        <div className='table-row caption'>
          <div className='vault-table-data name'>
            <T>app.name</T>
          </div>
          <div className='vault-table-data sum'>
            <T>app.available.balance</T>
          </div>
          <div className='vault-table-data sum'>
            <T>app.total.balance</T>
          </div>
          <div className='vault-table-data  sum'>
            <T>app.aml.blocked</T>
          </div>
          <div className='vault-table-data sum'>
            <T>app.allocated</T>
          </div>
          <div className='vault-table-data settings' />
        </div>
        {data.map((dataItem, key) => (
          <WalletTableRow key={key} wallet={dataItem} handleSelectWalletId={handleSelectWalletId} />
        ))}
        {data.length === 0 && (
          <div className='table-row no-active'>
            <div>
              <T>app.no.wallets</T>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WalletTable;
