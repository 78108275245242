import React from 'react';
import { T, t } from '../../../T';
import { useSelector } from 'react-redux';
import BasicModal from '../../../Modals/BasicModal/BasicModal';
import { vaultodyDate } from '../../../../helpers/date';
import EmailConfirmation from '../../../../assets/icons/email-conformation.svg';
import MobileSync from '../../../../assets/icons/mobile-sync.svg';
import ActiveIcon from '../../../../assets/icons/statuses/status-signed.svg';

const RemoveDeviceModal = ({ callback, removeDeviceHandler, dataItem }) => {
  const lng = useSelector((state) => state.localizationReducer.selectedData);

  const stautsShow = (stautsUser) => {
    if (stautsUser === 'verify-email') {
      return (
        <div className='status-remove-device'>
          <img className='status-bar-icon' src={EmailConfirmation} alt='Email confirmation' />
          <div className='status-name'>
            <T>app.email.confiramation</T>
          </div>
        </div>
      );
    } else if (stautsUser === 'register-mobile') {
      return (
        <div className='status-remove-device'>
          <img className='status-bar-icon' src={MobileSync} alt='Mobile sync' />
          <div className='status-name'>
            <T>app.mobile.sync</T>
          </div>
        </div>
      );
    } else if (stautsUser === 'deleted') {
      return (
        <div className='status-remove-device'>
          <div className='status-name'>
            <T>app.deleted</T>
          </div>
        </div>
      );
    } else {
      return (
        <div className='status-remove-device'>
          <img className='status-bar-icon' src={ActiveIcon} alt='Mobile sync' />
          <div className='status-name'>
            <T>app.active</T>
          </div>
        </div>
      );
    }
  };

  return (
    <BasicModal
      closeCallback={callback}
      submitCallback={removeDeviceHandler}
      title={t(lng, 'app.are.you.sure.remove.device')}
    >
      <div className='remove-device-info-wrapper'>
        <div className='remove-device-info-row'>
          <span className='remove-device-info-text'>
            <T>app.device.owner</T>
          </span>
          <span className='remove-device-info-sub-text'>{dataItem.name}</span>
        </div>
        <div className='remove-device-info-row'>
          <span className='remove-device-info-text'>
            <T>app.registration.date</T>
          </span>
          <span className='remove-device-info-sub-text'>{vaultodyDate(dataItem.createdAt, 'full')}</span>
        </div>
        <div className='remove-device-info-row'>
          <span className='remove-device-info-text'>
            <T>app.status</T>
          </span>
          <span className='remove-device-info-sub-text'>{stautsShow(dataItem.status)}</span>
        </div>
      </div>
    </BasicModal>
  );
};

export default RemoveDeviceModal;
