import React, { useState } from 'react';
import { contactsStatuses } from '../../../../helpers/transactionrequests/statuses';
import { useSelector } from 'react-redux';
import CopyButton from '../../../CopyButton/CopyButton';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete-icon.svg';
import './ContactRow.css';
import ContactDeleteModal from '../ContactDeleteModal/ContactDeleteModal';
import CryptoIcon from '../../../CryptoIcon/CryptoIcon';
import { T } from '../../../T';
import { smallAddress } from '../../../../helpers/strings';

const ContactRow = ({ addressName, blockchain, address, status, id, deleteCallback }) => {
  const myProfile = useSelector((state) => state.userReducer.myProfile);
  const protocols = useSelector((state) => state.globalReducer.protocols);
  const statusObj = contactsStatuses?.find((contactStatus) => contactStatus?.value === status?.toUpperCase());
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);

  const handleDeleteContactModalOpen = () => {
    setIsDeleteModalOpened(true);
  };

  return (
    <div className='contact-row-wrapper'>
      {isDeleteModalOpened && (
        <ContactDeleteModal
          id={id}
          address={address}
          addressName={addressName}
          blockchain={blockchain}
          closeCallback={() => {
            setIsDeleteModalOpened(false);
            if (deleteCallback) {
              deleteCallback();
            }
          }}
        />
      )}
      <div className='contact-row-address-name'>
        <span className='mobile-cell-title'>
          <T>app.contact.name</T>
        </span>
        <div>
          <span className='contact-row-address-name-text'>{addressName}</span>
        </div>
      </div>
      <div className='contact-row-blockchain'>
        <span className='mobile-cell-title'>
          <T>app.blockchain</T>
        </span>
        <div className='contact-row-blockchain'>
          <CryptoIcon
            className='token-image'
            symbol={
              protocols?.find((protocol) => protocol?.blockchain?.toLowerCase() === blockchain?.toLowerCase())
                ?.currency
            }
          />
          <span className='contact-row-blockchain-text'>
            {blockchain.charAt(0).toUpperCase() + blockchain.slice(1)}
          </span>
        </div>
      </div>
      <div className='contact-row-address'>
        <p className='mobile-cell-title'>
          <T>app.address</T>
        </p>
        <div className='contact-row-address'>
          <span>{smallAddress(address, 14)}</span>
          <CopyButton element={address} />
        </div>
      </div>
      <div className='contact-row-status'>
        <span className='mobile-cell-title'>
          <T>app.status</T>
        </span>
        <div className='contact-row-status'>
          <img src={statusObj?.icon} className='status-image' />
          <div className='status-name capitalize'>{statusObj?.name}</div>
        </div>
      </div>
      <div className='contact-row-settings'>
        {myProfile.premmisions?.includes('CONTACTS_DELETE') && status === 'APPROVED' && (
          <>
            <span className='mobile-cell-title'>
              <T>app.settings</T>
            </span>
            <div className='contact-row-settings-icon' onClick={handleDeleteContactModalOpen}>
              <DeleteIcon className='contact-row-settings-icon-svg' />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ContactRow;
