import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  page: '',
  protocols: [],
  trialBannerVisible: false,
  suspendedModalVisible: false,
  selectedVaultType: '',
};

export const globalSlice = createSlice({
  name: 'globalReducer',
  initialState: initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setMessageSocket: (state, action) => {
      state.messageSocket = action.payload;
    },
    setProtocols: (state, action) => {
      state.protocols = action.payload;
    },
    setTrialBannerVisible: (state, action) => {
      state.trialBannerVisible = action.payload;
    },
    setSuspendedModalVisible: (state, action) => {
      state.suspendedModalVisible = action.payload;
    },
    setSelectedVaultType: (state, action) => {
      state.selectedVaultType = action.payload;
    },
  },
});

export const {
  setMessageSocket,
  setPage,
  setProtocols,
  setTrialBannerVisible,
  setSelectedVaultType,
  setSuspendedModalVisible,
} = globalSlice.actions;

export default globalSlice.reducer;

// Actions
