import './LandscapeOrientationBlocker.css';
import Lottie from 'react-lottie';
import * as animationData from '../../helpers/lotties/portrait-mode.json';

const LandscapeOrientationBlocker = () => {
  return (
    <div className='landscape-warning-overlay'>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: animationData,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        style={{ transform: 'rotate(-90deg)', width: '50vw', height: '100vh', padding: 0, margin: 0 }}
      />
    </div>
  );
};

export default LandscapeOrientationBlocker;
