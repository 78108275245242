import { copy } from '../../helpers/strings';
import './CopyButton.css';

const CopyButton = ({ element }) => {
  const onHandlerClick = (e) => {
    e.stopPropagation();
    e.currentTarget.querySelector('.copy-tooltip').classList.remove('hide');
    // const nextelement = e.currentTarget.nextSibling;

    const test = e.currentTarget;
    setTimeout(() => {
      test.querySelector('.copy-tooltip').classList.add('hide');
    }, 500);
    // nextelement.classList.add('hide');
    copy(element);
  };
  return (
    <div className='copy-button' onClick={onHandlerClick}>
      <i className='icon-copy' />
      <div className='tooltip copy-tooltip hide'>Copied!</div>
    </div>
  );
};

export default CopyButton;
