import { blockchainNetworks } from '../constants';

export const blockchainTypes = {
  UTXO: 'utxo',
  ACCOUNT: 'account',
};
export const fee = [{ fee: 'Slow' }, { fee: 'Standard' }, { fee: 'Fast' }];
export const stragegy = [{ stragegy: 'Minimize Dust' }, { stragegy: 'Optimize Size ' }];

export const getBlockchainScannerLink = (blockchain, id, network, isAddress) => {
  if (blockchain === 'ethereum')
    return `https://${
      network?.toLowerCase() !== blockchainNetworks.MAINNET ? `${network}.` : ''
    }etherscan.io/${isAddress ? 'address' : 'tx'}/${id?.startsWith('0x') ? '' : '0x'}${id}`;
  if (blockchain === 'binance-smart-chain')
    return `https://${network === 'testnet' ? `${network}.` : ''}bscscan.com/${
      isAddress ? 'address' : 'tx'
    }/${id?.startsWith('0x') ? '' : '0x'}${id}`;
  if (blockchain === 'tron')
    return `https://${network === 'nile' ? `${network}.` : ''}tronscan.org/#/${
      isAddress ? 'address' : 'transaction'
    }/${id}`;
  if (blockchain === 'polygon')
    return `https://${network === 'amoy' ? `${network}.` : ''}polygonscan.com/${
      isAddress ? 'address' : 'tx'
    }/${id?.startsWith('0x') ? '' : '0x'}${id}`;
  if (blockchain === 'optimism') {
    return `https://${
      network?.toLowerCase() !== blockchainNetworks.MAINNET ? `${network}-optimism.` : 'optimistic.'
    }etherscan.io/${isAddress ? 'address' : 'tx'}/${id?.startsWith('0x') ? '' : '0x'}${id}`;
  }
  return `https://blockexplorer.one/${blockchain}/${network ? `${network}/` : ''}${
    isAddress ? 'address' : 'tx'
  }/${id}`;
};
