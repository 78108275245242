export const BASE_URL = (process.env.REACT_APP_BACKEND_HOST ?? '').replace(/\/$/, '');
export const HOST = BASE_URL + '/api';
export const DEFAULT_LANGUAGE = 'en';
export const isAppUnderMaintanance = false;
export const whitelistedIpAddresses = ['212.95.176.130', '192.168.0.105'];
export const APP_APPLE_STORE_URL = 'https://apps.apple.com/bg/app/vaultody-approver/id6446577538';
export const APP_PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=com.vaultody.approver';

export const LOGIN_STATUS = {
  SUCCESS: 'Success',
  FAILED: 'Failed',
};

export const stripePricingTableData = {
  testMode: {
    tablePublishableKey:
      'pk_test_51Mni2bHlj0tjMF4rW8Ukh02t5p4C7t6thmcPdnQz3gr68cNxaSOzxX53BsRzF46CGZxrl5MGYoANLC7Cc0qKFa1v00wWcR2DDu',
    tableIdWithTrial: 'prctbl_1OmzL0Hlj0tjMF4rkWNDJZFD',
    tableIdWithoutTrial: 'prctbl_1Os35bHlj0tjMF4rMBAOu1Xb',
  },
  productionMode: {
    tablePublishableKey:
      'pk_live_51Mni2bHlj0tjMF4rsgOh6Os2HfyAVJZfztenqjURe5N3wuzeevj0Ki4mUgKNSDFkuRWL47IWneM9v6dAuAAeibSm00xWbEbiZz',
    tableIdWithTrial: 'prctbl_1OmzGuHlj0tjMF4rOeSHzHoY',
    tableIdWithoutTrial: 'prctbl_1Os32uHlj0tjMF4rH5KZ0Bjn',
  },
};
