import { useEffect, useState } from 'react';
import { store } from '../../redux';
import { customTabsPages, pageTabsMapping } from '../constants';
import { routesViewPermissionMapping } from '../roles';
import { ROUTE_HOME, ROUTE_WALLET } from '../../routes/routes';
import { useSelector } from 'react-redux';

export default function useViewPermissions(setActiveTab) {
  const myProfile = useSelector((state) => state.userReducer.myProfile);
  const selectedVaultType = useSelector((state) => state.globalReducer.selectedVaultType);
  const [pageTabs, setPageTabs] = useState();
  const isWalletRoute = `/${window.location.pathname?.split('/')[1]}` === ROUTE_WALLET;

  const routeKey =
    window.location.pathname === ROUTE_HOME
      ? ROUTE_HOME
      : Object.keys(routesViewPermissionMapping)
          ?.filter((route) => route !== ROUTE_HOME)
          ?.find((key) => window.location.pathname?.includes(key));

  const pageTabInfo = pageTabsMapping()[routeKey]?.find((tab) => `#${tab?.name}` === window.location.hash);

  const isPageViewPermissionMissing =
    !!routeKey &&
    store.getState()?.authReducer?.isLogged &&
    store.getState()?.userReducer?.myProfile?.premmisions?.includes(routesViewPermissionMapping[routeKey]) ===
      false;

  const isTabViewPermissionMissing =
    !!window.location.hash &&
    pageTabInfo?.permissions?.length &&
    pageTabInfo?.permissions?.every((permission) =>
      store.getState()?.userReducer?.myProfile?.premmisions?.includes(permission),
    ) === false;

  const handlePageTabs = () => {
    const routeKey =
      window.location.pathname === ROUTE_HOME
        ? ROUTE_HOME
        : Object.keys(pageTabsMapping())
            ?.filter((route) => route !== ROUTE_HOME)
            ?.find((key) => `/${window.location.pathname?.split('/')[1]}` === key);

    setPageTabs(pageTabsMapping()[routeKey]);

    if (
      window.location.hash &&
      !pageTabsMapping()[routeKey]?.some((tab) => `#${tab?.name}` === window.location.hash) &&
      !customTabsPages?.some((page) => page === window.location.pathname)
    ) {
      window.location.hash = '';
    }
    if (!window.location.hash && pageTabsMapping()[routeKey]?.find((tab) => tab?.initiallyActive)?.name) {
      window.location.hash = pageTabsMapping()[routeKey]?.find((tab) => tab?.initiallyActive)?.name;
    }
    setActiveTab(window.location.hash?.replace('#', ''));
  };

  const handleRouteChange = () => {
    setActiveTab(undefined);
  };

  useEffect(() => {
    if (isWalletRoute) {
      if (selectedVaultType) {
        handlePageTabs();
      }
    } else {
      handlePageTabs();
    }
    return () => handleRouteChange();
  }, [window.location.pathname, window.location.hash, myProfile?.premmisions?.length, selectedVaultType]);

  return { isPageViewPermissionMissing, isTabViewPermissionMissing, pageTabs };
}
