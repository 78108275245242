import React from 'react';
import GuestLayout from '../components/GuestLayout';
import { T } from '../components/T';
import TeamIcon from '../assets/images/mobile-download.svg';
import AppStore from '../assets/images/appstore.svg';
import PlayStore from '../assets/images/playstore.svg';
import QRCode from 'react-qr-code';
import { APP_APPLE_STORE_URL, APP_PLAY_STORE_URL } from '../config/Common';
import { useNavigate } from 'react-router-dom';
import { ROUTE_VERIFY_MOBILE } from '../routes/routes';
import Button from '../components/Button/Button';

const VerifyMobileStep1 = () => {
  const navigate = useNavigate();
  return (
    <GuestLayout>
      <div className='guest-flow-form-wrapper'>
        <div className='guest-flow-form-main'>
          <div className='text-center'>
            <img src={TeamIcon} className='verify-mobile-page-img' />
          </div>
          <div className='text-center mt-2'>
            <h3 className='fw-bolder text-center link-account-bold-text'>
              <T>app.link-account-with-approver</T>
            </h3>
            <p className='guest-flow-subtitle text-center'>
              <T>app.link-account-with-approver-text</T>
            </p>
          </div>

          <div className='d-flex justify-content-center app-qr-codes-wrapper'>
            <div className=' px-1 col-6 d-flex flex-column align-items-center'>
              <QRCode value={APP_PLAY_STORE_URL} size={80} />
              <div className='mt-3'>
                <a href={APP_PLAY_STORE_URL} target='_blank' rel='noreferrer'>
                  <img src={PlayStore} />
                </a>
              </div>
            </div>
            <div className=' px-1 col-6 d-flex flex-column align-items-center'>
              <QRCode value={APP_APPLE_STORE_URL} size={80} />
              <div className='mt-3'>
                <a href={APP_APPLE_STORE_URL} target='_blank' rel='noreferrer'>
                  <img src={AppStore} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <Button size='md' fullWidth onClick={() => navigate(ROUTE_VERIFY_MOBILE)}>
          <T>app.continue-button</T>
        </Button>
      </div>
    </GuestLayout>
  );
};

export default VerifyMobileStep1;
