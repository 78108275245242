import { stripePricingTableData } from '../config/Common';

export const getStripePricingTable = (withTrial) => {
  if (window.location.href?.includes('https://my.vaultody.com')) {
    return {
      tableId:
        stripePricingTableData?.productionMode?.[withTrial ? 'tableIdWithTrial' : 'tableIdWithoutTrial'],
      tablePublishableKey: stripePricingTableData?.productionMode?.tablePublishableKey,
    };
  }
  return {
    tableId: stripePricingTableData?.testMode?.[withTrial ? 'tableIdWithTrial' : 'tableIdWithoutTrial'],
    tablePublishableKey: stripePricingTableData?.testMode?.tablePublishableKey,
  };
};
