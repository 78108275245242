import VModal from '../Modal/VModal';
import Limits from '../../../src/assets/images/limits.png';
import { T } from '../T';
const LimitsModal = ({ toggleFunction = () => {}, modalName = '' }) => {
  return (
    <VModal toggleFunction={toggleFunction} classHandler='mini-modal modal-limits'>
      <img src={Limits} />
      <div className='title'>
        <T>{modalName}</T>
      </div>
      <div className='link'>
        <a href='mailto: support@vaultody.com'>
          <T>app.upgrade.limit</T>
        </a>
      </div>
      <div
        className='cancel-button'
        onClick={() => {
          toggleFunction();
        }}
      >
        <T>app.close</T>
      </div>
    </VModal>
  );
};
export default LimitsModal;
