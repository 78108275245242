import React, { useState } from 'react';
import './AssetAccordionDetails.css';
import { currency } from '../../../../helpers/currency';
import { smallAddress } from '../../../../helpers/strings';
import { T, t } from '../../../T';
import CopyButton from '../../../CopyButton/CopyButton';
import CryptoIcon from '../../../CryptoIcon/CryptoIcon';
import { useSelector } from 'react-redux';
import VTooltip from '../../../VTooltip/VTooltip';
import CreateTransactionRequestNew from '../../../Modals/CreateTransactionRequestNew/CreateTransactionRequestNew';
import VaultIcon from '../../../VaultIcon/VaultIcon';
import { vaultTypes } from '../../../../helpers/constants';
import { blockchainTypes } from '../../../../helpers/blockchain/blockchain';

const AssetAccordionDetails = ({ rows, type, blockchain, asset, walletData, address }) => {
  const myProfile = useSelector((state) => state.userReducer.myProfile);
  const protocols = useSelector((state) => state.globalReducer.protocols);
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const [selectedAsset, setSelectedAsset] = useState();
  const [selectedVault, setSelectedVault] = useState();
  const [selectedAddress, setSelectedAddress] = useState();
  const [newTransactionRequestModal, setNewTransactionRequestModal] = useState(false);
  const createTrxReqPermission = myProfile.premmisions?.includes('TRANSACTION_REQUESTS_CREATE');

  const handleSendButton = async (row) => {
    if (!createTrxReqPermission) return;
    if (type === 'vault') {
      setSelectedVault({
        ...(row ? row : {}),
        id: row?.vaultId,
        name: row?.vaultName,
        color: row?.vaultColor || row?.color,
      });
      setSelectedAsset({
        ...(asset ? asset : {}),
        assetData: {
          ...row?.assetData,
          contract: asset?.tokenData?.contractAddress,
        },
        blockchain: asset?.blockchain || blockchain,
        type: asset?.type || row?.type,
        network: asset?.network || row?.network,
        symbol: asset?.symbol || row?.symbol,
        assetId: asset?.id || asset?.assetId || row?.assetId,
        assetName: asset?.assetName || row?.assetName,
      });
    }
    if (type === 'address') {
      const isUTXO =
        protocols.find((protocolVal) => {
          return protocolVal.blockchain === asset?.blockchain;
        })?.type === blockchainTypes.UTXO;
      if (asset?.blockchain && !isUTXO) {
        setSelectedAddress({ ...row, label: row?.label, address: row?.address });
      }
      setSelectedVault({
        ...(walletData ? walletData : {}),
        id: walletData?.vaultId || walletData?.id,
        name: walletData?.vaultName || walletData?.name,
      });
      setSelectedAsset({
        ...(asset ? asset : {}),
        blockchain: asset?.blockchain || blockchain,
        type: asset?.type || row?.type,
        network: asset?.network || row?.network,
        symbol: asset?.symbol || row?.symbol,
        assetId: asset?.id || asset?.assetId || row?.assetId,
        assetName: asset?.assetName || row?.assetName,
      });
    }
    if (type === 'assets') {
      if (walletData) {
        setSelectedVault({
          ...(walletData ? walletData : {}),
          id: walletData?.vaultId || walletData?.id,
          name: walletData?.vaultName || walletData?.name,
        });
        setSelectedAddress({ ...address, label: address?.name, address: address?.id });
      }
      setSelectedAsset({
        ...(row ? row : asset ? asset : {}),
        blockchain: asset?.blockchain || blockchain,
        type: asset?.type || row?.type,
        network: asset?.network || row?.network,
        symbol: asset?.symbol || row?.symbol,
        assetId: asset?.id || asset?.assetId || row?.assetId,
        assetName: asset?.assetName || row?.assetName,
      });
    }
    setNewTransactionRequestModal(true);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return rows?.length > 0 ? (
    <div className='asset-accordion-details-wrapper'>
      {newTransactionRequestModal && (
        <CreateTransactionRequestNew
          prefilledAddress={selectedAddress}
          prefilledAsset={selectedAsset}
          prefilledWallet={selectedVault}
          toggleFunction={setNewTransactionRequestModal}
          successCallback={() => {}}
        />
      )}
      <div className='asset-accordion-details'>
        <div className='first-column asset-accordion-header'>
          <div className='wallet-name'>
            {type === 'address' && <T>app.address</T>}
            {type === 'vault' && <T>app.wallet</T>}
            {type === 'assets' && <T>app.assets</T>}
          </div>
        </div>
        <div className='balance asset-accordion-header'>
          <T>app.available.balance</T>
        </div>
        <div className='balance asset-accordion-header'>
          <T>app.total.balance</T>
        </div>
        <div className='balance asset-accordion-header'>
          <T>app.aml.blocked</T>
        </div>
        <div className='balance asset-accordion-header'>
          <T>app.allocated</T>
        </div>
        <div className='settings asset-accordion-header' />
      </div>
      {rows?.length > 0
        ? rows?.map((row, index) => (
            <div key={index} className='asset-accordion-details'>
              <div className='first-column'>
                <span className='mobile-cell-title'>
                  <T>app.wallet</T>
                </span>
                <div className='first-column-asset'>
                  {type === 'address' && (
                    <div className='address-copy-wrapper'>
                      <div className='wallet-wrapper'>
                        <div
                          className='accordion-details-address-name'
                          data-tooltip-id={`address-name-${row?.label}-${index}`}
                          data-tooltip-content={row?.label}
                        >
                          {row?.label}
                          <VTooltip id={`address-name-${row?.label}-${index}`} place='top-start' />
                        </div>
                        {/* <div
                        className='address-sender-trx-details-to'
                        data-tooltip-id='trx-details-wallet-address'
                        data-tooltip-content={receiverVaultInfo?.address}
                      >
                        <VTooltip id='trx-details-wallet-address' />
                        {smallAddress(receiverVaultInfo?.address, 8)}
                      </div> */}
                        <div
                          className='accordion-details-address'
                          data-tooltip-id='asset-accordion-address'
                          data-tooltip-content={row?.address}
                        >
                          <VTooltip id='asset-accordion-address' />
                          {smallAddress(row?.address, 8)}
                        </div>
                      </div>
                      <CopyButton element={row?.address} />
                    </div>
                  )}
                  {type === 'vault' && (
                    <>
                      <VaultIcon
                        id={row?.vaultId}
                        name={row?.vaultName}
                        color={row?.vaultColor}
                        vaultType={row?.vaultType}
                      />
                      <div className='wallet-name'>{row?.vaultName}</div>
                    </>
                  )}
                  {type === 'assets' && (
                    <div className='first-column'>
                      <div className='asset-accordion-symbol-wrapper'>
                        <CryptoIcon className='main-symbol-icon' symbol={row?.symbol} />
                        {row?.symbol?.toLowerCase() !==
                          protocols
                            ?.find(
                              (protocol) => protocol?.blockchain?.toLowerCase() === blockchain?.toLowerCase(),
                            )
                            ?.currency?.toLowerCase() && (
                          <CryptoIcon
                            className='secondary-symbol-icon'
                            symbol={
                              protocols?.find(
                                (protocol) =>
                                  protocol?.blockchain?.toLowerCase() === blockchain?.toLowerCase(),
                              )?.currency
                            }
                          />
                        )}
                      </div>
                      <div className='name' title={row?.assetName || <T>app.no.name</T>}>
                        {row?.assetName || <T>app.no.name</T>}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='balance'>
                <span className='mobile-cell-title'>
                  <T>app.available.balance</T>
                </span>
                <div className='balance-asset-wrapper'>
                  <div className='text'>{currency(row?.assetData?.availableAmount * row?.exchangeRate)}</div>
                  <div className='sub-text'>
                    {Number(row?.assetData?.availableAmount)
                      ?.toFixed(8)
                      ?.replace(/\.?0+$/, '')}{' '}
                    {row?.symbol}
                  </div>
                </div>
              </div>
              <div className='balance'>
                <span className='mobile-cell-title'>
                  <T>app.total.balance</T>
                </span>
                <div className='balance-asset-wrapper'>
                  <div className='text'>{currency(row?.assetData?.amount * row?.exchangeRate)}</div>
                  <div className='sub-text'>
                    {Number(row?.assetData?.amount)
                      ?.toFixed(8)
                      ?.replace(/\.?0+$/, '')}{' '}
                    {row?.symbol}
                  </div>
                </div>
              </div>
              <div className='balance'>
                <span className='mobile-cell-title'>
                  <T>app.aml.blocked</T>
                </span>
                <div className='balance-asset-wrapper'>
                  <div className='text'>{currency(row?.assetData?.blockedBalance * row?.exchangeRate)}</div>
                  <div className='sub-text'>
                    {Number(row?.assetData?.blockedBalance)
                      ?.toFixed(8)
                      ?.replace(/\.?0+$/, '')}{' '}
                    {row?.symbol}
                  </div>
                </div>
              </div>
              <div className='balance'>
                <span className='mobile-cell-title'>
                  <T>app.allocated</T>
                </span>
                <div className='balance-asset-wrapper'>
                  <div className='text'>
                    {currency((row?.assetData?.allocatedBalance || row?.allocatedAmount) * row?.exchangeRate)}
                  </div>
                  <div className='sub-text'>
                    {Number(row?.assetData?.allocatedBalance)
                      ?.toFixed(8)
                      ?.replace(/\.?0+$/, '')}{' '}
                    {row?.symbol}
                  </div>
                </div>
              </div>
              <div className='settings'>
                {type !== 'assets' &&
                  row?.type !== 'station' &&
                  row?.vaultType?.toLowerCase() !== vaultTypes.AUTOMATION &&
                  walletData?.vaultType?.toLowerCase() !== vaultTypes.AUTOMATION && (
                    <div
                      className={`cancel-button ${!createTrxReqPermission ? 'disabled' : ''}`}
                      onClick={() => handleSendButton(row)}
                      data-tooltip-id={`disabled-send-button-${row?.label}-${index}`}
                      data-tooltip-content={t(lng, 'app.role.limitation')}
                    >
                      {!createTrxReqPermission && (
                        <VTooltip id={`disabled-send-button-${row?.label}-${index}`} />
                      )}
                      <i className='icon-send' />
                      <div>
                        <T>app.send</T>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          ))
        : null}
    </div>
  ) : (
    <div className='asset-accourdion-details-no-balance'>
      <T>app.no.assets.found</T>
    </div>
  );
};

export default AssetAccordionDetails;
