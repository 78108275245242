import { useRef, useState } from 'react';
import { useClickOutside } from '../../helpers/hooks/useClickOutside';
import './EditWallet.css';
import { useNavigate } from 'react-router-dom';
import { editWalletApi } from '../../api/endpoints';
import { walletColors } from '../../helpers/constants';
import { setWalletColor } from '../../redux/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { t } from '../T';
import { ROUTE_WALLETS } from '../../routes/routes';
import VaultIcon from '../VaultIcon/VaultIcon';
const EditWallet = ({ page, id, setPage }) => {
  const dispatch = useDispatch();
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const walletColor = useSelector((state) => state.userReducer.walletColor);
  const myProfile = useSelector((state) => state.userReducer.myProfile);
  const [name, setName] = useState(page);
  const [changeName, setChangeName] = useState(false);
  const [editName, setEditName] = useState(page);
  const [colorWallet, setColorWalelt] = useState(walletColor);
  const [colorMenu, setColorMenu] = useState(false);
  const wrapperRef = useRef('color-menu');
  const navigate = useNavigate();
  const walletUpdatePermission = myProfile.premmisions?.includes('WALLETS_UPDATE');

  useClickOutside(wrapperRef, () => {
    setColorMenu(false);
  });
  const changeNameInput = (event) => {
    setEditName(event.currentTarget.textContent);
  };
  const keyPressEdit = (event) => {
    if (event.charCode === 13 || event.currentTarget.textContent.length >= 30) {
      event.preventDefault();
    }
  };
  const onPasteName = (event) => {
    event.preventDefault();
  };
  const close = () => {
    setChangeName(false);
    setName((state) => {
      return state + ' ';
    });
  };
  const approveChangeColor = (colorItem) => {
    setColorWalelt(colorItem);
    dispatch(setWalletColor(colorItem));
    const data = {
      item: {
        name: editName,
        color: colorItem,
      },
    };
    editWalletApi(id, data).finally(() => {
      setColorMenu(false);
    });
  };
  const approveChangeName = () => {
    const data = {
      item: {
        name: editName,
        color: walletColor,
      },
    };
    if (data.item.name !== '') {
      editWalletApi(id, data)
        .then(() => {
          setName(editName);
        })
        .finally(() => setChangeName(false));
    }
  };

  return (
    <div className='edit-wallet'>
      <div
        className='return'
        onClick={() => {
          navigate(ROUTE_WALLETS);
          setPage(t(lng, 'app.wallets'));
        }}
      >
        <i className='icon-arrow-right' />
      </div>
      <div className={`color-changer ${colorMenu ? 'edit' : ''}`} ref={wrapperRef}>
        <VaultIcon
          onClick={() => {
            if (walletUpdatePermission) {
              setColorMenu(true);
            }
          }}
          id={id}
          name={name}
          color={walletColor}
        />
        {colorMenu && (
          <div className='color-menu'>
            {walletColors.map((colorItem, key) => {
              return (
                <div
                  key={key}
                  onClick={() => {
                    approveChangeColor(colorItem);
                  }}
                  className={`item ${colorItem === colorWallet ? 'selected' : ''}`}
                  style={{ backgroundColor: colorItem }}
                />
              );
            })}
          </div>
        )}
      </div>
      <div
        className='title'
        onPaste={(e) => {
          onPasteName(e);
        }}
        onKeyPress={(e) => {
          keyPressEdit(e);
        }}
        onInput={changeNameInput}
        contentEditable={changeName}
        suppressContentEditableWarning
      >
        {name}
      </div>
      {!changeName ? (
        <div
          className={`edit ${myProfile.premmisions?.includes('WALLETS_UPDATE') ? '' : 'inactive'} `}
          onClick={() => {
            setChangeName(true);
          }}
        >
          <i className='icon-edit' />
        </div>
      ) : (
        <>
          <div className='approve' onClick={approveChangeName}>
            <i className='icon-check' />
          </div>
          <div className='reject' onClick={close}>
            <i className='icon-close' />
          </div>
        </>
      )}
    </div>
  );
};
export default EditWallet;
