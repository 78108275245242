import { useEffect, useState } from 'react';
import { vaultodyDate } from '../../helpers/date';
import { useDispatch, useSelector } from 'react-redux';
import './LoginHistory.css';
import { LOGIN_STATUS } from '../../config/Common';
import ReactLoading from 'react-loading';
import LoadMore from '../../components/LoadMore/LoadMore';
import { T, t } from '../../components/T';
import { allTransactionStatuses } from '../../helpers/transactionrequests/statuses';
import { getUserHistoryApi } from '../../api/endpoints';
import { setPage } from '../../redux/slices/globalSlice';

const LoginHistory = () => {
  const dispatch = useDispatch();
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const trialBannerVisible = useSelector((state) => state.globalReducer.trialBannerVisible);
  const [userInfo, setUserInfo] = useState([]);
  const [loadMoreVisible, setLoadMoreVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    dispatch(setPage(t(lng, 'app.menu.history')));
  }, []);

  const getUserInfo = async () => {
    getUserHistoryApi({ params: { limit: 50 } })
      .then((res) => {
        setUserInfo(res.data.items);
        if (res?.data?.hasMore !== undefined) {
          setLoadMoreVisible(JSON.parse(res.data.hasMore));
        }
      })
      .finally(() => setIsLoading(false));
  };

  const loadMoreHandler = async () => {
    const lastRecord = userInfo[userInfo.length - 1];
    getUserHistoryApi({ params: { limit: 50, startingAfter: lastRecord.id } }).then((res) => {
      const newRecords = res.data.items;
      if (newRecords.length < 50) {
        setLoadMoreVisible(false);
        return;
      }
      setUserInfo((prevState) => [...prevState, ...newRecords]);
    });
  };

  // Need add keys with Hristo
  return isLoading ? (
    <div className='table-row data'>
      <div className='loading'>
        <ReactLoading width={55} height={55} type='spin' color='#020D1C' />
      </div>
    </div>
  ) : (
    <div className={`table login-history ${trialBannerVisible ? 'trial-banner-visible' : ''}`}>
      <div className='table-content'>
        <div className='table-row caption'>
          <div className='login-table-data status'>
            <T>app.history.status</T>
          </div>
          <div className='login-table-data created-at'>
            <T>app.history.datetime</T>
          </div>
          <div className='login-table-data device'>
            <T>app.history.device</T>
          </div>
          <div className='login-table-data browser'>
            <T>app.history.browser</T>
          </div>
          <div className='login-table-data system'>
            <T>app.history.system</T>
          </div>
          <div className='login-table-data ipaddress'>
            <T>app.history.ipaddress</T>
          </div>
        </div>
        {userInfo.length > 0 &&
          userInfo.map((info, key) => (
            <div className='table-row data' key={key}>
              <div className='login-table-data status'>
                <span className='mobile-cell-title'>
                  <T>app.history.status</T>
                </span>
                <div className='icon-holder'>
                  {info.success && (
                    <div className='login-status-wrapper'>
                      <img
                        src={allTransactionStatuses?.find((status) => status?.value === 'MINED')?.icon}
                        className='status-image'
                      />
                      <span className='status-info-text'>{LOGIN_STATUS.SUCCESS}</span>
                    </div>
                  )}
                  {!info.success && (
                    <div className='login-status-wrapper'>
                      <img
                        src={
                          allTransactionStatuses?.find((status) => status?.value === 'MINED_WITH_ERRORS')
                            ?.icon
                        }
                        className='status-image'
                      />
                      <span className='status-info-text'>{LOGIN_STATUS.FAILED}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='login-table-data created-at'>
                <span className='mobile-cell-title'>
                  <T>app.history.datetime</T>
                </span>
                {vaultodyDate(info.date, 'full')}
              </div>
              <div className='login-table-data device'>
                <span className='mobile-cell-title'>
                  <T>app.history.device</T>
                </span>
                <div className='sign-in-history-flex'>
                  <div className={`media-user ${info.type}`} />
                  <div> {info.type === 'normal' ? 'WEB' : 'Mobile'}</div>
                </div>
              </div>
              <div className='login-table-data browser'>
                <span className='mobile-cell-title'>
                  <T>app.history.browser</T>
                </span>
                <div className='sign-in-history-flex'>
                  <div
                    className={`media-user ${
                      info.userAgentBrowser
                        ? info.userAgentBrowser.toLowerCase().replaceAll(' ', '')
                        : info.userAgentBrowser
                    }`}
                  />
                  {info.userAgentBrowser === '' ? 'N/A' : info.userAgentBrowser}
                </div>
              </div>
              <div className='login-table-data system'>
                <span className='mobile-cell-title'>
                  <T>app.history.system</T>
                </span>
                <div className='sign-in-history-flex'>
                  <div
                    className={`media-user ${
                      info.userAgentOs ? info.userAgentOs.toLowerCase().replaceAll(' ', '') : info.userAgentOs
                    }`}
                  />
                  <div>{info.userAgentOs === '' ? 'N/A' : info.userAgentOs}</div>
                </div>
              </div>
              <div className='login-table-data ipaddress'>
                <span className='mobile-cell-title'>
                  <T>app.history.ipaddress</T>
                </span>
                {info.ip}
              </div>
            </div>
          ))}
        {loadMoreVisible && <LoadMore loadMoreHandler={loadMoreHandler} />}
      </div>
    </div>
  );
};
export default LoginHistory;
