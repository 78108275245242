import { useEffect } from 'react';
import GuestLayout from '../components/GuestLayout';
import { dashboardState, logoutUser } from '../redux/slices/authSlice';
import MobileIcon from '../assets/icons/team-icon.svg';
import ReactLoading from 'react-loading';
import { T } from '../components/T';
import { useDispatch, useSelector } from 'react-redux';
import { ROUTE_HOME } from '../routes/routes';

const LoginAttempt = () => {
  const messageSocket = useSelector((state) => state.globalReducer.messageSocket);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      window.location.reload(ROUTE_HOME);
    }, 300000);
  }, []);

  useEffect(() => {
    if (messageSocket !== '' && messageSocket) {
      const data = messageSocket;
      if (data.data.event === 'login_attempt_approved') {
        localStorage.setItem('token', data.data.item.token);
        localStorage.setItem('state', data.data.item.userState);
        dispatch(dashboardState(data.data.item.userState));
      } else if (data.data.event === 'login_attempt_rejected') {
        localStorage.clear();
        dispatch(logoutUser());
        window.location.reload(ROUTE_HOME);
      }
    }
  }, [messageSocket]);

  return (
    <GuestLayout>
      <div className='login-attempt'>
        <img src={MobileIcon} alt='' />
        <div className='fw-bolder'>
          <T>app.pending.log.in</T>
        </div>
        <div className='login-attempt-text'>
          <T>app.in.order.to.log.text</T>
        </div>
        <ReactLoading className='loading' width={60} height={10} type='spin' color='#020D1C' />
        <div className='login-attempt-sub-text'>
          <T>app.login.in.five.minutes</T>
        </div>
      </div>
    </GuestLayout>
  );
};

export default LoginAttempt;
