import { deleteRest, get, patch, post, put } from './axiosSingleton';

// GET /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\

export const getVaultBackupData = (walletId, options) =>
  get(`wallets/${walletId}/backup/download`, options, true);

export const getLocalizationApi = (options) => get('localization', options);

export const getWalletsApi = (options) => get('wallets', options);

export const getWalletApi = (walletId, options) => get(`wallets/${walletId}`, options);

export const getPermissibleRoutesApi = (options) => get('developers/permissible-routes', options);

export const getWalletsBalancesApi = (options) => get('wallets/balances', options);

export const getWalletBalancesApi = (id, options) => get(`wallets/${id}/balances`, options);

export const getWebhookEventsApi = (options) => get('webhooks/events', options);

export const getBlockchainProtocolsApi = (options) => get('blockchain-protocols/networks', options);

export const getWebhooksApi = (options) => get('webhooks', options);

export const getWebhookCallbackLogsApi = (id, options) => get(`webhooks/${id}/callbacks-logs`, options);

export const getWebhookCallbackLogDetailsApi = (id, callbackId, options) =>
  get(`webhooks/${id}/callbacks-logs/${callbackId}`, options);

export const getWalletAddressesApi = (walletId, options) => get(`wallets/${walletId}/addresses`, options);

export const getWalletRulesApi = (walletId, options) => get(`wallets/${walletId}/rules`, options);

export const getProfileCurrentUsageApi = (options) => get('profile/current-usage', options);

export const getPackageInfoApi = (options) => get('package/info', options);

export const getProfileInfoApi = (options) => get('profile/info', options);

export const getVerificationDataApi = (options) => get('verification-data', options);

export const getWebsocketTokenApi = (options) => get('websocket-token', options);

export const getGovernancePendingRulesApi = (options) => get('governance/rules/pending', options);

export const getGovernanceActiveRulesApi = (options) => get('governance/rules/active', options);

export const getTrxRequestsApproversApi = (options) => get('team/approvers/transaction-requests', options);

export const getUserHistoryApi = (options) => get('user-history', options);

export const getGovernanceRolesApi = (options) => get('governance/roles', options);

export const getTeamApi = (options) => get('team', options);

export const getTeamInvitationsApi = (options) => get('team/invitations', options);

export const getTrxRequestsApi = (options) => get('tx-requests', options);

export const getTrxHistoryApi = (options) => get('tx-requests/history', options);

export const getApiKeysApi = (options) => get('developers/api-keys', options);

export const getTrxDetailsApi = (trxId, options) => get(`tx-requests/details/${trxId}`, options);

export const getDeviceApi = (options) => get(`device`, options);

export const getStripeCheckoutLink = (options) => get('stripe/checkout-session', options);

export const getStripeBillingPortalLink = (options) => get('stripe/billing-portal-session', options);

export const getSumSubTokenApi = (options) => get('kyc/token', options);

export const getContactsApi = (network, blockchain, options) =>
  get(`${blockchain ? `${blockchain}/` : ''}${network?.toUpperCase()}/contacts`, options);

export const getContactsByNameSearchApi = (network, options) =>
  get(`${network?.toUpperCase()}/contacts/search`, options);

export const getAssetsByVaultApi = (walletId, options) => get(`vaults/${walletId}/assets`, options);

export const getAssetsByAddressApi = (walletId, addressBlockchain, addressId, options) =>
  get(`vaults/${walletId}/${addressBlockchain}/addresses/${addressId}/assets`, options);

export const getAddressesByAssetIdApi = (assetId, vaultId, blockchain, typeUser, options) =>
  get(`assets/${assetId}/vaults/${vaultId}/${blockchain}/${typeUser?.toUpperCase()}/addresses`, options);

export const getAssetsByClientId = (typeUser, options) => get(`assets/${typeUser?.toUpperCase()}`, options);

export const getVaultsByAssetIdApi = (assetId, network, typeUser, options) =>
  get(`assets/${assetId}/${network?.toLowerCase()}/vaults/${typeUser?.toUpperCase()}`, options);

export const getApiVersionsApi = (options) => get('developers/api-versions', options);

export const getWalletAutomationRulesApi = (walletId, options) =>
  get(`vaults/${walletId}/automation/rules`, options);

export const getWalletAutomationRulesSearchApi = (walletId, options) =>
  get(`vaults/${walletId}/automation/rules/search`, options);

export const getWalletAutomationRuleApi = (ruleId, options) =>
  get(`vaults/automation/rules/${ruleId}`, options);

export const getWalletAutomationRuleSearchApi = (vaultId, options) =>
  get(`vaults/${vaultId}/automation/rules/search`, options);

export const getSessionClientSecretApi = (options) => get('stripe/customer-session-secret', options);

export const getBlockchainStrategiesFeesApi = (blockchain, network, options) =>
  get(`governance/${blockchain?.toLowerCase()}/${network?.toLowerCase()}/fees`, options);

export const getSupportedSmartVaultsProtocolsApi = (options) =>
  get('blockchain-protocols/smart-vault-factories', options);

export const getAllAssetsApi = (typeUser, options) => get(`assets/${typeUser?.toUpperCase()}/all`, options);

export const getAllTrxDraftsApi = (typeUser, options) => get(`${typeUser?.toUpperCase()}/tx-drafts`, options);

export const getTrxDraftApi = (draftId, options) => get(`tx-drafts/${draftId}`, options);

export const getInternalAddressesSearchApi = (vaultId, typeUser, blockchain, options) =>
  get(`vaults/${vaultId}/${typeUser?.toUpperCase()}/${blockchain}/addresses/search`, options);

// PATCH /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\

export const editAddressApi = (data, addressId, options) => patch(`addresses/${addressId}`, data, options);

export const editWalletApi = (walletId, data, options) => patch(`wallets/${walletId}`, data, options);

export const editTeamApi = (teamMemberId, data, options) => patch(`team/${teamMemberId}`, data, options);

export const editApiKeyApi = (apiKeyId, data, options) =>
  patch(`developers/api-keys/${apiKeyId}`, data, options);

// DELETE /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\

export const deleteApiKeyApi = (apiKeyId, options) => deleteRest(`developers/api-keys/${apiKeyId}`, options);

export const deleteWebhookApi = (webhookId, options) => deleteRest(`webhooks/${webhookId}`, options);

export const deleteInvitationApi = (invitationId, options) =>
  deleteRest(`team/invitations/${invitationId}`, options);

export const deleteTeamMemberApi = (memberId, options) => deleteRest(`team/${memberId}`, options);

export const deleteContactApi = (contactId, options) => deleteRest(`${contactId}/contacts`, options);

export const deleteWalletAutomationRuleApi = (ruleId, options) =>
  deleteRest(`vaults/automation/rules/${ruleId}`, options);

// POST /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\

export const resendEmailCodeApi = (options) => post('resend-email-code', undefined, options);

export const loginApi = (data, options) => post('login', data, options);

export const registerApi = (data, options) => post('register', data, options);

export const verifyEmailApi = (data, options) => post('verify-email', data, options);

export const changeProfileEmailApi = (data, options) => post('profile/email', data, options);

export const changeProfileNameApi = (data, options) => post('profile/name', data, options);

export const changeProfilePasswordApi = (data, options) => post('profile/password', data, options);

export const createApiKeyApi = (data, options) => post('developers/api-keys', data, options);

export const createWebhookApi = (data, options) => post('webhooks', data, options);

export const createDepositApi = (walletId, data, options) =>
  post(`wallets/${walletId}/addresses/deposit`, data, options);

export const createGasTankerApi = (walletId, data, options) =>
  post(`wallets/${walletId}/addresses/station`, data, options);

export const createTransactionRuleApi = (data, options) => post('governance/rules', data, options);

export const initializeWalletBackupApi = (walletId, data, options) =>
  post(`wallets/${walletId}/backup/initialize`, data, options);

export const finalizeWalletBackupApi = (walletId, data, options) =>
  post(`wallets/${walletId}/backup/finalize`, data, options);

export const forgotPasswordApi = (data, options) => post(`forgot-password`, data, options);

export const resetPasswordApi = (data, options) => post(`reset-password`, data, options);

export const sendInvitationApi = (data, options) => post(`team/invitations`, data, options);

export const createWalletApi = (data, options) => post(`wallets`, data, options);

export const sendZohoContractEmail = (data, options) => post('contract/sign', data, options);

export const createNewContactApi = (network, data, options) =>
  post(`${network?.toUpperCase()}/contacts/create`, data, options);

export const postWalletTrxStaticFeeApi = (walletId, protocolType, tokenType, staticFee, data, options) =>
  post(`wallets/${walletId}/tx-requests/${protocolType}/${tokenType}${staticFee}`, data, options);

export const resetDeviceApi = (data, options) => post('profile/reset-device', data, options);

export const linkAddressApi = (walletId, data, options) =>
  post(`wallets/${walletId}/addresses/link`, data, options);

export const updateApiVersionApi = (data, options) => post('developers/api-version', data, options);

export const createAutomationRuleApi = (data, options) => post('vaults/automation/rules', data, options);

export const createTrxDraftApi = (data, options) => post('tx-drafts', data, options);

export const convertTrxDraftApi = (data, options) => post('tx-drafts/convert', data, options);

// PUT /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\

export const editTrxRule = (ruleId, data, options) => put(`governance/rules/${ruleId}`, data, options);

export const editAutomationRuleApi = (vaultId, ruleId, data, options) =>
  put(`vaults/${vaultId}/automation/rules/${ruleId}`, data, options);

export const editTrxDraftApi = (data, options) => put(`tx-drafts`, data, options);
