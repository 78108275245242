import { useEffect } from 'react';
import './Developers.css';
import { useDispatch } from 'react-redux';
import { setPage } from '../../redux/slices/globalSlice';
import { useOutletContext } from 'react-router-dom';
import APITable from '../../components/Tables/Developers/APITable';
import WebHooksTable from '../../components/Tables/Developers/Webhooks';
import { T } from '../../components/T';

const Developers = () => {
  const [activeTab, isTabViewPermissionMissing] = useOutletContext();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPage('Developers'));
  }, []);

  if (isTabViewPermissionMissing) {
    return (
      <div className='table governance-layer-table'>
        <div className='table-row no-active no-prem'>
          <T>app.role.limitation</T>
        </div>
      </div>
    );
  }

  return (
    <div className='developers'>
      {activeTab === 'api-keys' && <APITable />}
      {activeTab === 'webhooks' && <WebHooksTable />}
    </div>
  );
};

export default Developers;
