import { useCallback, useEffect, useMemo, useState } from 'react';
import DetailsTransactionRequest from '../../components/Modals/DetailsTransactionRequest';
import TransactionRequestsТable from '../../components/Tables/TransactionRequestsТable';
import './TransactionRequests.css';
import ReactLoading from 'react-loading';
import { T, t } from '../../components/T';
import Filter from '../../components/Filter/Filter';
import {
  transactionDraftsStatuses,
  transactionRequestStatuses,
} from '../../helpers/transactionrequests/statuses';
import { unique } from '../../helpers/array';
import { ReactComponent as DocumentIcon } from '../../assets/icons/document-icon.svg';
import {
  convertTrxDraftApi,
  getAllTrxDraftsApi,
  getTrxRequestsApi,
  getWalletsApi,
} from '../../api/endpoints';
import { useSelector } from 'react-redux';
import Button from '../../components/Button/Button';
import { getCryptoIconFromBucket } from '../../helpers/protocolIcons/protocolIcons';
import { useLocation, useOutletContext } from 'react-router-dom';
import CreateTransactionRequestNew from '../../components/Modals/CreateTransactionRequestNew/CreateTransactionRequestNew';
import CheckboxNew from '../../components/CheckboxNew/CheckboxNew';
import DetailsTransactionDraft from '../../components/Modals/DetailsTransactionDraft/DetailsTransactionDraft';
import TransactionsCsvUploadModal from '../../components/Modals/TransactionsCsvUploadModal/TransactionsCsvUploadModal';
import BasicModal from '../../components/Modals/BasicModal/BasicModal';
import VTooltip from '../../components/VTooltip/VTooltip';
import Alert from '../../components/Alert/Alert';
import { Drawer } from '../../components/Drawer/Drawer';

const TransactionRequests = () => {
  const [activeTab, isTabViewPermissionMissing] = useOutletContext();
  const location = useLocation();
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const protocols = useSelector((state) => state.globalReducer.protocols);
  const myProfile = useSelector((state) => state.userReducer.myProfile);
  const typeUser = useSelector((state) => state.userReducer.typeUser);
  const messageSocket = useSelector((state) => state.globalReducer.messageSocket);
  const trialBannerVisible = useSelector((state) => state.globalReducer.trialBannerVisible);
  const isTrxShelf = activeTab === 'drafts';
  const initialSelectedStatuses = isTrxShelf
    ? transactionDraftsStatuses?.filter((status) => status?.value === 'created' || status?.value === 'failed')
    : transactionRequestStatuses;
  const [openCreateTrasactionModal, setOpenCreateTrasactionModal] = useState(false);
  const [openDetailsTrasactionModal, setOpenDetailsTrasactionModal] = useState(false);
  const [openDetailsTrasactionDraftModal, setOpenDetailsTrasactionDraftModal] = useState(false);
  const [detailsData, setDetailsData] = useState([]);
  const [dataTable, setDataTable] = useState();
  const [openUploadCsvModal, setOpenUploadCsvModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [wallets, setWallets] = useState([]);
  const [selectedProtocols, setSelectedProtocols] = useState();
  const [selectedStatuses, setSelectedStatuses] = useState(initialSelectedStatuses);
  const [selectedVaults, setSelectedVaults] = useState();
  const [loadMoreVisible, setLoadMoreVisible] = useState(false);
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [openConvertDrafts, setOpenConvertDrafts] = useState(false);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const convertDraftPermission = myProfile.premmisions?.includes('TX_DRAFT_CONVERT');
  const createDraftPermission = myProfile.premmisions?.includes('TX_DRAFT_CREATE');
  const createTrxReqPermission = myProfile.premmisions?.includes('TRANSACTION_REQUESTS_CREATE');
  const handleOpen = useCallback(() => setIsFilterDrawerOpen(true), []);
  const handleClose = useCallback(() => setIsFilterDrawerOpen(false), []);

  const handleSelectTransaction = (transactionId, isSelected) => {
    setSelectedTransactions((prev) => {
      if (isSelected) {
        return selectedTransactions?.filter((trxId) => trxId !== transactionId);
      } else {
        return prev?.length > 0 ? [...new Set([...prev, transactionId])] : [transactionId];
      }
    });
  };

  const protocolsWithIcons = useMemo(
    () =>
      unique(protocols, 'blockchain').map((protocol) => ({
        ...protocol,
        icon: getCryptoIconFromBucket(protocol?.currency?.toLowerCase()),
      })),
    [protocols],
  );

  const handleVaultFilterChange = (vaults) => {
    setSelectedVaults(vaults);
  };

  const handleProtocolsFilterChange = (selectedProtocols) => {
    setSelectedProtocols(unique(selectedProtocols, 'blockchain'));
  };

  const handleStatusesFilterChange = (selectedStatuses) => {
    setSelectedStatuses(selectedStatuses);
  };

  const handleConvertDrafts = () => {
    convertTrxDraftApi({ item: { draftIds: selectedTransactions } }).then(() => {
      setOpenConvertDrafts(false);
      getTRData();
    });
  };

  const handleFiltersApiParams = useMemo(() => {
    return {
      ...(!selectedProtocols || selectedProtocols?.length === unique(protocols, 'blockchain')?.length
        ? {}
        : {
            blockchains: selectedProtocols.map((protocol) => {
              return protocol.blockchain;
            }),
          }),
      ...(!selectedVaults || selectedVaults?.length === wallets?.length
        ? {}
        : {
            [isTrxShelf ? 'vaultIds' : 'wallets']: selectedVaults.map((wallet) => {
              return wallet.id;
            }),
          }),
      ...(!selectedStatuses || selectedStatuses?.length === transactionRequestStatuses?.length
        ? {}
        : {
            statuses: selectedStatuses.map((status) => {
              return status.value;
            }),
          }),
    };
  }, [selectedStatuses, selectedVaults, selectedProtocols, protocols, isTrxShelf]);

  const getTRData = (abortSignal) => {
    setSelectedTransactions([]);
    setLoadMoreVisible(false);
    setLoading(true);
    setDataTable(undefined);
    if (isTrxShelf) {
      getAllTrxDraftsApi(typeUser, {
        params: { limit: 15, ...handleFiltersApiParams },
        signal: abortSignal,
      })
        .then((res) => {
          setDataTable(res.data.items);
          if (res.data.items.length === 0) {
            setLoadMoreVisible(false);
            return;
          }
          if (res?.data?.skip + res?.data?.limit < res?.data?.total) {
            setLoadMoreVisible(true);
          }
        })
        .finally(() => setLoading(false));
    } else {
      getTrxRequestsApi({
        params: { limit: 15, networkType: typeUser?.toUpperCase(), ...handleFiltersApiParams },
        signal: abortSignal,
      })
        .then((res) => {
          setDataTable(res?.data?.items);
          if (res?.data?.hasMore !== undefined && JSON.parse(res?.data?.hasMore) === true) {
            setLoadMoreVisible(true);
          }
        })
        .finally(() => setLoading(false));
    }
  };

  const getDetailsData = (transaction, isDraft) => {
    setDetailsData(transaction);
    if (isDraft) {
      setOpenDetailsTrasactionDraftModal(true);
    } else {
      setOpenDetailsTrasactionModal(true);
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const loadMoreHandler = async () => {
    const lastRecord = dataTable[dataTable.length - 1];
    if (isTrxShelf) {
      getAllTrxDraftsApi(typeUser, {
        params: {
          limit: 15,
          skip: dataTable?.length,
          ...handleFiltersApiParams,
        },
      }).then((res) => {
        setDataTable((prevState) =>
          res?.data?.items?.length > 0 ? [...prevState, ...res?.data?.items] : [...prevState],
        );

        if (res?.data?.skip + res?.data?.limit >= res?.data?.total) {
          setLoadMoreVisible(false);
        }
      });
    } else {
      getTrxRequestsApi({
        params: {
          limit: 15,
          networkType: typeUser?.toUpperCase(),
          startingAfter: lastRecord.id,
          ...handleFiltersApiParams,
        },
      }).then((res) => {
        setDataTable((prevState) => [...prevState, ...res?.data?.items]);
        if (res.data.hasMore === 'false') {
          setLoadMoreVisible(false);
        }
      });
    }
  };

  const successCallback = async () => {
    const res = await getWalletsApi({ params: { type: typeUser?.toUpperCase(), limit: 0 } });
    setSelectedVaults(res.data.items);
    setWallets(res.data.items);
    setOpenCreateTrasactionModal(false);
    setSelectedStatuses(initialSelectedStatuses);
    setSelectedProtocols(unique(protocols, 'blockchain'));
    getTRData();
  };

  useEffect(() => {
    if (!typeUser || isTabViewPermissionMissing) return;
    setSelectedStatuses(initialSelectedStatuses);
    getWalletsApi({ params: { type: typeUser?.toUpperCase(), limit: 0 } }).then((res) => {
      setSelectedVaults(res.data.items);
      setWallets(res.data.items);
    });
  }, [typeUser, location?.pathname, isTrxShelf]);

  useEffect(() => {
    if (typeof messageSocket === 'object') {
      if (
        messageSocket?.data?.event === 'transaction_request_rejected' ||
        messageSocket?.data?.event === 'transaction_request_approved'
      ) {
        if (!isTabViewPermissionMissing) {
          getTRData();
        }
      }
    }
  }, [messageSocket]);

  useEffect(() => {
    const controller = new AbortController();
    if (
      (typeUser && selectedProtocols?.length === 0) ||
      selectedVaults?.length === 0 ||
      selectedStatuses?.length === 0
    ) {
      setDataTable(undefined);
      setLoadMoreVisible(false);
      return;
    }
    if (
      typeUser &&
      selectedVaults &&
      selectedProtocols &&
      selectedStatuses &&
      protocols &&
      !isTabViewPermissionMissing
    ) {
      getTRData(controller?.signal);
    }
    return () => {
      if (
        controller &&
        typeUser &&
        selectedVaults &&
        selectedProtocols &&
        selectedStatuses &&
        protocols &&
        !isTabViewPermissionMissing
      ) {
        controller.abort();
      }
    };
  }, [
    typeUser,
    selectedVaults,
    selectedProtocols,
    selectedStatuses,
    protocols,
    isTrxShelf,
    isTabViewPermissionMissing,
  ]);

  useEffect(() => {
    if (!selectedProtocols && protocols?.length > 0 && !isTabViewPermissionMissing) {
      setSelectedProtocols(unique(protocols, 'blockchain'));
    }
  }, [protocols, location?.pathname, isTrxShelf, isTabViewPermissionMissing]);

  if (isTabViewPermissionMissing) {
    return (
      <div className='table governance-layer-table'>
        <div className='table-row no-active no-prem'>
          <T>app.role.limitation</T>
        </div>
      </div>
    );
  }

  return (
    <div className='transaction-requests-page team-page'>
      <div className='trx-req-button-filters-wrapper'>
        <Drawer anchor='bottom' open={isFilterDrawerOpen} onClose={handleClose}>
          <div className='trx-req-filters-drawer'>
            <span className='title'>
              <T>app.filters</T>
            </span>
            <Filter
              items={wallets}
              placeholder='app.filters.vaults'
              selectAllField
              displayIcon
              multiSelect
              onChange={handleVaultFilterChange}
              selectedItems={selectedVaults || []}
            />
            <Filter
              items={protocolsWithIcons}
              placeholder='app.filters.blockchains'
              selectAllField
              displayIcon
              roundedIcon
              customIconKey='icon'
              multiSelect
              onChange={handleProtocolsFilterChange}
              selectedItems={selectedProtocols || []}
              dataKey='blockchain'
              dataNameKey='publicName'
            />
            <Filter
              items={isTrxShelf ? transactionDraftsStatuses : transactionRequestStatuses}
              placeholder='app.filters.status'
              selectAllField
              displayIcon
              roundedIcon
              customIconKey='icon'
              multiSelect
              onChange={handleStatusesFilterChange}
              selectedItems={selectedStatuses || []}
              dataKey='value'
              dataNameKey='name'
            />
            <Button variant='dark' fullWidth onClick={handleClose}>
              <T>app.close</T>
            </Button>
          </div>
        </Drawer>
        <div className='trx-req-filters'>
          <Filter
            items={wallets}
            placeholder='app.filters.vaults'
            selectAllField
            displayIcon
            multiSelect
            onChange={handleVaultFilterChange}
            selectedItems={selectedVaults || []}
          />
          <Filter
            items={protocolsWithIcons}
            placeholder='app.filters.blockchains'
            selectAllField
            displayIcon
            roundedIcon
            customIconKey='icon'
            multiSelect
            onChange={handleProtocolsFilterChange}
            selectedItems={selectedProtocols || []}
            dataKey='blockchain'
            dataNameKey='publicName'
          />
          <Filter
            items={isTrxShelf ? transactionDraftsStatuses : transactionRequestStatuses}
            placeholder='app.filters.status'
            selectAllField
            displayIcon
            roundedIcon
            customIconKey='icon'
            multiSelect
            onChange={handleStatusesFilterChange}
            selectedItems={selectedStatuses || []}
            dataKey='value'
            dataNameKey='name'
          />
        </div>
        {myProfile?.premmisions && (
          <>
            {isTrxShelf ? (
              <div className='trx-requests-large-screen-draft-button'>
                <div className='button-holder'>
                  <Button
                    size='md'
                    onClick={() => setOpenCreateTrasactionModal(true)}
                    disabled={!createDraftPermission}
                    data-tooltip-id='create-draft-button'
                    data-tooltip-content={t(lng, 'app.role.limitation')}
                  >
                    {!createDraftPermission && <VTooltip id='create-draft-button' />}
                    <DocumentIcon style={{ width: '18px', height: '18px', marginRight: '3px' }} />
                    <T>app.create.draft</T>
                  </Button>
                </div>
              </div>
            ) : (
              <div className='trx-requests-large-screen-trx-button'>
                <div className='button-holder'>
                  <Button
                    size='md'
                    onClick={() => setOpenCreateTrasactionModal(true)}
                    disabled={!createTrxReqPermission}
                    data-tooltip-id='create-trx-button'
                    data-tooltip-content={t(lng, 'app.role.limitation')}
                  >
                    {!createTrxReqPermission && <VTooltip id='create-trx-button' />}
                    <i className='icon-transaction' />
                    <T>app.submit.a.transaction.request</T>
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <div className='filters-button-mobile'>
        <Button variant='dark' fullWidth onClick={handleOpen}>
          <T>app.filters</T>
        </Button>
      </div>
      {myProfile?.premmisions && !isTrxShelf && (
        <div className='trx-requests-small-screen-trx-button'>
          <div className='button-holder'>
            <Button
              size='md'
              onClick={() => setOpenCreateTrasactionModal(true)}
              disabled={!createTrxReqPermission}
              data-tooltip-id='create-trx-button'
              data-tooltip-content={t(lng, 'app.role.limitation')}
            >
              {!createTrxReqPermission && <VTooltip id='create-trx-button' />}
              <i className='icon-transaction' />
              <T>app.submit.a.transaction.request</T>
            </Button>
          </div>
        </div>
      )}
      {isTrxShelf && (
        <div className='draft-options-filters-wrapper'>
          <div className='transaction-requests-page-draft-buttons-wrapper'>
            <div className='transaction-requests-page-draft-selected selected-drafts'>
              <CheckboxNew
                id='selectedCheckbox'
                reverse
                multiSelect
                value={
                  dataTable?.filter((draft) => draft?.status === 'created')?.length > 0 &&
                  dataTable?.filter((draft) => draft?.status === 'created')?.length ===
                    selectedTransactions?.length
                }
                disabledHoverEffect
                onChange={() => {
                  const selectableDrafts = dataTable?.filter((draft) => draft?.status === 'created');
                  if (selectableDrafts?.length > 0) {
                    if (selectableDrafts?.length === selectedTransactions?.length) {
                      setSelectedTransactions([]);
                    } else {
                      setSelectedTransactions(
                        dataTable
                          ?.filter(
                            (draft) => !draft?.failReason && draft?.status?.toLowerCase() === 'created',
                          )
                          ?.map((trx) => trx?.id),
                      );
                    }
                  }
                }}
                label={
                  <label
                    className='transaction-requests-page-draft-select-all-button-label'
                    htmlFor='termsCheckbox'
                  >
                    <T>app.select.all</T>
                  </label>
                }
              />
              <div className='button-holder submit-drafts-mobile'>
                <Button
                  size='md'
                  onClick={() => setOpenConvertDrafts(true)}
                  disabled={!selectedTransactions?.length || !convertDraftPermission}
                  data-tooltip-id='convert-draft-button'
                  data-tooltip-content={t(lng, 'app.role.limitation')}
                >
                  {!convertDraftPermission && <VTooltip id='convert-draft-button' />}
                  <i className='icon-submit-for-approval' />
                  <T>app.submit.drafts</T>
                </Button>
              </div>
              {/* <div
                className='transaction-requests-page-draft-csv-button'
                onClick={() => setOpenUploadCsvModal(true)}
              >
                <img src={documentIcon} className='draft-image' />
                <div className='capitalize'>
                  <T>app.upload.csv</T>
                </div>
              </div> */}
            </div>
            <div className='transaction-requests-page-draft-selected'>
              <span className='transaction-requests-page-draft-selected-count'>
                <span>{selectedTransactions?.length}</span>
                <T>app.selected</T>
              </span>
              <div className='button-holder submit-drafts'>
                <Button
                  size='md'
                  onClick={() => setOpenConvertDrafts(true)}
                  disabled={!selectedTransactions?.length || !convertDraftPermission}
                  data-tooltip-id='convert-draft-button'
                  data-tooltip-content={t(lng, 'app.role.limitation')}
                >
                  {!convertDraftPermission && <VTooltip id='convert-draft-button' />}
                  <i className='icon-submit-for-approval' />
                  <T>app.submit.drafts</T>
                </Button>
              </div>
              <div className='trx-requests-small-screen-draft-button'>
                <div className='button-holder'>
                  <Button
                    size='md'
                    onClick={() => setOpenCreateTrasactionModal(true)}
                    disabled={!createDraftPermission}
                    data-tooltip-id='create-draft-button'
                    data-tooltip-content={t(lng, 'app.role.limitation')}
                  >
                    {!createDraftPermission && <VTooltip id='create-draft-button' />}
                    <DocumentIcon style={{ width: '18px', height: '18px', marginRight: '3px' }} />
                    <T>app.create.draft</T>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {loading ? (
        <div className='spinner-history'>
          <div className='loading'>
            <ReactLoading type='spin' color='##020D1C' />
          </div>
        </div>
      ) : (
        <div
          className={`trx-requests-table-wrapper ${isTrxShelf ? 'drafts' : ''} ${
            trialBannerVisible ? 'trial-banner-visible' : ''
          }`}
        >
          <TransactionRequestsТable
            draftDeleteButtonVisible={!selectedTransactions?.length}
            isShelf={isTrxShelf}
            data={dataTable}
            handleSelectTransaction={handleSelectTransaction}
            selectedTransactions={selectedTransactions}
            detailsHandler={getDetailsData}
            transactionKey={isTrxShelf ? 'app.no.transations.drafts' : 'app.no.transactions.req.data'}
            loadMoreHandler={loadMoreHandler}
            loadMore={loadMoreVisible}
            isTransactionRequests
            successCallback={successCallback}
          />
        </div>
      )}
      {openCreateTrasactionModal && (
        <CreateTransactionRequestNew
          isDraft={isTrxShelf}
          successCallback={successCallback}
          toggleFunction={setOpenCreateTrasactionModal}
        />
      )}
      {openDetailsTrasactionDraftModal && (
        <DetailsTransactionDraft
          data={detailsData}
          toggleFunction={() => {
            setOpenDetailsTrasactionDraftModal(false);
            getTRData();
          }}
        />
      )}
      {openDetailsTrasactionModal && (
        <DetailsTransactionRequest
          toggleFunction={setOpenDetailsTrasactionModal}
          data={detailsData}
          isTransactionRequest
        />
      )}
      {openUploadCsvModal && (
        <TransactionsCsvUploadModal closeCallback={setOpenUploadCsvModal} successCallback={() => {}} />
      )}
      {openConvertDrafts && (
        <BasicModal
          closeCallback={() => setOpenConvertDrafts(false)}
          submitCallback={handleConvertDrafts}
          icon={<i className='icon-transaction' />}
          title={t(lng, 'app.are.you.sure.convert.drafts')}
          submitButtonText={t(lng, 'app.yes.convert.drafts')}
        >
          <span className='convert-drafts-submit-button'>{`${selectedTransactions?.length} ${
            selectedTransactions?.length > 1
              ? t(lng, 'app.drafts')?.toLowerCase()
              : t(lng, 'app.draft')?.toLowerCase()
          } ${t(lng, 'app.selected')?.toLowerCase()}`}</span>
          <Alert variant='warning' text={t(lng, 'app.submit.convert.alert')} />
        </BasicModal>
      )}
    </div>
  );
};
export default TransactionRequests;
