import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../../../assets/images/logo.svg';
import DropDownMenu from '../../DropDownMenu/DropDownMenu';
import SwitchButton from '../../SwitchButton/SwitchButton';
import { T, t } from '../../T';
import DropDownHelpMenu from '../../DropDownMenu/DropDownHelpMenu';
import { useDispatch, useSelector } from 'react-redux';
import { clientStatuses, setTypeUser } from '../../../redux/slices/userSlice';
import { ROUTE_DEVELOPERS, ROUTE_HOME, ROUTE_WALLET, ROUTE_WEBHOOK } from '../../../routes/routes';
import VTooltip from '../../VTooltip/VTooltip';
// Header component
const Header = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const typeUser = useSelector((state) => state.userReducer.typeUser);
  const myProfile = useSelector((state) => state.userReducer.myProfile);
  const userVerificationInfo = useSelector((state) => state.userReducer.verificationInfo);
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const [isMaintanaceBarVisible, setIsMaintanaceBarVisible] = useState(true);

  const handleTestModeToggle = (switchState) => {
    if (
      userVerificationInfo?.state?.toUpperCase() === clientStatuses.ACTIVE ||
      userVerificationInfo?.state === null
    ) {
      const mode = switchState ? 'test' : 'main';
      dispatch(setTypeUser(mode));
      localStorage.setItem('mode', mode);
      if (location.pathname?.includes(`${ROUTE_WALLET}/`)) {
        navigate(ROUTE_HOME);
      }
      if (location.pathname?.includes(`${ROUTE_WEBHOOK}/`)) {
        navigate(`${ROUTE_DEVELOPERS}#api-keys`);
      }
    }
  };

  useEffect(() => {
    if (userVerificationInfo?.state !== undefined) {
      const typeUserFromLocalStorage =
        localStorage.getItem('mode') === 'test' ||
        (userVerificationInfo?.state !== null &&
          userVerificationInfo?.state?.toUpperCase() !== clientStatuses.ACTIVE)
          ? 'test'
          : 'main';
      dispatch(setTypeUser(typeUserFromLocalStorage));
    }
  }, [userVerificationInfo?.state]);

  return (
    <div className={`header ${typeUser}`}>
      <div className='logo'>
        <img src={logo} onClick={() => navigate(ROUTE_HOME)} />
      </div>
      <div className='logo-small'>
        <img src={'/logo192.png'} onClick={() => navigate(ROUTE_HOME)} />
      </div>
      <div className='header-menu'>
        <Link to={ROUTE_DEVELOPERS} className='clear-link'>
          <div className={`developers ${location.pathname.includes('developers') ? 'active' : ''} `}>
            <i className='icon-developers' />
            <span>
              <T>app.developers</T>
            </span>
          </div>
        </Link>
        <div className='testmode'>
          <SwitchButton
            label='Test mode'
            switchFunction={handleTestModeToggle}
            switchState={typeUser === 'test'}
          />
        </div>
        {typeUser === 'test' && (
          <div className='testdata'>
            <T>app.test.data</T>
          </div>
        )}
        <div
          className={`maintanance-bar-wrapper  ${
            t(lng, 'app.notification.bar.message') &&
            t(lng, 'app.notification.bar.message') !== 'app.notification.bar.message' &&
            isMaintanaceBarVisible
              ? ''
              : 'hide'
          }`}
        >
          <div
            className='maintanance-bar'
            data-tooltip-id='maintanance-bar'
            data-tooltip-content={t(lng, 'app.notification.bar.message')}
          >
            <span>
              {t(lng, 'app.notification.bar.message') &&
                t(lng, 'app.notification.bar.message') !== 'app.notification.bar.message' &&
                t(lng, 'app.notification.bar.message')}
            </span>
          </div>
          <VTooltip id='maintanance-bar' />
          <div className='icon icon-close' onClick={() => setIsMaintanaceBarVisible(false)} />
        </div>
        {/* Tempory logout */}
        <div className='end-menus'>
          <div className='logout help'>
            <DropDownHelpMenu />
          </div>
          <div className='logout'>
            <DropDownMenu data={myProfile} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
