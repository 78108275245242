import { useEffect, useState } from 'react';
import VModal from '../Modal/VModal';
import { T, t } from '../T';
import ActionModal from './ActionModal';
import { changeProfileEmailApi } from '../../api/endpoints';
import Textfield from '../Textfield/Textfield';
import { useSelector } from 'react-redux';
import Button from '../Button/Button';
import { emailValidator } from '../../helpers/validation';

const ChangeEmail = ({ toggleFunction }) => {
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const [data, setData] = useState({
    email: '',
  });
  const [emailError, setEmailError] = useState({
    status: false,
    message: '',
  });
  const [sussess, setSussess] = useState(false);
  const changeEmail = () => {
    changeProfileEmailApi({ item: data }).then(() => setSussess(true));
  };

  useEffect(() => {
    if (!data?.email) {
      setEmailError({
        status: false,
        message: '',
      });
    } else {
      const emailValidate = emailValidator(data?.email);
      setEmailError(emailValidate);
    }
  }, [data?.email]);

  return (
    <>
      {!sussess && (
        <VModal toggleFunction={toggleFunction} classHandler='change-email'>
          <div className='v-modal-title'>Change Email</div>
          <div className={`v-input-group `}>
            <label>Email</label>
            <Textfield
              trim
              type='text'
              name='email'
              size='lg'
              fullWidth
              error={emailError?.message}
              placeholder={t(lng, 'app.enter.new.email')}
              value={data?.email}
              onChange={(e) => setData({ email: e.currentTarget.value })}
            />
          </div>

          <div className='button-holder'>
            <Button size='md' fullWidth disabled={!emailError.status} onClick={() => changeEmail()}>
              <i className='icon-submit-for-approval' />
              <T>app.submit.for.approval</T>
            </Button>
          </div>
        </VModal>
      )}
      {sussess && (
        <ActionModal toggleFunction={toggleFunction}>
          <T>app.change.email.approval</T>
        </ActionModal>
      )}
    </>
  );
};

export default ChangeEmail;
